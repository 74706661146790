import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConsultarFichaExistenteDto, CriarFichaDto, Ficha } from '../models/ficha.model';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CondutaBase, CriarCondutaDto } from '../models/conduta.model';
import { Censura } from '../models/censura.model';
import { AuthService } from './auth.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { FilterAvancado } from '../interfaces/filter-config';
import { Clinica } from '../models/clinica.model';
import { IMenuModuloConfig, menuModulosConfig } from '../constants/modulos-cancer';
import { ModuloService } from './modulo.service';

const ONCOMAX_FICHA_ADVANCED_FILTERS = '_ficha_advanced_filters';
const ONCOMAX_FICHA_ADVANCED_FILTERS_FORM_VALUES = '_ficha_advanced_filters_form_values';

@Injectable({
  providedIn: 'root'
})
export class FichaService extends AbstractService<Ficha> {

  $filtrosAvancados: Subject<FilterAvancado[]> = new Subject<FilterAvancado[]>();
  $neoplasiasPaciente: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  moduloAtual: IMenuModuloConfig;

  constructor(public http: HttpClient,
              private authService: AuthService,
              private moduloService: ModuloService) {
    super('ficha', http);
    moduloService.$moduloAtual.subscribe(modulo => {
      this.moduloAtual = modulo;
    });
  }

  criar(fichaDto: CriarFichaDto|any) {
    return super.post(fichaDto,'criar');
  }

  excluir(ficha: Ficha) {
    return this.http.delete<any>(`${environment.apiUrl}/ficha/${ficha._id}`);
  }

  atualizarItemFicha(items: any, ficha: Ficha): Observable<Ficha> {
    return this.http.patch<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/items`, items);
  }

  removerItensficha(ficha: Ficha, ids): Observable<Ficha> {
    return this.http.post<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/items/excluir`, { ids });
  }

  atualizarAnotacoesFicha(anotacoes: string, ficha: Ficha) {
    return this.http.patch<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/anotacoes`, {
      valor: anotacoes
    });
  }

  salvarConduta(ficha: Ficha, conduta: CriarCondutaDto): Observable<Ficha> {
    return this.http.post<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/conduta`, conduta);
  }

  censurarFicha(ficha: Ficha, censura: Censura): Observable<Ficha> {
    return this.http.post<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/censura`, censura);
  }

  atualizarFicha(fichaDto: CriarFichaDto, ficha: Ficha): Observable<Ficha> {
    return this.http.put<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/atualizar`, fichaDto);
  }

  atualizarDataValidade(ficha: Ficha, dataValidade) {
    return this.http.post<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/atualizarDataValidade`, { dataValidade });
  }

  consultarFichaExistente(dto: ConsultarFichaExistenteDto) {
    return this.http.post<Ficha>(`${environment.apiUrl}/ficha/consultar-ficha-existente`, dto);
  }

  excluirConduta(ficha: Ficha, conduta: CondutaBase<any>) {
    return this.http.delete<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/conduta/${conduta.id}`);
  }

  excluirCensura(ficha: Ficha) {
    return this.http.delete<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/censura`);
  }

  getFichaCountPorModulo() {
    return this.http.get<Ficha>(`${environment.apiUrl}/ficha/modulos-ficha-count`) as any;
  }

  getListaFichas(params, filtro?: FilterAvancado[]) {
    const url = `${environment.apiUrl}/ficha`;
    const body: any = {};
    if (filtro) body.filtro = filtro;
    return this.http.post<Ficha>(url, body, { params }) as any;
  }

  exportarImagem(ficha: Ficha, width: number, height: number, modulo?: string) {
    const options: any = {
      responseType: 'blob',
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    return this.http.post<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/exportar?width=${width}&height=${height}${modulo ? `&modulo=${modulo}` : ''}`,
      {}, options);
  }

  exportarImagemAdmin(ficha: Ficha, idMedico: string, width: number, height: number) {
    const options: any = {
      responseType: 'blob',
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    return this.http.post<Ficha>(`${environment.apiUrl}/ficha/admin/${ficha._id}/${idMedico}/exportar?width=${width}&height=${height}`,
      {}, options);
  }

  inserirMarcacaoFicha(ficha: Ficha, marcacao: any) {
    return this.http.put<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/inserir-marcacao`, { marcacao });
  }

  transferirModuloFicha(ficha: Ficha, modulo: any) {
    return this.http.put<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/transferir-modulo`, { modulo });
  }

  preencherFichaAutomaticamente(ficha: Ficha) {
    return this.http.put<Ficha>(`${environment.apiUrl}/ficha/${ficha._id}/preenchimento-automatico`, { itens: ficha.itens, condutas: ficha.condutas });
  }

  buscarTodasNeoplasiasPaciente(idFicha: string) {
    return this.http.get<Ficha>(`${environment.apiUrl}/ficha/${idFicha}/buscar-todas-neoplasias-paciente`) as any;
  }

  exportarHistoriaFicha(ficha, width, height, modulo) {
    const token = this.authService.getToken();
    const url = `${environment.apiUrl}/ficha/${ficha._id}/exportar-historia-ficha?width=${width}&height=${height}${modulo ? `&modulo=${modulo}` : ''}`;
    const fetchPromise = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/zip",
        "Authorization": `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .catch(error => {
        throw error;
      });
    return fromPromise(fetchPromise);
  }

  setFiltrosAvancados(modulo: string, filtros: FilterAvancado[], propagate: boolean = true) {
    localStorage.setItem(`${ONCOMAX_FICHA_ADVANCED_FILTERS}_${modulo}`, JSON.stringify(filtros));
    if (propagate) this.$filtrosAvancados.next(filtros);
  }

  getFiltrosAvancados(modulo: string) {
    const filtros = localStorage.getItem(`${ONCOMAX_FICHA_ADVANCED_FILTERS}_${modulo}`);
    if (filtros) return JSON.parse(filtros);
  }

  clearFiltrosAvancados(modulo: string) {
    localStorage.removeItem(`${ONCOMAX_FICHA_ADVANCED_FILTERS}_${modulo}`);
    this.$filtrosAvancados.next(null);
  }

  setFiltrosAvancadosFormValues(modulo: string, formRawValues: any) {
    localStorage.setItem(`${ONCOMAX_FICHA_ADVANCED_FILTERS_FORM_VALUES}_${modulo}`, JSON.stringify(formRawValues));
  }

  getFiltrosAvancadosFormValues(modulo: string) {
    const formValues = localStorage.getItem(`${ONCOMAX_FICHA_ADVANCED_FILTERS_FORM_VALUES}_${modulo}`);
    if (formValues) return JSON.parse(formValues);
  }

  clearFiltrosAvancadosFormValues(modulo: string) {
    localStorage.removeItem(`${ONCOMAX_FICHA_ADVANCED_FILTERS_FORM_VALUES}_${modulo}`);
  }

  setInstituicaoAtual(clinica: Clinica) {
    const nomesModulos = [];
    const filtroClinica = {
      tipo: 'ficha',
      detalhe: {
        chave: 'clinica',
        valores: [clinica._id]
      }
    };

    const adicionarNomesModulos = (modulos: IMenuModuloConfig[]) => {
      for (let modulo of modulos) {
        if (modulo.modules) adicionarNomesModulos(modulo.modules);
        else if (modulo.available && !modulo.building) nomesModulos.push(modulo.name);
      }
    };

    adicionarNomesModulos(menuModulosConfig);

    nomesModulos.forEach((nomeModulo) => {
      let formValues = (this.moduloAtual?.name && this.getFiltrosAvancadosFormValues(this.moduloAtual.name)) || {};
      const filtrosAvancados = (this.moduloAtual?.name &&  this.getFiltrosAvancados(this.moduloAtual.name)) || [];
      const filtroIndex = filtrosAvancados.findIndex(filtro => filtro.detalhe.chave === 'clinica');

      if (filtroIndex >= 0) filtrosAvancados.splice(filtroIndex, 1, filtroClinica);
      else filtrosAvancados.push(filtroClinica);

      if (formValues.clinica) formValues.clinica = [clinica.nome];
      else formValues = { clinica: [clinica.nome] };

      const propagate = this.moduloAtual?.name === nomeModulo;
      this.setFiltrosAvancadosFormValues(nomeModulo, formValues);
      this.setFiltrosAvancados(nomeModulo, filtrosAvancados, propagate);
    });
  }

  setNeoplasiasPaciente(neoplasias: any[]) {
    this.$neoplasiasPaciente.next(neoplasias);
  }
}

