import { Component, Injector, OnInit } from '@angular/core';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { FormControl, FormGroup } from '@angular/forms';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/prostata/util';
import { RangeOption } from '../../../../components/custom-input/custom-range-input/custom-range-input.component';

export const GLEASON_CHAVE = 'gleason';
export const GLEASON_OPTIONS: RangeOption[] = [
  {
    label: '1',
    value: '≤ 3+3',
  },
  {
    label: '2',
    value: '3+4',
  },
  {
    label: '3',
    value: '4+3',
  },
  {
    label: '4',
    value: '4+4, 3+5, 5+3',
  },
  {
    label: '5',
    value: '4+5, 5+4, 5+5',
  }
];

@Component({
  selector: 'app-input-gleason',
  templateUrl: './input-gleason.component.html',
})
export class InputGleasonComponent extends AbstractModal implements OnInit {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '500px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  $digestingDesconhecido: boolean = false;
  form: FormGroup = new FormGroup({
    gleason: new FormControl('≤ 3+3'),
  });
  desconhecidoForm: FormGroup = new FormGroup({
    gleason: new FormControl(true),
  });
  gleasonRangeOptions: RangeConfig = {
    showValueOnLeft: true,
    options: GLEASON_OPTIONS
  };

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { gleason, id } = inputValueResolver(GLEASON_CHAVE, this.ficha?.itens);
    this.id = id;
    if (gleason !== undefined) {
      this.form.patchValue({
        gleason: gleason ? gleason : '≤ 3+3'
      });
    }
    this.desconhecidoForm.patchValue({ gleason : gleason === null });
    this.registerFormListeners();
  }

  registerFormListeners() {
    const gleasonFormControl = this.form.get(GLEASON_CHAVE);
    const gleasonDesconhecidoFormControl = this.desconhecidoForm.get(GLEASON_CHAVE);
    gleasonDesconhecidoFormControl.valueChanges.subscribe((value) => {
      this.$digestingDesconhecido = true;
      if (value) {
        this.form.controls.gleason.setValue('≤ 3+3');
      }
    });
    gleasonFormControl.valueChanges.subscribe(() => {
      if (this.$digestingDesconhecido) {
        this.$digestingDesconhecido = false;
        return;
      }
      gleasonDesconhecidoFormControl.setValue(false, { emitEvent : false });
    });
  }

  async handleEndEditClick() {
    const newItem: ItemFicha = {
      chave: GLEASON_CHAVE,
      valor: !this.desconhecidoForm.get(GLEASON_CHAVE).value ? this.form.get(GLEASON_CHAVE).value : null
    };
    if (this.id) newItem.id = this.id;
    await this.modalController.dismiss([newItem]);
  }
}
