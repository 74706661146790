import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';
import { optionsResolver } from './util';

@Component({
  selector: 'app-input-m',
  templateUrl: './input-m.component.html',
})
export class InputMComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '460px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    m: new FormControl('Mx')
  });

  rangeConfig: RangeConfig = optionsResolver();

  constructor(public override injector: Injector,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }

  ngOnInit() {
    const { m, id } = inputValueResolver('m', this.ficha?.itens);
    this.id = id;
    if (![undefined, null].includes(m)) this.form.patchValue({ m });
  }

  async handleResetValuesClick() {
    const alert = await this.alertHelper.create({
      title: 'Redefinir valores',
      text: 'Tem certeza que deseja zerar os valores para este estadiamento?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          const items: ItemFicha[] = [];
          if (this.id && this.form.get('m').value) items.push({ chave: 'm', valor: null, id: this.id });
          setTimeout(async () => {
            await this.modalController.dismiss(items);
          }, 100);
        }
      }
    });
    await alert.present();
  }

  async handleEndEditClick() {
    const newItem: ItemFicha = {
      chave: 'm',
      valor: this.form.get('m').value
    };
    if (this.id) newItem.id = this.id;
    const items: ItemFicha[] = [newItem];
    await this.modalController.dismiss(items);
  }
}
