import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SelectionOption } from '../components/_base/abstract-input';
import { Ficha } from '../models/ficha.model';

export type PassoAdicionarConduta =
  'selecao-tipo' |
  'subtipo-cirurgia' |
  'subtipo-radioterapia' |
  'subtipo-quimioterapia' |
  'drogas' |
  'calendario' |
  'calendario-fim';

export const passoCalendario: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'calendario',
  altura: 430
};

export const passoDrogas: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'drogas',
  altura: 140
};

@Injectable({
  providedIn: 'root'
})
export class CondutaColorretalRetoService {

  static condutasDePeriodo = [

  ];

  static tiposConduta: SelectionOption[] = [
    {
      nome: 'Cirurgia',
      valor: 'Cirurgia'
    },
    {
      nome: 'Radioterapia',
      valor: 'Radioterapia'
    },
    {
      nome: 'Quimioterapia',
      valor: 'Quimioterapia'
    },
    {
      nome: 'Seguimento',
      valor: 'Seguimento'
    },
  ];

  static subtiposCirurgia: SelectionOption[] = [
    {
      nome: 'Excisão transanal',
      valor: 'Excisão transanal'
    },
    {
      nome: 'Ressecção transabdominal',
      valor: 'Ressecção transabdominal'
    },
    {
      nome: 'Excisão total de mesorreto',
      valor: 'Excisão total de mesorreto'
    },
    {
      nome: 'Excisão parcial do mesorreto',
      valor: 'Excisão parcial do mesorreto'
    },
    {
      nome: 'Proctectomia abdominoperineal',
      valor: 'Proctectomia abdominoperineal'
    },
    {
      nome: 'Ressecção anterior baixa',
      valor: 'Ressecção anterior baixa'
    },
    {
      nome: 'Metastasectomia hepática ou pulmonar',
      valor: 'Metastasectomia hepática ou pulmonar'
    },
    {
      nome: 'Retossigmoidectomia',
      valor: 'Retossigmoidectomia'
    },
  ];

  static subtiposRadioterapia: SelectionOption[] = [
    {
      nome: 'Radioterapia isolada short course',
      valor: 'Radioterapia isolada short course'
    },
    {
      nome: 'Radioterapia isolada long course',
      valor: 'Radioterapia isolada long course'
    },
    {
      nome: 'Radioterapia concomitante a QT',
      valor: 'Radioterapia concomitante a QT'
    },
    {
      nome: 'Radioterapia para metástases',
      valor: 'Radioterapia para metástases'
    },
  ];

  static subtiposQuimioterapia: SelectionOption[] = [
    {
      nome: 'Neoadjuvante',
      valor: 'Neoadjuvante'
    },
    {
      nome: 'Adjuvante',
      valor: 'Adjuvante'
    },
    {
      nome: 'Paliativa',
      valor: 'Paliativa'
    },
    {
      nome: 'Concomitante a radioterapia',
      valor: 'Concomitante a radioterapia'
    },
  ];

  static drogasTratamentoPaliativo = [
    'Fluorouracil',
    'Fluorouracil BIC 46h',
    'Oxaliplatina',
    'Irinotecano',
    'Leucovorin',
    'Bevacizumabe',
    'Ramucirumabe',
    'Capecitabina',
    'TAS102',
    'Regorafenibe',
    'Raltitrexede',
    'Aflibercept',
];

  static drogasTratamentoAdjuvante = [
    'Fluorouracil',
    'Fluorouracil BIC 46h',
    'Oxaliplatina',
    'Leucovorin',
    'Capecitabina',
    'Raltitrexede',
  ];

  static drogasTratamentoNeoAdjuvante = [
    'Fluorouracil',
    'Fluorouracil BIC 46h',
    'Oxaliplatina',
    'Irinotecano',
    'Leucovorin',
    'Capecitabina',
    'Raltitrexede',
  ];

  static passosCondutaMapping = {
    'Cirurgia' : {
      proximoPasso: 'subtipo-cirurgia',
      altura: 510
    },
    'Cirurgia Excisão transanal': passoCalendario,
    'Cirurgia Ressecção transabdominal': passoCalendario,
    'Cirurgia Excisão total de mesorreto': passoCalendario,
    'Cirurgia Excisão parcial do mesorreto': passoCalendario,
    'Cirurgia Proctectomia abdominoperineal': passoCalendario,
    'Cirurgia Ressecção anterior baixa': passoCalendario,
    'Cirurgia Metastasectomia hepática ou pulmonar': passoCalendario,
    'Cirurgia Retossigmoidectomia': passoCalendario,
    'Radioterapia' : {
      proximoPasso: 'subtipo-radioterapia',
      altura: 300
    },
    'Radioterapia Radioterapia isolada short course': passoCalendario,
    'Radioterapia Radioterapia isolada long course': passoCalendario,
    'Radioterapia Radioterapia concomitante a QT': passoDrogas,
    'Radioterapia Radioterapia para metástases': passoCalendario,
    'Quimioterapia': {
      proximoPasso: 'subtipo-quimioterapia',
      altura: 300
    },
    'Quimioterapia Neoadjuvante': passoDrogas,
    'Quimioterapia Adjuvante': passoDrogas,
    'Quimioterapia Paliativa': passoDrogas,
    'Quimioterapia Concomitante a radioterapia': passoDrogas,
    'Seguimento': passoCalendario,
  };

  getTiposConduta(): Observable<SelectionOption[]> {
    return of(CondutaColorretalRetoService.tiposConduta);
  }

  getSubtiposCirurgia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalRetoService.subtiposCirurgia);
  }

  getSubtiposRadioterapia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalRetoService.subtiposRadioterapia);
  }

  getSubtiposQuimioterapia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalRetoService.subtiposQuimioterapia);
  }

  getListaDeDrogasTratamentoPaliativo(ficha: Ficha, filtro: boolean) {
    const drogasTratamentoPaliativo = CondutaColorretalRetoService.drogasTratamentoPaliativo;
    const item_KRAS = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.KRAS');
    const item_NRAS = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.NRAS');
    const item_HER2 = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.HER-2');
    const item_MSI_H_dMMR = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.MSI–H ou dMMR');
    const item_BRAF_V600E = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.BRAF V600E');
    const item_NTRK = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.NTRK');
    const item_KRAS_G12c = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.KRAS G12c');

    let drogasExtras = [];

    if (item_KRAS?.valor || item_NRAS?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Cetuximabe',
        'Panitumumabe',
      ]);
    }

    if (item_HER2?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Trastuzumabe',
        'Tucatinibe',
        'Pertuzumabe',
        'Lapatinibe',
      ]);
    }

    if (item_MSI_H_dMMR?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Pembrolizumabe',
        'Nivolumabe',
        'Ipilimumabe',
      ]);
    }

    if (item_BRAF_V600E?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Encorafenibe',
      ]);
    }

    if (item_NTRK?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Entrectinibe',
        'Larotrectinibe',
      ]);
    }

    if (item_KRAS_G12c?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Sotorasibe',
      ]);
    }

    return [].concat(drogasTratamentoPaliativo, drogasExtras);
  }

  getListaDeDrogasTratamentoNeoAdjuvante(ficha: Ficha, filtro: boolean) {
    const drogasTratamentoNeoAdjuvante = CondutaColorretalRetoService.drogasTratamentoNeoAdjuvante;
    const item_MSI_H_dMMR = ficha?.itens?.find((item) => item.chave === 'outrosTestes.MSI–H ou dMMR');
    let drogasExtras = [];

    if (item_MSI_H_dMMR?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Pembrolizumabe',
        'Dorstalimabe',
        'Nivolumabe',
      ]);
    }

    return [].concat(drogasTratamentoNeoAdjuvante, drogasExtras);
  }

  getListaDeDrogasRadioterapiaConcomitanteQT() {
    return CondutaColorretalRetoService.drogasTratamentoAdjuvante;
  }

  getDrogasTratamentoAdjuvante() {
    return CondutaColorretalRetoService.drogasTratamentoAdjuvante;
  }

  getCondutasDePeriodo(): string[] {
    return CondutaColorretalRetoService.condutasDePeriodo;
  }
}
