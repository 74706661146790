import { Component } from '@angular/core';
import { AbstractButton } from '../../_base/abstract-button';

@Component({
  selector: 'app-outlined-brand',
  templateUrl: './outlined-brand.component.html',
})
export class OutlinedBrandButtonComponent extends AbstractButton {

  constructor() {
    super();
  }
}
