import { Component } from '@angular/core';
import { AbstractButton } from '../../_base/abstract-button';

@Component({
  selector: 'app-outlined-primary-button',
  templateUrl: './outlined-primary.component.html',
})
export class OutlinedPrimaryButtonComponent extends AbstractButton {

  constructor() {
    super();
  }

}
