import { GrupoAnatomico } from './grupo-anatomico';
import { Ficha, ItemFicha } from '../../../../../../shared/models/ficha.model';
import { OUTROS_TESTES_THREE_WAY_TOGGLE_TEXT } from '../../../../../../shared/modals/modulo-mama/ficha-paciente/outros-testes/outros-testes.component';
import { AbstractLabelResolver, AbstractValueResolver } from '../../_base/abstract-resolver';

class TestesPreditivosSeveridadeResolver {

  static oncotype = (oncotype) => {
    if (!oncotype.length) {
      return;
    }
    if (oncotype >= 0 && oncotype <= 15) return 'Baixo risco';
    if (oncotype >= 16 && oncotype <= 25) return 'Intermediário risco';
    if (oncotype >= 26 && oncotype <= 100) return 'Alto risco';
  };

  static prosignaN0 = (prosignaN0) => {
    if (!prosignaN0.length) {
      return;
    }
    if (prosignaN0 >= 0 && prosignaN0 <= 40) return 'Baixo risco';
    if (prosignaN0 >= 41 && prosignaN0 <= 60) return 'Intermediário risco';
    if (prosignaN0 >= 61 && prosignaN0 <= 100) return 'Alto risco';
  };

  static prosignaN1 = (prosignaN1) => {
    if (!prosignaN1.length) {
      return;
    }
    if (prosignaN1 >= 0 && prosignaN1 <= 40) return 'Baixo risco';
    if (prosignaN1 >= 41 && prosignaN1 <= 100) return 'Alto risco';
  };

  static bci = (bci) => {
    if (!bci.length) {
      return;
    }
    if (bci >= 0 && bci <= 5) return 'Baixo risco';
    if (bci >= 5.1 && bci <= 10) return 'Alto risco';
  };

  static endopredict = (endopredict) => {
    if (!endopredict.length) {
      return;
    }
    if (endopredict >= 0 && endopredict <= 3.3) return 'Baixo risco';
    if (endopredict >= 3.4 && endopredict <= 10) return 'Alto risco';
  };
}

class LabelResolver extends AbstractLabelResolver {

  static histopatologico = (items: ItemFicha[]): string => {
    const itemArea = items?.find((item) => item.chave === 'histopatologico.area');
    if (itemArea?.valor) return `${itemArea.valor}`;
    else return '';
  };

  static tipoAtendimento = (items: ItemFicha[]): string => {
    const itemTipoAtendimento = items?.find((item) => item.chave === 'tipoAtendimento');
    if (itemTipoAtendimento?.valor) return `${itemTipoAtendimento?.valor}`;
    return '';
  };
  
  static sexo = (items: ItemFicha[]): string => {
    const itemSexo = items?.find((item) => item.chave === 'sexo');
    if (itemSexo?.valor) return `${itemSexo?.valor}`;
    return '';
  };
  
  static tipoTumoral = (items: ItemFicha[]): string => {
    const itemTipoTumoral = items?.find((item) => item.chave === 'tipoTumoral');
    if (itemTipoTumoral?.valor) return `${itemTipoTumoral?.valor}`;
    return '';
  };
  
  static grupoEstadiamento = (items: ItemFicha[]): string => {
    const itemGrupoEstadiamento = items?.find((item) => item.chave === 'grupoEstadiamento');
    if (itemGrupoEstadiamento?.valor) return `${itemGrupoEstadiamento?.valor}`;
    return '';
  };
  
  static grau = (items: ItemFicha[]): string => {
    const itemGrau = items?.find((item) => item.chave === 'grau');
    if (itemGrau?.valor) return `${itemGrau?.valor}`;
    return '';
  };

  static dataDiagnostico = (items: ItemFicha[]): string => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    if (itemDataDiagnostico?.valor)
      return `${new Date(String(itemDataDiagnostico.valor)).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}`;
    return '';
  };

  static reRp = (items: ItemFicha[], index: number): string => {
    const itemsRe = items?.filter((item) => item.chave === 're');
    const itemsRp = items?.filter((item) => item.chave === 'rp');
    const itemRe = (itemsRe || [])[index];
    const itemRp = (itemsRp || [])[index];
    let reValue = '';
    let rpValue = '';
    let finalValue = '';
    if (itemRe?.valor !== undefined) {
      if (itemRe?.valor === 'positivo') {
        reValue += 'RE: Pos.';
      } else {
        reValue += `RE:${itemRe?.valor !== null ? `${itemRe?.valor}%` : 'Desc.'}`;
      }
    }

    if (itemRp?.valor !== undefined) {
      if (itemRp?.valor === 'positivo') {
        rpValue += 'RE: Pos.';
      } else {
        rpValue += `RP:${itemRp?.valor !== null ? `${itemRp?.valor}%` : 'Desc.'}`;
      }
    }
    if (reValue) finalValue += `${reValue}`;
    if (rpValue) finalValue += ` - ${rpValue}`;
    return `${finalValue}`;
  };

  static her2 = (items: ItemFicha[], index: number): string => {
    const itemsHer2 = items?.filter((item) => item.chave === 'her2');
    const itemsFish = items?.filter((item) => item.chave === 'fish');
    const itemHer2 = (itemsHer2 || [])[index];
    const itemFish = (itemsFish || [])[index];
    if (itemHer2?.valor && itemFish?.valor && itemHer2?.valor !== itemFish?.valor) return `${itemHer2.valor}, ${itemFish.valor}`;
    if (itemHer2?.valor !== undefined) return `${itemHer2?.valor !== null ? `${itemHer2?.valor}` : 'Desc.'}`;
    return '';
  };

  static ki67 = (items: ItemFicha[], index: number): string => {
    const itemsKi67 = items?.filter((item) => item.chave === 'ki67');
    const itemKi67 = (itemsKi67 || [])[index];
    if (itemKi67?.valor !== undefined) return `${itemKi67?.valor !== null ? `${itemKi67?.valor}%` : 'Desc.'}`;
    return '';
  };

  static t = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    if (itemT?.valor && itemYpt?.valor) return `${itemT.valor}/${itemYpt.valor}`;
    if (itemT?.valor) return `${itemT.valor}`;
    if (itemYpt?.valor) return `${itemYpt.valor}`;
    return '';
  };

  static n = (items: ItemFicha[]): string => {
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    if (itemN?.valor && itemYpn?.valor) return `${itemN.valor}/${itemYpn.valor}`;
    if (itemN?.valor) return `${itemN.valor}`;
    if (itemYpn?.valor) return `${itemYpn.valor}`;
    return '';
  };

  static m = (items: ItemFicha[]): string => {
    const itemM = items?.find((item) => item.chave === 'm');
    if (itemM?.valor) return `${itemM.valor}`;
    return '';
  };

  static grupoAnatomico = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    const itemM = items?.find((item) => item.chave === 'm');
    if (itemT?.valor && itemN?.valor && itemM?.valor) {
      const grupo = GrupoAnatomico.calcular(itemT.valor, itemN.valor, itemM.valor);
      let grupoPosNeo;
      if (itemYpt?.valor && itemYpn?.valor && itemM?.valor) {
        grupoPosNeo = GrupoAnatomico.calcular(itemYpt.valor, itemYpn.valor, itemM.valor);
      }
      if (grupo && grupoPosNeo) return `c ${grupo} - yp ${grupoPosNeo}`;
      else if (grupo) return grupo;
    }
    return '';
  };

  static testesPreditivos = (items: ItemFicha[]): string => {
    const itemOncotype = items?.find((item) => item.chave === 'testesPreditivos.oncotype');
    const itemMamaprint = items?.find((item) => item.chave === 'testesPreditivos.mamaprint');
    const itemProsignaN0 = items?.find((item) => item.chave === 'testesPreditivos.prosignaN0');
    const itemProsignaN1 = items?.find((item) => item.chave === 'testesPreditivos.prosignaN1');
    const itemBci = items?.find((item) => item.chave === 'testesPreditivos.bci');
    const itemEndopredict = items?.find((item) => item.chave === 'testesPreditivos.endopredict');
    let labelValues = [];

    if (itemOncotype?.valor) {
      labelValues.push(`Oncotype ${calcSeveridadeTestesPreditivos('oncotype', itemOncotype.valor)}: ${itemOncotype.valor}`);
    }
    if (itemMamaprint?.valor) {
      labelValues.push(`Mamaprint ${itemMamaprint.valor}`);
    }
    if (itemProsignaN0?.valor) {
      labelValues.push(`Prosigna N0 ${calcSeveridadeTestesPreditivos('prosignaN0', itemProsignaN0.valor)}: ${itemProsignaN0.valor}`);
    }
    if (itemProsignaN1?.valor) {
      labelValues.push(`Prosigna N1 ${calcSeveridadeTestesPreditivos('prosignaN1', itemProsignaN1.valor)}: ${itemProsignaN1.valor}`);
    }
    if (itemBci?.valor) {
      labelValues.push(`BCI ${calcSeveridadeTestesPreditivos('bci', itemBci.valor)}: ${itemBci.valor}`);
    }
    if (itemEndopredict?.valor) {
      labelValues.push(`Endopredict ${calcSeveridadeTestesPreditivos('endopredict', itemEndopredict.valor)}: ${itemEndopredict.valor}`);
    }

    return labelValues.join(', ');
  };

  static outrosTestes = (items: ItemFicha[]): string => {
    const outrosTestes = items?.filter((item) => item.chave.startsWith('outrosTestes'));
    const testesNames = [];
    if (outrosTestes?.length) {
      outrosTestes.forEach((item) => {
        const testeKey = item.chave.replace('outrosTestes.', '');
        let testeValue;
        if (item.valor === false) {
          const text = OUTROS_TESTES_THREE_WAY_TOGGLE_TEXT[testeKey]?.negativeValueText;
          testeValue = text ? (text.length >= 7 ? `${text.substr(0, 7)}.` : text) : '';
        }
        if (item.valor === true) {
          const text = OUTROS_TESTES_THREE_WAY_TOGGLE_TEXT[testeKey]?.positiveValueText;
          testeValue = text ? (text.length >= 6 ? `${text.substr(0, 3)}.` : text) : '';
        }
        if (testeKey && testeValue) testesNames.push(`${testeKey}: ${testeValue}`);
      });
    }
    return testesNames.length ? testesNames.join(', ') : null;
  };

  static antecedentesInformacoes = (items: ItemFicha[]): string => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    if (itemAntecedentesInformacoes?.valor) return `${itemAntecedentesInformacoes.valor}`;
    return '';
  };

  static dataImunoHistoquimica = (items: ItemFicha[], index: number): string => {
    const itensRe = items?.filter((item) => item.chave === 're');
    const itensHer2 = items?.filter((item) => item.chave === 'her2');
    const itensKi67 = items?.filter((item) => item.chave === 'ki67');
    const item = (itensRe || itensHer2 || itensKi67 || [])[index];
    if (item?.data)
      return `${new Date(String(item.data)).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}`;
    return '';
  };

  static sitio = (items: ItemFicha[], index: number): string => {
    const itensSitio = items?.filter((item) => item.chave === 'sitio');
    const itemSitio = (itensSitio || [])[index];
    if (itemSitio?.valor)
      return `${itemSitio.valor}`;
    return '';
  };
}

class ValueResolver extends AbstractValueResolver {

  static histopatologico = (items: ItemFicha[]): any => {
    const itemArea = items?.find((item) => item.chave === 'histopatologico.area');
    return {
      area: itemArea?.valor,
      id: itemArea?.id
    };
  };

  static grau = (items: ItemFicha[]): any => {
    const itemGrau = items?.find((item) => item.chave === 'grau');
    return {
      grau: itemGrau?.valor,
      id: itemGrau?.id
    };
  };
  
  static tipoAtendimento = (items: any[]): any => {
    const itemTipoAtendimento = items?.find((item) => item.chave === 'tipoAtendimento');
    return {
      tipoAtendimento: itemTipoAtendimento?.valor,
      id: itemTipoAtendimento?.id
    };
  };
  
  static sexo = (items: any[]): any => {
    const itemSexo = items?.find((item) => item.chave === 'sexo');
    return {
      sexo: itemSexo?.valor,
      id: itemSexo?.id
    };
  };

  static tipoTumoral = (items: any[]): any => {
    const itemTipoTumoral = items?.find((item) => item.chave === 'tipoTumoral');
    return {
      sexo: itemTipoTumoral?.valor,
      id: itemTipoTumoral?.id
    };
  };

  static grupoEstadiamento = (items: any[]): any => {
    const itemGrupoEstadiamento = items?.find((item) => item.chave === 'grupoEstadiamento');
    return {
      sexo: itemGrupoEstadiamento?.valor,
      id: itemGrupoEstadiamento?.id
    };
  };

  static dataDiagnostico = (items: ItemFicha[]): any => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    return {
      dataDiagnostico: (new Date(String(itemDataDiagnostico?.valor))).toJSON(),
      id: itemDataDiagnostico?.id
    };
  };

  static reRp = (items: ItemFicha[], index: number): any => {
    const itemsRe = items?.filter((item) => item.chave === 're');
    const itemsRp = items?.filter((item) => item.chave === 'rp');
    const itemRe = (itemsRe || [])[index];
    const itemRp = (itemsRp || [])[index];
    return {
      re: itemRe?.valor,
      rp: itemRp?.valor,
      idRe: itemRe?.id,
      idRp: itemRp?.id,
      data: itemRe?.data,
      analitico: itemRp?.analitico
    };
  };

  static her2 = (items: ItemFicha[], index: number): any => {
    const itemsHer2 = items?.filter((item) => item.chave === 'her2');
    const itemsFish = items?.filter((item) => item.chave === 'fish');
    const itemHer2 = (itemsHer2 || [])[index];
    const itemFish = (itemsFish || [])[index];
    return {
      her2: itemHer2?.valor,
      fish: itemFish?.valor,
      idHer2: itemHer2?.id,
      idFish: itemFish?.id,
      data: itemHer2?.data,
      analitico: itemHer2?.analitico
    };
  };

  static ki67 = (items: ItemFicha[], index: number): any => {
    const itemsKi67 = items?.filter((item) => item.chave === 'ki67');
    const itemKi67 = (itemsKi67 || [])[index];
    return {
      ki67: itemKi67?.valor,
      id: itemKi67?.id,
      analitico: itemKi67?.analitico
    };
  };

  static t = (items: ItemFicha[]): any => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    return {
      t: itemT?.valor,
      ypt: itemYpt?.valor,
      idT: itemT?.id,
      idYpt: itemYpt?.id,
    };
  };

  static n = (items: ItemFicha[]): any => {
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    const itemAxilaBiopsiada = items?.find((item) => item.chave === 'axilaBiopsiada');
    return {
      n: itemN?.valor,
      ypn: itemYpn?.valor,
      idN: itemN?.id,
      idYpn: itemYpn?.id,
      axilaBiopsiada: itemAxilaBiopsiada?.valor,
      idAxilaBiopsiada: itemAxilaBiopsiada?.id
    };
  };

  static m = (items: ItemFicha[]): any => {
    const itemM = items?.find((item) => item.chave === 'm');
    return {
      m: itemM?.valor,
      id: itemM?.id,
    };
  };

  static testesPreditivos = (items: ItemFicha[]): any => {
    const itemOncotype = items?.find((item) => item.chave === 'testesPreditivos.oncotype');
    const itemMamaprint = items?.find((item) => item.chave === 'testesPreditivos.mamaprint');
    const itemProsignaN0 = items?.find((item) => item.chave === 'testesPreditivos.prosignaN0');
    const itemProsignaN1 = items?.find((item) => item.chave === 'testesPreditivos.prosignaN1');
    const itemBci = items?.find((item) => item.chave === 'testesPreditivos.bci');
    const itemEndopredict = items?.find((item) => item.chave === 'testesPreditivos.endopredict');

    return {
      oncotype: itemOncotype?.valor,
      mamaprint: itemMamaprint?.valor,
      prosignaN0: itemProsignaN0?.valor,
      prosignaN1: itemProsignaN1?.valor,
      bci: itemBci?.valor,
      endopredict: itemEndopredict?.valor,
      idOncotype: itemOncotype?.id,
      idMamaprint: itemMamaprint?.id,
      idProsignaN0: itemProsignaN0?.id,
      idProsignaN1: itemProsignaN1?.id,
      idBci: itemBci?.id,
      idEndopredict: itemEndopredict?.id,
    }
  };

  static outrosTestes = (items: ItemFicha[]): any => {
    const objItemsMapping: any = {};
    const objItemsIdsMapping: any = {};
    items?.filter((item) => item.chave.startsWith('outrosTestes'))
      .forEach((item) => {
        const itemKeyName = item.chave.split('.')[1];
        objItemsMapping[itemKeyName] = item.valor;
        objItemsIdsMapping[itemKeyName] = item.id;
      });

    return {
      values: {
        ['BRCA 1']: (objItemsMapping || {})['BRCA 1'],
        ['BRCA 2']: (objItemsMapping || {})['BRCA 2'],
        ['CPS/PDL-1']: (objItemsMapping || {})['CPS/PDL-1'],
        PIK3CA: objItemsMapping?.PIK3CA,
      },
      ids: {
        ['BRCA 1']: (objItemsIdsMapping || {})['BRCA 1'],
        ['BRCA 2']: (objItemsIdsMapping || {})['BRCA 2'],
        ['CPS/PDL-1']: (objItemsIdsMapping || {})['CPS/PDL-1'],
        PIK3CA: objItemsIdsMapping?.PIK3CA,
      }
    };
  };

  static antecedentesInformacoes = (items: ItemFicha[]): any => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    return {
      antecedentesInformacoes: itemAntecedentesInformacoes?.valor,
      id: itemAntecedentesInformacoes?.id,
    };
  };

  static dataImunoHistoquimica = (items: ItemFicha[], index: number): any => {
    const itensRe = items?.filter((item) => item.chave === 're');
    const itensHer2 = items?.filter((item) => item.chave === 'her2');
    const itensKi67 = items?.filter((item) => item.chave === 'ki67');
    const item = (itensRe || itensHer2 || itensKi67 || [])[index];
    return {
      dataImunoHistoquimica: item?.data
    };
  };

  static sitio = (items: ItemFicha[], index: number): any => {
    const itensSitio = items?.filter((item) => item.chave === 'sitio');
    const itemSitio = itensSitio[index];
    return {
      sitio: itemSitio?.valor,
      id: itemSitio?.id,
      analitico: itemSitio?.analitico,
      data: itemSitio?.data
    };
  };
}

export const inputLabelResolver = (resolver, items: ItemFicha[], index?: number) => {
  return LabelResolver[resolver](items, index);
};

export const inputValueResolver = (resolver, items: ItemFicha[], index?: number) => {
  return ValueResolver[resolver](items, index);
};

export const calcSeveridadeTestesPreditivos = (field: string, value: any) => {
  try {
    return TestesPreditivosSeveridadeResolver[field](value);
  } catch (e) {
    return '';
  }
};

export const verificarCirurgiaNNeoAdjuvancia = (ficha: Ficha) => {
  const hasCirurgia = ficha?.condutas?.some(conduta => conduta.tipo === 'Cirurgia');
  const hasNeoAdjuvancia = ficha?.condutas?.some(conduta => conduta.subtipo === 'Neoadjuvante');
  return hasCirurgia && hasNeoAdjuvancia;
};
