import { Component, OnDestroy, OnInit } from '@angular/core';
import { ROUTE } from '../../constants/route';
import { Router } from '@angular/router';
import { loading } from '../../util/loading';
import { CampanhaService } from '../../services/campanha.service';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { Progresso } from '../../models/campanha.model';
import { niveisGamification } from '../../constants/gamification';
import { Subscription } from 'rxjs';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { SaibaMaisNiveisComponent } from '../../modals/saiba-mais-niveis/saiba-mais-niveis.component';
import { SaibaTempoCampanhaComponent } from '../../modals/saiba-tempo-campanha/saiba-tempo-campanha.component';

@Component({
  selector: 'app-tab-campanhas',
  templateUrl: './tab-campanhas.component.html',
  styles: [`
      progress[value]::-webkit-progress-value {
          background-color: var(--om-module-primary-color);
      }
      progress[value]::-moz-progress-bar {
          background-color: var(--om-module-primary-color);
      }
  `]
})
export class TabCampanhasComponent implements OnInit, OnDestroy {

  progresso: Progresso;
  moduloAtual: IMenuModuloConfig;
  niveisGamification = niveisGamification;
  percentualProgresso: number = 0;
  $subscriptions: Subscription[];
  quizIndisponivel;
  premios: string[] = [];

  constructor(private router: Router,
              private campanhaService: CampanhaService,
              private moduloService: ModuloService,
              private modalHelper: CustomModalHelper) {
  }

  ngOnInit() {
    this.$subscriptions = [
      this.moduloService.$moduloAtual.subscribe((modulo) => {
        this.moduloAtual = modulo;
        this.loadPerguntaQuiz();
        this.loadPremiosCampanha();
      })
    ];
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  loadPerguntaQuiz() {
    this.campanhaService.getPerguntaAleatoria(this.moduloAtual.name).subscribe((pergunta) => {
      this.quizIndisponivel = !Object.keys(pergunta).length;
      this.loadProgressaoQuiz();
    });
  }

  loadProgressaoQuiz() {
    this.progresso = null;
    if (!this.moduloAtual?.name) { return; }
    loading(this.campanhaService.getProgresso(this.moduloAtual.name).subscribe((progresso) => {
      this.progresso = progresso;
      const percentual = progresso.totalDeAcertos !== 0 ? (progresso.totalDeAcertos / progresso.totalDePerguntas * 100) : 0;
      this.percentualProgresso = Number(Number(percentual).toFixed(2));
    }));
  }

  loadPremiosCampanha() {
    loading(this.campanhaService.getPremios(this.moduloAtual.name).subscribe((premios) => {
      this.premios = premios;
    }));
  }

  getPremioIconColor(index: number): string {
    if (index === 0) return '#E8C71E';
    else if (index === 1) return '#989898';
    else if (index === 2) return '#8E732E';
    else return '#2B2BA6';
  }

  async handleNavegarParaQuizClick() {
    await this.router.navigate([ROUTE.CAMPANHA_QUIZ], { replaceUrl : true });
  }

  async handleSaibaMaisNivelClick() {
    const modal = await this.modalHelper.create({
      component: SaibaMaisNiveisComponent,
      keyboardClose: true,
      backdropDismiss: true,
      componentProps: {}
    });
    await modal.present();
  }

  async handleSaibaMaisTempoCampanhaClick() {
    const modal = await this.modalHelper.create({
      component: SaibaTempoCampanhaComponent,
      keyboardClose: true,
      backdropDismiss: true,
      componentProps: {}
    });
    await modal.present();
  }
}
