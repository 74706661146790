import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SelectionOption } from '../components/_base/abstract-input';
import { Ficha } from '../models/ficha.model';

export type PassoAdicionarConduta =
  'selecao-tipo' |
  'subtipo-cirurgia' |
  'subtipo-radioterapia' |
  'subtipo-quimioterapia' |
  'drogas' |
  'calendario' |
  'calendario-fim';

export const passoCalendario: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'calendario',
  altura: 430
};

export const passoDrogas: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'drogas',
  altura: 140
};

@Injectable({
  providedIn: 'root'
})
export class CondutaColorretalColonService {

  static condutasDePeriodo = [
    'Quimioterapia paliativa',
  ];

  static tiposConduta: SelectionOption[] = [
    {
      nome: 'Cirurgia',
      valor: 'Cirurgia'
    },
    {
      nome: 'Radioterapia',
      valor: 'Radioterapia'
    },
    {
      nome: 'Quimioterapia',
      valor: 'Quimioterapia'
    },
    {
      nome: 'Seguimento',
      valor: 'Seguimento'
    },
  ];

  static subtiposCirurgia: SelectionOption[] = [
    {
      nome: 'Colectomia total + dissecção linfonodal',
      valor: 'Colectomia total + dissecção linfonodal'
    },
    {
      nome: 'Hemicolectomia direita + dissecção linfonodal',
      valor: 'Hemicolectomia direita + dissecção linfonodal'
    },
    {
      nome: 'Hemicolectomia esquerda + dissecção linfonodal',
      valor: 'Hemicolectomia esquerda + dissecção linfonodal'
    },
    {
      nome: 'Segmentectomia + dissecção linfonodal',
      valor: 'Segmentectomia + dissecção linfonodal'
    },
    {
      nome: 'Metastasectomia hepática ou pulmonar',
      valor: 'Metastasectomia hepática ou pulmonar'
    },
    {
      nome: 'Retossigmoidectomia',
      valor: 'Retossigmoidectomia'
    },
  ];

  static subtiposRadioterapia: SelectionOption[] = [
    {
      nome: 'Radioterapia para metástases',
      valor: 'Radioterapia para metástases'
    },
    {
      nome: 'Radioterapia locorregional',
      valor: 'Radioterapia locorregional'
    },
  ];

  static subtiposQuimioterapia: SelectionOption[] = [
    {
      nome: 'Neoadjuvante',
      valor: 'Neoadjuvante'
    },
    {
      nome: 'Adjuvante',
      valor: 'Adjuvante'
    },
    {
      nome: 'Paliativa',
      valor: 'Paliativa'
    },
  ];

  static drogasQuimioterapia = [
    'Fluorouracil',
    'Fluorouracil BIC 46h',
    'Oxaliplatina',
    'Irinotecano',
    'Leucovorin',
    'Bevacizumabe',
    'Ramucirumabe',
    'Capecitabina',
    'TAS102',
    'Regorafenibe',
    'Raltitrexede',
    'Aflibercept',
  ];

  static drogasTratamentoAdjuvante = [
    'Fluorouracil',
    'Fluorouracil BIC 46h',
    'Oxaliplatina',
    'Leucovorin',
    'Capecitabina',
    'Raltitrexede',
  ];

  static passosCondutaMapping = {
    'Cirurgia' : {
      proximoPasso: 'subtipo-cirurgia',
      altura: 405
    },
    'Cirurgia Colectomia total + dissecção linfonodal': passoCalendario,
    'Cirurgia Hemicolectomia direita + dissecção linfonodal': passoCalendario,
    'Cirurgia Hemicolectomia esquerda + dissecção linfonodal': passoCalendario,
    'Cirurgia Segmentectomia + dissecção linfonodal': passoCalendario,
    'Cirurgia Metastasectomia hepática ou pulmonar': passoCalendario,
    'Cirurgia Retossigmoidectomia': passoCalendario,
    'Radioterapia' : {
      proximoPasso: 'subtipo-radioterapia',
      altura: 200
    },
    'Radioterapia Radioterapia para metástases': passoCalendario,
    'Radioterapia Radioterapia locorregional': passoCalendario,
    'Quimioterapia': {
      proximoPasso: 'subtipo-quimioterapia',
      altura: 250
    },
    'Quimioterapia Neoadjuvante': passoDrogas,
    'Quimioterapia Adjuvante': passoDrogas,
    'Quimioterapia Paliativa': passoDrogas,
    'Seguimento': passoCalendario,
  };

  getTiposConduta(): Observable<SelectionOption[]> {
    return of(CondutaColorretalColonService.tiposConduta);
  }

  getSubtiposCirurgia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalColonService.subtiposCirurgia);
  }

  getSubtiposRadioterapia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalColonService.subtiposRadioterapia);
  }

  getSubtiposQuimioterapia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalColonService.subtiposQuimioterapia);
  }

  getListaDeDrogasQuimioterapia(ficha: Ficha, filtro: boolean) {
    const drogasQuimioterapia = CondutaColorretalColonService.drogasQuimioterapia;
    const item_HER2 = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.HER-2');
    const item_MSI_H_dMMR = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.MSI–H ou dMMR');
    const item_BRAF_V600E = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.BRAF V600E');
    const item_NTRK = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.NTRK');
    const item_KRAS_G12c = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.KRAS G12c');

    let drogasExtras = [];

    if (item_HER2?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Trastuzumabe',
        'Pertuzumabe',
        'Lapatinibe',
        'TDM-1',
      ]);
    }

    if (item_MSI_H_dMMR?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Pembrolizumabe',
        'Nivolumabe',
        'Ipilimumabe',
      ]);
    }

    if (item_BRAF_V600E?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Encorafenibe',
      ]);
    }

    if (item_NTRK?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Entrectinibe',
        'Larotrectinibe',
      ]);
    }

    if (item_KRAS_G12c?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
         'Sotorasibe',
      ]);
    }

    return [].concat(drogasQuimioterapia, drogasExtras);
  }

  getDrogasTratamentoAdjuvante() {
    return CondutaColorretalColonService.drogasTratamentoAdjuvante;
  }

  getCondutasDePeriodo(): string[] {
    return CondutaColorretalColonService.condutasDePeriodo;
  }
}
