import { ItemFicha } from '../../../../../../shared/models/ficha.model';
import { AbstractLabelResolver, AbstractValueResolver } from '../../_base/abstract-resolver';
import { TESTES_MOLECULARES_THREE_WAY_TOGGLE_TEXT } from '../../../../../../shared/modals/modulo-colorretal-reto/ficha-paciente/testes-moleculares/testes-moleculares.component';
import { GrupoTNM } from './grupo-t-n-m';

class LabelResolver extends AbstractLabelResolver {

  static t = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    if (itemT?.valor && itemYpt?.valor) return `${itemT.valor}/${itemYpt.valor}`;
    if (itemT?.valor) return `${itemT.valor}`;
    if (itemYpt?.valor) return `${itemYpt.valor}`;
    return '';
  };

  static n = (items: ItemFicha[]): string => {
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    if (itemN?.valor && itemYpn?.valor) return `${itemN.valor}/${itemYpn.valor}`;
    if (itemN?.valor) return `${itemN.valor}`;
    if (itemYpn?.valor) return `${itemYpn.valor}`;
    return '';
  };

  static m = (items: ItemFicha[]): string => {
    const itemM = items?.find((item) => item.chave === 'm');
    if (itemM?.valor) return `${itemM.valor}`;
    return '';
  };

  static grupoTNM = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    const itemM = items?.find((item) => item.chave === 'm');
    if (itemT?.valor && itemN?.valor && itemM?.valor) {
      const grupo = GrupoTNM.calcular(itemT.valor, itemN.valor, itemM.valor);
      let grupoPosNeo;
      if (itemYpt?.valor && itemYpn?.valor && itemM?.valor) {
        grupoPosNeo = GrupoTNM.calcular(itemYpt.valor, itemYpn.valor, itemM.valor);
      }
      if (grupo && grupoPosNeo) return `c ${grupo} - yp ${grupoPosNeo}`;
      else if (grupo) return grupo;
    }
    return '';
  };

  static riscoAdjuvancia = (items: ItemFicha[]): string => {
    const itemRiscoAdjuvancia = items?.find((item) => item.chave === 'riscoAdjuvancia');
    const itemM = items?.find((item) => item.chave === 'm');
    if (itemRiscoAdjuvancia?.valor) return `${itemRiscoAdjuvancia.valor}`;
    if (itemM?.valor?.startsWith('M1')) return 'Não se aplica';
    else return '';
  };

  static testesMoleculares = (items: ItemFicha[]): string => {
    const testesMoleculares = items?.filter((item) => item.chave.startsWith('testesMoleculares'));
    const testesNames = [];
    if (testesMoleculares?.length) {
      testesMoleculares.forEach((item) => {
        const testeKey = item.chave.replace('testesMoleculares.', '');
        let testeValue;
        if (item.valor === false) {
          const text = TESTES_MOLECULARES_THREE_WAY_TOGGLE_TEXT[testeKey]?.negativeValueText;
          testeValue = text ? text : '';
        }
        if (item.valor === true) {
          const text = TESTES_MOLECULARES_THREE_WAY_TOGGLE_TEXT[testeKey]?.positiveValueText;
          testeValue = text ? text : '';
        }
        if (testeKey && testeValue) testesNames.push(`${testeKey}: ${testeValue}`);
      });
    }
    return testesNames.length ? testesNames.join(', ') : null;
  };

}

class ValueResolver extends AbstractValueResolver {

  static t = (items: ItemFicha[]): any => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    return {
      t: itemT?.valor,
      idT: itemT?.id,
      ypt: itemYpt?.valor,
      idYpt: itemYpt?.id,
    };
  };

  static n = (items: ItemFicha[]): any => {
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    return {
      n: itemN?.valor,
      idN: itemN?.id,
      ypn: itemYpn?.valor,
      idYpn: itemYpn?.id,
    };
  };

  static m = (items: ItemFicha[]): any => {
    const itemM = items?.find((item) => item.chave === 'm');
    return {
      m: itemM?.valor,
      id: itemM?.id,
    };
  };

  static riscoAdjuvancia = (items: ItemFicha[]): any => {
    const itemRiscoAdjuvancia = items?.find((item) => item.chave === 'riscoAdjuvancia');
    return {
      riscoAdjuvancia: itemRiscoAdjuvancia?.valor,
      id: itemRiscoAdjuvancia?.id
    };
  };

  static testesMoleculares = (items: ItemFicha[]): any => {
    const objItemsMapping: any = {};
    const objItemsIdsMapping: any = {};
    Object.keys(TESTES_MOLECULARES_THREE_WAY_TOGGLE_TEXT)
      .forEach((key) => {
        const item = items?.find((item) => item.chave === `testesMoleculares.${key}`);
        objItemsMapping[key] = item?.valor;
        objItemsIdsMapping[key] = item?.id;
      });

    return {
      values: { ...objItemsMapping },
      ids: { ...objItemsIdsMapping },
    };
  };
}

export const inputLabelResolver = (resolver, items: ItemFicha[], index?: number) => {
  return LabelResolver[resolver](items, index);
};

export const inputValueResolver = (resolver, items: ItemFicha[], index?: number) => {
  return ValueResolver[resolver](items, index);
};
