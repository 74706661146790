import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';
import { toast } from '../../../util/toast';
import { ResponsiveService } from '../../../services/responsive.service';

export interface RangeOption {
  value: string;
  label: string;
  hint?: string;
  options?: RangeOption[];
}

@Component({
  selector: 'app-custom-range-input',
  templateUrl: './custom-range-input.component.html',
  styleUrls: ['./custom-range-input.component.scss']
})
export class CustomRangeInputComponent  extends BaseCustomInputComponent implements OnInit, AfterViewInit {
  
  @ViewChild('rangeValueRef') rangeValueRef: ElementRef;

  isMobile: boolean;
  rangeInputValue: string;
  hasHints: boolean;

  constructor(private responsiveService: ResponsiveService) {
    super();
    this.isMobile = responsiveService.isMobilePlatform;
  }

  get min() {
    if (this.rangeConfig?.min) {
      return this.rangeConfig.min;
    }
    return 0;
  }

  // TODO: Terminar esta bagaça...
  get step() {
    if (this.rangeConfig?.step) {
      if (this.rangeConfig.firstStep !== undefined && Number(this.control.value) < this.rangeConfig.step) {
        return this.rangeConfig.firstStep;
      } else if (Number(this.control.value) >= this.rangeConfig.step) {
        return this.rangeConfig.step;
      } else {
        this.rangeConfig.step;
      }
    }
    return 1;
  }

  // get step() {
  //   if (this.rangeConfig?.step) {
  //     return this.rangeConfig.step;
  //   }
  //   return 1;
  // }

  get max() {
    if (this.rangeConfig?.options) {
      return this.rangeConfig.options.length - 1;
    }
    return this.rangeConfig.max;
  }

  get showValueOnLeft() {
    if (this.rangeConfig?.showValueOnLeft !== undefined) {
      return this.rangeConfig.showValueOnLeft;
    }

    return false;
  }

  get options() {
    if (this.rangeConfig?.options) {
      return this.rangeConfig.options;
    }

    return null;
  }

  get dualKnobs() {
    if (this.rangeConfig?.dualKnobs !== undefined) {
      return this.rangeConfig.dualKnobs;
    }

    return false;
  }

  get showTooltipValues() {
    if (this.rangeConfig?.showTooltipValues !== undefined) {
      return this.rangeConfig.showTooltipValues;
    }

    return true;
  }

  get showPercentageSteps() {
    if (this.rangeConfig?.showPercentageSteps !== undefined) {
      return this.rangeConfig.showPercentageSteps;
    }

    return false;
  }

  ngOnInit() {
    this.hasHints = this.rangeConfig?.options?.some(o => !!o.hint);
    if (!this.value && this.rangeConfig?.options && this.rangeConfig?.options?.length) {
      if (this.control) {
        const setValue = (value, pristine) => {
          const optionIndex = this.rangeConfig.options.findIndex(option => option.value === value);
          if (optionIndex < 0) { return; }
          this.value = this.rangeConfig.options[optionIndex].value;
          this.rangeInputValue = String(optionIndex);
          this.control.pristine = pristine;
        };
        this.control.valueChanges.subscribe((value) => {
          setValue(value, false);
        });
        const value = this.control?.value;
        setValue(value, true);
      } else {
        this.rangeInputValue = this.rangeConfig.options[0].value;
      }
    } else {
      if (this.control) {
        const setValue = (value, pristine) => {
          this.value = value;
          this.rangeInputValue = String(value);
          this.setTipPosition();
          this.control.pristine = pristine;
        };
        this.control.valueChanges.subscribe((value) => {
          setValue(value, false);
        });
        const value = this.control?.value;
        setValue(value, true);
      } else {
        this.value = 0;
      }
    }
  }

  ngAfterViewInit() {
    this.setTipPosition();
  }

  handleRangeValueChange(event) {
    const value = event.target.value;
    if (this.rangeConfig.options) {
      this.value = this.rangeConfig.options[Number(value)].value;
    } else {
      this.value = value;
    }
    if (this.control) {
      this.control.setValue(this.value);
    }
    this.handleChange(this.value);
    this.setTipPosition();
  }

  setTipPosition() {
    if (this.options) return;
    const newValue = Number( (this.value - this.min) * 100 / (this.max - this.min) );
    const newPosition = 5 - (newValue * 0.2);
    if (!this.rangeValueRef) return;
    const { nativeElement } = this.rangeValueRef;
    nativeElement.style.bottom = `calc(${newValue}% + (${newPosition}px))`;
  }

  handleDualKnobsRangeChange(event) {
    this.handleChange(event.el.value);
  }

  async handleHintClick(hint: string) {
    if (this.isMobile) await toast(hint, 'dark', 2000);
  }
}
