import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ResponsiveService } from '../../../../services/responsive.service';
import { ItemFichaService } from '../../../../services/item-ficha.service';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { ItemFicha } from '../../../../models/ficha.model';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';

@Component({
  selector: 'app-input-n',
  templateUrl: './input-n.component.html',
})
export class InputNComponent extends AbstractModal implements InputWithModal {
  
  fichaPaciente: any;
  id: string;
  offsetWidth = 10;
  fixedHeight = '390px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  
  
  form: FormGroup = new FormGroup({
    n: new FormControl('')
  });
  
  nOptions = [
    'cN3',
    'cN2',
    'cN1',
    'cN0',
    'cNx',
  ];
  
  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService,
              public itemFichaService: ItemFichaService) {
    super(injector);
  }
  
  ngOnInit() {
    const { n, id } = inputValueResolver('n', this.fichaPaciente?.itens);
    this.id = id;
    if (n !== undefined) this.form.patchValue({ n });
  }
  
  async handleEndEditClick() {
    if (this.form.get('n').pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: 'n',
        valor: this.form.get('n').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }

}
