import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Clinica } from "../models/clinica.model";
import { Observable, Subject } from 'rxjs';
import { encodeCnpj, getAppUrl } from '../util/utils';
import { map } from 'rxjs/operators';
import { FichaService } from './ficha.service';

export const ONCOMAX_INSTITUICAO_ATUAL = '_actual_institution_oncomax';

@Injectable({
  providedIn: 'root'
})
export class ClinicaService extends AbstractService<Clinica> {

  $clinicaAtual: Subject<Clinica> = new Subject<Clinica>();

  constructor(public http: HttpClient,
              private fichaService: FichaService) {
    super('clinica', http);
  }

  getInstituicaoAtual() {
    if (!localStorage.getItem(ONCOMAX_INSTITUICAO_ATUAL)) {
      return null;
    }
    return JSON.parse(localStorage.getItem(ONCOMAX_INSTITUICAO_ATUAL));
  }

  setInstituicaoAtual(clinica: Clinica) {
    localStorage.setItem(ONCOMAX_INSTITUICAO_ATUAL, JSON.stringify(clinica));
    this.$clinicaAtual.next(clinica);
    this.fichaService.setInstituicaoAtual(clinica);
  }

  getClinicaByCpnj(cnpj: string): Observable<any> {
    return super.get(`cnpj/${encodeCnpj(cnpj)}`);
  }

  getClinicas(uf, cidade): Observable<Clinica[]> {
    return super.get(`${uf}/${cidade}`);
  }

  buscar(busca: string) {
    return super.get(null, { busca });
  }

  getAllCsv(params) {
    const options: any = {
      responseType: 'blob',
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    };
    return this.http.get<any>(`${getAppUrl()}/clinica/csv`, options);
  }

  buscarContasInstitucionais() {
    return super.get('conta-institucional').pipe(map((clinicas) => {
      return clinicas.map(clinica => Object.assign({}, clinica.contaInstituicao, {
        clinica: clinica.nome,
        idInstituicao: clinica._id
      }));
    }));
  }

  criarContaInstitucional(dto: any) {
    return super.post(dto, 'criar-conta-institucional');
  }

  adicionarStaff(dto: any) {
    return super.put(dto, 'adicionar-staff');
  }

  adicionarResidente(dto: any) {
    return super.put(dto, 'adicionar-residente');
  }

  removerResidente(idConvite: string) {
    return super.delete(`remover-residente/${idConvite}`);
  }

  listarMedicosStaff() {
    return super.get('listar-medicos-staff')
      .pipe(map((res) => res.sort((a: any, b: any) => a.nome.localeCompare(b.nome))))
      .pipe(map((res) => this.mapDomainToOption(res, 'nome', '_id')));
  }
}

