import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Ficha, ResumoCaso, SlpResumoCaso } from '../../models/ficha.model';
import { graficoBarraColors } from '../../constants/colors';
import { getDiffInDays, intervalToDurationString } from '../../util/utils';
import { tipoCensuraMapping } from '../../constants/censura';
import { CondutaMama } from '../../models/conduta.model';

@Component({
  selector: 'app-timeline-vertical-condutas',
  templateUrl: './timeline-vertical-condutas.component.html',
})
export class TimelineVerticalCondutasComponent implements OnChanges, AfterViewInit, OnDestroy {

  @Input() ficha: Ficha;
  @Input() resumoCaso: ResumoCaso;
  @Input() loading: boolean = false;
  @Input() emptyStateText: string = 'Ops! O resumo do caso deste paciente ainda não está disponível, adicione condutas para que os valores sejam calculados.';
  @Input() offsetHeight: number = 80;
  @ViewChild('timeLineContainerRef') timeLineContainerRef: ElementRef
  @ViewChild('timelineContentRef') timelineContentRef: ElementRef
  containerHeight;
  sobrevidaGlobal: string;
  tipoCensuraMapping = tipoCensuraMapping;
  slps: SlpResumoCaso[];
  slpsBuilt: boolean = false;

  private resizeObserver: ResizeObserver;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.observeSizeChanges();
  }

  ngOnDestroy() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }

  observeSizeChanges() {
    this.resizeObserver = new ResizeObserver((entries) => {
      let hasChanges = false;
      for (let entry of entries) hasChanges = true;
      if (hasChanges && this.slpsBuilt) this.rebuild();
    });
    this.resizeObserver.observe(this.timelineContentRef.nativeElement);
  }

  ngOnChanges() {
    this.build();
  }

  rebuild() {
    this.slps = [];
    this.cdRef.detectChanges();
    this.build();
    this.cdRef.detectChanges();
  }

  build() {
    this.containerHeight = this.timeLineContainerRef?.nativeElement?.clientHeight;
    if (this.containerHeight && this.containerHeight > 0) {
      const sg = this.resumoCaso?.sg ? this.resumoCaso.sg : 0;
      this.sobrevidaGlobal = intervalToDurationString(sg);
      this.setSlps();
    }
  }

  setSlps() {
    let lastColorIndex = 0;
    let primeiraCondutaPontual: any;
    if (this.resumoCaso?.slps) {
      const periodoTotal = this.resumoCaso?.slps
        .reduce((accumulated, slp) => accumulated + (slp.periodo ? slp.periodo : 0), 0);

      const slps: SlpResumoCaso[] = this.resumoCaso?.slps?.map((slp) => {
        const drogas = new Set();
        slp.condutas?.forEach((conduta: CondutaMama) => {
          conduta.drogas?.forEach((droga) => drogas.add(droga));
        });
        if (slp.drogas?.length) slp.drogas?.forEach((droga) => drogas.add(droga));
        const newObj: any = {
          nome: slp.label,
          descricao: Array.from(drogas).join(', '),
          colorResolved: graficoBarraColors[lastColorIndex],
          tipo: slp.tipo,
          data: slp.data,
          periodoNum: slp.periodo,
          subtipo: slp.subtipo,
          alturaResolved: `${(this.containerHeight - this.offsetHeight) * (slp.periodo / periodoTotal)}px`
        };
        if (!!slp.periodo) {
          newObj.periodo = intervalToDurationString(slp.periodo);
        }
        lastColorIndex += 1;
        if (lastColorIndex === (graficoBarraColors.length)) lastColorIndex = 0;
        return newObj;
      });
      slps.forEach((slp, index) => {
        if (!slp.periodo) {
          if (slps[index - 1]) {
            if (!primeiraCondutaPontual) {
              primeiraCondutaPontual = {
                label: `${slp.tipo ? slp.tipo : ''} ${slp.subtipo ? ` ${slp.subtipo}` : ''}`,
                data: slp.data
              }
            } else {
              const indexSlp = this.getPreviousSlpIndex(slps, index);
              if (indexSlp === undefined) { return; }
              const percentualAlturaBottom = (getDiffInDays(slps[indexSlp].data, slp.data) / slps[indexSlp].periodoNum * 100);
              const percentualAlturaTop = 100 - percentualAlturaBottom;
              if (!slps[indexSlp].condutasPontuais) slps[indexSlp].condutasPontuais = [];
              slps[indexSlp].condutasPontuais.push({
                label: `${slp.tipo}${slp.subtipo ? (` ${slp.subtipo}`) : ''}`,
                data: slp.data,
                percentualAlturaTop: `${percentualAlturaTop.toFixed(3)}%`,
                percentualAlturaBottom: `${percentualAlturaBottom.toFixed(3)}%`,
                position: percentualAlturaTop > 50 ? 'bottom' : 'top'
              });
            }
          }
        }
      });
      const filteredSlps = slps.filter(slp => Boolean(slp.periodo));
      this.setPrimeiraCondutaPontual(filteredSlps, primeiraCondutaPontual);
      this.reviewCondutasPontuaisHeight(filteredSlps);
      this.slps = filteredSlps;
      setTimeout(() => this.slpsBuilt = true);
    } else {
      this.slps = [];
    }
  }

  reviewCondutasPontuaisHeight(slps: SlpResumoCaso[]) {
    slps.forEach((slp) => {
      if (slp.condutasPontuais?.length && slp.condutasPontuais?.length > 1) {
        slp.condutasPontuais.sort((condutaA, condutaB) => {
          const heightA = String(condutaA.percentualAlturaBottom).replace('%', '');
          const heightB = String(condutaB.percentualAlturaBottom).replace('%', '');
          return Number(heightA) - Number(heightB);
        });
        const heights = slp.condutasPontuais
          .map(conduta => Number(String(conduta.percentualAlturaBottom)
            .replace('%', ''))
          );
        const newHeights = heights.map((height, index) => {
          if (index === 0) return height;
          const diff = height - heights[index - 1];
          if (Math.abs(diff) < 12) {
            return heights[index - 1] + 12;
          } else {
            return height;
          }
        });
        slp.condutasPontuais.forEach((conduta, index) => conduta.percentualAlturaBottom = `${newHeights[index]}%`)
      }
    });
  }

  getPreviousSlpIndex(slps: SlpResumoCaso[], index: number) {
    for (let i = index; i >= 0; i--) {
      if (slps[i]?.nome?.startsWith('SLD') ||
        slps[i]?.nome?.startsWith('SLE') ||
        slps[i]?.nome?.startsWith('SLP')) {
        return i;
      }
    }
  }

  setPrimeiraCondutaPontual(slps, primeiraCondutaPontual) {
    if (primeiraCondutaPontual) {
      if (slps[0]?.nome === 'SLD') {
        if (!slps[0]?.condutasPontuais) slps[0].condutasPontuais = [];
        slps[0].condutasPontuais.push({
          label: primeiraCondutaPontual.label,
          data: primeiraCondutaPontual.data,
          percentualAlturaBottom: `0%`,
          position: 'bottom'
        });
      }
      if (slps[0]?.nome === 'SLE' || slps[0]?.nome?.startsWith('SLP')) {
        const percentualAlturaBottom = (getDiffInDays(slps[0].data, primeiraCondutaPontual.data) / slps[0].periodoNum * 100);
        const percentualAlturaTop = 100 - percentualAlturaBottom;
        if (!slps[0].condutasPontuais) slps[0].condutasPontuais = [];
        slps[0].condutasPontuais.push({
          label: primeiraCondutaPontual.label,
          data: primeiraCondutaPontual.data,
          percentualAlturaTop: `${percentualAlturaTop.toFixed(3)}%`,
          percentualAlturaBottom: `${percentualAlturaBottom.toFixed(3)}%`,
          position: percentualAlturaTop > 50 ? 'bottom' : 'top'
        });
      }
    }
  }
}
