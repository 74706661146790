import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { RangeOption } from '../../../../components/custom-input/custom-range-input/custom-range-input.component';

export const KI67_CHAVE = 'ki67';

@Component({
  selector: 'app-input-ki',
  templateUrl: './input-ki.component.html',
})
export class InputKiComponent extends AbstractModal implements InputWithModal, OnInit  {

  ficha: Ficha;
  index: number;
  id: string;
  analitico: boolean;
  data: Date;
  offsetWidth = 10;
  fixedHeight = '485px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    ki67: new FormControl(0),
  });

  desconhecidoForm: FormGroup = new FormGroup({
    ki67: new FormControl(true),
  });

  rangeOptions: RangeConfig = {
    max: 100,
    step: 5,
    showPercentageSteps: true,
    firstStep: 1
  };

  constructor(public override injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { ki67, id, data, analitico } = inputValueResolver(KI67_CHAVE, this.ficha?.itens, this.index);
    this.id = id;
    this.data = data;
    this.analitico = analitico;
    if (ki67 !== undefined) {
      this.form.patchValue({
        ki67 : ki67 ? ki67 : 0
      });
    }
    this.desconhecidoForm.patchValue({ ki67 : ki67 === null });
    this.registerFormListeners();
  }

  fillRangeOptions() {
    const initialValue = 0;
    const step = 5;
    const firstNonZeroValue = 1;
    const finalValue = 100;
    const options: RangeOption[]  = [];

    for (let actualStep = step; actualStep <= finalValue; actualStep += step) {
      options.push({
        label: String(actualStep),
        value: String(actualStep)
      });
    }

    options.unshift({
      label: String(firstNonZeroValue),
      value: String(firstNonZeroValue)
    });

    options.unshift({
      label: String(initialValue),
      value: String(initialValue)
    });

    this.rangeOptions.options = options;
  }

  registerFormListeners() {
    const ki67FormControl = this.form.get(KI67_CHAVE);
    const ki67DesconhecidoFormControl = this.desconhecidoForm.get(KI67_CHAVE);
    ki67DesconhecidoFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.ki67.setValue(0);
      }
    });
    ki67FormControl.valueChanges.subscribe((value) => {
      if (Number(value) !== 0) {
        ki67DesconhecidoFormControl.setValue(false, { emitEvent : false });
      }
    });
  }

  handleClearInputClick() {
    const items: ItemFicha[] = [
      { chave: 'ki67', valor: void 0, id: this.id },
    ];
    setTimeout(async () => {
      await this.modalController.dismiss(items);
    }, 100);
  }

  async handleEndEditClick() {
    const hasItemKi67 = this.ficha.itens?.some(item => item.chave === KI67_CHAVE);
    const newItem: ItemFicha = {
      chave: KI67_CHAVE,
      valor: !this.desconhecidoForm.get(KI67_CHAVE).value ? Number(this.form.get(KI67_CHAVE).value) : null
    };
    if (this.id) newItem.id = this.id;
    if (this.data) newItem.data = this.data;
    if (!hasItemKi67 || this.analitico) newItem.analitico = true;
    const items: ItemFicha[] = [newItem];
    await this.modalController.dismiss(items);
  }
}
