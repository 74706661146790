import { AfterContentInit, Component, Injector, ViewContainerRef } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ResponsiveService } from '../../services/responsive.service';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-close-button',
  template: `
    <div class="close-button-container">
      <button class="primary-button ion-activatable relative rounded-xl overflow-hidden btn flex
              bg-om-grey-dividers no-animation
              hover:bg-om-grey-dividers
              hover:brightness-90"
              (click)="handleCLickClose()">
        <ion-icon name="close-outline" class="text-xl text-om-brand-500"></ion-icon>
        <ion-ripple-effect></ion-ripple-effect>
      </button>
    </div>
  `
})
export class ModalCloseButtonComponent {
  handleCLickClose() {}
}

@Component({
  template: ''
})
export abstract class AbstractModal implements AfterContentInit {

  offsetWidth = 0;
  offsetHeight = 0;
  fixedHeight: number|string = 0;
  fixedWidth: number|string = 0;
  responsive: boolean = true;
  fullWhenKeyboardOpen: boolean = false;

  public modalController;
  public responsiveService: ResponsiveService;
  public windowHeight;
  public windowWidth;

  private modal: any;
  private containerRef;

  protected constructor(public injector: Injector) {
    this.buildAbstractModal();
    this.windowWidth = this.responsiveService.$screenWidth.value;
    this.windowHeight = this.responsiveService.$screenHeight.value;
    this.responsiveService.$screenWidth.subscribe((width) => {
      this.windowWidth = width;
      this.setModalWidth.bind(this)();
    });
    this.responsiveService.$screenHeight.subscribe((height) => {
      this.windowHeight = height;
      this.setModalHeight.bind(this)();
    });
    this.registerListeners();
  }

  registerListeners() {
    const platform = this.injector.get(Platform);
    if (platform.is('hybrid')) {
      Keyboard.addListener('keyboardDidShow', info => {
        if (this.fullWhenKeyboardOpen) {
          this.unsetModalHeight();
        }
      });

      Keyboard.addListener('keyboardWillHide', () => {
        if (this.fullWhenKeyboardOpen) {
          this.setModalHeight();
        }
      });
    }
  }

  buildAbstractModal() {
    const modalController = this.injector.get(ModalController);
    const viewContainerRef = this.injector.get(ViewContainerRef);
    const responsiveService = this.injector.get(ResponsiveService);

    const componentName = this.constructor.name;

    if (!modalController) {throw new Error(`Não foi possível injetar ModalController no componente ${componentName}`);}
    else {this.modalController = modalController;}

    if (!viewContainerRef) {throw new Error(`Não foi possível injetar ViewContainerRef no componente ${componentName}`);}
    else {this.containerRef = viewContainerRef;}

    if (!responsiveService) {throw new Error(`Não foi possível injetar ResponsiveService no componente ${componentName}`);}
    else {this.responsiveService = responsiveService;}
  }

  ngAfterContentInit(): void {
    this.setModalDefaultClass();
    this.setModalDefaultWidthAndHeight();
  }

  async handleCLickClose(confirm = false, payload?: any) {
    setTimeout(async () => await this.modalController.dismiss({ confirm, payload }), 100);
  }

  setModalDefaultClass() {
    const closeButtonComponent = this.containerRef.createComponent(ModalCloseButtonComponent);
    closeButtonComponent.instance.handleCLickClose = this.handleCLickClose.bind(this);
    const instance = this.modal;
    const modalDefaultClass = 'app-oncomax-custom-modal-content';
    const { children } = instance;
    if (children.length) {
      Array.from(children).forEach((child: HTMLElement) => {
        const headerElement = child.querySelector('ion-header');
        if (headerElement) {
          headerElement.append(closeButtonComponent.location.nativeElement);
        }
        if (!child.classList.contains(modalDefaultClass)) {
          child.classList.add(modalDefaultClass);
        }
      });
    }
  }

  public setHeight(height: number) {
    this.modal.style.setProperty('--height', `${height}px`);
    this.modal.style.setProperty('--min-height', `${height}px`);
  }

  public setWidth(width: number) {
    this.modal.style.setProperty('--width', `${width}px`);
    this.modal.style.setProperty('--min-width', `${width}px`);
  }

  private setModalDefaultWidthAndHeight() {
    this.setModalHeight();
    this.setModalWidth();
  }

  private unsetModalHeight() {
    this.modal.style.removeProperty('--height');
    if (!this.responsive) this.modal.style.removeProperty('--min-height');
  }

  private setModalHeight() {
    if (!this.offsetHeight && !this.fixedHeight) {return;}
    if (this.fixedHeight) {
      this.modal.style.setProperty('--height', `${this.fixedHeight}`);
      if (!this.responsive) this.modal.style.setProperty('--min-height', `${this.fixedHeight}`);
      return;
    }
    const newHeight = (this.windowHeight - this.offsetHeight);
    this.modal.style.setProperty('--height', `${newHeight}px`);
  }

  private setModalWidth() {
    if (!this.offsetWidth && !this.fixedWidth) {return;}
    if (this.fixedWidth) {
      this.modal.style.setProperty('--width', `${this.fixedWidth}`);
      if (!this.responsive) this.modal.style.setProperty('--min-width', `${this.fixedWidth}`);
      return;
    }
    const newWidth = (this.windowWidth - this.offsetWidth);
    this.modal.style.setProperty('--width', `${newWidth}px`);
  }
}
