import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import MenuService from '../../../../shared/services/menu.service';
import UsuarioService from '../../../../shared/services/usuario.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { environment } from '../../../../environments/environment';
import { ValidatorsApp } from '../../../../shared/util/validators';
import { FALE_CONOSCO, POLITICA_PRIVACIDADE, ROUTE, TERMOS_CONDICOES } from '../../../../shared/constants/route';
import { loading } from '../../../../shared/util/loading';
import { Usuario } from '../../../../shared/models/usuario.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
})
export class LoginPage {
  
  socialLoginConfig;

  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.compose([Validators.required, ValidatorsApp.email])]),
    senha: new FormControl('', [Validators.required]),
  });

  constructor(private menuService: MenuService,
              private router: Router,
              private usuarioService: UsuarioService,
              private authService: AuthService) {
    const {availableSocialLogin} = environment;
    this.socialLoginConfig = Object.keys(availableSocialLogin).filter(socialNet => !!availableSocialLogin[socialNet]);
  }

  async ionViewWillEnter() {
    await this.menuService.disableMenu();
    const lastUserMail = this.authService.getLastUserMail();
    if (lastUserMail) {
      this.loginForm.get('email').setValue(lastUserMail);
    }
    this.verifyIfIsLogged();
  }

  async ionViewWillLeave() {
    await this.menuService.enableMenu();
  }

  async handleClickCriarConta() {
    await this.router.navigate([ROUTE.CADASTRO_USUARIO]);
  }

  async handleClickEsqueciSenha() {
    await this.router.navigate([ROUTE.REDEFINIR_SENHA]);
  }

  async handleClickLogin() {
    if (ValidatorsApp.formularioValido(this.loginForm)) {
      const { email, senha } = this.loginForm.getRawValue();
      loading(this.usuarioService.autenticar(email, senha).subscribe(async() => {
        await this.navigateToHomePage();
      }));
    }
  }

  async verifyIfIsLogged() {
    const token = this.authService.getToken();
    if (token) {
      await this.router.navigate([ROUTE.MODULOS_CANCER], { replaceUrl: true });
    }
  }

  async navigateToHomePage() {
    const usuario: Usuario = this.authService.getUser();
    const route = usuario.perfil === 'admin' ? ROUTE.ADMIN : ROUTE.MODULOS_CANCER;
    await this.router.navigate([route], { replaceUrl: true });
  }

  async handleTermosUsoClick() {
    await this.router.navigate([TERMOS_CONDICOES], { replaceUrl: true });
  }

  async handlePoliticaPrivacidadeClick() {
    await this.router.navigate([POLITICA_PRIVACIDADE], { replaceUrl: true });
  }

  async handleFaleConoscoCLick() {
    await this.router.navigate([FALE_CONOSCO], { replaceUrl: true });
  }
}
