import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../models/ficha.model';
import { FormControl } from '@angular/forms';
import { InteracaoMedicamentosaService } from '../../services/interacao-medicamentosa.service';
import { loading } from '../../util/loading';
import { Tab } from '../../components/tabs-component/tabs-component.component';
import { inputValueResolver } from '../../../app/modules/client/modulos-cancer/mama/util';

@Component({
  selector: 'app-interacoes-medicamentosas',
  templateUrl: './interacoes-medicamentosas.component.html',
})
export class InteracoesMedicamentosasComponent extends AbstractModal implements OnInit, OnDestroy {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '600px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '720px' : null;
  medicamento: FormControl = new FormControl('');
  medicamentoslist: any[];
  medicamentosSelecionados = [];
  interacoesMedicamentosas = [];
  tabVisivel: Tab;
  tabsConfig: Tab[] = [
    {
      id: 'medicamentos',
      name: 'MEDICAMENTOS',
      active: true
    },
    {
      id: 'interacoes',
      name: 'INTERAÇÕES'
    },
  ];

  constructor(public readonly injector: Injector,
              private interacaoMedicamentosaService: InteracaoMedicamentosaService) {
    super(injector);
  }

  ngOnInit() {
    this.registerFormListeners();
    this.loadCurrentActiveTab();
    const { id, medicamentosSelecionados, interacoesMedicamentosas } = inputValueResolver('interacoesMedicamentosas', this.ficha?.itens);
    this.id = id;
    if (medicamentosSelecionados) this.medicamentosSelecionados = medicamentosSelecionados;
    if (interacoesMedicamentosas) this.interacoesMedicamentosas = interacoesMedicamentosas;
  }

  ngOnDestroy() {
    this.medicamentosSelecionados = [];
    this.interacoesMedicamentosas = [];
  }

  loadCurrentActiveTab() {
    this.tabVisivel = this.tabsConfig.find(t => t.active);
  }

  handleEscolherTabVisivel(tab: Tab) {
    this.tabsConfig = [].concat(this.tabsConfig.map(({id, name}) => ({ id, name, active : id === tab.id })));
    this.tabVisivel = tab;
  }

  registerFormListeners() {
    this.medicamento.valueChanges.subscribe((termo) => {
      loading(this.interacaoMedicamentosaService.buscaPorTermo(termo).subscribe((medicamentos) => {
        this.medicamentoslist = medicamentos;
      }));
    });
  }

  handleItemMedicamentoClick(medicamento) {
    this.medicamentosSelecionados.push(medicamento);
  }

  handleExcluirMedicamentoClick(index) {
    this.medicamentosSelecionados.splice(index, 1);
  }

  handleVerificarClick() {
    const identificadores = this.medicamentosSelecionados.map(medicamento => medicamento.identificador);
    loading(this.interacaoMedicamentosaService.interacaoListaMedicamentos(identificadores).subscribe((interacoesMedicamentosas) => {
      this.interacoesMedicamentosas = interacoesMedicamentosas;
      this.handleEscolherTabVisivel(this.tabsConfig[1]);
    }));
  }

  async handleEndEditClick() {
    const newItem: ItemFicha = {
      chave: 'interacoesMedicamentosas',
      valor: {
        medicamentosSelecionados: this.medicamentosSelecionados,
        interacoesMedicamentosas: this.interacoesMedicamentosas
      },
    };
    if (this.id) newItem.id = this.id;
    await this.modalController.dismiss([newItem]);
  }

  getInteracaoColor(nivel: string) {
    return {
      alta : 'var(--om-error-hover)',
      media: 'var(--om-attention-hover)',
      baixa: 'var(--om-successful-hover)'
    }[nivel];
  }
}
