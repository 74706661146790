import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Tip } from '../../../../components/button-tip-selector/button-tip-selector.component';
import { RangeConfig, ThreeWayToggleConfig } from '../../../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { optionsResolver } from './util';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';

@Component({
  selector: 'app-input-n',
  templateUrl: './input-n.component.html',
})
export class InputNComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  idN: string;
  idYpn: string;
  idAxilaBiopsiada: string;
  $digesting: boolean = false;
  offsetWidth = 10;
  fixedHeight = '575px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  posOperatorioEAdjuvancia: boolean;
  form: FormGroup = new FormGroup({
    n: new FormControl('cNx'),
    ypn: new FormControl('ypNx'),
    axilaBiopsiada: new FormControl()
  });
  tipo: FormControl = new FormControl(null);
  tipoNeoAdjuvancia: FormControl = new FormControl(null);
  tipoEstadiamento: FormControl = new FormControl('clinico');
  posOperatorioTips: Tip[] = [
    {
      name: 'Pré neoadvuvancia',
      value: 'pre'
    },
    {
      name: 'Pós neoadjuvancia',
      value: 'pos'
    }
  ];
  tipoEstadiamentoTips: Tip[] = [
    {
      name: 'Clínico',
      value: 'clinico'
    },
    {
      name: 'Patológico',
      value: 'patologico'
    }
  ];

  nRangeOptions: RangeConfig = optionsResolver('clinico');
  nRangeOptionsPosNeo: RangeConfig = optionsResolver('patologico', 'y');
  nRangeSubOptions: RangeConfig;
  nRangeSubOptionsPosNeo: RangeConfig;

  threeWayToggleConfig: ThreeWayToggleConfig = {
    nonValueText: 'Desconhecido',
    negativeValueText: 'Não',
    positiveValueText: 'Sim',
  };

  constructor(public override injector: Injector,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }

  ngOnInit() {
    const { n, ypn, idN, idYpn, axilaBiopsiada, idAxilaBiopsiada } = inputValueResolver('n', this.ficha?.itens);
    this.idN = idN;
    this.idYpn = idYpn;
    this.idAxilaBiopsiada = idAxilaBiopsiada;
    this.form.get('axilaBiopsiada').setValue(axilaBiopsiada);
    const cirurgiaNNeoAdjuvancia = this.verificarCirurgiaNNeoAdjuvancia();
    if (String(n).startsWith('pN')) {
      this.tipoEstadiamento.setValue('patologico');
      this.updateNRangeOptions(false);
    }
    if (cirurgiaNNeoAdjuvancia) {
      this.atualizarValoresInputEModal(n, ypn);
      if (ypn) this.loadYpnInputFormValues(ypn);
    }
    const tipoPreOperatorio = this.getIfNValueIsClinicoPatologico(n);
    this.setTipoOptions(tipoPreOperatorio);
    this.loadNInputFormValues(n);

    this.form.get('n').valueChanges.subscribe((value) => {
      this.nRangeSubOptions = null;
      this.$digesting = true;
      const selectedOption = this.nRangeOptions.options.find(o => o.value === value);
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.nRangeSubOptions = {
            options: selectedOption.options
          };
        });
      } else {
        this.nRangeSubOptions = null;
      }
      this.$digesting = false;
    });
    this.form.get('ypn').valueChanges.subscribe((value) => {
      this.nRangeSubOptionsPosNeo = null;
      this.$digesting = true;
      const selectedOption = this.nRangeOptionsPosNeo.options.find(o => o.value === value);
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.nRangeSubOptionsPosNeo = {
            options: selectedOption.options
          };
        });
      } else {
        this.nRangeSubOptionsPosNeo = null;
      }
      this.$digesting = false;
    });
  }

  atualizarValoresInputEModal(nValue, ypnValue) {
    this.posOperatorioEAdjuvancia = true;
    if (!nValue && !!ypnValue) this.tipoNeoAdjuvancia.setValue('pos');
    else this.tipoNeoAdjuvancia.setValue('pre');
  }

  verificarCirurgiaNNeoAdjuvancia() {
    const hasCirurgia = this.ficha?.condutas?.some(conduta => conduta.tipo === 'Cirurgia');
    const hasNeoAdjuvancia = this.ficha?.condutas?.some(conduta => conduta.subtipo === 'Neoadjuvante');
    return hasCirurgia && hasNeoAdjuvancia;
  }

  handleTipoChange(event: any) {
    this.setTipoOptions(event.value);
    setTimeout(() => {
      this.form.get('n').setValue(`${event.value[0]}Nx`);
    });
  }

  setTipoOptions(tipo: 'clinico' | 'patologico' = 'clinico') {
    this.tipo.setValue(tipo);
    this.nRangeOptions = optionsResolver(tipo);
  }

  handleTipoNeoAdjuvanciaChange(event: any) {
    if (event.value === 'pre') this.setHeight(575);
    else this.setHeight(535);
    this.tipoNeoAdjuvancia.setValue(event.value);
  }

  handleTipoEstadiamentoChange(event: any) {
    if (event.value === 'clinico') this.setHeight(575);
    else this.setHeight(535);
    this.tipoEstadiamento.setValue(event.value);
    this.updateNRangeOptions();
  }

  updateNRangeOptions(setValueOnInput = true) {
    const tipoEstadiamento = this.tipoEstadiamento.value;
    const prefixo = String(tipoEstadiamento)[0];
    this.nRangeOptions = optionsResolver(tipoEstadiamento);
    setTimeout(() => {
      if (setValueOnInput) this.form.get('n').setValue(`${prefixo}Nx`);
    }, 100);
  }

  getIfNValueIsClinicoPatologico(nValue) {
    if (!nValue) return;
    return nValue.startsWith('c') ? 'clinico' : 'patologico';
  }

  loadNInputFormValues(nValue: string) {
    if (![null, undefined].includes(nValue)) {
      const nOption = this.nRangeOptions.options.find((option) => nValue.startsWith(option.value));
      this.form.patchValue({ n: nOption.value });
      if (nOption?.options?.length) {
        this.nRangeSubOptions = {
          options: nOption.options
        };
        this.form.addControl(nOption.value, new FormControl(nValue));
      }
    }
  }

  loadYpnInputFormValues(ypnValue: string) {
    const ypnOptions = optionsResolver('patologico', 'y');
    const ypnOption = ypnOptions.options.find((option) => ypnValue.startsWith(option.value));
    if (ypnOption?.options?.length) {
      this.nRangeSubOptionsPosNeo = { options :  ypnOption.options };
      this.form.patchValue({ ypn: ypnOption.value });
      this.form.addControl(ypnOption.value, new FormControl(ypnValue));
    } else {
      this.form.patchValue({ ypn: ypnOption.value });
    }
  }

  async handleEndEditClick() {
    const items: ItemFicha[] = [];
    let valor;
    if (!this.form.get('axilaBiopsiada').pristine) {
      const newItem: ItemFicha = {
        chave: 'axilaBiopsiada',
        valor: this.form.get('axilaBiopsiada').value
      };
      if (this.idAxilaBiopsiada) newItem.id = this.idAxilaBiopsiada;
      items.push(newItem);
    }
    if (this.posOperatorioEAdjuvancia && !this.form.get('ypn').pristine) {
      if (this.nRangeSubOptionsPosNeo?.options?.length) {
        const fieldName = this.form.get('ypn').value;
        valor = this.form.get(fieldName).value;
      } else {
        valor = this.form.get('ypn').value;
      }
      const newItem: ItemFicha = { chave: 'ypn', valor };
      if (this.idYpn) newItem.id = this.idYpn;
      items.push(newItem);
    }
    if (this.nRangeSubOptions?.options?.length) {
      const fieldName = this.form.get('n').value;
      valor = this.form.get(fieldName).value;
    } else {
      valor = this.form.get('n').value;
    }
    const newItem: ItemFicha = { chave: 'n', valor };
    if (this.idN) newItem.id = this.idN;
    items.push(newItem);
    await this.modalController.dismiss(items);
  }

  async handleResetValuesClick() {
    const alert = await this.alertHelper.create({
      title: 'Redefinir valores',
      text: 'Tem certeza que deseja zerar os valores para este estadiamento?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          const items: ItemFicha[] = [];
          if (this.form.get('n').value && this.idN) items.push({ chave: 'n', valor: null, id: this.idN });
          if (this.form.get('ypn').value && this.idYpn) items.push({ chave: 'ypn', valor: null, id: this.idYpn });
          setTimeout(async () => {
            await this.modalController.dismiss(items);
          }, 100);
        }
      }
    });
    await alert.present();
  }
}
