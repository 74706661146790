import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/colorretal-colon/util';
import { SelectionOption } from '../../../../components/_base/abstract-input';

export const RISCO_ADJUVANCIA_CHAVE = 'riscoAdjuvancia';
export const RISCO_ADJUVANCIA_OPTIONS = [
  {
    nome: 'Alto risco',
    valor: 'Alto risco',
    hint: 'pT4 OU <12 linfonodos na dissecção linfonodal OU invasão angiolinfática OU céls anel de sinete OU cirurgia de emergência'
  },
  {
    nome: 'Baixo risco',
    valor: 'Baixo risco',
    hint: 'pT1-3 pN0 sem outros critérios'
  },
  {
    nome: 'Desconhecido',
    valor: 'Desconhecido',
    hint: 'Desconhecido',
    customClasses: 'italic'
  }
];

@Component({
  selector: 'app-input-risco-adjuvancia',
  templateUrl: './input-risco-adjuvancia.component.html',
})
export class InputRiscoAdjuvanciaComponent extends AbstractModal {

  id: string;
  ficha: Ficha;
  offsetWidth = 10;
  fixedHeight = '285px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    riscoAdjuvancia: new FormControl(false)
  });

  riscoAdjuvanciaOptions: SelectionOption[] = RISCO_ADJUVANCIA_OPTIONS;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { riscoAdjuvancia, id } = inputValueResolver(RISCO_ADJUVANCIA_CHAVE, this.ficha?.itens);
    this.id = id;
    this.form.patchValue({ riscoAdjuvancia });
  }

  async handleEndEditClick() {
    if (this.form.get(RISCO_ADJUVANCIA_CHAVE).pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: RISCO_ADJUVANCIA_CHAVE,
        valor: this.form.get(RISCO_ADJUVANCIA_CHAVE).value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
