import { Component, Input, OnInit } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';

@Component({
  selector: 'app-three-way-toggle',
  templateUrl: './three-way-toggle.component.html',
})
export class ThreeWayToggleComponent extends BaseCustomInputComponent implements OnInit {

  static possibleValues = [ undefined, false, true ];

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.control) {
      this.value = this.control.value;
    }
  }

  handleInputClick() {
    const currentValueIndex = ThreeWayToggleComponent.possibleValues.findIndex(v => v === this.value);
    let nextValue;
    if (ThreeWayToggleComponent.possibleValues.hasOwnProperty(currentValueIndex + 1)) {
      nextValue = ThreeWayToggleComponent.possibleValues[currentValueIndex + 1];
    } else {
      nextValue = ThreeWayToggleComponent.possibleValues[0];
    }
    if (this.control) {
      this.value = nextValue;
      this.control.markAsDirty();
      this.control.setValue(nextValue);
    } else {
      this.value = nextValue;
      this.handleChange(nextValue);
    }
  }
}
