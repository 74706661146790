const LABEL_DESCONHECIDO = 'Desconhecido';

export class GrupoTNM {

  static mappingN0_1 = {
    N0: {
      M0: {
        '<10 ng/ml': {
          1: 'I'
        },
        '10 a 19,99 ng/ml': {
          1: 'IIA'
        }
      }
    }
  };

  static mappingN0_2 = {
    N0: {
      M0: {
        '<10 ng/ml': {
          1: 'IIA'
        },
        '10 a 19,99 ng/ml': {
          1: 'IIA'
        }
      }
    }
  };

  static defaultMapping = {
    'cT1a cT1b cT1c cT2a': GrupoTNM.mappingN0_1,
    'pT2': GrupoTNM.mappingN0_1,
    'cT2b cT2c': GrupoTNM.mappingN0_2
  };

  static defaultGroupIVB = {
    1: 'IVB',
    2: 'IVB',
    3: 'IVB',
    4: 'IVB',
    5: 'IVB'
  };

  static T1_T2_mapping = {
    N0: {
      M0: {
        '<10 ng/ml': {
          1: 'IIA',
          2: 'IIB',
          3: 'IIC',
          4: 'IIC'
        },
        '10 a 19,99 ng/ml': {
          1: 'IIA',
          2: 'IIB',
          3: 'IIC',
          4: 'IIC'
        },
        '≥ 20 ng/ml': {
          1: 'IIIA',
          2: 'IIIA',
          3: 'IIIA',
          4: 'IIIA',
        }
      },
      M1: {
        '<10 ng/ml': GrupoTNM.defaultGroupIVB,
        '10 a 19,99 ng/ml': GrupoTNM.defaultGroupIVB,
        '≥ 20 ng/ml': GrupoTNM.defaultGroupIVB,
      },
      M1b: {
        '<10 ng/ml': {},
        '10 a 19,99 ng/ml': {},
        '≥ 20 ng/ml': {},
      }
    },
    N1: {
      M0: {
        '<10 ng/ml': {},
        '10 a 19,99 ng/ml': {},
        '≥ 20 ng/ml': {},
      },
      M1: {
        '<10 ng/ml': {},
        '10 a 19,99 ng/ml': {},
        '≥ 20 ng/ml': {},
      }
    }
  };

  static calcular(tValue, nValue, mValue, psaValue, grupoISUP) {
    try {
      let grupoTNMDefault;
      const tValueKey = Object.keys(GrupoTNM.defaultMapping).find(key => key.includes(tValue));
      try {
        grupoTNMDefault = GrupoTNM.defaultMapping[tValueKey][nValue][mValue][psaValue][grupoISUP];
        if (grupoTNMDefault) return grupoTNMDefault;
        else throw new Error();
      } catch (e) {
        if (tValue) {
          if ((tValue.includes('x') || nValue?.includes('x') || mValue.includes('x')) && !mValue?.startsWith('M1')) return LABEL_DESCONHECIDO;
          if (tValue.includes('T1') || tValue.includes('T2') && nValue && mValue && psaValue && grupoISUP) {
            const grupoT1_T2 = GrupoTNM.T1_T2_mapping[nValue][mValue][psaValue][grupoISUP];
            if (grupoT1_T2) return grupoT1_T2;
          }
          if (tValue.includes('T3') || tValue.includes('T4') && psaValue) {
            if (nValue === 'N0' && mValue === 'M0' && Number(grupoISUP) < 5) return 'IIIB';
          }
          if (nValue === 'N0' && mValue === 'M0' && psaValue && Number(grupoISUP) === 5) {
            return 'IIIC';
          } else if (nValue === 'N1' && mValue === 'M0' && psaValue && grupoISUP) {
            return 'IVA';
          } else if (nValue && mValue?.startsWith('M1') && psaValue && grupoISUP) {
            return 'IVB';
          } else {
            return LABEL_DESCONHECIDO;
          }
        }
      }
    } catch (e) {
      if (nValue && mValue?.startsWith('M1') && psaValue && grupoISUP) {
        return 'IVB';
      } else {
        return LABEL_DESCONHECIDO;
      }
    }
  }
}
