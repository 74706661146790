const LABEL_DESCONHECIDO = 'Desconhecido';

export class GrupoAnatomico {

  static mapping = {
    Tx: {
      Nx: {
        M0: LABEL_DESCONHECIDO,
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N0: {
        M0: LABEL_DESCONHECIDO,
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1: {
        M0: 'IIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N2: {
        M0: 'IIIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N3: {
        M0: 'IIIC',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1mic: {
        M0: 'IB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
    },
    Tis: {
      N0: {
        M0: '0',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1: {
        M0: 'IIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N2: {
        M0: 'IIIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N3: {
        M0: 'IIIC',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1mic: {
        M0: 'IB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
    },
    T0: {
      N0: {
        M0: '0',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1: {
        M0: 'IIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N2: {
        M0: 'IIIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N3: {
        M0: 'IIIC',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1mic: {
        M0: 'IB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
    },
    T1: {
      N0: {
        M0: 'IA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1: {
        M0: 'IIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N2: {
        M0: 'IIIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N3: {
        M0: 'IIIC',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1mic: {
        M0: 'IB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
    },
    T2: {
      N0: {
        M0: 'IIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1: {
        M0: 'IIB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N2: {
        M0: 'IIIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N3: {
        M0: 'IIIC',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1mic: {
        M0: 'IIB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
    },
    T3: {
      N0: {
        M0: 'IIB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1: {
        M0: 'IIIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N2: {
        M0: 'IIIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N3: {
        M0: 'IIIC',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1mic: {
        M0: 'IIIA',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
    },
    T4: {
      N0: {
        M0: 'IIIB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1: {
        M0: 'IIIB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N2: {
        M0: 'IIIB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N3: {
        M0: 'IIIC',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
      N1mic: {
        M0: 'IIIB',
        M1: 'IV',
        Mx: LABEL_DESCONHECIDO
      },
    },
  };

  static calcular(tValue, nValue, mValue) {
    tValue = tValue.startsWith('yp') ? tValue.substr(2) : tValue.substr(1);
    nValue = nValue.startsWith('yp') ? nValue.substr(2) : nValue.substr(1);
    if (tValue.startsWith('T1')) tValue = 'T1';
    if (tValue.startsWith('T4')) tValue = 'T4';
    if (nValue !== 'N1mic') {
      if (nValue.startsWith('N0')) nValue = 'N0';
      if (nValue.startsWith('N1')) nValue = 'N1';
      if (nValue.startsWith('N2')) nValue = 'N2';
      if (nValue.startsWith('N3')) nValue = 'N3';
    }
    try {
      return GrupoAnatomico.mapping[tValue][nValue][mValue];
    } catch (e) {
      return LABEL_DESCONHECIDO;
    }
  }
}
