import { Component } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';

@Component({
  selector: 'app-custom-toggle',
  templateUrl: './custom-toggle-input.component.html',
  styleUrls: ['./custom-toggle-input.component.scss'],
})
export class CustomToggleInputComponent extends BaseCustomInputComponent {

  constructor() {
    super();
  }
}
