import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import MenuService from '../../../../shared/services/menu.service';
import { ROUTE } from '../../../../shared/constants/route';
import { toast } from '../../../../shared/util/toast';
import { AjudeMelhorarService } from '../../../../shared/services/ajude-melhorar.service';
import { loading } from '../../../../shared/util/loading';
import { ValidatorsApp } from '../../../../shared/util/validators';

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.page.html',
})
export class FaleConoscoPage {

  form: FormGroup = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    email: new FormControl('', Validators.compose([Validators.required, ValidatorsApp.email])),
    telefone: new FormControl('', [Validators.required]),
    assunto: new FormControl('', [Validators.required]),
    mensagem: new FormControl('', [Validators.required]),
  });

  constructor(private router: Router,
              private menuService: MenuService,
              private ajudeMelhorarService: AjudeMelhorarService) { }

  async ionViewWillEnter() {
    await this.menuService.disableMenu();
  }

  async handleClickVoltar() {
    await this.router.navigate([ROUTE.LOGIN], { replaceUrl: true });
  }

  async handleEnviarClick() {
    if (!ValidatorsApp.formularioValido(this.form)) { return; }
    await loading(this.ajudeMelhorarService.faleConosco({ ...this.form.getRawValue() }).subscribe(async () => {
      await toast('Sua solicitação foi encaminhada com sucesso, favor aguardar contato via e-mail');
      await this.router.navigate([ROUTE.LOGIN], { replaceUrl: true });
    }));
  }
}
