import {
  CondutaColorretal,
  CondutaMama,
  CondutaProstata,
  CondutaPulmaoNSCLC,
  CondutaPulmaoSCLC
} from '../../../../../shared/models/conduta.model';
import { Ficha } from '../../../../../shared/models/ficha.model';
import { inputValueResolver } from '../prostata/util';
import { toCamelCase } from '../../../../../shared/util/utils';

type CondutasType = CondutaMama | CondutaProstata;

export default class CondutaUtil {

  static sortDrogas(drogas: string[]) {
    return drogas.sort((a, b) => a.localeCompare(b));
  }

  static mama(conduta: CondutaMama, ficha: Ficha) {
    const tipoCondutaAliases = {
      Quimioterapia: 'QT',
      Hormonioterapia: 'HT'
    };
    const subtipoCondutaAliases = {
      Neoadjuvante: 'Neo',
      Adjuvante: 'Adj',
      Paliativa: 'Pal'
    };
    const tipo = (tipoCondutaAliases[conduta.tipo]) ? tipoCondutaAliases[conduta.tipo] : conduta.tipo;
    const subtipo = ((subtipoCondutaAliases[conduta.subtipo]) ? subtipoCondutaAliases[conduta.subtipo] : conduta.subtipo || '');
    let drogas = (CondutaUtil.sortDrogas(conduta.drogas|| [])).join(', ');
    if (drogas) {
      drogas = `: ${drogas}`;
    }
    return `${tipo} ${subtipo}${drogas}`;
  }

  static prostata(conduta: CondutaProstata, ficha: Ficha) {
    const tipoCondutaAliases = {
      'Quimioterapia paliativa': 'QT Pal',
      'HT Paliativa & Radiofármacos': 'HT Pal & Radiofármacos',
      'Radioterapia prostática': 'RT prostática',
      'Radioterapia para metástases': 'RT meta',
      'Tratamento local': '',
    };

    const subtipoCondutaAliases = {
      'Radioterapia prostática sem ADT': ' RT prostática s/ ADT',
      'Radioterapia prostática com ADT': ' RT prostática + ADT',
    };

    let tipo = (conduta.tipo in tipoCondutaAliases) ? tipoCondutaAliases[conduta.tipo] : conduta.tipo;
    if (tipo === 'Vigilância ativa' && ficha) {
      const { t } = inputValueResolver('t', ficha.itens);
      const { n } = inputValueResolver('n', ficha.itens);
      const { m } = inputValueResolver('m', ficha.itens);
      const estadiamentos = `${t ? t : ''} ${n ? n : ''} ${m ? m : ''}`;
      if (estadiamentos.trim()) tipo += `: ${estadiamentos}`;
    }
    const subtipo = conduta.subtipo ? ((conduta.subtipo in subtipoCondutaAliases) ? subtipoCondutaAliases[conduta.subtipo] : conduta.subtipo) : '';
    let drogas = (CondutaUtil.sortDrogas(conduta.drogas|| [])).join(', ');
    if (drogas) {
      drogas = `: ${drogas}`;
    }
    return `${tipo}${subtipo ? ` ${subtipo}` : ''}${drogas}`;
  }

  static pulmaoNsclc(conduta: CondutaPulmaoNSCLC, ficha: Ficha) {
    const tipo = conduta.tipo;
    const subtipo = conduta.subtipo;
    let drogas = (CondutaUtil.sortDrogas(conduta.drogas|| [])).join(', ');
    if (drogas) {
      drogas = `: ${drogas}`;
    }
    return `${tipo !== 'Radioterapia' ? tipo : ''}${subtipo ? ` ${subtipo}` : ''}${drogas}`;
  }

  static pulmaoSclc(conduta: CondutaPulmaoSCLC, ficha: Ficha) {
    const tipo = conduta.tipo;
    const subtipo = conduta.subtipo;
    let drogas = (CondutaUtil.sortDrogas(conduta.drogas|| [])).join(', ');
    if (drogas) {
      drogas = `: ${drogas}`;
    }
    return `${tipo !== 'Radioterapia' ? tipo : ''}${subtipo ? ` ${subtipo}` : ''}${drogas}`;
  }

  static colorretalColon(conduta: CondutaColorretal, ficha: Ficha) {
    const tipo = conduta.tipo;
    const subtipo = conduta.subtipo;
    let drogas = (CondutaUtil.sortDrogas(conduta.drogas|| [])).join(', ');
    if (drogas) {
      drogas = `: ${drogas}`;
    }
    return `${tipo !== 'Radioterapia' ? tipo : ''}${subtipo ? ` ${subtipo}` : ''}${drogas}`;
  }

  static colorretalReto(conduta: CondutaColorretal, ficha: Ficha) {
    const tipo = conduta.tipo;
    const subtipo = conduta.subtipo;
    let drogas = (CondutaUtil.sortDrogas(conduta.drogas|| [])).join(', ');
    if (drogas) {
      drogas = `: ${drogas}`;
    }
    return `${tipo !== 'Radioterapia' ? tipo : ''}${subtipo ? ` ${subtipo}` : ''}${drogas}`;
  }

  static colorretalCanalAnal(conduta: CondutaColorretal, ficha: Ficha) {
    const tipo = conduta.tipo;
    const subtipo = conduta.subtipo;
    let drogas = (CondutaUtil.sortDrogas(conduta.drogas|| [])).join(', ');
    if (drogas) {
      drogas = `: ${drogas}`;
    }
    return `${tipo !== 'Radioterapia' ? tipo : ''}${subtipo ? ` ${subtipo}` : ''}${drogas}`;
  }

  static getCondutaLabel(functionName: string, conduta: CondutasType, ficha: Ficha) {
    if (functionName.includes('-')) {
      functionName = toCamelCase(functionName);
    }
    return CondutaUtil[functionName](conduta, ficha);
  }
}
