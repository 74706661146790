import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CondutaMama,
  CondutaProstata,
  CondutaPulmaoSCLC,
  CondutaPulmaoNSCLC,
  CondutaBase
} from '../../models/conduta.model';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import CondutaUtil from '../../../app/modules/client/modulos-cancer/util/conduta';
import { Ficha } from '../../models/ficha.model';

interface DrogaCiclo {
  nome: string,
  dosePlanejada: number,
  doseAdministrada: number,
  unidadeMedidaPlanejada: string,
  unidadeMedidaAdministrada: string
}

@Component({
  selector: 'app-lista-condutas',
  templateUrl: './lista-condutas.component.html',
  styles: [`
      progress[value]::-webkit-progress-value {
          background-color: var(--om-module-primary-color);
      }
      progress[value]::-moz-progress-bar {
          background-color: var(--om-module-primary-color);
      }
  `]
})
export class ListaCondutasComponent {

  moduloAtual: IMenuModuloConfig;

  @Input() uniqueColumn: boolean = false;
  @Input() lastConduta: boolean = false;
  @Input() condutas: Array<CondutaBase<any>>;
  @Input() loading: boolean;
  @Input() filtros: boolean = false;
  @Input() ficha: Ficha;

  @Output() condutaClick: EventEmitter<CondutaBase<any>> = new EventEmitter<CondutaBase<any>>();

  constructor(private moduloService: ModuloService) {
    moduloService.$moduloAtual.subscribe((modulo) => this.moduloAtual = modulo);
  }

  toggleCiclosConduta(index: number) {
    this[`ciclosIsOpenned${index}`] = !this[`ciclosIsOpenned${index}`];
  }

  getCiclosIsOpened(index: number) {
    return this[`ciclosIsOpenned${index}`];
  }

  getFormattedDate(date) {
    return `${new Date(String(date)).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    })}`;
  }

  getCondutaLabel(conduta: CondutaBase<any>): string {
    if (this.moduloAtual) return CondutaUtil.getCondutaLabel(this.moduloAtual.name, conduta, this.ficha);
  }

  handleCondutaRowClick(conduta: CondutaBase<any>) {
    this.condutaClick.emit(conduta);
  }

  getDescricaoCicloDroga(droga: DrogaCiclo) {
    const { nome, doseAdministrada, unidadeMedidaAdministrada, unidadeMedidaPlanejada, dosePlanejada } = droga;
    return `${nome} ${doseAdministrada}${unidadeMedidaAdministrada} / ${dosePlanejada}${unidadeMedidaPlanejada}`;
  }

  getDensidadeDroga(droga: DrogaCiclo) {
    const { dosePlanejada,  doseAdministrada } = droga;
    return Number(doseAdministrada / dosePlanejada * 100).toFixed(2);
  }
}
