import { Injectable } from '@angular/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';

@Injectable({
	providedIn: 'root'
})
export class FileService {

	constructor() {}

	async getNativeFileOpener() {
		try {
			const result = await FilePicker.pickFiles({
				types: ['image/png', 'image/jpeg'],
				readData: true
			});
			if (result?.files?.length) {
				const file = result.files[0];
				return `data:${file.mimeType};base64,${file.data}`;
			}
		} catch (e) {

		}
	}
}
