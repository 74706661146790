import { EChartsOption } from 'echarts';

export const getBarChartOptions = (source: any[], color: string[], tooltipPosition: any = 'right', title?: string, animation: boolean = true, tooltipDataField?: string): EChartsOption => {
  const documentStyle = getComputedStyle(document.documentElement);
  const chartLabelTextColor = documentStyle.getPropertyValue('--om-grey-primary').trim();
  const getTooltipData = (seriesName) => {
    const tuple = source.find((item) => item.label === seriesName);
    if (tuple.info && tuple.info[tooltipDataField]) return tuple.info[tooltipDataField];
    else return [];
  };

  let emptyBarSeries = {
    name: 'empty',
    type: 'bar',
    silent: true,
    itemStyle: {
      color: 'lightgray',
      borderRadius: [6, 6, 0, 0],
    },
    data: new Array(3).fill(1),
    barGap: '-100%',
    barCategoryGap: '40%',
    animation: false
  };

  return {
    title: {
      text: title,
      left: 'center',
      textStyle: {
        color: chartLabelTextColor
      }
    },
    dataset: {
      source
    },
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow'
      },
      formatter: (params) => {
        return `${params.marker} ${params.seriesName}: ${params.value}<br/>${getTooltipData(params.seriesName).join('<br/>')}`;
      }
    },
    color,
    grid: {
      top: '10%',
      left: '3%',
      right: '4%',
      containLabel: true,
      height: '52%'
    },
    legend: {
      data: source.map(item => item.label),
      left: 'center',
      orient: 'vertical',
      itemWidth: 12,
      itemHeight: 12,
      padding: [10, 0],
      textStyle: {
        fontSize: 12,
        color: chartLabelTextColor
      },
      top: '70%',
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        show: false,
      }
    ],
    yAxis: [
      {
        type: 'value',
        interval: 1
      }
    ],
    graphic: source?.length ? [] : [
      {
        type: 'text',
        left: 'center',
        top: '70%',
        z: 100,
        style: {
          text: 'Não há pacientes novos a serem exibidos',
          textAlign: 'center',
          fill: chartLabelTextColor,
          fontSize: 12
        }
      }
    ],
    series: source?.length ? source.map((item, index) => ({
      name: item.label,
      type: 'bar',
      itemStyle: {
        borderRadius: [6, 6, 0, 0],
        color: () => color[index]
      },
      label: {
        show: true,
        position: 'inside',
        color: '#ffffff',
        fontSize: 24,
        fontWeight: 'bold',
      },
      data: [item.value],
      info: item.info
    })) : [emptyBarSeries]
  } as any;
};
