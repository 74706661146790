import { Ficha, ItemFicha } from '../../../../../../shared/models/ficha.model';
import { GrupoTNM } from './grupo-t-n-m';
import { INPUT_TOGGLE_LIST } from '../../../../../../shared/modals/modulo-pulmao-nsclc/ficha-paciente/input-painel-ngs/input-painel-ngs.component';
import { AbstractLabelResolver, AbstractValueResolver } from '../../_base/abstract-resolver';

class LabelResolver extends AbstractLabelResolver {

  static histopatologico = (items: ItemFicha[]): string => {
    const itemArea = items?.find((item) => item.chave === 'histopatologico');
    if (itemArea?.valor) return `${itemArea.valor}`;
    else return '';
  };

  static dataDiagnostico = (items: ItemFicha[]): string => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    if (itemDataDiagnostico?.valor)
      return `${new Date(String(itemDataDiagnostico.valor)).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}`;
    return '';
  };

  static t = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    if (itemT?.valor && itemYpt?.valor) return `${itemT.valor}/${itemYpt.valor}`;
    if (itemT?.valor) return `${itemT.valor}`;
    if (itemYpt?.valor) return `${itemYpt.valor}`;
    return '';
  };

  static n = (items: ItemFicha[]): string => {
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    if (itemN?.valor && itemYpn?.valor) return `${itemN.valor}/${itemYpn.valor}`;
    if (itemN?.valor) return `${itemN.valor}`;
    if (itemYpn?.valor) return `${itemYpn.valor}`;
    return '';
  };

  static m = (items: ItemFicha[]): string => {
    const itemM = items?.find((item) => item.chave === 'm');
    if (itemM?.valor) return `${itemM.valor}`;
    return '';
  };

  static grupoTNM = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    const itemM = items?.find((item) => item.chave === 'm');
    if (itemT?.valor && itemN?.valor && itemM?.valor) {
      const grupo = GrupoTNM.calcular(itemT.valor, itemN.valor, itemM.valor);
      let grupoPosNeo;
      if (itemYpt?.valor && itemYpn?.valor && itemM?.valor) {
        grupoPosNeo = GrupoTNM.calcular(itemYpt.valor, itemYpn.valor, itemM.valor);
      }
      if (grupo && grupoPosNeo) return `c ${grupo} - yp ${grupoPosNeo}`;
      else if (grupo) return grupo;
    }
    return '';
  };

  static pdl1 = (items: ItemFicha[]): string => {
    const itemPdl1 = items?.find((item) => item.chave === 'pdl1');
    if (itemPdl1?.valor === null) return 'Não testado';
    if (itemPdl1?.valor) return `${itemPdl1.valor}`;
    return '';
  };

  static painelNGS = (items: ItemFicha[]): string => {
    const itemPainelNGS = items?.find((item) => item.chave === 'painelNGS');
    if (itemPainelNGS?.valor) {
      const values = [];
      INPUT_TOGGLE_LIST.forEach((input) => {
        const item = items?.find((item) => item.chave === input.name);
        if (!item || !item?.valor) { return; }
        values.push(`${input.label}: ${input.aliases[`${item.valor}`]}`);
      });
      return values.join(', ');
    } else {
      return '';
    }
  };

  static antecedentesInformacoes = (items: ItemFicha[]): string => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    if (itemAntecedentesInformacoes?.valor) return `${itemAntecedentesInformacoes.valor}`;
    return '';
  };
}

class ValueResolver extends AbstractValueResolver {

  static histopatologico = (items: ItemFicha[]): any => {
    const itemArea = items?.find((item) => item.chave === 'histopatologico');
    return {
      histopatologico: itemArea?.valor,
      id: itemArea?.id
    };
  };

  static dataDiagnostico = (items: ItemFicha[]): any => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    return {
      dataDiagnostico: itemDataDiagnostico?.valor,
      id: itemDataDiagnostico?.id
    };
  };

  static t = (items: ItemFicha[]): any => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemYpt = items?.find((item) => item.chave === 'ypt');
    return {
      t: itemT?.valor,
      idT: itemT?.id,
      ypt: itemYpt?.valor,
      idYpt: itemYpt?.id,
    };
  };

  static n = (items: ItemFicha[]): any => {
    const itemN = items?.find((item) => item.chave === 'n');
    const itemYpn = items?.find((item) => item.chave === 'ypn');
    const itemMediastinoscopia = items?.find((item) => item.chave === 'mediastinoscopia');
    return {
      n: itemN?.valor,
      idN: itemN?.id,
      ypn: itemYpn?.valor,
      idYpn: itemYpn?.id,
      mediastinoscopia: itemMediastinoscopia?.valor,
      idMediastinoscopia: itemMediastinoscopia?.id
    };
  };

  static m = (items: ItemFicha[]): any => {
    const itemM = items?.find((item) => item.chave === 'm');
    return {
      m: itemM?.valor,
      id: itemM?.id,
    };
  };

  static pdl1 = (items: ItemFicha[]): any => {
    const itemPdl1 = items?.find((item) => item.chave === 'pdl1');
    return {
      pdl1: itemPdl1?.valor,
      id: itemPdl1?.id,
    };
  };

  static painelNGS = (items: ItemFicha[]): any => {
    const objItemsMapping: any = {};
    const objItemsIdsMapping: any = {};

    [{ name: 'painelNGS' }]
      .concat(INPUT_TOGGLE_LIST)
      .forEach((input) => {
      const item = items?.find((item) => item.chave === input.name);
      if (!item) { return; }
      objItemsMapping[item.chave] = item.valor;
      objItemsIdsMapping[item.chave] = item.id;
    });

    return {
      values: { ...objItemsMapping },
      ids: { ...objItemsIdsMapping },
    };
  };

  static antecedentesInformacoes = (items: ItemFicha[]): any => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    return {
      antecedentesInformacoes: itemAntecedentesInformacoes?.valor,
      id: itemAntecedentesInformacoes?.id,
    };
  };
}

export const inputLabelResolver = (resolver, items: ItemFicha[], index?: number) => {
  return LabelResolver[resolver](items, index);
};

export const inputValueResolver = (resolver, items: ItemFicha[], index?: number) => {
  return ValueResolver[resolver](items, index);
};

export const verificarNeoAdjuvancia = (ficha: Ficha) => {
  return ficha?.condutas?.some(conduta => conduta.subtipo === 'Neoadjuvante');
};
