import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { FormControl, FormGroup } from '@angular/forms';
import { FileService } from '../../services/file-service';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { Observable, Subscription } from 'rxjs';
import { toast } from '../../util/toast';
import { ConteudoRelacionado } from '../../models/campanha.model';
import { CampanhaService } from '../../services/campanha.service';
import { loading } from '../../util/loading';

@Component({
  selector: 'app-criar-novo-conteudo-relacionado',
  templateUrl: './criar-novo-conteudo-relacionado.component.html',
})
export class CriarNovoConteudoRelacionadoComponent extends AbstractModal implements OnInit, OnDestroy {

  conteudoRelacionado: ConteudoRelacionado;
  offsetHeight = 10;
  fixedWidth = '800px';
  moduloAtual: IMenuModuloConfig;
  $subscriptions: Subscription[] = [];
  imagemAlterada: boolean = false;
  form: FormGroup = new FormGroup({
    _id: new FormControl(null),
    nome: new FormControl(''),
    descricao: new FormControl(''),
    imagem: new FormControl(''),
    tags: new FormControl([]),
    link: new FormControl(''),
  });

  constructor(public readonly injector: Injector,
              private fileService: FileService,
              private modalHelper: CustomModalHelper,
              private moduloService: ModuloService,
              private campanhaService: CampanhaService) {
    super(injector);
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.moduloService.$moduloAtual.subscribe(modulo => this.moduloAtual = modulo)
    );
    this.form.patchValue({ ...this.conteudoRelacionado });
    if (this.conteudoRelacionado?._id) {
      this.campanhaService.getImagemConteudoRelacionado(this.conteudoRelacionado).subscribe((response: any) => {
        this.form.get('imagem').setValue(response.imagem);
      });
    }
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async handleAdicionarImagemClick() {
    const image = await this.fileService.getNativeFileOpener();
    if (image) {
      const modal = await this.modalHelper.create({
        component: ImageCropperComponent,
        componentProps: { image },
        handler: ({ data }) => {
          if (data.confirm) {
            this.form.get('imagem').setValue(data.base64);
            this.imagemAlterada = true;
          }
        }
      });
      await modal.present();
    }
  }

  handleExcluirImagemClick() {
    this.form.get('imagem').setValue('');
  }

  async handleConfirmClick() {
    const conteudo: ConteudoRelacionado = this.form.getRawValue();
    if (!conteudo.imagem) {
      toast('É obrigatório inserir uma imagem', 'warning');
      return;
    }
    if (!this.imagemAlterada) delete conteudo.imagem;
    await this.handleCLickClose(true, { conteudo });
  }
}
