import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/colorretal-colon/util';
import { SelectionOption } from '../../../../components/_base/abstract-input';

export const LATERALIDADE_CHAVE = 'lateralidade';
export const LATERALIDADE_OPTIONS = [
  {
    nome: 'Direito',
    valor: 'Direito',
    hint: 'Até flexura esplênica'
  },
  {
    nome: 'Esquerdo',
    valor: 'Esquerdo',
    hint: 'Da flexura esplênica até o final'
  },
  {
    nome: 'Desconhecido',
    valor: 'Desconhecido',
    hint: 'Desconhecido',
    customClasses: 'italic'
  },
];

@Component({
  selector: 'app-input-lateralidade',
  templateUrl: './input-lateralidade.component.html',
})
export class InputLateralidadeComponent extends AbstractModal {

  id: string;
  ficha: Ficha;
  offsetWidth = 10;
  fixedHeight = '285px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    lateralidade: new FormControl(false)
  });

  lateralidadeOptions: SelectionOption[] = LATERALIDADE_OPTIONS;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { lateralidade, id } = inputValueResolver(LATERALIDADE_CHAVE, this.ficha?.itens);
    this.id = id;
    this.form.patchValue({ lateralidade });
  }

  async handleEndEditClick() {
    if (this.form.get(LATERALIDADE_CHAVE).pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: LATERALIDADE_CHAVE,
        valor: this.form.get(LATERALIDADE_CHAVE).value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
