import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IMenuModuloConfig, menuModulosConfig } from "../../constants/modulos-cancer";
import { getNavigateRoutePath, ROUTE } from '../../constants/route';
import { Router } from "@angular/router";
import { ModuloService } from "../../services/modulo.service";
import { ResponsiveService } from "../../services/responsive.service";

@Component({
  selector: 'app-menu-modulos',
  templateUrl: './menu-modulos.component.html',
  styles: [`
      swiper-container { 
          --swiper-navigation-size : 16px; 
          --swiper-navigation-color : var(--om-grey-primary);
      }
  `]
})
export class MenuModulosComponent implements AfterViewInit {

  moduloAtual: IMenuModuloConfig;
  @ViewChild('swiperComponentRef') swiperComponentRef: any;
  @Input() vertical: boolean = false;
  @Input() iconsSize: 'large' | 'small' = 'large';
  @Input() navigate: boolean = true;
  @Input() showAll: boolean = true;
  @Input() carousel: boolean = true;
  @Input() moduloCountPacientes: { [key: string]: number };

  @Output() onModuleClick: EventEmitter<IMenuModuloConfig> = new EventEmitter<IMenuModuloConfig>();

  menuModulosConfig: IMenuModuloConfig[] = menuModulosConfig;

  constructor(private router: Router,
              private moduloService: ModuloService,
              private responsiveService: ResponsiveService) {
    moduloService.$moduloAtual.subscribe((modulo) => this.moduloAtual = modulo);
  }

  async handleModuleClick(config: IMenuModuloConfig) {
    if (this.moduloAtual?.name === config.name) { return; }
    this.onModuleClick.emit(config);
    if (!config.available || !this.navigate) { return }
    if (config.building) {
      localStorage.setItem('modulo_construcao', config.name);
      await this.router.navigate([ROUTE.PAGINA_EM_CONSTRUCAO]);
      return;
    }
    const navigationPath = getNavigateRoutePath(config.name);
    this.moduloService.setModuloAtual(config.name);
    if (!this.responsiveService.isMobilePlatform) return;
    await this.router.navigate([navigationPath.LISTA_PACIENTES]);
  }

  ngAfterViewInit() {
    if (!this.carousel) { return; }
    const swiperElement = this.swiperComponentRef.nativeElement;
    const nextButtonElement: HTMLElement = swiperElement.swiper.el.querySelector('.swiper-button-next');
    const prevButtonElement: HTMLElement = swiperElement.swiper.el.querySelector('.swiper-button-prev');
    nextButtonElement.style.setProperty('right', '0');
    prevButtonElement.style.setProperty('left', '0');
  }
}
