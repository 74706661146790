import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {getAppUrl} from '../util/utils';
import {catchError, map, retryWhen} from 'rxjs/operators';
import {genericRetryStrategy} from '../util/retry-strategy';
import { PaginateResult } from '../models/paginate-result.model';
import { toast } from "../util/toast";

export interface RepositoryInterface<T> {
  getAll(): Observable<PaginateResult<T>>;

  get(): Observable<T[]>;

  getById(id: number): Observable<T>;

  post(entity: T): Observable<T>;

  put(entity: any, id: number): Observable<T>;

  delete(id: number): Observable<any>;
}

export abstract class AbstractService<T> implements RepositoryInterface<T> {

  public baseUrl: string;
  excludedStatusCodes = [500, 403, 401, 400, 404, 201, 204, 409];

  protected constructor(baseUrl: string, public http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  public get(relativeUrl?: string, params = {}): Observable<T[]> {
    return this.http.get<T[]>(this.getUrl(relativeUrl), {params}).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public getAll(pagina = 1, itensPorPagina = 10, relativeUrl?: string, params?): Observable<PaginateResult<T>> {
    params = params ? Object.assign(params, {pagina, itensPorPagina}) : {pagina, itensPorPagina};
    return this.http.get<PaginateResult<T>>(this.getUrl(relativeUrl), Object.assign({
      headers: this.headers().headers,
      params
    })).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public getById(id?: number | string, relativeUrl?: string, options?): Observable<T> {
    options = Object.assign(this.headers(), options);
    const strId = id ? `${id}` : '';
    let url = `${strId}`;
    if (relativeUrl) {
      url = `${relativeUrl}/${strId}`;
    }
    return this.http.get<T>(this.getUrl(url), this.headers()).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }


  public postGetAll(entity: T, relativeUrl?: string, options?): Observable<T[]> {
    options = Object.assign(this.headers(), options);
    return this.http.post<T>(this.getUrl(relativeUrl), entity, options).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public post(entity: T, relativeUrl?: string, options?): Observable<T> {
    options = Object.assign(this.headers(), options);
    return this.http.post<T>(this.getUrl(relativeUrl), entity, options).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public put(entity: T, id: number | string, relativeUrl?: string, options?): Observable<T> {
    options = Object.assign(this.headers(), options);
    const strId = id ? `${id}` : '';
    let url = `${strId}`;
    if (relativeUrl) {
      url = `${relativeUrl}/${strId}`;
    }
    return this.http.put<T>(
      this.getUrl(url), entity, options).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public patch(entity: T, id: number | string, relativeUrl?: string, options?): Observable<T> {
    options = Object.assign(this.headers(), options);
    const strId = id ? `${id}` : '';
    let url = `${strId}`;
    if (relativeUrl) {
      url = `${relativeUrl}/${strId}`;
    }
    return this.http.patch<T>(
      this.getUrl(url), entity, options).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public delete(id: any, relativeUrl?: string, options?): Observable<any> {
    options = Object.assign(this.headers(), options);
    const strId = id ? `${id}` : '';
    let url = `${strId}`;
    if (relativeUrl) {
      url = `${relativeUrl}/${strId}`;
    }
    return this.http.delete(this.getUrl(url), options).pipe(
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  protected getUrl(relativeUrl?: string): string {
    let absoluteUrl = `${getAppUrl()}/${this.baseUrl}`;
    const path = /^(http|https):\/\//g;
    if (path.test(this.baseUrl)) {
      absoluteUrl = this.baseUrl;
    }
    if (relativeUrl !== null && relativeUrl !== undefined) {
      if (path.test(relativeUrl)) {
        return relativeUrl;
      }
      absoluteUrl += `/${relativeUrl}`;
    }
    return absoluteUrl;
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      toast(error.error.message);
    }
    return throwError(error.error);
  }

  protected headers() {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  protected extractData(res: any) {
    let body: any = res;
    if ('result' in body) {
      body = body.result;
    }
    return body;
  }

  mapDomainToOption(response, labelField, valueField) {
    return response.map(r => ({
      nome: r[labelField],
      valor: r[valueField]
    }));
  }

  mapArrayStringToOption(response) {
    return response.map(r => ({ nome: r, valor: r }));
  }
}
