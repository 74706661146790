import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (prefixo: string = ''): RangeConfig => ({
  options: [
    {
      label: `${prefixo}Nx`,
      value: `${prefixo}Nx`,
      hint: 'Desconhecido'
    },
    {
      label: `${prefixo}N0`,
      value: `${prefixo}N0`,
      hint: 'Nenhuma metástase nos linfonodos regionais'
    },
    {
      label: `${prefixo}N1`,
      value: `${prefixo}N1`,
      hint: 'Metástases aos linfonodos peribrônquicos ipsilaterais e/ou hilares ipsilaterais e aos linfonodos intrapulmonares, incluindo aqueles envolvidos por extensão direta do tumor primário'
    },
    {
      label: `${prefixo}N2`,
      value: `${prefixo}N2`,
      hint: 'Metástases aos linfonodos mediastinais ipsilaterais e/ou subcarinais'
    },
    {
      label: `${prefixo}N3`,
      value: `${prefixo}N3`,
      hint: 'Metástases aos linfonodos mediastinais contralaterais, hilares contralaterais, escalênicos ipsilaterais ou contralaterais, ou supraclaviculares, ou uma combinação'
    }
  ]
});
