import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import {
  CondutaProstataService,
  PassoAdicionarConduta,
  passoCalendario
} from '../../../../services/conduta-prostata.service';
import { zip } from 'rxjs';
import { Ficha } from '../../../../models/ficha.model';
import { toast } from '../../../../util/toast';
import { CondutaMama, CondutaProstata } from '../../../../models/conduta.model';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';
import * as moment from 'moment';
import { FichaService } from '../../../../services/ficha.service';
import { loading } from '../../../../util/loading';
import { SugestaoConduta } from '../../../../../app/modules/client/modulos-cancer/mama/util/sugestao-conduta';

@Component({
  selector: 'app-adicionar-conduta',
  templateUrl: './adicionar-conduta.component.html',
})
export class AdicionarCondutaComponent extends AbstractModal implements OnInit {

  ficha: Ficha;
  filtro: boolean;
  editarFiltro: boolean;
  conduta: CondutaMama;
  offsetWidth = 10;
  fixedHeight = '355px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  maxDate = moment().format('YYYY-MM-DD');
  passoAtual: PassoAdicionarConduta = 'selecao-tipo';
  tipoCondutaOptions: SelectionOption[];
  subtiposTratamentoLocal: SelectionOption[];
  tiposAdt: SelectionOption[];
  drogasTratamentoPaliativo: string[];
  sugestaoConduta: string[];
  oldCondutaTipo: string;
  oldCondutaSubtipo: string;
  operadorDrogasOptions: SelectionOption[];

  form: FormGroup = new FormGroup({
    tipo: new FormControl('', [Validators.required]),
    subtipo: new FormControl('', [Validators.required]),
    drogas: new FormControl([]),
    continuidade: new FormControl(false),
    data: new FormControl(moment().set({ date: 1 }).format('YYYY-MM-DD'), [Validators.required]),
  });

  constructor(public readonly injector: Injector,
              private condutaService: CondutaProstataService,
              private alertHelper: CustomAlertHelper,
              private fichaService: FichaService) {
    super(injector);
  }

  get numeroLinhaTratamento() {
    const subtipo = this.form.get('subtipo').value;
    const continuidade = this.form.get('continuidade').value;
    return (this.ficha?.condutas || [])
      .filter(conduta => conduta.subtipo === subtipo && !conduta.continuidade)
      .length + (!continuidade ? 1 : 0);
  }
  
  ngOnInit() {
    this.loadDominios();
    this.verifyAndSetFormFields();
    this.registerFormListeners();
    if (this.filtro) this.registerAdditionalFilterFields();
  }

  registerFormListeners() {
    const controls = [
      this.form.get('tipo'),
      this.form.get('subtipo'),
    ];
    controls.forEach(async (control) => {
      control.valueChanges.subscribe(async () => {
        await this.handleConfirmStepClick();
      });
    });
  }

  registerAdditionalFilterFields() {
    this.operadorDrogasOptions = [
      {
        nome: 'Todas as drogas',
        valor: 'AND',
      },
      {
        nome: 'Qualquer das drogas',
        valor: 'OR',
      }
    ];
    this.form.removeControl('continuidade');
    this.form.addControl('dataFim', new FormControl(moment().set({ date: 1 }).format('YYYY-MM-DD')));
    this.form.addControl('operadorDrogas', new FormControl(this.conduta ? this.conduta['operadorDrogas'] : 'AND'));
    this.form.addControl('ultima', new FormControl(Boolean((this.conduta && this.conduta['ultima']))));
  }

  loadDominios() {
    zip(
      this.condutaService.getTiposConduta(),
      this.condutaService.getSubtiposTratamentoLocal(),
      this.condutaService.getTiposAdt(),
      this.condutaService.getDrogasTratamentoPaliativo()
    ).subscribe((
      [
        tiposConduta,
        subtiposTratamentoLocal,
        tiposAdt,
        drogasTratamentoPaliativo
      ]
    ) => {
      this.tipoCondutaOptions = tiposConduta;
      this.subtiposTratamentoLocal = subtiposTratamentoLocal;
      this.tiposAdt = tiposAdt;
      this.drogasTratamentoPaliativo = drogasTratamentoPaliativo;
    });
  }

  verifyAndSetFormFields() {
    if (this.conduta) {
      const { tipo, drogas, data, subtipo } = this.conduta;
      this.form.patchValue({ tipo, drogas, data });
      if (tipo) this.oldCondutaTipo = tipo;
      if (subtipo) this.oldCondutaSubtipo = subtipo;
    }
  }

  async handleDeletarCondutaClick() {
    const alert = await this.alertHelper.create({
      title: 'Excluir conduta',
      text: 'Tem certeza que deseja excluir a conduta selecionada?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          loading(this.fichaService.excluirConduta(this.ficha, this.conduta).subscribe((ficha) => {
            this.handleCLickClose(false, { excluirConduta: true, ficha });
            toast('Conduta excluída com sucesso');
          }));
        }
      }
    });
    await alert.present();
  }

  async handleConfirmStepClick() {
    const condutasDePeriodo = this.condutaService.getCondutasDePeriodo().filter((conduta) => conduta !== 'Vigilância ativa');
    const hasCondutaDePeriodo = condutasDePeriodo.some((condutaPeriodo) => this.ficha?.condutas?.some(conduta => conduta.tipo === condutaPeriodo));
    let tipo = this.form.get('tipo').value;
    let subtipo = this.form.get('subtipo').value;

    if (tipo && tipo !== this.oldCondutaTipo && this.passoAtual === 'selecao-tipo') this.form.get('drogas').setValue([]);

    const verificar = async () => {
      const { confirm: houveRecidiva, ultimaConduta } = await this.verificarUltimaCondutaDePeriodo();
      if (!houveRecidiva && !!ultimaConduta) {
        this.form.get('continuidade').setValue(!houveRecidiva, { emitEvent: false });
      }
    };

    if (this.passoAtual === 'selecao-tipo' && !!tipo && !subtipo) {
      if (tipo !== 'Seguimento' && tipo !== 'Vigilância ativa') {
        await verificar();
      }
    }

    const etapaConfig: {
      proximoPasso: PassoAdicionarConduta;
      altura: number
    } = (CondutaProstataService.passosCondutaMapping || {})[`${tipo}${subtipo ? ` ${subtipo}` : ''}`];

    if (!this.filtro) {
      this.sugestaoConduta = SugestaoConduta.get(
        this.ficha,
        tipo,
        subtipo
      );
    }

    if (String(etapaConfig.proximoPasso).includes('subtipo')) {
      this.form.get('subtipo').setValue(this.oldCondutaSubtipo, { emitEvent : false });
    }

    if (this.passoAtual === 'calendario-fim') {
      if (moment(this.form.get('dataFim').value).isBefore(this.form.get('data').value)) {
        await toast('É necessário que a data fim seja posterior a data de início', 'warning');
        return;
      } else {
        if (!['Tratamento paliativo'].includes(tipo)) this.form.removeControl('operadorDrogas');
        await this.validateAndSaveConduta();
        return;
      }
    }

    if (etapaConfig && etapaConfig.proximoPasso !== this.passoAtual && this.passoAtual !== 'calendario') {
      this.passoAtual = etapaConfig.proximoPasso;
      let altura = etapaConfig.altura;
      if (['drogas-tratamento-paliativo'].includes(this.passoAtual)) {
        if (this.sugestaoConduta?.length) altura = (altura + 75);
        if (this.filtro) altura = (altura + 190);
      }
      this.setHeight(altura);
    } else if (['drogas-tratamento-paliativo'].includes(this.passoAtual)) {
      this.passoAtual = passoCalendario.proximoPasso;
      this.setHeight(passoCalendario.altura)
    } else {
      if (this.form.invalid && this.passoAtual !== 'calendario') {
        await toast('É necessário preencher os dados para esta conduta', 'warning');
      } else {
        if (this.passoAtual === 'calendario' && this.filtro) {
          this.passoAtual = 'calendario-fim';
        } else {
          await this.validateAndSaveConduta();
        }
      }
    }
  }

  verificarUltimaCondutaDePeriodo(): Promise<{ confirm: boolean, ultimaConduta?: CondutaProstata }> {
    return new Promise(async (resolve) => {
      const ultimaConduta: CondutaProstata = (this.ficha?.condutas || [])[(this.ficha?.condutas?.length - 1) || 0] as CondutaProstata;
      if (!ultimaConduta) resolve({ confirm: true });
      else {
        const isCondutaDePeriodo = this.condutaService.getCondutasDePeriodo().includes(ultimaConduta.tipo);
        if (isCondutaDePeriodo) {
          const alert = await this.alertHelper.create({
            title: 'Conduta ativa',
            text: 'Houve Progressão de doença ou recidiva?',
            okText: 'Sim',
            cancelText: 'Não',
            confirmButtonType: 'default',
            cancelButtonType: 'danger',
            handler: async ({data}) => {
              resolve({ confirm: data.confirm, ultimaConduta });
            }
          });
          await alert.present();
        } else {
          resolve({ confirm: true });
        }
      }
    });
  }

  validateAndSaveConduta() {
    const condutaFormValues = this.form.getRawValue();
    const conduta: any = {};
    Object.keys(condutaFormValues).forEach((key) => {
      if (condutaFormValues[key] !== undefined) conduta[key] = condutaFormValues[key];
    });
    if (this.conduta?.id) conduta.id = this.conduta.id;
    return this.modalController.dismiss({ path: 'conduta', conduta });
  }
}
