import { Component } from '@angular/core';
import { Router } from '@angular/router';
import MenuService from '../../../../shared/services/menu.service';
import { ROUTE } from '../../../../shared/constants/route';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.page.html',
})
export class PoliticaPrivacidadePage {

  constructor(private router: Router,
              private menuService: MenuService) { }

  async ionViewWillEnter() {
    await this.menuService.disableMenu();
  }

  async handleClickVoltar() {
    await this.router.navigate([ROUTE.LOGIN], { replaceUrl: true });
  }
}
