import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/pulmao-sclc/util';

export const ESTADIAMENTO_CHAVE = 'estadiamento';
export const ESTADIAMENTO_OPTIONS = [
  {
    nome: 'Doença limitada',
    valor: 'Doença limitada',
    hint: 'Tumor confinado a um hemitórax e linfonodos regionais que podem sor envolvidos em um só campo de radioterapia (RT). Linfonodos mediastinais contralaterais e linfonodos supraclaviculares ipsilaterais são geralmente classificados como doença limitada.'
  },
  {
    nome: 'Doença extensa',
    valor: 'Doença extensa',
    hint: 'Doença que não seja limitada. Inclui também derrame pleural maligno. Linfonodos hilares contralaterais e linfonodos supraclaviculares contralaterais são geralmente classificados como doença extensa.'
  },
  {
    nome: 'Desconhecido',
    valor: 'Desconhecido',
    hint: 'Desconhecido',
    customClasses: 'italic'
  }
];

@Component({
  selector: 'app-input-estadiamento',
  templateUrl: './input-estadiamento.component.html',
})
export class InputEstadiamentoComponent extends AbstractModal implements OnInit {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '250px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;

  form: FormGroup = new FormGroup({
    estadiamento: new FormControl('')
  });

  estadiamentoOptions: SelectionOption[] = ESTADIAMENTO_OPTIONS;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { estadiamento, id } = inputValueResolver(ESTADIAMENTO_CHAVE, this.ficha?.itens);
    this.id = id;
    if (estadiamento !== undefined) this.form.patchValue({ estadiamento });
  }

  async handleEndEditClick() {
    if (this.form.get(ESTADIAMENTO_CHAVE).pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: ESTADIAMENTO_CHAVE,
        valor: this.form.get(ESTADIAMENTO_CHAVE).value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
