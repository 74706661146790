import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/pulmao-nsclc/util';

@Component({
  selector: 'app-input-pdl1',
  templateUrl: './input-pdl1.component.html',
})
export class InputPdl1Component extends AbstractModal implements OnInit {

  id: string;
  ficha: Ficha;
  offsetWidth = 10;
  fixedHeight = '495px';
  $digestingNaoTestado: boolean = false;
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    pdl1: new FormControl('<1%')
  });
  naoTestadoForm: FormGroup = new FormGroup({
    pdl1: new FormControl(true),
  });
  pdl1Options: RangeConfig = {
    options: [
      {
        label: '<1%',
        value: '<1%',
      },
      {
        label: '1% a 49%',
        value: '1% a 49%',
      },
      {
        label: '≥50%',
        value: '≥50%',
      },
    ]
  };

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { pdl1, id } = inputValueResolver('pdl1', this.ficha?.itens);
    this.id = id;
    if (pdl1 !== undefined) {
      this.form.patchValue({
        pdl1: pdl1 ? pdl1 : '<1%'
      });
    }
    this.naoTestadoForm.patchValue({ pdl1 : pdl1 === null });
    this.registerFormListeners();
  }

  registerFormListeners() {
    const pdl1FormControl = this.form.get('pdl1');
    const pdl1NaoTestadoFormControl = this.naoTestadoForm.get('pdl1');
    pdl1NaoTestadoFormControl.valueChanges.subscribe((value) => {
      this.$digestingNaoTestado = true;
      if (value) {
        this.form.controls.pdl1.setValue('<1%');
      }
    });
    pdl1FormControl.valueChanges.subscribe(() => {
      if (this.$digestingNaoTestado) {
        return this.$digestingNaoTestado = false;
      }
      pdl1NaoTestadoFormControl.setValue(false, { emitEvent : false });
    });
  }

  async handleEndEditClick() {
    const newItem: ItemFicha = {
      chave: 'pdl1',
      valor: !this.naoTestadoForm.get('pdl1').value ? this.form.get('pdl1').value : null
    };
    if (this.id) newItem.id = this.id;
    const items: ItemFicha[] = [newItem];
    await this.modalController.dismiss(items);
  }
}
