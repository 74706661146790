import { Component, OnInit, Injector } from '@angular/core';
import { AbstractModal } from '../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../interfaces/input-with-modal';
import { ResponsiveService } from '../../../services/responsive.service';
import { inputValueResolver } from '../../../../app/modules/client/modulos-cancer/mama/util';
import { ItemFicha, Ficha } from '../../../models/ficha.model';
import { FormGroup, FormControl } from '@angular/forms';
import { SelectionOption } from '../../../components/_base/abstract-input';
import { DominioService } from '../../../services/dominio.service';
import { toast } from '../../../util/toast';

@Component({
  selector: 'app-input-tipo-atendimento',
  templateUrl: './input-tipo-atendimento.component.html',
})
export class InputTipoAtendimentoComponent  extends AbstractModal implements InputWithModal {
  
  fichaPaciente: any;
  id: string;
  offsetWidth = 10;
  fixedHeight = '245px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  
  tiposAtendimentos: SelectionOption[] = [];
  
  form: FormGroup = new FormGroup({
    tipoAtendiimento: new FormControl('')
  });
  
  constructor(public override injector: Injector,
              private dominioService: DominioService,
              public responsiveService: ResponsiveService) {
    super(injector);
  }
  
  ngOnInit() {
    this.getTiposAtentimentos();
    const { tipoAtendiimento, id } = inputValueResolver('tipoAtendimento', this.fichaPaciente?.itens);
    this.id = id;
    if (tipoAtendiimento !== undefined) this.form.patchValue({ tipoAtendiimento });
  }
  
  async handleEndEditClick() {
    if (this.form.get('tipoAtendiimento').pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: 'tipoAtendiimento',
        valor: this.form.get('tipoAtendiimento').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
  
  getTiposAtentimentos() {
    this.dominioService.getTipoAtendimento().subscribe(res => {
      this.tiposAtendimentos = res;
    }, () => {
      toast('Erro ao recuperar tipos de atendimento');
    })
  }

}
