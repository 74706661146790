import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-extended-header',
  templateUrl: './extended-header.component.html',
})
export class ExtendedHeaderComponent {

  @Input()
  background: boolean = true;

  constructor() { }
}
