import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import { ResponsiveService } from '../../../../services/responsive.service';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/colorretal-canal-anal/util';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';

export const HISTOPATOLOGICO_CHAVE = 'histopatologico';
export const HISTOPATOLOGICO_OPTIONS = [
  {
    nome: 'CEC',
    valor: 'CEC',
  },
  {
    nome: 'Adenocarcinoma',
    valor: 'Adenocarcinoma',
  },
  {
    nome: 'Outros',
    valor: 'Outros',
    customClasses: 'italic'
  },
];

@Component({
  selector: 'app-input-histopatologico',
  templateUrl: './input-histopatologico.component.html',
})
export class InputHistopatologicoComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '250px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;

  form: FormGroup = new FormGroup({
    histopatologico: new FormControl('')
  });

  // TODO: Buscar esses valores de algum lugar fixo...
  areaOptions: SelectionOption[] = HISTOPATOLOGICO_OPTIONS;

  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }
  
  ngOnInit() {
    const { histopatologico, id } = inputValueResolver(HISTOPATOLOGICO_CHAVE, this.ficha?.itens);
    this.id = id;
    if (histopatologico !== undefined) this.form.patchValue({ histopatologico });
  }

  async handleEndEditClick() {
    const histopatologico = this.form.get(HISTOPATOLOGICO_CHAVE).value;
    if (histopatologico === 'Adenocarcinoma') {
      await this.tratarAdenocarcinoma();
    } else {
      await this.handleSalvarItem();
    }
  }

  async tratarAdenocarcinoma() {
    const alert = await this.alertHelper.create({
      title: 'Adenocarcinoma',
      text: 'Deseja tratar como câncer de reto?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          setTimeout(this.handleSalvarItem.bind(this), 300);
        }
      }
    });
    await alert.present();
  }

  async handleSalvarItem() {
    if (this.form.get(HISTOPATOLOGICO_CHAVE).pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: HISTOPATOLOGICO_CHAVE,
        valor: this.form.get(HISTOPATOLOGICO_CHAVE).value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
