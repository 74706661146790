import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { SelectionOption } from '../components/_base/abstract-input';
import { environment } from '../../environments/environment';
import { ConteudoRelacionado, Progresso } from '../models/campanha.model';
import { Pergunta } from '../models/pergunta';
import { getAppUrl } from '../util/utils';

@Injectable({
	providedIn: 'root'
})
export class CampanhaService extends AbstractService<any> {
	
	constructor(public http: HttpClient) {
		super('campanha', http);
	}
	
	postPergunta(dto: any) {
		return super.post(dto, 'manter-pergunta-quiz');
	}
	
	getPerguntas(modulo) {
		return super.get( 'perguntas-quiz', { modulo });
	}
	
	getPerguntaAleatoria(modulo): Observable<Pergunta> {
		return super.get( 'pergunta-aleatoria-quiz', { modulo }) as Observable<any>;
	}
	
	responderPerguntaQuiz(dto: any) {
		return super.post(dto, 'responder-pergunta-quiz');
	}
	
	excluirPergunta(dto: any) {
		return super.post(dto,'excluir-pergunta-quiz');
	}
	
	getProgresso(modulo): Observable<Progresso> {
		return super.get('progressao-quiz', { modulo }) as any;
	}
	
	getRanking(modulo) {
		return super.get('ranking-campanha', { modulo });
	}

	getPremios(modulo: string) {
		return super.get('premios', { modulo });
	}

	getConteudoRelacionadoPergunta(modulo: string, tags: string[]) {
		return super.get('relacionado/tag', { modulo, tags: tags.join(',') });
	}

	listarTodosConteudosRelacionadosPorModulo(modulo: string) {
		return super.get('relacionado', { modulo });
	}

	adicionarConteudoRelacionado(conteudo: ConteudoRelacionado) {
		return super.post(conteudo, 'relacionado');
	}

	editarConteudoRelacionado(conteudo: ConteudoRelacionado) {
		return this.http.put<any>(`${getAppUrl()}/campanha/relacionado`, conteudo);
	}

	getImagemConteudoRelacionado(conteudo: ConteudoRelacionado) {
		return super.get(`relacionado/${conteudo._id}/imagem`);
	}

	deletarConteudoRelacionado(conteudo: ConteudoRelacionado) {
		return this.http.delete<any>(`${getAppUrl()}/campanha/relacionado/${conteudo._id}`);
	}
}
