import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import UsuarioService from '../../services/usuario.service';
import { SelectionOption } from '../../components/_base/abstract-input';
import { FormControl } from '@angular/forms';
import { loading } from '../../util/loading';
import { Usuario } from '../../models/usuario.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-confirmar-staff',
  templateUrl: './confirmar-staff.component.html',
})
export class ConfirmarStaffComponent extends AbstractModal implements OnInit {

  fixedHeight = '400px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  staffsOptions: SelectionOption[];
  staffControl: FormControl = new FormControl('');
  usuario: Usuario;
  currentStaff: any;

  constructor(public readonly injector: Injector,
              private usuarioService: UsuarioService,
              private authService: AuthService) {
    super(injector);
  }
  
  ngOnInit() {
   this.usuario = this.authService.getUser();
   this.loadStaffs();
  }

  loadStaffs() {
    loading(this.usuarioService.listarStaffs().subscribe((staffs) => {
      this.staffsOptions = staffs;
      this.currentStaff = this.staffsOptions.find(staff => staff.valor === this.usuario.currentStaff);
    }));
  }
}
