import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../_base/abstract-modal';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
})
export class CustomDatepickerComponent extends AbstractModal {

  date: Date;
  minDate: Date;
  offsetWidth = 10;
  fixedHeight = '360px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '400px' : null;
  dataControl: FormControl;

  constructor(public override injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.dataControl = new FormControl(moment(this.date).format('YYYY-MM-DD'));
  }

  async handleEndEditClick() {
    const formattedDate = parseISO(this.dataControl.value?.split('/').reverse().join('-'));
    await this.handleCLickClose(true, { date: formattedDate });
  }
}
