import {
  CONDUTA_1,
  CONDUTA_10, CONDUTA_11, CONDUTA_12, CONDUTA_13, CONDUTA_14, CONDUTA_15,
  CONDUTA_16,
  CONDUTA_17,
  CONDUTA_18, CONDUTA_2, CONDUTA_3, CONDUTA_4, CONDUTA_5, CONDUTA_6, CONDUTA_7, CONDUTA_8,
  CONDUTA_9, CONDUTA_BRCA, CONDUTA_CPS_PDL1, CONDUTA_PIK3CA
} from '../../../../../../shared/constants/sugestao-conduta';
import { Ficha } from '../../../../../../shared/models/ficha.model';

export class SugestaoConduta {

  private static condutas = {
    M0: {
      'Hormonioterapia Neoadjuvante': {
        ['RH+'] : {
          ['HER2-'] : CONDUTA_18, // OK
          ['HER2+'] : CONDUTA_17,  // OK
        }
      },
      'Hormonioterapia Adjuvante': {
        ['RH+'] : {
          ['HER2-'] : CONDUTA_10, // OK
          ['HER2+'] : CONDUTA_9,  // OK
        }
      },
      'Quimioterapia Neoadjuvante' : {
        ['RH-'] : {
          ['HER2-'] : CONDUTA_16, // OK
          ['HER2+'] : CONDUTA_15  // OK
        },
        ['RH+'] : {
          ['HER2-'] : CONDUTA_13, // OK
          ['HER2+'] : CONDUTA_14  // OK
        }
      },
      'Quimioterapia Adjuvante' : {
        ['RH-'] : {
          ['HER2-'] : CONDUTA_7,  // OK
          ['HER2+'] : CONDUTA_6  // OK
        },
        ['RH+'] : {
          ['HER2-'] : CONDUTA_8,  // OK
          ['HER2+'] : CONDUTA_5  // OK
        }
      }
    },
    M1 : {
      'Quimioterapia Paliativa': {
        ['RH+'] : {
          ['HER2-'] : CONDUTA_4, // OK
          ['HER2+'] : CONDUTA_2 // OK
        },
        ['RH-'] : {
          ['HER2-'] : CONDUTA_3, // OK
          ['HER2+'] : CONDUTA_1  // OK
        }
      },
      'Hormonioterapia Paliativa': {
        ['RH+'] : {
          ['HER2-'] : CONDUTA_11,  // OK
          ['HER2+'] : CONDUTA_12  // OK
        }
      }
    }
  };

  private static getReceptorHormonal(ficha: Ficha) {
    const itemRe = ficha.itens?.find((item) => item.chave === 're');
    const itemRp = ficha.itens?.find((item) => item.chave === 'rp');
    if (itemRe?.valor >= 10 && itemRp?.valor >= 10) { return 'RH+'; }
    else if (itemRe?.valor < 10 || itemRp?.valor < 10)  { return 'RH-'; }
  }

  private static getHer2(ficha: Ficha) {
    const itemHer2 = ficha.itens?.find((item) => item.chave === 'her2');
    if (['+3', 'FISH+'].includes(String(itemHer2?.valor))) {
      return 'HER2+';
    } else if (['0', '+1', '+2'].includes(String(itemHer2?.valor))) {
      return 'HER2-';
    }
  }

  private static getCondutaAgnostica(ficha: Ficha) {
    let condutas = [];
    const outrosTestes = ficha?.itens?.filter((item) => item.chave.startsWith('outrosTestes'));

    if (!outrosTestes?.length) { return condutas; }

    if (outrosTestes.some(teste => teste.chave.startsWith('outrosTestes.BRCA') && !!teste.valor)) {
      condutas.push('');
      condutas = condutas.concat(CONDUTA_BRCA);
    }

    if (outrosTestes.some(teste => teste.chave === 'outrosTestes.PIK3CA' && !!teste.valor)) {
      condutas.push('');
      condutas = condutas.concat(CONDUTA_PIK3CA);
    }

    if (outrosTestes.some(teste => teste.chave === 'outrosTestes.CPS/PDL-1' && !!teste.valor)) {
      condutas.push('');
      condutas = condutas.concat(CONDUTA_CPS_PDL1);
    }

    return condutas;
  }

  static get(ficha: Ficha, tipo, subtipo) {
    const receptorHormonal = SugestaoConduta.getReceptorHormonal(ficha);
    let itemM: any = ficha.itens?.find((item) => item.chave === 'm');
    const her2 = SugestaoConduta.getHer2(ficha);
    let condutaParametrizada = [];

    try {
      condutaParametrizada = SugestaoConduta.condutas[itemM.valor][`${tipo} ${subtipo}`][receptorHormonal][her2];
    } catch (e) {}

    return [].concat(condutaParametrizada, SugestaoConduta.getCondutaAgnostica(ficha));
  }
}
