import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionOption } from '../../../../shared/components/_base/abstract-input';
import { Subject, Subscription, zip } from 'rxjs';
import MenuService from '../../../../shared/services/menu.service';
import UsuarioService from '../../../../shared/services/usuario.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { DominioService } from '../../../../shared/services/dominio.service';
import { environment } from '../../../../environments/environment';
import { ValidatorsApp } from '../../../../shared/util/validators';
import { toast } from '../../../../shared/util/toast';
import { FALE_CONOSCO, POLITICA_PRIVACIDADE, ROUTE, TERMOS_CONDICOES } from '../../../../shared/constants/route';
import { loading } from '../../../../shared/util/loading';
import { Usuario } from '../../../../shared/models/usuario.model';
import CustomModalHelper from '../../../../shared/helper/custom-modal-helper';
import { SolicitarCadastroClinicaComponent } from '../../../../shared/modals/solicitar-cadastro-clinica/solicitar-cadastro-clinica.component';
import { GrupoService } from '../../../../shared/services/grupo.service';
import { Clinica, Grupo } from '../../../../shared/models/clinica.model';
import { ClinicaService } from '../../../../shared/services/clinica.service';

const formInstituicoesTemplate = () => {
  return new FormGroup({
    estado: new FormControl(''),
    cidade: new FormControl(''),
    cnpj: new FormControl('', Validators.compose([ValidatorsApp.cnpj])),
    grupo: new FormControl(''),
    instituicao: new FormControl(''),
  });
};

const formCrmTemplate = () => {
  return new FormGroup({
    numero: new FormControl('', [Validators.required]),
    uf: new FormControl('', [Validators.required]),
    especialidade: new FormControl(''),
  });
};

const CLINICA_NAO_ENCONTRADA_OPTION = {
  nome: 'Não encontrei minha instituição',
  valor: 'instituicao-nao-encontrada',
  notFilterable: true
};

@Component({
  selector: 'app-cadastro-usuario',
  templateUrl: './cadastro-usuario.page.html',
})
export class CadastroUsuarioPage {

  socialLoginConfig;

  @ViewChild('ionSlidesRef')
  ionSlidesRef: IonSlides;

  formDadosPessoais: FormGroup = new FormGroup({
    aceiteTermos: new FormControl(false),
    nome: new FormControl('', [Validators.compose([Validators.required, ValidatorsApp.nome])]),
    email: new FormControl('', [Validators.compose([Validators.required, ValidatorsApp.email])]),
    telefone: new FormControl(''),
    senha: new FormControl('', [Validators.compose([
      Validators.required,
      Validators.minLength(6)
    ])]),
  });

  preValidacaoForm: FormGroup = formCrmTemplate();

  formDetalhesPefil: FormGroup = new FormGroup({
    crms: new FormArray([
      formCrmTemplate()
    ]),
    instituicoes: new FormArray([
      formInstituicoesTemplate()
    ]),
  });

  ufsOptions: any[];
  ufsClinicas: SelectionOption[];
  estados: SelectionOption[];
  cidades: any = { 0 : [] };
  clinicas: any = { 0 : [] };
  $subscriptions: Subscription[] = [];
  footerIsVisible: boolean = true;
  loadingClinicas: any = { 0 : false };
  loadingCidades: any = { 0 : false };
  preventCidadeChange: boolean = false;

  filteredUfsClinicas = [];

  constructor(private menuService: MenuService,
              private router: Router,
              private usuarioService: UsuarioService,
              private autenticacaoService: AuthService,
              private dominioService: DominioService,
              private modalHelper: CustomModalHelper,
              private clinicaService: ClinicaService,
              private grupoService: GrupoService) {
    const { availableSocialLogin } = environment;
    this.socialLoginConfig = Object.keys(availableSocialLogin).filter(socialNet => !!availableSocialLogin[socialNet]);
  }

  get crms() {
    return this.formDetalhesPefil.controls["crms"] as FormArray;
  }

  get instituicoes() {
    return this.formDetalhesPefil.controls["instituicoes"] as FormArray;
  }

  setFilteredUfsClinicas() {
    const selectedUfs = this.crms.getRawValue();
    const selectedUfsMapping = {};
    selectedUfs.forEach(a => selectedUfsMapping[a.uf] = true);
    this.filteredUfsClinicas = Object.keys(selectedUfsMapping).map(uf => ({ nome: uf, valor: uf }));
    this.resetNecessaryFormsFields();
  }

  async handleCrmValueChange(fieldIndex: number) {
    if (fieldIndex === 0) { return; }
    const crm = this.crms.at(fieldIndex).get('numero').value;
    const uf = this.crms.at(fieldIndex).get('uf').value;
    if (!!uf && !!crm) {
      await loading(this.usuarioService.validarCRM(crm, uf).subscribe(async (response: any) => {
        if (response) {
          const { nome } = response;
          const nomeUsuario = this.formDadosPessoais.get('nome').value;
          if (String(nome).toUpperCase() !== String(nomeUsuario).toUpperCase()) {
            await toast('O CRM informado não confere com o nome de usuário do CRM anteriormente informado, será desconsiderado');
            this.crms.at(fieldIndex).get('numero').setValue('');
          }
        }
      }, async (response) => {
        await toast(response.error.message);
        this.crms.at(fieldIndex).get('numero').setValue('');
      }));
    }
  }

  resetNecessaryFormsFields() {
   const { controls } = this.instituicoes;
   controls.forEach((control) => {
     const itsAvailableInSelectedUfs = this.filteredUfsClinicas.some(uf => uf.nome === control.get('estado').value);
     if (!itsAvailableInSelectedUfs) {
       control.patchValue({
         estado: '',
         cidade: '',
         instituicao: '',
       }, { emitEvent: false });
     }
   });
  }

  handleClickAdicionarCrm() {
    if (this.crms.length < 5) {
      this.crms.push(formCrmTemplate());
    } else {
      toast('Atingida a quantidade máxima de registros para CRM', 'warning');
    }
  }

  handleClickRemoverCrm() {
    this.crms.removeAt(this.crms.length - 1);
  }

  handleClickAdicionarInstituicao() {
    if (this.instituicoes.controls[this.instituicoes.controls.length - 1].invalid) { return; }
    if (this.instituicoes.length < 5) {
      const lastCidadesOptionsIndex = Math.max(...Object.keys(this.cidades).map(n => Number(n)));
      this.cidades[lastCidadesOptionsIndex + 1] = [];
      this.instituicoes.push(formInstituicoesTemplate());
      this.registerFormListeners();
    } else {
      toast('Atingida a quantidade máxima de registros para Clínicas de atendimento', 'warning');
    }
  }

  handleClickRemoverInstituicao() {
    this.instituicoes.removeAt(this.instituicoes.length - 1);
  }

  async ionViewWillEnter() {
    await this.menuService.disableMenu();
    this.loadDominios();
    this.registerFormListeners();
    this.formDadosPessoais.get('nome').disable();
    this.preValidacaoForm.get('especialidade').disable();
  }

  async ionViewWillLeave() {
    await this.menuService.enableMenu();
    this.$subscriptions.forEach(s => s.unsubscribe());
  }

  registerFormListeners() {
    this.$subscriptions.forEach(s => s.unsubscribe());
    this.instituicoes.controls.forEach((control, index) => {
      this.$subscriptions.push(
        control.get('estado').valueChanges.subscribe((uf) => {
          control.get('cidade').setValue(null);
          this.getCidadesByUfToFormControl(uf, control, index);
        }),
        control.get('cnpj').valueChanges.subscribe((cnpj) => {
          const error = ValidatorsApp.cnpj(control.get('cnpj'));
          if (cnpj && error === null) {
            loading(this.grupoService.getByCpnj(cnpj).subscribe((grupo: Grupo) => {
              if (grupo && grupo?.clinicas?.length) {
                control.get('grupo').setValue(grupo.nome);
                this.clinicas[index] = grupo.clinicas.map(clinica => ({ nome: clinica.nome, valor: clinica._id }));
                this.clinicas[index].unshift(CLINICA_NAO_ENCONTRADA_OPTION);
                if (grupo.clinicas.length === 1) {
                  control.get('instituicao').setValue(this.clinicas[index][0].valor);
                }
              } else {
                loading(this.clinicaService.getClinicaByCpnj(cnpj).subscribe((clinica: Clinica) => {
                  if (clinica) {
                    const { grupo } = clinica;
                    this.clinicas[index] = grupo.clinicas.map(clinica => ({ nome: clinica.nome, valor: clinica._id }));
                    this.clinicas[index].unshift(CLINICA_NAO_ENCONTRADA_OPTION);
                    control.get('instituicao').setValue(clinica._id, { emitEvent: false });
                    control.get('grupo').setValue(grupo.nome, { emitEvent: false });
                  } else {
                    toast(`Grupo não encontrado na nossa base de dados, por favor verifique o CNPJ ou clique em "Solicitar Cadastro"`);
                  }
                }));
              }
            }));
          } else {
            control.get('instituicao').setValue('', { emitEvent: false });
            control.get('grupo').setValue('', { emitEvent: false });
          }
        }),
        control.get('cidade').valueChanges.subscribe((cidade) => {
          if (cidade !== null) {
            control.get('instituicao').setValue('');
            if (this.preventCidadeChange) {
              this.preventCidadeChange = false;
              return;
            }
            this.resetNecessaryFormsFields();
            const uf = control.get('estado').value;
            this.getClinicasByUfCidadeToFormControl(uf, cidade, control, index);
          }}),
        control.get('instituicao').valueChanges.subscribe(async (instituicao) => {
          if (instituicao === 'instituicao-nao-encontrada') {
            control.get('instituicao').setValue('');
            await this.handleSolicitarCadastroClinicaCLick(index);
          }
        })
      );
    });
    const consultarCRM = () => {
      const { numero, uf } = this.preValidacaoForm.getRawValue();
      if (!!numero && !!uf) {
        loading(this.usuarioService.validarCRM(numero, uf).subscribe((response: any) => {
          if (Object.keys(response).length) {
            const { nome, especialidade } = response;
            this.formDadosPessoais.get('nome').setValue(String(nome).toUpperCase());
            this.preValidacaoForm.get('especialidade').setValue(String(especialidade).toUpperCase());
          }
        }, async (response) => {
          this.formDadosPessoais.get('nome').setValue('');
          this.preValidacaoForm.get('especialidade').setValue('');
          await toast(response.error.message);
        }));
      }
    };
    this.preValidacaoForm.get('numero').valueChanges.subscribe(consultarCRM);
    this.preValidacaoForm.get('uf').valueChanges.subscribe(consultarCRM);
  }

  getClinicasByUfCidadeToFormControl(uf, cidade, control, index, setUniqueValue = true) {
    this.loadingClinicas[index] = true;
    const subject = new Subject();
    loading(this.clinicaService.getClinicas(uf, cidade).subscribe((clinicas) => {
      this.clinicas[index] = clinicas.map(clinica => ({ nome: clinica.nome, valor: clinica._id }));
      this.clinicas[index].unshift(CLINICA_NAO_ENCONTRADA_OPTION);
      if (clinicas.length === 1 && setUniqueValue) {
        control.get('instituicao').setValue(this.clinicas[index][1].valor);
      }
      subject.next();
      this.loadingClinicas[index] = false;
    }));
    return subject;
  }

  getCidadesByUfToFormControl(uf, control, index, setOnControl = true) {
    this.loadingCidades[index] = true;
    const subject = new Subject();
    loading(this.dominioService.getCidadesByUf(uf).subscribe((cidades) => {
      ['cidade', 'instituicao'].forEach(c => control.get(c).setValue('', { emitEvent: false }));
      this.cidades[index] = cidades;
      if (cidades.length === 1 && setOnControl) {
        control.get('cidade').setValue(cidades[0].valor);
      }
      subject.next();
      this.loadingCidades[index] = false;
    }));
    return subject;
  }

  async handleClickLogin() {
    await this.router.navigate([ROUTE.LOGIN]);
  }

  async handleProsseguirClick() {
    if (!this.formDadosPessoais.get('aceiteTermos').value) {
      toast('Para prosseguir é necessário concordar com a política de privacidade e termos');
      return;
    }
    if (ValidatorsApp.formularioValido(this.formDadosPessoais)) {
      await this.ionSlidesRef.slideNext();
      this.crms.at(0).patchValue({
        numero: this.preValidacaoForm.get('numero').value,
        uf: this.preValidacaoForm.get('uf').value,
        especialidade: this.preValidacaoForm.get('especialidade').value,
      }, { emitEvent: false });
      // this.crms.at(0).get('numero').disable();
      // setTimeout(() => {
      //   this.crms.at(0).get('uf').disable();
      // }, 100);
      this.crms.at(0).get('numero').setValidators(null);
      this.crms.at(0).get('uf').setValidators(null);
      this.setFilteredUfsClinicas();
      this.footerIsVisible = false;
    }
  }

  async handleClickVoltar() {
    await this.ionSlidesRef.slidePrev();
    this.footerIsVisible = true;
    this.crms.at(0).get('numero').setValidators([Validators.required]);
    this.crms.at(0).get('uf').setValidators(Validators.required);
  }

  loadDominios() {
    loading(zip(
      this.dominioService.getUfs(),
      this.dominioService.getEstados(),
    ).subscribe(([ufs, estados]) => {
      this.ufsOptions = ufs.sort((a, b) => a.nome.localeCompare(b.nome));
      this.estados = estados.sort((a, b) => a.nome.localeCompare(b.nome));
    }));
  }

  getCidadesOptions(index) {
    return this.cidades[index]?.options || [];
  }

  async handleSolicitarCadastroClinicaCLick(index?) {
    index = index ? index : this.instituicoes.length - 1;
    const modal = await this.modalHelper.create({
      component: SolicitarCadastroClinicaComponent,
      componentProps: {
        estado: this.instituicoes.at(index)?.get('estado').value,
        cidade: this.instituicoes.at(index)?.get('cidade').value,
        filteredUfsClinicas: this.filteredUfsClinicas,
        validado: false
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.confirm) {
      if (data?.payload?.instituicao) {
        const clinica: Clinica = data.payload.instituicao;
        await this.setClinicaCadastrada(clinica, this.instituicoes.length - 1);
      }
    }
  }

  async handleTermosUsoClick() {
    await this.router.navigate([TERMOS_CONDICOES], { replaceUrl: true });
  }

  async handleFaleConoscoCLick() {
    await this.router.navigate([FALE_CONOSCO], { replaceUrl: true });
  }

  async handlePoliticaPrivacidadeClick() {
    await this.router.navigate([POLITICA_PRIVACIDADE], { replaceUrl: true });
  }

  handleChangeAceiteTermos(checked) {
    this.formDadosPessoais.get('aceiteTermos').setValue(checked);
  }

  async setClinicaCadastrada(clinica: Clinica, controlIndex) {
    const selectedUfs = this.filteredUfsClinicas.map(c => c.nome);
    const hasClinicaOnUfCrm = selectedUfs.includes(clinica.uf);
    if (hasClinicaOnUfCrm) {
      this.clinicas[controlIndex] = clinica.grupo?.clinicas?.map(clinica => ({ nome: clinica.nome, valor: clinica._id }));
      const control = this.instituicoes.at(controlIndex);
      control.patchValue({
        cnpj: clinica.cnpj,
        grupo: clinica.grupo?.nome,
      }, { emitEvent: false });
      control.get('estado').setValue(clinica.uf, { emitEvent: false });
      this.getCidadesByUfToFormControl(clinica.uf, control, controlIndex, false).subscribe(() => {
        this.preventCidadeChange = true;
        control.get('cidade').setValue(clinica.cidade);
        this.getClinicasByUfCidadeToFormControl(clinica.uf, clinica.cidade, control, controlIndex, false)
          .subscribe(() => {
          control.get('instituicao').setValue(clinica._id);
        });
      });
    } else {
      await toast('A instituição selecionada não está presente nas ufs dos CRMs informados, cadastre um CRM na UF da instituição desejada');
    }
  }

  handleClickFinalizarCadastro() {
    if (ValidatorsApp.formularioValido(this.formDadosPessoais) && this.crms.valid && this.instituicoes.valid) {
      const usuario: Usuario = {
        ...this.formDadosPessoais.getRawValue(),
        crmList : this.crms.getRawValue(),
        clinicaIdList: this.instituicoes.getRawValue()
          .map((value) => value.instituicao)
          .filter(id => !!id),
        perfil: 'medico'
      };
      usuario.nome = String(usuario.nome).toUpperCase();
      if (usuario.telefone) usuario.telefone = String(usuario.telefone).replace(/\D/g, '');
      loading(this.usuarioService.cadastrar(usuario)
        .subscribe(async (usuarioCadastrado) => {
          toast(`Usuário cadastrado com sucesso, uma mensagem de confirmação de cadastro foi enviada para o e-mail ${usuario.email}, após a confirmação do cadastro você poderá efetuar o login.`);
          await this.autenticacaoService.setUser(usuarioCadastrado);
          await this.router.navigate([ROUTE.MODULOS_CANCER], { replaceUrl: true });
        })
      );
    } else {
      toast('Por favor, preencha todos os campos corretamente antes de confirmar');
    }
  }
}
