import { Component, Injector, Input } from '@angular/core';
import { AbstractModal } from '../_base/abstract-modal';
import { ButtonType } from '../custom-button/custom-button.component';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
})
export class CustomAlertComponent extends AbstractModal {

  @Input() title;
  @Input() text;
  @Input() okText;
  @Input() cancelText;
  @Input() confirmButtonType: ButtonType = 'primary';
  @Input() cancelButtonType: ButtonType = 'default';
  @Input() confirmButtonIcon: string;

  offsetWidth = this.responsiveService.isMobilePlatform ? 10 : null;

  constructor(public injector: Injector,
              public responsiveService: ResponsiveService) {
    super(injector);
  }
}
