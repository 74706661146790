import { environment } from '../../environments/environment';
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  formatDuration,
  intervalToDuration,
  subDays
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { RangeConfig } from '../components/_base/abstract-input';

export const compareTextIndexOf = (str1, str2) => removerAcentos(str1).toLowerCase().indexOf(removerAcentos(str2).toLowerCase()) > -1;

export const getAppUrl = () => {
  return environment.apiUrl;
};

export const removerAcentos = (value: string) => {
  const mapHexAcentos = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g,
  };

  // eslint-disable-next-line guard-for-in
  for (const char in mapHexAcentos) {
    const regExp = mapHexAcentos[char];

    value = value.replace(regExp, char);
  }

  return value;
};

export const luhnChk = (((arr) => (ccNum) => {
    // tslint:disable-next-line:one-variable-per-declaration
    let
      len = ccNum.length;
      let bit = 1;
      let sum = 0;
      let val;

    while (len) {
      val = parseInt(ccNum.charAt(--len), 10);
      // tslint:disable-next-line:no-bitwise no-conditional-assignment
      sum += (bit ^= 1) ? arr[val] : val;
    }

    return sum && sum % 10 === 0;
  })([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

export const soDigitos = (numeroFormatado: string) => {
  if (typeof numeroFormatado === 'number') {
    return numeroFormatado + '';
  }
  const numerosArr = numeroFormatado.match(/\d/g);

  if (numerosArr && numerosArr.length) {
    return numerosArr.join('');
  } else {
    return numeroFormatado;
  }
};

export const calculateAge = (dob) => {
  return `${differenceInYears(new Date(), new Date(dob))}`;
}

export const getDiffInMonths = (date) => {
  return `${differenceInMonths(new Date(), new Date(date))}`;
};

export const getDiffInDays = (firstDate, lastDate) => {
  return differenceInDays(new Date(lastDate), new Date(firstDate));
};

export const intervalToDurationString = (daysCount) => {
  return formatDuration(intervalToDuration({
    start: subDays(new Date(), daysCount),
    end: new Date(),
  }), {
    delimiter: ', ',
    format: ['years', 'months', 'days'],
    locale: ptBR
  });
};

export const encodeCnpj = (cnpj: string) => {
  cnpj = cnpj.replace('/', '%2F');
  return cnpj;
};

export const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const numBRToUs = (num: any): number => {
  return parseFloat((num+'').replace(/\./g, '').replace(/\,/g, '.'));
}

export const numUsToBR = (num: any): string => {
  return Number(num).toLocaleString('pt-BR');
}

export const toCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
}

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    throw err;
  }
}

export const copyFormattedText = async (html: string) => {
  try {
    setTimeout(async () => {
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Promise<Blob>((resolve) => {
            resolve(new Blob([html], { type: "text/html" }));
          }),
        }),
      ]);
    }, 1);
  } catch (err) {
    console.error("Failed to copy text: ", err);
    throw err;
  }
}

export const getKaplanMeierData = (data: any[]) => {
  // data = data.map(a => ({ patient: a.paciente, survivalTime: a.sobrevida, event: a.evento === 'obito oncologica' }));
  data = data.map(a => ({ patient: a.paciente, survivalTime: a.sobrevida, event: String(a.evento).includes('obito') }));
  data.sort((a, b) => a.survivalTime - b.survivalTime);
  let atRisk = data.length;
  let survivalProb = 1;
  let survivalProbs = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].event) {
      let survivalProbAtTime = (atRisk - 1) / atRisk;
      survivalProb *= survivalProbAtTime;
    }
    atRisk--;
    survivalProbs.push([data[i].survivalTime, survivalProb]);
  }
  return survivalProbs;
};

export const rangeConfigToSelectionOption = (rangeConfig: RangeConfig, recursive: boolean = true, valueIsLabel: boolean = false, distinct: boolean = true) => {
  let result = [];
  const processOptions = (item) => {
    let newOption = {
      nome: valueIsLabel ? item.value : item.label,
      valor: item.value,
    };
    result.push(newOption);
    if (item.options && recursive) {
      for (let option of item.options) {
        processOptions(option);
      }
    }
  };
  for (let item of rangeConfig.options) {
    processOptions(item);
  }
  if (distinct) {
    let distinctValues = {};
    result.forEach(option => distinctValues[option.valor] = option);
    return Object.values(distinctValues);
  }
  return result;
};

export const fromStrTo2DigitDate = (date) => {
  return `${new Date(String(date)).toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  })}`;
};
