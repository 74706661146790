import {
  HISTOPATOLOGICO_CHAVE,
  HISTOPATOLOGICO_OPTIONS,
  InputHistopatologicoComponent
} from './input-histopatologico/input-histopatologico.component';
import { InputTComponent } from './input-t/input-t.component';
import { InputNComponent } from './input-n/input-n.component';
import { InputMComponent } from './input-m/input-m.component';
import {
  InputRiscoAdjuvanciaComponent,
  RISCO_ADJUVANCIA_CHAVE,
  RISCO_ADJUVANCIA_OPTIONS
} from './input-risco-adjuvancia/input-risco-adjuvancia.component';
import { TestesMolecularesComponent } from './testes-moleculares/testes-moleculares.component';
import { AdicionarCondutaComponent } from './adicionar-conduta/adicionar-conduta.component';
import { GRAU_CHAVE, GRAU_OPTIONS, InputGrauComponent } from './input-grau/input-grau.component';
import { FilterConfig } from '../../../interfaces/filter-config';
import { rangeConfigToSelectionOption } from '../../../util/utils';
import { optionsResolver as tOptionsResolver } from './input-t/util';
import { optionsResolver as nOptionsResolver } from './input-n/util';
import { optionsResolver as mOptionsResolver } from './input-m/util';

export const MODALS = [
  InputHistopatologicoComponent,
  InputTComponent,
  InputNComponent,
  InputMComponent,
  InputRiscoAdjuvanciaComponent,
  TestesMolecularesComponent,
  AdicionarCondutaComponent,
  InputGrauComponent
];

export const FILTERS_CONFIG: FilterConfig[] = [
  {
    categoria: 'Dados de exames',
    titulo: 'Histopatológico',
    nome: HISTOPATOLOGICO_CHAVE,
    largura: '3',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: HISTOPATOLOGICO_OPTIONS
  },
  {
    categoria: 'Dados de exames',
    titulo: 'Grau',
    nome: GRAU_CHAVE,
    largura: '3',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(GRAU_OPTIONS)
  },
  {
    categoria: 'Dados de exames',
    titulo: 'T',
    alturaDinamica: false,
    nome: 't',
    largura: '3',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: [].concat(
      rangeConfigToSelectionOption(tOptionsResolver('c')),
      rangeConfigToSelectionOption(tOptionsResolver('p')),
      rangeConfigToSelectionOption(tOptionsResolver('yp')),
    )
  },
  {
    categoria: 'Dados de exames',
    titulo: 'N',
    nome: 'n',
    largura: '3',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: [].concat(
      rangeConfigToSelectionOption(nOptionsResolver('c')),
      rangeConfigToSelectionOption(nOptionsResolver('p')),
      rangeConfigToSelectionOption(nOptionsResolver('yp')),
    )
  },
  {
    categoria: 'Dados de exames',
    titulo: 'M',
    nome: 'm',
    largura: '3',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(mOptionsResolver())
  },
  {
    categoria: 'Dados de exames',
    titulo: 'Risco na adjuvância',
    nome: RISCO_ADJUVANCIA_CHAVE,
    largura: '3',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: RISCO_ADJUVANCIA_OPTIONS
  },
];
