import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AjudeMelhorarService extends AbstractService<any> {

  constructor(public http: HttpClient) {
    super('ajude-melhorar', http);
  }

  faleConosco(payload) {
    return super.post(payload, 'fale-conosco');
  }

  bugReport(mensagem) {
    return super.post({ mensagem }, 'bug-report');
  }
}

