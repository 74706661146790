export interface IMenuModuloConfig {
  id?: string;
  name: string;
  color?: string,
  icon?: string,
  available?: boolean
  title: string;
  building?: boolean;
  modules?: IMenuModuloConfig[];
}

export const MODULO_CANCER = {
  MAMA: 'mama',
  PULMAO_SCLC: 'pulmao-sclc',
  PULMAO_NSCLC: 'pulmao-nsclc',
  RIM: 'rim',
  COLORRETAL_COLON: 'colorretal-colon',
  COLORRETAL_RETO: 'colorretal-reto',
  COLORRETAL_CANAL_ANAL: 'colorretal-canal-anal',
  PROSTATA: 'prostata',
  BEXIGA: 'bexiga',
};

export const MODULO_CORES = {
  [MODULO_CANCER.MAMA] : {
    primary: 'var(--om-mama-default-color)',
    secondary: 'var(--om-mama-secondary-color)',
  },
  [MODULO_CANCER.PULMAO_NSCLC] : {
    primary: 'var(--om-pulmao-nsclc-default-color)',
    secondary: 'var(--om-pulmao-nsclc-secondary-color)',
  },
  [MODULO_CANCER.PULMAO_SCLC] : {
    primary: 'var(--om-pulmao-sclc-default-color)',
    secondary: 'var(--om-pulmao-sclc-secondary-color)',
  },
  [MODULO_CANCER.PROSTATA] : {
    primary: 'var(--om-prostata-default-color)',
    secondary: 'var(--om-prostata-secondary-color)',
  },
  [MODULO_CANCER.BEXIGA] : {
    primary: 'var(--om-bexiga-default-color)',
    secondary: 'var(--om-bexiga-secondary-color)',
  },
  [MODULO_CANCER.COLORRETAL_COLON] : {
    primary: 'var(--om-colorretal-default-color)',
    secondary: 'var(--om-colorretal-secondary-color)',
  },
  [MODULO_CANCER.COLORRETAL_RETO] : {
    primary: 'var(--om-colorretal-default-color)',
    secondary: 'var(--om-colorretal-secondary-color)',
  },
  [MODULO_CANCER.COLORRETAL_CANAL_ANAL] : {
    primary: 'var(--om-colorretal-default-color)',
    secondary: 'var(--om-colorretal-secondary-color)',
  },
  [MODULO_CANCER.RIM] : {
    primary: 'var(--om-rim-default-color)',
    secondary: 'var(--om-rim-secondary-color)',
  }
};

export const PATROCINADORES = {
  [MODULO_CANCER.MAMA] : [
    { img: 'assets/img/patrocinadores/mama/astrazeneca-logo.png', class: 'w-40' },
    { img: 'assets/img/patrocinadores/mama/novartis-logo.png', class: 'w-40' },
    { img: 'assets/img/patrocinadores/mama/lilly-logo.png', class: 'w-28' },
  ],
  [MODULO_CANCER.PROSTATA] : [
    { img: 'assets/img/patrocinadores/prostata/astellas-logo.png', class: 'w-40' },
    { img: 'assets/img/patrocinadores/prostata/astrazeneca-logo.png', class: 'w-40' },
    { img: 'assets/img/patrocinadores/prostata/bayer-logo.png' },
    { img: 'assets/img/patrocinadores/prostata/janssen-logo.png' },
    { img: 'assets/img/patrocinadores/prostata/ipsen-logo.png' },
    { img: 'assets/img/patrocinadores/prostata/merck-pfizer-logo.png' },
    { img: 'assets/img/patrocinadores/prostata/msd-logo.png' },
  ],
  [MODULO_CANCER.PULMAO_SCLC] : [],
  [MODULO_CANCER.BEXIGA] : [
    { img: 'assets/img/patrocinadores/prostata/merck-logo.png', class: 'w-40' },
  ],
};

export const menuModulosConfig: IMenuModuloConfig[] = [
  {
    name: MODULO_CANCER.MAMA,
    title: 'Mama',
    color: MODULO_CORES[MODULO_CANCER.MAMA].primary,
    icon: 'menu-modulo-mama-frame',
    available: true,
  },
  {
    name: 'pulmao',
    title: 'Pulmão',
    icon: 'menu-modulo-pulmao-frame',
    available: true,
    modules: [
      {
        name: MODULO_CANCER.PULMAO_NSCLC,
        title: 'NSCLC',
        color: MODULO_CORES[MODULO_CANCER.PULMAO_NSCLC].primary,
        icon: 'menu-modulo-pulmao-frame',
        available: true,
      },
      {
        name: MODULO_CANCER.PULMAO_SCLC,
        title: 'SCLC',
        color: MODULO_CORES[MODULO_CANCER.PULMAO_SCLC].primary,
        icon: 'menu-modulo-pulmao-frame',
        available: true,
      },
    ]
  },
  {
    name: MODULO_CANCER.PROSTATA,
    title: 'Próstata',
    color: MODULO_CORES[MODULO_CANCER.PROSTATA].primary,
    icon: 'menu-modulo-prostata-frame',
    available: true,
  },
  {
    name: 'bexiga',
    title: 'Bexiga',
    color: MODULO_CORES[MODULO_CANCER.BEXIGA].primary,
    icon: 'menu-modulo-bexiga-frame',
    available: true,
    building: true
  },
  {
    name: 'colorretal',
    title: 'Colorretal',
    icon: 'menu-modulo-colorretal-frame',
    color: MODULO_CORES[MODULO_CANCER.COLORRETAL_COLON].primary,
    available: true,
    modules: [
      {
        name: MODULO_CANCER.COLORRETAL_COLON,
        title: 'Cólon',
        color: MODULO_CORES[MODULO_CANCER.COLORRETAL_COLON].primary,
        icon: 'menu-modulo-colorretal-frame',
        available: true,
      },
      {
        name: MODULO_CANCER.COLORRETAL_RETO,
        title: 'Reto',
        color: MODULO_CORES[MODULO_CANCER.COLORRETAL_RETO].primary,
        icon: 'menu-modulo-colorretal-frame',
        available: true,
      },
      {
        name: MODULO_CANCER.COLORRETAL_CANAL_ANAL,
        title: 'Canal anal',
        color: MODULO_CORES[MODULO_CANCER.COLORRETAL_CANAL_ANAL].primary,
        icon: 'menu-modulo-colorretal-frame',
        available: true,
      }
    ]
  },
  {
    name: 'rim',
    title: 'Rim',
    color: MODULO_CORES[MODULO_CANCER.RIM].primary,
    icon: 'menu-modulo-rim-frame',
    available: true,
    building: true
  },
  {
    name: 'estomago',
    title: 'Estômago',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'melanoma',
    title: 'Melanoma',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'ovario',
    title: 'Ovário',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'cervix',
    title: 'Cérvix',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'utero',
    title: 'Útero',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'ccp',
    title: 'CCP',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'figado',
    title: 'Fígado',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'pancreas',
    title: 'Pâncreas',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'vias-biliares',
    title: 'Vias biliares',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'esofago',
    title: 'Esôfago',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'snc',
    title: 'SNC',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'canal-anal',
    title: 'Canal anal',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'sarcoma',
    title: 'Sarcoma',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'testiculo',
    title: 'Testículo',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  },
  {
    name: 'tireoide',
    title: 'Tireóide',
    icon: 'menu-modulo-generic-frame',
    available: true,
    building: true
  }
];


