import { Component, ComponentRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseCustomInputComponent, InputComponent, InputType } from '../_base/abstract-input';
import { DynamicComponentDirective } from '../../directives/dynamic-component.directive';
import { CustomTextInputComponent } from './custom-text-input/custom-text-input.component';
import { ButtonType } from '../custom-button/custom-button.component';
import { CustomToggleInputComponent } from './custom-toggle-input/custom-toggle-input.component';
import { CustomSelectInputComponent } from './custom-select-input/custom-select-input.component';
import { CustomRadioInputComponent } from './custom-radio-input/custom-radio-input.component';
import { CustomRangeInputComponent } from './custom-range-input/custom-range-input.component';
import { CustomTextareaInputComponent } from './custom-textarea/custom-textarea-input.component';
import { CheckboxGroupInputComponent } from './checkbox-group-input/checkbox-group-input.component';
import { CustomCheckboxInputComponent } from './custom-checkbox-input/custom-checkbox-input.component';
import { CustomAutocompleteVirtualScrollInputComponent } from './custom-autocomplete-virtual-scroll-input/custom-autocomplete-virutal-scroll-input.component';
import { ThreeWayToggleComponent } from './three-way-toggle/three-way-toggle.component';
import { CustomChipsInputComponent } from './custom-chips-input/custom-chips-input.component';

const inputTypesMapping = {
  select : CustomSelectInputComponent,
  text : CustomTextInputComponent,
  password : CustomTextInputComponent,
  toggle : CustomToggleInputComponent,
  radio: CustomRadioInputComponent,
  range: CustomRangeInputComponent,
  textarea: CustomTextareaInputComponent,
  checkbox: CustomCheckboxInputComponent,
  checkgroup: CheckboxGroupInputComponent,
  autocomplete: CustomAutocompleteVirtualScrollInputComponent,
  ['three-way-toggle']: ThreeWayToggleComponent,
  chips: CustomChipsInputComponent,
};

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
})
export class CustomInputMoleculeComponent extends BaseCustomInputComponent implements OnInit, OnChanges {

  @Input()
  type: InputType;

  @Input()
  inputButton = false;

  @Input()
  actionButton = false;

  @Input()
  actionButtonIcon: string;

  @Input()
  actionButtonType: ButtonType;

  @ViewChild(DynamicComponentDirective, { static: true }) componentHost!: DynamicComponentDirective;

  componentRef: ComponentRef<InputComponent>;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.componentRef) {return;}
    Object.keys(changes).forEach(key => this.componentRef.instance[key] = changes[key].currentValue);
  }

  ngOnInit() {
    this.injectSelectedInput();
  }

  injectSelectedInput() {
    const component = inputTypesMapping[this.type];
    const { viewContainerRef } = this.componentHost;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent<InputComponent>(component);
    const classList = ['flex-1', 'max-w-full'];
    if (this.type === 'range') classList.push('pointer-events-none');
    this.componentRef.location.nativeElement.classList.add(...classList);
    this.componentRef.instance.customClasses = this.customClasses;
    this.componentRef.instance.value = this.value;
    this.componentRef.instance.type = this.type;
    this.componentRef.instance.placeholder = this.placeholder;
    this.componentRef.instance.icon = this.icon;
    this.componentRef.instance.label = this.label;
    this.componentRef.instance.valueChanged = this.valueChanged;
    this.componentRef.instance.inputIconName = this.inputIconName;
    this.componentRef.instance.maskConfig = this.maskConfig;
    this.componentRef.instance.maxlength = this.maxlength;
    this.componentRef.instance.selectOptions = this.selectOptions;
    this.componentRef.instance.rangeConfig = this.rangeConfig;
    this.componentRef.instance.disabled = this.disabled;
    this.componentRef.instance.debounce = this.debounce;
    this.componentRef.instance.fields = this.fields;
    this.componentRef.instance.direction = this.direction;
    this.componentRef.instance.keyUpEnter = this.keyUpEnter;
    this.componentRef.instance.setValueOnInput = this.setValueOnInput;
    this.componentRef.instance.loading = this.loading;
    this.componentRef.instance.firstSelected = this.firstSelected;
    this.componentRef.instance.listPosition = this.listPosition;
    this.componentRef.instance.onIonFocus = this.onIonFocus;
    this.componentRef.instance.onIonBlur = this.onIonBlur;
    this.componentRef.instance.handleSearchChanged = this.handleSearchChanged;
    this.componentRef.instance.rowsNumber = this.rowsNumber;
    this.componentRef.instance.threeWayToggleConfig = this.threeWayToggleConfig;
    this.componentRef.instance.fontSize = this.fontSize;
    this.componentRef.instance.hooverCheck = this.hooverCheck;

    if (!this.uncontrolled) {
      this.componentRef.instance.control = this.control;
    }
  }
}
