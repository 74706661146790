import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { FormControl, FormGroup } from '@angular/forms';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/colorretal-colon/util';
import { ThreeWayToggleConfig } from '../../../../components/_base/abstract-input';

export const TESTES_MOLECULARES_THREE_WAY_TOGGLE_TEXT: { [key: string]: ThreeWayToggleConfig } = {
  KRAS: {
    nonValueText: 'Não testado',
    negativeValueText: 'Mutado',
    positiveValueText: 'Selvagem'
  },
  NRAS: {
    nonValueText: 'Não testado',
    negativeValueText: 'Mutado',
    positiveValueText: 'Selvagem'
  },
  ['MSI–H ou dMMR']: {
    nonValueText: 'Não testado',
    negativeValueText: 'Ausente',
    positiveValueText: 'Presente'
  },
  ['BRAF V600E']: {
    nonValueText: 'Não testado',
    negativeValueText: 'Selvagem',
    positiveValueText: 'Mutado'
  },
  ['HER-2']: {
    nonValueText: 'Não testado',
    negativeValueText: 'Normal',
    positiveValueText: 'Amplificado'
  },
  NTRK: {
    nonValueText: 'Não testado',
    negativeValueText: 'Fusão negativa',
    positiveValueText: 'Fusão positiva'
  },
  ['KRAS G12c']: {
    nonValueText: 'Não testado',
    negativeValueText: 'Mutado',
    positiveValueText: 'Selvagem'
  },
};

@Component({
  selector: 'app-testes-moleculares',
  templateUrl: './testes-moleculares.component.html',
})
export class TestesMolecularesComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  ids: any;
  offsetWidth = 10;
  fixedHeight = '440px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    KRAS: new FormControl(),
    NRAS: new FormControl(),
    ['MSI–H ou dMMR']: new FormControl(),
    ['BRAF V600E']: new FormControl(),
    ['HER-2']: new FormControl(),
    NTRK: new FormControl(),
    ['KRAS G12c']: new FormControl(),
  });

  threeWayToggleConfig: { [key: string]: ThreeWayToggleConfig } = TESTES_MOLECULARES_THREE_WAY_TOGGLE_TEXT;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { values, ids } = inputValueResolver('testesMoleculares', this.ficha?.itens);
    this.ids = ids;
    this.form.patchValue({ ...values });
  }

  async handleEndEditClick() {
    if (!this.form.pristine) {
      const formFields = Object.keys(this.form.controls);
      const items: ItemFicha[] = formFields
        .filter(field => this.form.get(field).dirty)
        .map((field) => {
          const newItem: ItemFicha =  {
            chave: `testesMoleculares.${field}`,
            valor: this.form.get(field).value
          };
          if (this.ids[field]) newItem.id = this.ids[field];
          return newItem;
        });
      await this.modalController.dismiss(items);
    } else {
      await this.modalController.dismiss({ confirm: false });
    }
  }
}
