import { Component } from '@angular/core';

@Component({
  selector: 'app-termos-condicoes-component',
  templateUrl: './termos-condicoes.component.html',
})
export class TermosCondicoesComponent {

  constructor() { }
}
