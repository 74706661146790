import { Component, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { InputWithModal } from '../../../interfaces/input-with-modal';
import { AbstractModal } from '../../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../../models/ficha.model';
import { inputValueResolver } from '../../../../app/modules/client/modulos-cancer/mama/util';

@Component({
  selector: 'app-data-diagnostico',
  templateUrl: './data-diagnostico.component.html',
})
export class DataDiagnosticoComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '360px';
  maxDate = moment().format('YYYY-MM-DD');
  fixedWidth = !this.responsiveService.isMobilePlatform ? '400px' : null;
  form: FormGroup = new FormGroup({
    dataDiagnostico: new FormControl(moment().set({ date: 1 }).format('YYYY-MM-DD'))
  });

  constructor(public override injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { dataDiagnostico, id } = inputValueResolver('dataDiagnostico', this.ficha?.itens);
    this.id = id;
    if (dataDiagnostico !== undefined && dataDiagnostico !== null) this.form.patchValue({ dataDiagnostico });
  }

  handleEndEditClick() {
    setTimeout(() => {
      const newItem: ItemFicha = {
        chave: 'dataDiagnostico',
        valor: this.form.get('dataDiagnostico').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      this.modalController.dismiss(items);
    }, 0);
  }
}


