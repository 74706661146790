import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { Paciente } from '../models/paciente.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { getAppUrl } from '../util/utils';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PacienteService extends AbstractService<Paciente> {

  $nomePacienteTermoPesquisa: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(public http: HttpClient) {
    super('paciente', http);
  }

  changeNomePacienteTermoPesquisa(nome: string) {
    this.$nomePacienteTermoPesquisa.next(nome);
  }

  getPacientesMedico(medicoId) {
    return super.get(`medico/${medicoId}`);
  }

  exportarCsvPacientes(params) {
    const options: any = {
      responseType: 'blob',
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    };
    return this.http.get<any>(`${getAppUrl()}/paciente/csv`, options);
  }

  consultarCpf(cpf: string, modulo: string) {
    const { apiUrl, token } = environment.consultaCpfCnpj;
    if (environment.production) {
      return fetch(`${apiUrl}/${token}/2/${cpf}`, { method: 'GET' }).then(response => response.json());
    } else {
      return this.http.get<any>(`${environment.apiUrl}/mock/criar-paciente-por-cpf/${cpf}`, { params: {modulo} }).toPromise();
    }
  }
}
