import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Tip } from '../../../../components/button-tip-selector/button-tip-selector.component';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/colorretal-colon/util';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { optionsResolver } from './util';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';
import { toast } from '../../../../util/toast';

@Component({
  selector: 'app-input-n',
  templateUrl: './input-n.component.html',
})
export class InputNComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  idN: string;
  idYpn: string;
  offsetWidth = 10;
  fixedHeight = '530px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  posNeoAdjuvancia: boolean;
  $digesting: boolean = false;
  tipoEstadiamento: FormControl = new FormControl('clinico');
  tipoNeoAdjuvancia: FormControl = new FormControl(null);
  form: FormGroup = new FormGroup({
    n: new FormControl('cNx'),
    ypn: new FormControl('ypNx'),
  });

  posNeoAdjuvanciaTips: Tip[] = [
    {
      name: 'Pré neoadvuvancia',
      value: 'pre'
    },
    {
      name: 'Pós neoadjuvancia',
      value: 'pos'
    }
  ];
  tipoEstadiamentoTips: Tip[] = [
    {
      name: 'Clínico',
      value: 'clinico'
    },
    {
      name: 'Patológico',
      value: 'patologico'
    }
  ];

  nRangeOptions: RangeConfig = optionsResolver('c');
  nRangeOptionsPosNeo: RangeConfig = optionsResolver('yp');
  nRangeSubOptions: RangeConfig;
  nRangeSubOptionsPosNeo: RangeConfig;

  constructor(public override injector: Injector,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }

  ngOnInit() {
    const { n, ypn, idN, idYpn } = inputValueResolver('n', this.ficha?.itens);
    this.idN = idN;
    this.idYpn = idYpn;
    if (String(n).startsWith('pN')) {
      this.tipoEstadiamento.setValue('patologico');
      this.updateNRangeOptions(false);
    }
    if (this.ficha.hasNeoAdjuvancia) {
      this.atualizarValoresInputEModal(n, ypn);
      if (ypn) this.loadYptInputFormValues(ypn);
    }
    this.loadFormValues(n);
    this.form.get('n').valueChanges.subscribe((value) => {
      this.nRangeSubOptions = null;
      this.$digesting = true;
      const selectedOption = this.nRangeOptions.options.find(o => o.value === value);
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.nRangeSubOptions = {
            options: selectedOption.options
          };
        });
      } else {
        this.nRangeSubOptions = null;
      }
      this.$digesting = false;
    });

    this.form.get('ypn').valueChanges.subscribe((value) => {
      this.nRangeSubOptionsPosNeo = null;
      this.$digesting = true;
      const selectedOption = this.nRangeOptionsPosNeo.options.find(o => o.value === value);
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.nRangeSubOptionsPosNeo = {
            options: selectedOption.options
          };
        });
      } else {
        this.nRangeSubOptionsPosNeo = null;
      }
      this.$digesting = false;
    });
  }

  loadFormValues(nValue: string) {
    if (![null, undefined].includes(nValue)) {
      const nOption = this.nRangeOptions.options.find((option) => nValue.startsWith(option.value));
      this.form.patchValue({ n: nOption.value });
      if (nOption?.options?.length) {
        this.nRangeSubOptions = {
          options: nOption.options
        };
        this.form.addControl(nOption.value, new FormControl(nValue));
      }
    }
  }

  loadYptInputFormValues(ypnValue: string) {
    const selectedOption = this.nRangeOptionsPosNeo.options.find(option => ypnValue.startsWith(option.value));
    if (selectedOption?.options?.length) {
      this.nRangeSubOptionsPosNeo = { options :  selectedOption.options };
      this.form.patchValue({ ypn: selectedOption.value });
      this.form.addControl(selectedOption.value, new FormControl(ypnValue));
    } else {
      this.form.patchValue({ ypn: selectedOption.value });
    }
  }

  atualizarValoresInputEModal(nValue, ypnValue) {
    this.posNeoAdjuvancia = true;
    if (!nValue && !!ypnValue) this.tipoNeoAdjuvancia.setValue('pos');
    else this.tipoNeoAdjuvancia.setValue('pre');
  }

  handleTipoNeoAdjuvanciaChange(event: any) {
    this.tipoNeoAdjuvancia.setValue(event.value);
  }

  handleTipoEstadiamentoChange(event: any) {
    this.tipoEstadiamento.setValue(event.value);
    this.updateNRangeOptions();
  }

  updateNRangeOptions(setValueOnInput = true) {
    const prefixo = String(this.tipoEstadiamento.value)[0];
    this.nRangeOptions = optionsResolver(prefixo);
    setTimeout(() => {
      if (setValueOnInput) this.form.get('n').setValue(`${prefixo}Nx`);
    }, 100);
  }

  removeUnusedFormFields() {
    const unusedFields = Object.keys(this.form.controls).filter(key => key !== 'n');
    unusedFields.forEach((field) => this.form.removeControl(field));
  }

  async handleResetValuesClick() {
    const alert = await this.alertHelper.create({
      title: 'Redefinir valores',
      text: 'Tem certeza que deseja zerar os valores para este estadiamento?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          const items: ItemFicha[] = [];
          if (this.form.get('n').value && this.idN) items.push({ chave: 'n', valor: null, id: this.idN });
          if (this.form.get('ypn').value && this.idYpn) items.push({ chave: 'ypn', valor: null, id: this.idYpn });
          setTimeout(async () => {
            await this.modalController.dismiss(items);
          }, 100);
        }
      }
    });
    await alert.present();
  }

  async handleEndEditClick() {
    const items: ItemFicha[] = [];
    let valor;
    if (this.posNeoAdjuvancia && !this.form.get('ypn').pristine) {
      if (this.nRangeSubOptionsPosNeo?.options?.length) {
        const fieldName = this.form.get('ypn').value;
        valor = this.form.get(fieldName).value;
      } else {
        valor = this.form.get('ypn').value;
      }
      if (['ypN1', 'ypN2'].includes(valor)) {
        await toast('É necessário escolher a subclassificação');
        return;
      }
      const newItem: ItemFicha = { chave: 'ypn', valor };
      if (this.idYpn) newItem.id = this.idYpn;
      items.push(newItem);
    }
    if (this.nRangeSubOptions?.options?.length) {
      const fieldName = this.form.get('n').value;
      valor = this.form.get(fieldName).value;
      if (['cN1', 'cN2', 'pN1', 'pN2'].includes(valor)) {
        await toast('É necessário escolher a subclassificação');
        return;
      }
    } else {
      valor = this.form.get('n').value;
    }
    const newItem: ItemFicha = { chave: 'n', valor };
    if (this.idN) newItem.id = this.idN;
    items.push(newItem);
    await this.modalController.dismiss(items);
  }
}
