import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (prefixo: string = ''):RangeConfig => ({
  options: [
    {
      label: `${prefixo}Tx`,
      value: `${prefixo}Tx`,
      hint: 'Tumor Primário não pode ser acessado'
    },
    {
      label: `${prefixo}T0`,
      value: `${prefixo}T0`,
      hint: 'Sem evidência de tumor primário'
    },
    {
      label: `${prefixo}Tis`,
      value: `${prefixo}Tis`,
      hint: 'Carcinoma in situ ductal. Lobular in situ é benigno.'
    },
    {
      label: `${prefixo}T1`,
      value: `${prefixo}T1`,
      hint: 'Tumor ≤20 mm',
      options: [
        {
          label: `${prefixo}T1`,
          value: `${prefixo}T1`,
          hint: 'Tumor ≤20 mm',
        },
        {
          label: `${prefixo}T1mi`,
          value: `${prefixo}T1mi`,
          hint: '≤1mm',
        },
        {
          label: `${prefixo}T1a`,
          value: `${prefixo}T1a`,
          hint: '>1mm e ≤5mm',

        },
        {
          label: `${prefixo}T1b`,
          value: `${prefixo}T1b`,
          hint: '>5mm e ≤10mm'
        },
        {
          label: `${prefixo}T1c`,
          value: `${prefixo}T1c`,
          hint: '>10mm e ≤20mm'
        }
      ]
    },
    {
      label: `${prefixo}T2`,
      value: `${prefixo}T2`,
      hint: '>20mm e ≤50mm'
    },
    {
      label: `${prefixo}T3`,
      value: `${prefixo}T3`,
      hint: '>50mm'
    },
    {
      label: `${prefixo}T4`,
      value: `${prefixo}T4`,
      hint: 'Tumor de qualquer tamanho com extensão a parede torácica ou infiltração da pele.',
      options: [
        {
          label: `${prefixo}T4`,
          value: `${prefixo}T4`,
          hint: 'Tumor de qualquer tamanho com extensão a parede torácica ou infiltração da pele.',
        },
        {
          label: `${prefixo}T4a`,
          value: `${prefixo}T4a`,
          hint: 'Extensão à parede torácica',
        },
        {
          label: `${prefixo}T4b`,
          value: `${prefixo}T4b`,
          hint: 'Qualquer tamanho com: ulceração, peau d\'orange, edema ou nódulo satélite'
        },
        {
          label: `${prefixo}T4c`,
          value: `${prefixo}T4c`,
          hint: 'T4a e T4b'
        },
        {
          label: `${prefixo}T4d`,
          value: `${prefixo}T4d`,
          hint: 'Inflamatório'
        }
      ]
    }
  ]
});
