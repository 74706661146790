import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';

@Component({
  selector: 'app-saiba-mais-niveis',
  templateUrl: './saiba-mais-niveis.component.html',
})
export class SaibaMaisNiveisComponent extends AbstractModal {

  fixedHeight = '535px';

  constructor(public readonly injector: Injector) {
    super(injector);
  }
}
