import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionOption } from '../../components/_base/abstract-input';
import { ClinicaService } from '../../services/clinica.service';
import { Observable, zip } from 'rxjs';
import { loading } from '../../util/loading';
import { toast } from '../../util/toast';
import UsuarioService from '../../services/usuario.service';

const staffFormTemplate = () => {
  return new FormGroup({
    idMedico: new FormControl('', [Validators.required]),
    tipo: new FormControl('', [Validators.required]),
  });
};

@Component({
  selector: 'app-adicionar-staff',
  templateUrl: './adicionar-staff.component.html',
})
export class AdicionarStaffComponent extends AbstractModal implements OnInit {

  idInstituicao: string = '';
  staffOptions: SelectionOption[];
  tipoOptions: SelectionOption[] = [
    {
      nome: 'Administrador',
      valor: 'ADMIN'
    },
    {
      nome: 'Médico',
      valor: 'MEDICO'
    }
  ];

  form: FormGroup = new FormGroup({
    staffs: new FormArray([
      staffFormTemplate()
    ])
  });

  constructor(public readonly injector: Injector,
              private clinicaService: ClinicaService,
              private usuarioService: UsuarioService) {
    super(injector);
  }

  get staffs() {
    return this.form.controls["staffs"] as FormArray;
  }
  
  ngOnInit() {
    loading(this.clinicaService.listarMedicosStaff().subscribe((response) => {
      this.staffOptions = response;
    }));
  }

  handleAdicionarNovoStaff() {
    this.staffs.push(staffFormTemplate());
  }

  handleRemoverStaff(index: number) {
    this.staffs.removeAt(index);
  }

  async handleConfirmClick() {
    const staffs = this.staffs.getRawValue().map(staff => Object.assign({}, staff, { idInstituicao: this.idInstituicao }));
    const observables: Observable<any>[] = staffs.map((staff) => this.clinicaService.adicionarStaff(staff));
    await loading(zip(...observables).subscribe(async (responses) => {
      this.usuarioService.executarRefreshToken()
      await toast('Staff atualizada com sucesso');
    }));
  }
}
