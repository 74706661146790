import { Component, Input } from '@angular/core';
import { Censura } from '../../models/censura.model';
import { tipoCensuraMapping } from '../../constants/censura';

@Component({
  selector: 'app-censura',
  templateUrl: './censura.component.html',
})
export class CensuraComponent {

  @Input() censura: Censura

  constructor() {
  }

  getFormattedDate() {
    return `${new Date(String(this.censura?.data)).toLocaleDateString('pt-BR', { 
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit', 
      year: '2-digit'
    })}`;
  }

  getCensuraLabel() {
    return tipoCensuraMapping[`${this.censura?.tipo}${this.censura?.subtipo ? ` ${this.censura?.subtipo}` : ''}`];
  }
}
