import { Component, OnInit, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { FormGroup, FormControl } from '@angular/forms';
import { ResponsiveService } from '../../../../services/responsive.service';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { ItemFicha } from '../../../../models/ficha.model';
import { ItemFichaService } from '../../../../services/item-ficha.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-input-grau',
  templateUrl: './input-grau.component.html',
})
export class InputGrauComponent extends AbstractModal implements InputWithModal {
  
  fichaPaciente: any;
  id: string;
  offsetWidth = 10;
  fixedHeight = '340px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  
  
  form: FormGroup = new FormGroup({
    tipoGrau: new FormControl('')
  });
  
  tiposGrau: string[] = [];
  
  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService,
              public itemFichaService: ItemFichaService) {
    super(injector);
  }
  
  ngOnInit() {
    this.loadDominios();
    const { tipoGrau, id } = inputValueResolver('grau', this.fichaPaciente?.itens);
    this.id = id;
    if (tipoGrau !== undefined) this.form.patchValue({ tipoGrau });
  }
  
  async handleEndEditClick() {
    if (this.form.get('tipoGrau').pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: 'tipoGrau',
        valor: this.form.get('tipoGrau').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
  
  loadDominios() {
    zip(
    this.itemFichaService.getTiposGrau(),
    ).subscribe((
    [
      tiposGrau,
    ]
    ) => {
      this.tiposGrau = tiposGrau;
    });
  }
  
}
