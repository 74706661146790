import { Component } from '@angular/core';
import { AbstractButton } from '../../_base/abstract-button';

@Component({
  selector: 'app-ghost-button',
  templateUrl: './ghost.component.html',
})
export class GhostButtonComponent extends AbstractButton {

  constructor() {
    super();
  }
}
