import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Ficha } from '../models/ficha.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromptService extends AbstractService<any> {

  constructor(public http: HttpClient) {
    super('prompt', http);
  }

  atualizarPrompt(idPrompt: string, prompt: any) {
    return super.put(prompt, `${idPrompt}`);
  }

  buscarConsultasPorModulo(modulo: string) {
    return super.get(`consultas/${modulo}`);
  }

  executar(promptId: string, ficha: Ficha, extras) {
    return super.post({ extras }, `executar/${promptId}/ficha/${ficha._id}`);
  }

  buscarPromptsTemplate() {
    return super.get('buscar-prompts-template');
  }

  solicitarPreenchimentoAutomaticoFicha(modulo: string, prontuario: string) {
    return super.post({ modulo, prontuario }, 'ficha/solicitar-preenchimento-automatico');
  }
}

