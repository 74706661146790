import { Component } from '@angular/core';
import { AbstractButton } from '../../_base/abstract-button';

@Component({
  selector: 'app-default-button',
  templateUrl: './default.component.html',
})
export class DefaultButtonComponent extends AbstractButton {

  constructor() {
    super();
  }
}
