import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

declare const window;

export type BreakPointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const BREAKPOINT = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl'
};

@Injectable({ providedIn : 'root' })
export class ResponsiveService implements OnDestroy {

  $screenWidth: BehaviorSubject<number> = new BehaviorSubject(null);
  $screenHeight: BehaviorSubject<number> = new BehaviorSubject(null);
  $platform: BehaviorSubject<string> = new BehaviorSubject('');
  $mediaBreakpoint: BehaviorSubject<BreakPointType|string> = new BehaviorSubject(null);
  static isMobilePlatform = window.innerWidth <= 768;
  private $unSubscriber: Subject<any> = new Subject();
  private lastMediaBreakPoint: string;
  private lastPlatform: string;

  constructor() {
    this.init();
  }

  init() {
    this.setScreenWidth(window.innerWidth);
    this.setScreenHeight(window.innerHeight);
    this.setMediaBreakpoint(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(100),
        takeUntil(this.$unSubscriber)
      ).subscribe((evt: any) => {
      this.setScreenWidth(evt.target.innerWidth);
      this.setMediaBreakpoint(evt.target.innerWidth);
    });
  }

  ngOnDestroy() {
    this.$unSubscriber.next();
    this.$unSubscriber.complete();
  }

  get isMobilePlatform() {
    return ResponsiveService.isMobilePlatform;
  }

  private setScreenWidth(width: number): void {
    this.$screenWidth.next(width);
  }

  private setScreenHeight(height: number): void {
    this.$screenHeight.next(height);
  }

  private setMediaBreakpoint(width: number): void {
    if (width < 576) {
      this.emitBreakPoint(BREAKPOINT.XS);
    } else if (width >= 576 && width < 768) {
      this.emitBreakPoint(BREAKPOINT.SM);
    } else {
      this.emitBreakPoint();
    }
  }

  private emitBreakPoint(breakpoint?: string) {
    if (this.lastMediaBreakPoint !== breakpoint) {
      ResponsiveService.isMobilePlatform = [BREAKPOINT.XS, BREAKPOINT.SM].includes(breakpoint);
      this.lastMediaBreakPoint = breakpoint;
      this.changePlatform(ResponsiveService.isMobilePlatform ? 'mobile' : 'web');
      this.$mediaBreakpoint.next(breakpoint);
    }
  }

  private changePlatform(platform) {
    if (platform !== this.lastPlatform) {
      this.$platform.next(platform);
    }
  }
}
