import { Component } from '@angular/core';
import MenuService from '../../../../shared/services/menu.service';
import { loading } from '../../../../shared/util/loading';
import { ActivatedRoute, Router } from '@angular/router';
import UsuarioService from '../../../../shared/services/usuario.service';
import { ROUTE } from '../../../../shared/constants/route';

@Component({
  selector: 'app-confirmacao-cadastro',
  templateUrl: './confirmacao-cadastro.page.html',
})
export class ConfirmacaoCadastroPage {

  constructor(private menuService: MenuService,
              private route: ActivatedRoute,
              private usuarioService: UsuarioService,
              private router: Router) { }

  async ionViewWillEnter() {
    await this.menuService.disableMenu();
    this.confirmarCadastro();
  }

  async ionViewWillLeave() {
    await this.menuService.enableMenu();
  }

  confirmarCadastro() {
    this.route.params.subscribe((params) => {
      loading(this.usuarioService.confirmarCadastro(params.id).subscribe(async (usuario) => {
        if (usuario.validado) {
          await this.router.navigate([ROUTE.MODULOS_CANCER], { replaceUrl: true });
        }
      }));
    });
  }
}
