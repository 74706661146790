import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Ficha } from '../../../../models/ficha.model';
import { CondutaColorretal } from '../../../../models/conduta.model';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import { zip } from 'rxjs';
import { toast } from '../../../../util/toast';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';
import { loading } from '../../../../util/loading';
import { FichaService } from '../../../../services/ficha.service';
import {
  CondutaColorretalCanalAnalService,
  PassoAdicionarConduta, passoCalendario
} from '../../../../services/conduta-colorretal-canal-anal.service';

@Component({
  selector: 'app-adicionar-conduta',
  templateUrl: './adicionar-conduta.component.html',
})
export class AdicionarCondutaComponent extends AbstractModal implements OnInit {

  ficha: Ficha;
  filtro: boolean;
  editarFiltro: boolean;
  conduta: CondutaColorretal;
  sugestaoConduta: string[];
  offsetWidth = 10;
  fixedHeight = '300px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  maxDate = moment().format('YYYY-MM-DD');
  passoAtual: PassoAdicionarConduta = 'selecao-tipo';
  tipoCondutaOptions: SelectionOption[];
  subtipoCirugiaOptions: SelectionOption[];
  subtipoRadioterapiaOptions: SelectionOption[];
  subtiposQuimioterapia: SelectionOption[];
  listaDeDrogas: string[];
  oldCondutaTipo: string;
  oldCondutaSubtipo: string;
  operadorDrogasOptions: SelectionOption[];

  form: FormGroup = new FormGroup({
    tipo: new FormControl('', [Validators.required]),
    subtipo: new FormControl(''),
    data: new FormControl(moment().set({ date: 1 }).format('YYYY-MM-DD'), [Validators.required]),
    drogas: new FormControl([]),
    continuidade: new FormControl(false),
  });

  constructor(public readonly injector: Injector,
              private condutaService: CondutaColorretalCanalAnalService,
              private alertHelper: CustomAlertHelper,
              private fichaService: FichaService) {
    super(injector);
  }

  get numeroLinhaTratamento() {
    const subtipo = this.form.get('subtipo').value;
    const continuidade = this.form.get('continuidade')?.value;
    return (this.ficha?.condutas || [])
      .filter(conduta => conduta.subtipo === subtipo && !conduta.continuidade)
      .length + (!continuidade ? 1 : 0);
  }

  ngOnInit() {
    this.loadDominios();
    this.verifyAndSetFormFields();
    this.registerFormListeners();
    if (this.filtro) this.registerAdditionalFilterFields();
  }

  registerFormListeners() {
    this.form.get('tipo').valueChanges.subscribe(async () => {
      await this.handleConfirmStepClick();
    });
    this.form.get('subtipo').valueChanges.subscribe(async () => {
      await this.handleConfirmStepClick();
    });
  }

  registerAdditionalFilterFields() {
    this.operadorDrogasOptions = [
      {
        nome: 'Todas as drogas',
        valor: 'AND',
      },
      {
        nome: 'Qualquer das drogas',
        valor: 'OR',
      }
    ];
    this.form.removeControl('continuidade');
    this.form.addControl('dataFim', new FormControl(moment().set({ date: 1 }).format('YYYY-MM-DD')));
    this.form.addControl('operadorDrogas', new FormControl(this.conduta ? this.conduta['operadorDrogas'] : 'AND'));
    this.form.addControl('ultima', new FormControl(Boolean((this.conduta && this.conduta['ultima']))));
  }

  verifyAndSetFormFields() {
    if (this.conduta) {
      const { tipo, drogas, data, subtipo } = this.conduta;
      this.form.patchValue({ tipo, drogas, data });
      if (tipo) this.oldCondutaTipo = tipo;
      if (subtipo) this.oldCondutaSubtipo = subtipo;
    }
  }

  loadDominios() {
    zip(
      this.condutaService.getTiposConduta(),
      this.condutaService.getSubtiposCirurgia(),
      this.condutaService.getSubtiposRadioterapia(),
      this.condutaService.getSubtiposQuimioterapia(),
    ).subscribe((
      [
        tiposConduta,
        subtiposCirurgia,
        subtiposRadioterapia,
        subtiposQuimioterapia,
      ]
    ) => {
      this.tipoCondutaOptions = tiposConduta;
      this.subtipoCirugiaOptions = subtiposCirurgia;
      this.subtipoRadioterapiaOptions = subtiposRadioterapia;
      this.subtiposQuimioterapia = subtiposQuimioterapia;
    });
  }

  async handleConfirmStepClick() {
    let tipo = this.form.get('tipo').value;
    let subtipo = this.form.get('subtipo').value;

    if (tipo && tipo !== this.oldCondutaTipo && this.passoAtual === 'selecao-tipo') this.form.get('drogas').setValue([]);

    if (!!tipo && tipo !== 'Seguimento' && subtipo === 'Paliativa' && !['drogas', 'calendario'].includes(this.passoAtual)) {
      if (!['Cirurgia', 'Radioterapia'].includes(tipo) && !this.conduta?.id) {
        const { confirm: houveRecidiva, ultimaConduta } = await this.verificarUltimaCondutaDePeriodo();
        if (!houveRecidiva && !!ultimaConduta) {
          this.form.get('continuidade').setValue(!houveRecidiva, { emitEvent: false });
        }
      }
    }

    const etapaConfig: {
      proximoPasso: PassoAdicionarConduta;
      altura: number
    } = (CondutaColorretalCanalAnalService.passosCondutaMapping || {})[`${tipo}${subtipo ? ` ${subtipo}` : ''}`];

    // this.sugestaoConduta = SugestaoConduta.get(
    //   this.ficha,
    //   tipo,
    //   subtipo
    // );

    if (String(etapaConfig.proximoPasso).startsWith('subtipo')) {
      this.form.get('subtipo').setValue(this.oldCondutaSubtipo, { emitEvent : false });
    }

    if (this.passoAtual === 'calendario-fim') {
      if (moment(this.form.get('dataFim').value).isBefore(this.form.get('data').value)) {
        await toast('É necessário que a data fim seja posterior a data de início', 'warning');
        return;
      } else {
        if (tipo !== 'Quimioterapia' && subtipo !== 'Radioterapia concomitante a QT') {
          this.form.removeControl('operadorDrogas');
        }
        await this.validateAndSaveConduta();
        return;
      }
    }

    if (etapaConfig && etapaConfig.proximoPasso !== this.passoAtual && this.passoAtual !== 'calendario') {
      this.passoAtual = etapaConfig.proximoPasso;
      let altura = etapaConfig.altura;
      if (['drogas'].includes(this.passoAtual)) {
        const drogas = this.getListaDeDrogas();
        this.changeModalHeight(drogas, etapaConfig.altura);
        this.listaDeDrogas = drogas
        if (this.sugestaoConduta?.length) altura = (altura + 75);
      } else {
        this.setHeight(altura);
      }
    } else if (['drogas'].includes(this.passoAtual)) {
      this.passoAtual = passoCalendario.proximoPasso;
      this.setHeight(passoCalendario.altura)
    } else {
      if (this.form.invalid && this.passoAtual !== 'calendario') {
        await toast('É necessário preencher os dados para esta conduta', 'warning');
      } else {
        if (this.passoAtual === 'calendario' && this.filtro) {
          this.passoAtual = 'calendario-fim';
        } else {
          await this.validateAndSaveConduta();
        }
      }
    }
  }

  validateAndSaveConduta() {
    const conduta = this.form.getRawValue();
    if (this.conduta?.id) conduta.id = this.conduta.id;
    return this.modalController.dismiss({ path: 'conduta', conduta });
  }

  verificarUltimaCondutaDePeriodo(): Promise<{ confirm: boolean, ultimaConduta?: CondutaColorretal }> {
    return new Promise(async (resolve) => {
      const ultimaConduta: CondutaColorretal = (this.ficha?.condutas || [])[(this.ficha?.condutas?.length - 1) || 0] as CondutaColorretal;
      if (!ultimaConduta) resolve({ confirm: true });
      else {
        const hasCondutaDePeriodo = this.ficha.condutas?.some(conduta => conduta.subtipo === 'Paliativa');
        if (hasCondutaDePeriodo) {
          const alert = await this.alertHelper.create({
            title: 'Conduta ativa',
            text: 'Houve Progressão de doença ou recidiva?',
            okText: 'Sim',
            cancelText: 'Não',
            confirmButtonType: 'default',
            cancelButtonType: 'danger',
            handler: async ({data}) => {
              resolve({ confirm: data.confirm, ultimaConduta });
            }
          });
          await alert.present();
        } else {
          resolve({ confirm: true });
        }
      }
    });
  }

  changeModalHeight(drogas: string[], altura: number) {
    const rowHeight = 32.5;
    const rowsNumber = Math.ceil(drogas.length / 2);
    const subtipo = this.form.get('subtipo').value;
    const alturaLinhaTratamento = (this.numeroLinhaTratamento > 0 && subtipo === 'Paliativa') ? 25 : 0;
    this.setHeight((rowHeight * rowsNumber) + altura + alturaLinhaTratamento);
  }

  async handleDeletarCondutaClick() {
    const alert = await this.alertHelper.create({
      title: 'Excluir conduta',
      text: 'Tem certeza que deseja excluir a conduta selecionada?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          loading(this.fichaService.excluirConduta(this.ficha, this.conduta).subscribe((ficha) => {
            this.handleCLickClose(false, { excluirConduta: true, ficha });
            toast('Conduta excluída com sucesso');
          }));
        }
      }
    });
    await alert.present();
  }

  getListaDeDrogas() {
    let tipo, subtipo, listaDrogas = [];
    if (!this.filtro) {
      tipo = this.form.get('tipo').value;
      subtipo = this.form.get('subtipo').value;
    }

    if (tipo === 'Quimioterapia' || this.filtro) {
      if (subtipo === 'Concomitante a radioterapia' || this.filtro) {
        listaDrogas = [].concat(this.condutaService.getDrogasConcomitanteRadioterapia());
      }
      if (subtipo === 'Paliativa' || this.filtro) {
        listaDrogas = [].concat(this.condutaService.getListaDeDrogasQuimioterapiaPaliativo(this.ficha, this.filtro));
      }
    }

    if (tipo === 'Radioterapia' || this.filtro) {
      if (subtipo === 'Radioterapia concomitante a QT' || this.filtro) {
        listaDrogas = [].concat(this.condutaService.getDrogasConcomitanteRadioterapia());
      }
    }

    let mapping: any = {};
    listaDrogas.forEach(droga => mapping[droga] = droga);
    return (Object.values(mapping) as string []).sort((a, b) => a.localeCompare(b));
  }
}
