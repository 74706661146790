import { Component } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';

@Component({
  selector: 'app-custom-checkbox-input',
  templateUrl: './custom-checkbox-input.component.html',
  styleUrls: ['./custom-checkbox-input.component.scss'],
})
export class CustomCheckboxInputComponent extends BaseCustomInputComponent {

  constructor() {
    super();
  }

  handleCheckboxChange( event: any) {
    this.value = event.target.checked;
    this.handleChange(event.target.checked);
  }
}
