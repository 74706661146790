import { Component, Injector, ViewChild } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { loading } from '../../util/loading';
import { Subject, zip } from 'rxjs';
import { DominioService } from '../../services/dominio.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionOption } from '../../components/_base/abstract-input';
import { ValidatorsApp } from '../../util/validators';
import { ClinicaService } from '../../services/clinica.service';
import { Clinica, Grupo } from '../../models/clinica.model';
import { GrupoService } from '../../services/grupo.service';
import { toast } from '../../util/toast';
// import { SwiperComponent } from 'swiper/angular';

const GRUPO_NAO_ENCONTRADO_OPTION = {
  nome: 'Não encontrei o grupo que procuro',
  valor: 'grupo-nao-encontrado',
  notFilterable: true
};

@Component({
  selector: 'app-solicitar-cadastro-clinica',
  templateUrl: './solicitar-cadastro-clinica.component.html',
})
export class SolicitarCadastroClinicaComponent extends AbstractModal {

  @ViewChild('swiperRef') swiperRef: any;
  
  filteredUfsClinicas: SelectionOption[];
  estado: string;
  cidade: string;
  validado: boolean;
  pesquisaGrupoTermo: string;
  loadingCidades: boolean = false;
  clinicaNaoEncontrada: boolean = false;
  grupos: Grupo[];
  ufsOptions: SelectionOption[];
  gruposOptions: SelectionOption[];
  cidadeOptions: SelectionOption[];
  tiposAtendimentoOptions: SelectionOption[];
  offsetWidth = 10;
  clinicaOnGrupo: boolean;
  fixedHeight = '630px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  clinicaCadastrada: Clinica;
  form: FormGroup = new FormGroup({
    uf: new FormControl('', Validators.required),
    cidade: new FormControl('', Validators.required),
    grupoCnpj: new FormControl('', ValidatorsApp.cnpj),
    grupoNome: new FormControl(''),
    cnpj: new FormControl('', Validators.compose([Validators.required, ValidatorsApp.cnpj])),
    nome: new FormControl('', Validators.required),
    tipoAtendimento: new FormControl(''),
  });

  grupoControl: FormControl = new FormControl(null);

  constructor(public readonly injector: Injector,
              private dominioService: DominioService,
              private clinicaService: ClinicaService,
              private grupoService: GrupoService) {
    super(injector);
  }

  ngOnInit() {
    this.loadDominios().subscribe(() => {
      this.registryFormListeners();
      this.setFormValuesIfWasPassed();
    });
  }

  loadDominios() {
    const subject = new Subject();
    zip(
      this.dominioService.getUfs(),
      this.grupoService.buscar(''),
      this.dominioService.getTipoAtendimento()
    ).subscribe(([ufs, grupos, tiposAtendimento]) => {
      this.grupos = grupos;
      if(this.filteredUfsClinicas) {
        this.ufsOptions = this.filteredUfsClinicas;
      } else {
        this.ufsOptions = ufs.sort((a, b) => a.nome.localeCompare(b.nome));
      }
      this.gruposOptions = grupos.map(grupo => ({ nome: grupo.nome, valor: grupo._id }));
      this.gruposOptions.unshift(GRUPO_NAO_ENCONTRADO_OPTION);
      tiposAtendimento.push({
        nome: 'Misto',
        valor: void 0
      });
      this.tiposAtendimentoOptions = tiposAtendimento;
      subject.next();
    });
    loading(this.dominioService.getUfs().subscribe((ufs) => {
      if(this.filteredUfsClinicas) {
        this.ufsOptions = this.filteredUfsClinicas;
      } else {
        this.ufsOptions = ufs.sort((a, b) => a.nome.localeCompare(b.nome));
      }
      subject.next();
    }));
    return subject;
  }

  handleSearchGrupoChanged(nomeGrupo) {
    if (nomeGrupo !== GRUPO_NAO_ENCONTRADO_OPTION.nome) {
      this.pesquisaGrupoTermo = nomeGrupo;
      this.clinicaNaoEncontrada = false;
    }
  }

  registryFormListeners() {
    const cnpjControl = this.form.get('cnpj');
    this.form.get('uf').valueChanges.subscribe((uf) => {
      this.loadCidadesByUf(uf);
    });

    this.grupoControl.valueChanges.subscribe((grupo: any) => {
      if (String(grupo) === 'grupo-nao-encontrado') {
        this.form.get('grupoNome').setValue(this.pesquisaGrupoTermo);
        this.form.get('grupoCnpj').setValue('');
        this.clinicaNaoEncontrada = true;
      } else {
        const selectedGroup: Grupo = this.grupos.find(g => g._id === grupo);
        if (selectedGroup) {
          this.form.get('grupoCnpj').setValue(selectedGroup.cnpj);
          this.form.get('grupoNome').setValue(selectedGroup.nome);
        }
      }
    });
    cnpjControl.valueChanges.subscribe((cnpj) => {
      if (cnpjControl.valid) {
        loading(this.clinicaService.getClinicaByCpnj(cnpj).subscribe((clinica: Clinica) => {
          if (Object.keys(clinica).length !== 0) {
            toast('O CNPJ informado já possui cadastro em nossa base de dados, a instituição encontrada será vinculada ao seu cadastro');
            this.form.patchValue({
              nome: clinica.nome,
              uf: clinica.uf
            });
            this.loadCidadesByUf(clinica.uf, false).subscribe(() => {
              this.form.get('cidade').setValue(clinica.cidade);
              this.clinicaCadastrada = clinica;
              this.form.markAsPristine();
            })
          } else {
            this.form.get('nome').setValue('');
          }
        }));
      }
    });
  }

  setFormValuesIfWasPassed() {
    if (this.estado) {
      this.form.get('uf').setValue(this.estado, {emitEvent: false});
      this.loadCidadesByUf(this.estado, false).subscribe(() => {
        if (this.cidade) {
          setTimeout(() => {
            this.form.get('cidade').setValue(this.cidade, {emitEvent: false});
          }, 100);
        }
      });
    }
  }

  loadCidadesByUf(uf, setUniqueValue = true) {
    this.loadingCidades = true;
    const subject = new Subject();
    loading(this.dominioService.getCidadesByUf(uf).subscribe((cidades) => {
      this.cidadeOptions = cidades;
      if (cidades.length === 1 && setUniqueValue) {
        this.form.get('cidade').setValue(cidades[0].valor);
      }
      subject.next();
      this.loadingCidades = false;
    }));
    return subject;
  }

  validateIfIsOnGrupoOrNot() {
    const activeIndex = this.swiperRef.nativeElement.swiper.activeIndex;
    if (this.clinicaOnGrupo) {
      if (activeIndex === 0) {
        this.swiperRef.nativeElement.swiper.slideNext();
        return false;
      } else if (activeIndex === 1) {
        const grupoNome = this.form.get('grupoNome').value;
        const grupoCnpj = this.form.get('grupoCnpj').value;
        if (grupoNome && grupoCnpj) {
          return true;
        } else {
          toast('Por favor, preencha todos os campos corretamente antes de confirmar');
          return false;
        }
      }
    }
    return true;
  }

  async handleBackClick() {
    if (this.clinicaOnGrupo) {
      const activeIndex = this.swiperRef.nativeElement.swiper.activeIndex;
      if (activeIndex === 1) {
        await this.swiperRef.nativeElement.swiper.slidePrev();
      } else {
        await this.handleCLickClose(false);
      }
    } else {
      await this.handleCLickClose(false);
    }
  }

  async handleSaveClick() {
    if (this.form.invalid) {
      await toast('Por favor, preencha todos os campos corretamente antes de confirmar')
      return;
    }
    const shouldSaveInstituicao = this.validateIfIsOnGrupoOrNot();
    if (!shouldSaveInstituicao) { return; }
    const clinica: Clinica = this.form.getRawValue();
    clinica.nome = String(clinica.nome).toUpperCase();
    clinica.grupoNome = String(clinica.grupoNome).toUpperCase();
    clinica.validado = this.validado;
    if (!this.form.get('tipoAtendimento').value) delete clinica.tipoAtendimento;
    if (this.form.pristine) {
      await this.handleCLickClose(true, { instituicao: this.clinicaCadastrada });
      return;
    }
    await loading(this.clinicaService.post({...clinica}).subscribe(async (clinica) => {
      await this.handleCLickClose(true, { instituicao: clinica });
    }));
  }

  handleClinicaOnGrupoChange(checked) {
    this.clinicaOnGrupo = checked;
  }
}
