import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SelectionOption } from '../components/_base/abstract-input';
import { Ficha } from '../models/ficha.model';

export type PassoAdicionarConduta =
  'selecao-tipo' |
  'subtipo-cirurgia' |
  'subtipo-radioterapia' |
  'subtipo-quimioterapia' |
  'drogas' |
  'calendario' |
  'calendario-fim';

export const passoCalendario: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'calendario',
  altura: 430
};

export const passoDrogas: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'drogas',
  altura: 140
};

@Injectable({
  providedIn: 'root'
})
export class CondutaColorretalCanalAnalService {

  static condutasDePeriodo = [

  ];

  static tiposConduta: SelectionOption[] = [
    {
      nome: 'Cirurgia',
      valor: 'Cirurgia'
    },
    {
      nome: 'Radioterapia',
      valor: 'Radioterapia'
    },
    {
      nome: 'Quimioterapia',
      valor: 'Quimioterapia'
    },
    {
      nome: 'Seguimento',
      valor: 'Seguimento'
    },
  ];

  static subtiposCirurgia: SelectionOption[] = [
    {
      nome: 'Excisão local',
      valor: 'Excisão local'
    },
    {
      nome: 'Amputação abdominoperineal',
      valor: 'Amputação abdominoperineal'
    },
    {
      nome: 'Cirurgia poupadora de esfíncter',
      valor: 'Cirurgia poupadora de esfíncter'
    },
    {
      nome: 'Metastasectomia',
      valor: 'Metastasectomia'
    },
  ];

  static subtiposRadioterapia: SelectionOption[] = [
    {
      nome: 'Radioterapia isolada',
      valor: 'Radioterapia isolada'
    },
    {
      nome: 'Radioterapia concomitante a QT',
      valor: 'Radioterapia concomitante a QT'
    },
    {
      nome: 'Radioterapia para metástases',
      valor: 'Radioterapia para metástases'
    },
  ];

  static subtiposQuimioterapia: SelectionOption[] = [
    {
      nome: 'Concomitante a radioterapia',
      valor: 'Concomitante a radioterapia',
    },
    {
      nome: 'Paliativa',
      valor: 'Paliativa'
    },
  ];

  static drogasQuimioterapiaPaliativo = [
    'Fluorouracil',
    'Fluorouracil BIC 46h',
    'Mitomicina',
    'Oxaliplatina',
    'Irinotecano',
    'Leucovorin',
    'Capecitabina',
    'Carboplatina',
    'Raltitrexede',
    'Docetaxel',
    'Paclitaxel',
    'Cisplatina',
  ];

  static drogasConcomitanteQuimioterapia = [
    'Fluorouracil',
    'Fluorouracil BIC 46h',
    'Mitomicina',
    'Cisplatina',
    'Capecitabina',
    'Carboplatina',
    'Paclitaxel',
    'Docetaxel',
    'Raltitrexede',
  ];

  static passosCondutaMapping = {
    'Cirurgia' : {
      proximoPasso: 'subtipo-cirurgia',
      altura: 300
    },
    'Cirurgia Excisão local': passoCalendario,
    'Cirurgia Amputação abdominoperineal': passoCalendario,
    'Cirurgia Cirurgia poupadora de esfíncter': passoCalendario,
    'Cirurgia Metastasectomia': passoCalendario,
    'Radioterapia' : {
      proximoPasso: 'subtipo-radioterapia',
      altura: 250
    },
    'Radioterapia Radioterapia isolada': passoCalendario,
    'Radioterapia Radioterapia concomitante a QT': passoDrogas,
    'Radioterapia Radioterapia para metástases': passoCalendario,
    'Quimioterapia': {
      proximoPasso: 'subtipo-quimioterapia',
      altura: 200
    },
    'Quimioterapia Concomitante a radioterapia': passoDrogas,
    'Quimioterapia Paliativa': passoDrogas,
    'Seguimento': passoCalendario,
  };

  getTiposConduta(): Observable<SelectionOption[]> {
    return of(CondutaColorretalCanalAnalService.tiposConduta);
  }

  getSubtiposCirurgia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalCanalAnalService.subtiposCirurgia);
  }

  getSubtiposRadioterapia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalCanalAnalService.subtiposRadioterapia);
  }

  getSubtiposQuimioterapia(): Observable<SelectionOption[]> {
    return of(CondutaColorretalCanalAnalService.subtiposQuimioterapia);
  }

  getListaDeDrogasQuimioterapiaPaliativo(ficha: Ficha, filtro: boolean) {
    const drogasQuimioterapiaPaliativo = CondutaColorretalCanalAnalService.drogasQuimioterapiaPaliativo;
    const item_MSI_H_dMMR = ficha?.itens?.find((item) => item.chave === 'testesMoleculares.MSI–H ou dMMR');

    let drogasExtras = [];

    if (item_MSI_H_dMMR?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Pembrolizumabe',
        'Nivolumabe',
      ]);
    }

    return [].concat(drogasQuimioterapiaPaliativo, drogasExtras);
  }

  getDrogasConcomitanteRadioterapia() {
    return CondutaColorretalCanalAnalService.drogasConcomitanteQuimioterapia;
  }

  getCondutasDePeriodo(): string[] {
    return CondutaColorretalCanalAnalService.condutasDePeriodo;
  }
}
