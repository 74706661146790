const LABEL_DESCONHECIDO = 'Desconhecido';

export class GrupoTNM {

  static mapping = {
    T0: {
      N0: {
        M0: 'Estágio 0'
      },
      N1: {
        M0: 'Estágio IIB'
      }
    },
    T1: {
      N0: {
        M0: 'Estágio I'
      },
      N1: {
        M0: 'Estágio IIB'
      }
    },
    T2: {
      N0: {
        M0: 'Estágio IIA'
      },
      N1: {
        M0: 'Estágio IIB'
      }
    },
    T3: {
      N0: {
        M0: 'Estágio IIIA'
      },
      N1: {
        M0: 'Estágio IIIA'
      }
    },
    T4: {
      N0: {
        M0: 'Estágio IIIB'
      },
      N1: {
        M0: 'Estágio IIIC'
      }
    },
  };

  static calcular(tValue, nValue, mValue) {
    tValue = tValue?.startsWith('yp') ? tValue?.substr(2) : tValue?.substr(1);
    nValue = nValue?.startsWith('yp') ? nValue?.substr(2) : nValue?.substr(1);
    try {
      const grupoTNMDefault = GrupoTNM.mapping[tValue][nValue][mValue];
      if (grupoTNMDefault) return grupoTNMDefault;
      else throw new Error();
    } catch (e) {
      if (tValue && nValue && mValue === 'M1') {
        return 'Estágio IV';
      } else {
        return LABEL_DESCONHECIDO;
      }
    }
  }
}
