import { AfterViewInit, Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-onboarding-slides',
  templateUrl: './onboarding-slides.component.html',
  styles: [`
    swiper-container {
      --swiper-pagination-bottom: -6px
    }
  `]
})
export class OnboardingSlidesComponent implements AfterViewInit {

  @ViewChild('swiperComponentRef') swiperComponentRef: any;

  slides = [
    {
      text: `Somos uma instituição sem fins lucrativos que visa construir uma rede de dados de vida real em 
      oncologia voltada para oncologistas.`
    },
    {
      text: `Aqui você encontrará ferramentas que facilitarão o seu dia-a-dia e construirá um banco de dados de todos os pacientes da sua carreira.`
    },
    {
      text: `Apenas dados anônimos tanto do oncologista quanto dos pacientes serão disponibilizados e analisados, tudo dentro da LGPD e da Ética.`
    },
    {
      text: `Esses dados serão utilizados para entendermos melhor os desfechos clínicos e as condutas de cada microrregião e subpopulação.`
    },
    {
      text: `Acesse também pelo Desktop e aproveite todas as funcionalidades, quando quiser, de onde quiser.`
    },
    {
      text: `Venha fazer parte e transforme a sua oncologia e da sua região!`
    }
  ];

  constructor() {}

  ngAfterViewInit() {
    setTimeout(this.initSwiper.bind(this), 100);
  }

  initSwiper() {
    const swiperElement = this.swiperComponentRef?.nativeElement?.swiper;
    if (!swiperElement) setTimeout(this.initSwiper.bind(this), 100);
    else {
      setTimeout(() => {
        swiperElement.autoplay.start();
        swiperElement.slideNext();
      }, 2500);
    }
  }
}
