import { Ficha } from '../models/ficha.model';
import {
  CondutaColorretal,
  CondutaMama,
  CondutaProstata,
  CondutaPulmaoNSCLC,
  CondutaPulmaoSCLC
} from '../models/conduta.model';
import { CondutaMamaService } from '../services/conduta-mama.service';
import { CondutaProstataService } from '../services/conduta-prostata.service';
import { toCamelCase } from './utils';

export interface StatusCondutaType {
  cor?: string,
  texto?: string
}

const statusAtualizarConduta: StatusCondutaType = {
  cor: 'var(--om-error-hover)',
  texto: 'Atualizar conduta'
};

const statusCondutaAtualizada: StatusCondutaType = {
  cor: 'var(--om-brand-700)',
  texto: 'Conduta atualizada'
};

const getUltimaCondutaDePeriodoMama = (condutas: CondutaMama[]) => {
  const condutasDePeriodo = [].concat(CondutaMamaService.condutasDePeriodo, ['Seguimento']);
  if (condutas?.length) {
    for (let i = condutas.length - 1; i >= 0; i--) {
      if (condutasDePeriodo.includes(condutas[i].tipo)) {
        return condutas[i];
      }
    }
  }
  return {};
};

const getUltimaCondutaDePeriodoProstata = (condutas: CondutaProstata[]) => {
  const condutasDePeriodo = [].concat(CondutaProstataService.condutasDePeriodo);
  if (condutas?.length) {
    for (let i = condutas.length - 1; i >= 0; i--) {
      if (condutasDePeriodo.includes(`${condutas[i].tipo}${condutas[i].subtipo ? ` ${condutas[i].subtipo}` : ''}`)) {
        return condutas[i];
      }
    }
  }
  return {};
};

const getUltimaCondutaDePeriodoPulmao = (condutas: CondutaPulmaoNSCLC[]|CondutaPulmaoSCLC[]) => {
  const newCondutas = Object.assign([], condutas);
  return (newCondutas.reverse().find(conduta => {
    const condutasDePeriodo = ['Tratamento sistêmico'];
    return condutasDePeriodo.includes([conduta.tipo].filter(Boolean).join(' ')) || conduta.subtipo === 'Radioterapia concomitante a QT';
  }) || {});
};

const getUltimaCondutaDePeriodoColorretal = (condutas: CondutaColorretal[]) => {
  const newCondutas = Object.assign([], condutas);
  return (newCondutas.reverse().find(conduta => {
    const condutasDePeriodo = ['Quimioterapia', 'Seguimento'];
    return condutasDePeriodo.includes(conduta.tipo) || (conduta.tipo === 'Radioterapia' && conduta.subtipo === 'Radioterapia concomitante a QT');
  }) || {});
};

export const getUltimaCondutaDePeriodo = (ficha: Ficha) => {
  let { nome: functionName } = ficha.modulo;
  if (functionName.includes('-')) functionName = toCamelCase(functionName);
  const getUltimaCondutaDePeriodoMapping = {
    mama: getUltimaCondutaDePeriodoMama,
    prostata: getUltimaCondutaDePeriodoProstata,
    pulmaoNsclc: getUltimaCondutaDePeriodoPulmao,
    pulmaoSclc: getUltimaCondutaDePeriodoPulmao,
    colorretalColon: getUltimaCondutaDePeriodoColorretal,
    colorretalReto: getUltimaCondutaDePeriodoColorretal,
    colorretalCanalAnal: getUltimaCondutaDePeriodoColorretal
  };
  return getUltimaCondutaDePeriodoMapping[functionName](ficha.condutas);
};

export const calcularStatusFicha = (ficha: Ficha): StatusCondutaType => {
  const getTimerEmMeses = (conduta, modulo) => {
    const { tipo, subtipo } = conduta || {};
    if (modulo === 'mama') {
      switch (tipo) {
        case 'Quimioterapia': return 3;
        case 'Hormonioterapia': return 6;
        case 'Seguimento': return 12;
      }
    } else if (modulo === 'prostata') {
      if (['Vigilância ativa', 'Tratamento local', 'Seguimento'].includes(tipo) || (tipo === 'Radioterapia prostática' && subtipo === 'Sem ADT')) {
        return 12;
      } else if ((tipo === 'Radioterapia prostática' && subtipo !== 'Sem ADT')) {
        return 6;
      } else if (['Radioterapia para metástases', 'HT Paliativa & Radiofármacos', 'Quimioterapia paliativa'].includes(tipo)) {
        return 3;
      }
    } else if (['pulmao-nsclc', 'pulmao-sclc'].includes(modulo)) {
      if (tipo === 'Radioterapia' && subtipo === 'Radioterapia concomitante a QT') {
        return 3;
      } else if (tipo === 'Tratamento sistêmico') {
        if (['Neoadjuvante', 'Paliativo'].includes(subtipo)) {
          return 3;
        } else if (subtipo === 'Adjuvante') {
          return 6;
        }
      } else if (tipo === 'Seguimento') {
        return 12;
      }
    } else if (modulo === 'colorretal-colon') {
      if (tipo === 'Radioterapia') {
        return 3;
      } else if (tipo === 'Quimioterapia') {
        if (subtipo === 'Neoadjuvante') return 3;
        if (subtipo === 'Adjuvante') return 6;
        if (subtipo === 'Paliativa') return 3;
      } else if (tipo === 'Seguimento') {
        return 12;
      }
    } else if (['colorretal-reto', 'colorretal-canal-anal'].includes(modulo)) {
      if (tipo === 'Radioterapia') {
        if (subtipo === 'Radioterapia isolada short course') return 2;
        else return 3;
      } else if (tipo === 'Quimioterapia') {
        if (subtipo === 'Neoadjuvante') return 3;
        if (subtipo === 'Adjuvante') return 6;
        if (subtipo === 'Paliativa') return 3;
      } else if (tipo === 'Seguimento') {
        return 12;
      }
    } return 0;
  };

  const getUltimaCondutaDePeriodo = (condutas, modulo) => {
    let newCondutas = Object.assign([], (condutas || []));
    if (modulo === 'mama') {
      return newCondutas.reverse().find(conduta => {
        const condutasDePeriodo = ['Hormonioterapia', 'Quimioterapia', 'Seguimento'];
        return condutasDePeriodo.includes(conduta.tipo);
      });
    } else if (modulo === 'prostata') {
      return newCondutas.reverse().find(conduta => {
        const condutasDePeriodo = ['Vigilância ativa', 'Radioterapia prostática Com ADT por até 6 meses', 'Radioterapia prostática Com ADT por 18 meses', 'Radioterapia prostática Com ADT por 24 meses', 'Radioterapia prostática Com ADT por 36 meses', 'HT Paliativa & Radiofármacos', 'Quimioterapia paliativa', 'Seguimento',];
        return condutasDePeriodo.includes([conduta.tipo, conduta.subtipo].filter(Boolean).join(' '));
      });
    } else if (['pulmao-nsclc', 'pulmao-sclc'].includes(modulo)) {
      return newCondutas.reverse().find(conduta => {
        const condutasDePeriodo = ['Tratamento sistêmico'];
        return condutasDePeriodo.includes([conduta.tipo].filter(Boolean).join(' ')) || conduta.subtipo === 'Radioterapia concomitante a QT';
      });
    } else if (modulo === 'colorretal-colon') {
      return newCondutas.reverse().find(conduta => {
        const condutasDePeriodo = ['Quimioterapia', 'Seguimento'];
        return condutasDePeriodo.includes(conduta.tipo);
      });
    } else if (['colorretal-reto', 'colorretal-canal-anal'].includes(modulo)) {
      return newCondutas.reverse().find(conduta => {
        const condutasDePeriodo = ['Quimioterapia', 'Seguimento'];
        return condutasDePeriodo.includes(conduta.tipo) || (conduta.tipo === 'Radioterapia' && conduta.subtipo === 'Radioterapia concomitante a QT');
      });
    }
  }

  let { censura, condutas = [], dataValidade, clinica, itens, modulo } = (ficha || {});

  if (!clinica) {
    return {
      cor: 'var(--om-error-hover)',
      texto: 'Inserir instituição'
    };
  }

  if (!(itens && itens.some(item => item.chave === 'dataDiagnostico'))) {
    return {
      cor: 'var(--om-accent-primary)',
      texto: 'Inserir data do diagnóstico'
    };
  }

  if (censura) {
    let cor;
    let texto;
    if (censura.tipo === 'alta') {
      cor = 'var(--om-successful-hover)';
      texto = 'Alta';
    }
    if (censura.tipo === 'obito') {
      cor = 'var(--om-grey-tertiary)';
      texto = 'Óbito';
    }
    if (censura.tipo === 'perda') {
      cor = 'var(--om-attention-hover)';
      texto = 'Perda de seguimento';
    }
    return { cor, texto };
  }

  const ultimaCondutaDePeriodo = getUltimaCondutaDePeriodo(condutas, modulo.nome);

  if (dataValidade && new Date(dataValidade).getTime() >= new Date().getTime()) {
    return statusCondutaAtualizada;
  }

  if (!ultimaCondutaDePeriodo) {
    return statusAtualizarConduta;
  }

  if (!dataValidade) {
    const timerEmMeses = getTimerEmMeses(ultimaCondutaDePeriodo, modulo.nome);
    const dataUltimaConduta = new Date(ultimaCondutaDePeriodo.data);
    const dataUltimaCondutaPlusTimer = new Date(dataUltimaConduta.setMonth(dataUltimaConduta.getMonth() + timerEmMeses));
    if (dataUltimaCondutaPlusTimer && new Date(dataUltimaCondutaPlusTimer).getTime() >= new Date().getTime()) {
      if (ultimaCondutaDePeriodo.tipo === 'Seguimento') {
        return {
          cor: 'var(--om-brand-300)',
          texto: 'Em seguimento'
        };
      }
      return statusCondutaAtualizada;
    } else {
      return statusAtualizarConduta;
    }
  }
  return statusAtualizarConduta;
};
