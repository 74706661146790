import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

export const AVAILABLE_MESSAGE_LISTENERS = {
  NOVO_CASO_PUBLICADO : 'novo_caso_publicado',
  TERMO_CONSENTIMENTO_ENVIADO : 'termo_consentimento_enviado',
  TERMO_CONSENTIMENTO_ACEITO : 'termo_consentimento_aceito',
  TERMO_CONSENTIMENTO_RECUSADO : 'termo_consentimento_recusado',
};

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {

  private socket: Socket;

  constructor(private authService: AuthService) {
    this.prepareConnection();
  }

  prepareConnection() {
    const usuario = this.authService.getUser();
    if (!usuario) { return }
    const socketUrl = environment.apiUrl.split('/dataonco-api')[0];
    this.socket = io(socketUrl, {
      query: {
        idUsuario: usuario._id
      }
    });
  }

  registerMessageListener(listenerName: string, callback: (payload?) => void) {
    if (!this.socket) { return }
    this.socket.on(listenerName, callback);
  }
}
