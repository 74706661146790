import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario.model';
import { getAppUrl } from '../util/utils';
import { Clinica } from '../models/clinica.model';
import { PacienteDto } from '../models/paciente.model';

export const CLINICA_MESCLAGEM = '_oncomax_clinica_mesclagem';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends AbstractService<any> {

  constructor(public http: HttpClient) {
    super('admin', http);
  }

  getDadosMedicoById(medicoId: string): Observable<Usuario> {
    return this.http.get<Usuario>(`${getAppUrl()}/admin/medico/${medicoId}`);
  }

  editarUsuario(usuario: Usuario) {
    return this.http.put<Usuario>(`${getAppUrl()}/admin/medico/${usuario._id}`, usuario);
  }

  validarMedico(idMedico: string, validado: boolean) {
    return this.http.put<any>(`${getAppUrl()}/admin/medico/${idMedico}/validar`, { validado });
  }

  excluirMedico(idMedico: string) {
    return this.http.delete<any>(`${getAppUrl()}/admin/medico/${idMedico}/deletar`);
  }

  getInstituicaoById(idClinica) {
    return this.http.get<Clinica>(`${getAppUrl()}/admin/clinica/${idClinica}`);
  }

  setClinicaMesclagem(clinica: Clinica) {
    localStorage.setItem(CLINICA_MESCLAGEM, JSON.stringify(clinica));
  }

  getClinicaMesclagem() {
    const clinica = localStorage.getItem(CLINICA_MESCLAGEM);
    if (clinica) return JSON.parse(clinica);
  }

  clearClinicaMesclagem() {
    localStorage.removeItem(CLINICA_MESCLAGEM);
  }

  mesclarClinicas(idInstituicao, idInstituicaoDestino) {
    return this.http.put<Clinica>(`${getAppUrl()}/admin/clinica/substituir`, {
      idInstituicao,
      idInstituicaoDestino
    });
  }

  validarInstituicao(idClinica: string, validado) {
    return this.http.put<any>(`${getAppUrl()}/admin/clinica/${idClinica}/validar`, { validado });
  }

  excluirFicha(idFicha: string) {
    return this.http.delete<any>(`${getAppUrl()}/admin/ficha/${idFicha}`);
  }

  atualizarInstituicao(clinica: Clinica) {
    return this.http.put<any>(`${getAppUrl()}/admin/clinica/${clinica._id}`, clinica);
  }

  salvarObservacoesMedico(idMedico: string, observacao: string) {
    return this.http.put<any>(`${getAppUrl()}/admin/observacao/medico/${idMedico}`, { observacao });
  }

  buscarObservacoesMedico(idMedico: string) {
    return this.http.get<any>(`${getAppUrl()}/admin/observacao/medico/${idMedico}`);
  }

  buscarFichasPacientes(params): Observable<PacienteDto[]> {
    return this.http.get<PacienteDto[]>(`${getAppUrl()}/admin/paciente`, { params });
  }

  buscarMedicos(params) {
    return this.http.get<PacienteDto[]>(`${getAppUrl()}/admin/medico`, { params });
  }

  buscarMedicosRelatorio(params?) {
    return this.http.get<PacienteDto[]>(`${getAppUrl()}/admin/medico/relatorio`, { params });
  }

  detalharMedico(idMedico: string) {
    return this.http.get<PacienteDto[]>(`${getAppUrl()}/admin/medico/${idMedico}/detalhar`);
  }

  listarContasInstitucionais(): Observable<Clinica[]>{
    return this.http.get(`${getAppUrl()}/admin/clinica/listar-contas-institucionais`) as Observable<any>;
  }

  aprovarContaInstitucional(idClinica: string): Observable<any>{
    return this.http.post( `${getAppUrl()}/admin/clinica/${idClinica}/conta-institucional/aprovar`, {}) as Observable<any>;
  }

  reprovarContaInstitucional(idClinica: string): Observable<any>{
    return this.http.post(`${getAppUrl()}/admin/clinica/${idClinica}/conta-institucional/reprovar`, {}) as Observable<any>;
  }
}

