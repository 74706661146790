import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CustomAlertComponent } from '../components/custom-alert/custom-alert.component';
import { ButtonType } from '../components/custom-button/custom-button.component';

interface CreateAlert {
  title: string;
  text: string;
  customHeight?: number;
  customWidth?: number;
  warn?: string;
  handler?: ({data}) => void;
  okText?: string;
  cancelText?: string;
  confirmButtonType?: ButtonType;
  confirmButtonIcon?: string;
  cancelButtonType?: ButtonType;
  textType?: 'danger' | 'primary';
}

@Injectable({
  providedIn: 'root'
})
export default class CustomAlertHelper {

  constructor(private modalController: ModalController) { }

  async create({title, text, handler, okText, cancelText, warn, confirmButtonIcon, confirmButtonType = 'primary', cancelButtonType = 'default', textType, customWidth, customHeight}: CreateAlert) {
    const modal = await this.modalController.create({
      component: CustomAlertComponent,
      componentProps: {
        title,
        text,
        okText,
        cancelText,
        warn,
        confirmButtonType,
        confirmButtonIcon,
        cancelButtonType,
        textType
      },
      cssClass: 'app-oncomax-custom-alert',
      animated: false,
      backdropDismiss: false
    });

    if (customWidth) {
      modal.style.setProperty('--width', `${customWidth}px`);
      modal.style.setProperty('--max-width', `${customWidth}px`);
    }

    if (customHeight) {
      modal.style.setProperty('--height', `${customHeight}px`);
      modal.style.setProperty('--max-height', `${customHeight}px`);
    }

    modal.onWillDismiss().then(handler);
    return modal;
  }
}
