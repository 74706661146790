import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

export interface Notification {
  type: string;
  text: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class SystemNotificationService {

  constructor(private authService: AuthService) {
  }

  get(): Notification[] {
    const notifications: any[] | Notification[] = [];
    const user = this.authService.getUser();
    if (!user.clinicaList.length) {
      notifications.push({
        type: 'Dados cadastrais',
        text: 'Inserir instituição de atendimento.',
        color: '#DA1111'
      });
    }
    return notifications;
  }
}
