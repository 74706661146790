const LABEL_DESCONHECIDO = 'Desconhecido';

export class GrupoTNM {

  static mapping = {
    Tx: {
      N0: {
        M0: 'Carcinoma Oculto'
      }
    },
    Tis: {
      N0: {
        M0: '0'
      },
      N1: {
        M0: 'IIB'
      },
      N2 : {
        M0: 'IIIA'
      },
      N3: {
        M0: 'IIIB'
      }
    },
    T1mi: {
      N0: {
        M0: 'IA1'
      },
      N1: {
        M0: 'IIB'
      },
      N2: {
        M0: 'IIIA'
      },
      N3: {
        M0: 'IIIB'
      },
    },
    T1a: {
      N0: {
        M0: 'IA1'
      },
      N1: {
        M0: 'IIB'
      },
      N2: {
        M0: 'IIIA'
      },
      N3: {
        M0: 'IIIB'
      }
    },
    T1b: {
      N0: {
        M0: 'IA2'
      },
      N1: {
        M0: 'IIB'
      },
      N2: {
        M0: 'IIIA'
      },
      N3: {
        M0: 'IIIB'
      }
    },
    T1c: {
      N0: {
        M0: 'IA3'
      },
      N1: {
        M0: 'IIB'
      },
      N2: {
        M0: 'IIIA'
      },
      N3: {
        M0: 'IIIB'
      }
    },
    T2a: {
      N0: {
        M0: 'IB'
      },
      N1: {
        M0: 'IIB'
      },
      N2: {
        M0: 'IIIA'
      },
      N3: {
        M0: 'IIIB'
      }
    },
    T2b: {
      N0: {
        M0: 'IIA'
      },
      N1: {
        M0: 'IIB'
      },
      N2: {
        M0: 'IIIA'
      },
      N3: {
        M0: 'IIIB'
      }
    },
    T3: {
      N0: {
        M0: 'IIB'
      },
      N1: {
        M0: 'IIIA'
      },
      N2: {
        M0: 'IIIB'
      },
      N3: {
        M0: 'IIIC'
      }
    },
    T4: {
      N0: {
        M0: 'IIIA'
      },
      N1: {
        M0: 'IIIA'
      },
      N2: {
        M0: 'IIIB'
      },
      N3: {
        M0: 'IIIC'
      }
    },
  };

  static calcular(tValue, nValue, mValue) {
    tValue = tValue.startsWith('yp') ? tValue.substr(2) : tValue.substr(1);
    nValue = nValue.startsWith('yp') ? nValue.substr(2) : nValue.substr(1);
    try {
      const grupoTNM = GrupoTNM.mapping[tValue][nValue][mValue];
      if (grupoTNM) return grupoTNM;
      else throw new Error();
    } catch (e) {
      if (tValue && nValue && ['M1a', 'M1b'].includes(mValue)) {
        return 'IVA';
      } else  if (tValue && nValue && mValue === 'M1c') {
        return 'IVB';
      } else {
        return LABEL_DESCONHECIDO;
      }
    }
  }
}
