import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { FichaService } from '../../services/ficha.service';
import { Ficha, ResumoCaso } from '../../models/ficha.model';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import * as saveAs from 'file-saver';
import { toast } from '../../util/toast';
import { ConnectionStatus } from '@capacitor/network/dist/esm/definitions';
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';

@Component({
  selector: 'app-exportar-imagem-ficha',
  templateUrl: './exportar-ficha.component.html',
})
export class ExportarFichaComponent extends AbstractModal {

  ficha: Ficha;
  resumoCaso: ResumoCaso;
  moduloAtual: IMenuModuloConfig;
  fixedHeight = '230px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  width: number;
  height: number;
  loading: boolean = false;
  isMobile: boolean;
  loadingText: string;
  loadingValue: number = 0;
  loadingBuffer: number = 0;
  loadingInterval: number = 0;
  loadingTexts: string[] = [
    'Solicitando imagens...',
    'Processando requisição...',
    'Processando dados da ficha do paciente...',
    'Em alguns instantes as imagens estarão disponíveis...',
  ];

  constructor(public readonly injector: Injector,
              private fichaService: FichaService,
              private moduloService: ModuloService,
              private platform: Platform) {
    super(injector);
    moduloService.$moduloAtual.subscribe(modulo => this.moduloAtual = modulo);
  }

  turnOnLoading() {
    let textIndex = 0;
    this.loading = true;
    this.loadingText = this.loadingTexts[textIndex];
    this.loadingInterval = setInterval(() => {
      if (textIndex === this.loadingTexts.length) { textIndex = 0; }
      this.loadingText = this.loadingTexts[textIndex];
      if (this.loadingValue < 1) {
        this.loadingValue += 0.15;
        this.loadingBuffer += 0.2;
      }
      textIndex += 1;
    }, 2000);
  }

  turnOffLoading() {
    this.loading = false;
    clearInterval(this.loadingInterval);
  }

  async handleExpotarFichaClick() {
    if (!this.resumoCaso?.slps?.length) {
      await toast('O paciente ainda não tem dados para serem exportados');
      return;
    }
    const networkStatus: ConnectionStatus = await Network.getStatus();
    if (networkStatus.connected) {
      this.turnOnLoading();
      this.fichaService.exportarImagem(this.ficha, this.width, this.height, this.moduloAtual.name).subscribe(async (blob: any) => {
        if (this.platform.is('hybrid')) {
          this.nativeShareFile(blob);
        } else {
          saveAs(blob, `${this.ficha?.paciente?.nome}.jpeg`);
        }
        await this.handleCLickClose();
        this.turnOffLoading();
      })
    } else {
      await toast('Para exportar a imagem da ficha do paciente é necessário conexão com a internet, tente novamente mais tarde');
    }
  }

  async handleExportarHistoriaFichaClick() {
    if (!this.resumoCaso?.slps?.length) {
      await toast('O paciente ainda não tem dados para serem exportados');
      return;
    }
    const networkStatus: ConnectionStatus = await Network.getStatus();
    if (networkStatus.connected) {
      this.turnOnLoading();
      this.fichaService.exportarHistoriaFicha(this.ficha, this.width, this.height, this.moduloAtual.name).subscribe(async (blob: any) => {
        if (this.platform.is('hybrid')) {
          this.nativeShareFile(blob);
        } else {
          saveAs(blob, `${this.ficha?.paciente?.nome}.zip`);
        }
        await this.handleCLickClose();
        this.turnOffLoading();
      });
    } else {
      await toast('Para exportar a imagem da ficha do paciente é necessário conexão com a internet, tente novamente mais tarde');

    }
  }

  private nativeShareFile(blob: Blob) {
    const reader = new FileReader();
    const fileName = `${this.ficha?.paciente?.nome}.jpeg`;
    reader.readAsDataURL(blob);
    reader.onloadend = async () => {
      const base64Data: any = reader.result;
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Cache
      });
      const uriResult = await Filesystem.getUri({ directory: Directory.Cache, path: fileName });
      await Share.share({ files: [uriResult.uri] });
    }
  }
}
