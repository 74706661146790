import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsentimentoService extends AbstractService<any> {

  $consentimentoAtualizado: Subject<any> = new Subject<any>();
  $termoConsentimentoEnviado: Subject<any> = new Subject<any>();
  $termoConsentimentoAceito: Subject<any> = new Subject<any>();
  $termoConsentimentoRecusado: Subject<any> = new Subject<any>();

  constructor(public http: HttpClient) {
    super('consentimento', http);
  }

  aceitar(idTermo: string) {
    return super.post( {}, `${idTermo}/aceitar`);
  }

  recusar(idTermo: string) {
    return super.post({}, `${idTermo}/recusar`);
  }

  enviar(idFicha: string) {
    return super.post({}, `enviar/ficha/${idFicha}`);
  }

  setConsentimentoAtualizado(idTermo: string, idFicha: string) {
    this.$consentimentoAtualizado.next({ idTermo, idFicha });
  }

  setTermoConsentimentoEnviado({consentimento}) {
    this.$termoConsentimentoAceito.next(consentimento);
  }

  setTermoConsentimentoAceito({consentimento}) {
    this.$termoConsentimentoAceito.next(consentimento);
  }

  setTermoConsentimentoRecusado({consentimento}) {
    this.$termoConsentimentoRecusado.next(consentimento);
  }
}

