import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import { ResponsiveService } from '../../../../services/responsive.service';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/pulmao-nsclc/util';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';

export const HISTOPATOLOGICO_CHAVE = 'histopatologico';
export const HISTOPATOLOGICO_OPTIONS: SelectionOption[] = [
  {
    nome: 'Pequenas células',
    valor: 'Pequenas células',
  },
  {
    nome: 'Carcinóide típico',
    valor: 'Carcinóide típico',
  },
  {
    nome: 'Carcinóide atípico',
    valor: 'Carcinóide atípico',
  },
  {
    nome: 'Outros',
    valor: 'Outros',
    customClasses: 'italic'
  },
];

@Component({
  selector: 'app-input-histopatologico',
  templateUrl: './input-histopatologico.component.html',
})
export class InputHistopatologicoComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '300px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;

  form: FormGroup = new FormGroup({
    histopatologico: new FormControl('')
  });

  histopatologicoOptions: SelectionOption[] = HISTOPATOLOGICO_OPTIONS;

  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }
  
  ngOnInit() {
    const { histopatologico, id } = inputValueResolver(HISTOPATOLOGICO_CHAVE, this.ficha?.itens);
    this.id = id;
    if (histopatologico !== undefined) this.form.patchValue({ histopatologico });
  }

  async handleEndEditClick() {
    const histopatologico = this.form.get(HISTOPATOLOGICO_CHAVE).value;
    if (histopatologico === 'Carcinóide típico') {
      await this.tratarCarcinoideTipico();
    } else if (histopatologico === 'Carcinóide atípico') {
      await this.tratarCarcinoideAtipico();
    } else {
      await this.handleSalvarItem();
    }
  }

  async tratarCarcinoideAtipico() {
    const alert = await this.alertHelper.create({
      title: 'Carcinóide atípico',
      text: 'Deseja tratar como tumor de pequenas células ou tumor Neuroendócrino',
      okText: 'Neuroendócrino',
      cancelText: 'Pequenas células',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          setTimeout(this.handleSalvarItem.bind(this), 300);
        } else {
          const control = this.form.get(HISTOPATOLOGICO_CHAVE);
          control.valueChanges.subscribe(() => {
            setTimeout(this.handleSalvarItem.bind(this), 300);
          });
          control.setValue('Pequenas células');
        }
      }
    });
    await alert.present();
  }

  async tratarCarcinoideTipico() {
    const alert = await this.alertHelper.create({
      title: 'Carcinóide típico',
      text: 'Este paciente deve ser inserido no módulo de Tumor Neuroendócrino',
      okText: 'Continuar',
      cancelText: 'Cancelar',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          setTimeout(this.handleSalvarItem.bind(this), 300);
        }
      }
    });
    await alert.present();
  }

  async handleSalvarItem() {
    const newItem: ItemFicha = {
      chave: HISTOPATOLOGICO_CHAVE,
      valor: this.form.get(HISTOPATOLOGICO_CHAVE).value
    };
    if (this.id) newItem.id = this.id;
    const items: ItemFicha[] = [newItem];
    await this.modalController.dismiss(items);
  }
}
