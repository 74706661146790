import { Component } from '@angular/core';
import { AbstractButton } from '../../_base/abstract-button';

@Component({
  selector: 'app-danger-button',
  templateUrl: './danger.component.html',
})
export class DangerButtonComponent extends AbstractButton {

  constructor() {
    super();
  }
}
