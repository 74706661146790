import { Component, Injector, ViewChild } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Ficha } from '../../models/ficha.model';
import { PromptService } from '../../services/prompt.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import CustomAlertHelper from '../../helper/custom-alert-helper';
import { parseISO } from 'date-fns';
import { FichaService } from '../../services/ficha.service';
import { loading } from '../../util/loading';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { CustomDatepickerComponent } from '../../components/custom-datepicker/custom-datepicker.component';
import * as moment from 'moment';
import { toast } from '../../util/toast';

@Component({
  selector: 'app-preenchimento-automatico-ficha',
  templateUrl: './preenchimento-automatico-ficha.component.html',
  styles: [`
      progress[value]::-webkit-progress-value {
          background-color: var(--progress-custom-background-color);
      }
      progress[value]::-moz-progress-bar {
          background-color: var(--progress-custom-background-color);
      }
  `]
})
export class PreenchimentoAutomaticoFichaComponent extends AbstractModal {

  @ViewChild('editorComponentRef') editorComponentRef;
  modulo: IMenuModuloConfig;
  ficha: Ficha;
  texto: string;
  fixedWidth = '800px';
  offsetHeight = 10;
  loading: boolean = false;
  loadingText: string;
  loadingValue: number = 0;
  loadingBuffer: number = 0;
  loadingInterval: number = 0;
  editorToolbarConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    enableToolbar: false,
    height: '100%',
    toolbarHiddenButtons: [
      [],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };
  resposta: any;
  form: FormGroup = new FormGroup({
    itens: new FormArray([]),
    condutas: new FormArray([])
  });

  itensLabels: string[] = [];
  itensKeys: string[] = [];
  itensPrecisao: number[] = [];

  loadingTexts: string[] = [
    'A consulta está sendo processada..',
    'Consultando...',
    'Buscando melhor resultado...',
    'Refinando a busca com a inteligência artificial...',
    'Em instantes a resposta estará disponível...',
  ];

  constructor(public readonly injector: Injector,
              private promptService: PromptService,
              private alertHelper: CustomAlertHelper,
              private fichaService: FichaService,
              private modalHelper: CustomModalHelper) {
    super(injector);
  }

  get itens() {
    return this.form.controls["itens"] as FormArray;
  }

  get condutas() {
    return this.form.controls["condutas"] as FormArray;
  }

  condutaInputResolver(index, fieldName) {
    return () => {
      if (fieldName.startsWith('data')) {
        const data = this.condutas.at(index).get(fieldName).value;
        return data?.split('-').reverse().join('/');
      }
      return this.condutas.at(index).get(fieldName).value;
    };
  }

  async openCondutaDatepicker(index, fieldName) {
    const date = this.condutas.at(index).get(fieldName).value;
    const modal = await this.modalHelper.create({
      component: CustomDatepickerComponent,
      componentProps: {
        date: date ? date : moment().format('YYYY-MM-DD')
      },
      handler: ({ data }) => {
        if (data.confirm) {
          const date = moment(data.payload.date).format('YYYY-MM-DD');
          this.condutas.at(index).get(fieldName).setValue(date);
        }
      }
    });
    await modal.present();
  }

  async edicaoNaoDisponivel() {
    await toast('A edição deste campo ainda não está disponível');
  }

  turnOnLoading() {
    let textIndex = 0;
    this.loading = true;
    this.loadingText = this.loadingTexts[textIndex];
    this.loadingInterval = setInterval(() => {
      if (textIndex === this.loadingTexts.length) { textIndex = 0; }
      this.loadingText = this.loadingTexts[textIndex];
      if (this.loadingValue < 8) {
        this.loadingValue += 0.15;
        this.loadingBuffer += 0.2;
      }
      textIndex += 1;
    }, 7000);
  }

  turnOffLoading() {
    clearInterval(this.loadingInterval);
    this.loading = false;
  }

  buildFormControls() {
    const { itens, condutas } = this.resposta;
    if (itens) this.buildFormControlsItens(itens);
    if (condutas) this.buildFormControlsCondutas(condutas);
  }

  buildFormControlsItens(itens) {
    Object.values(itens).forEach((item: any) => {
      this.itens.push(new FormControl(item.valor));
      this.itensLabels.push(item.label);
      this.itensKeys.push(item.chave);
      this.itensPrecisao.push(item.precisao);
    });
  }

  buildFormControlsCondutas(condutas: any[]) {
    condutas.forEach((conduta) => {
      const data = conduta.data ? conduta.data.split('/').reverse().join('-') : null;
      const dataFim = conduta.dataFim ? conduta.dataFim.split('/').reverse().join('-') : null;
      this.condutas.push(new FormGroup({
        tipo: new FormControl(conduta.tipo),
        subtipo: new FormControl(conduta.subtipo),
        data: new FormControl(data),
        dataFim: new FormControl(dataFim),
        drogas: new FormControl(conduta.drogas),
        precisao: new FormControl(conduta.precisao),
      }));
    });
  }

  handleSolicitarPreenchimentoClick() {
    this.turnOnLoading();
    this.promptService.solicitarPreenchimentoAutomaticoFicha(this.modulo.name, this.texto)
      .subscribe((resposta) => {
        this.resposta = resposta;
        this.turnOffLoading();
        this.buildFormControls();
        this.setCustomModalWidth();
    });
  }

  async handleConfirmarPreenchimentoClick() {
    const { itens, condutas } = this.ficha;
    if (!itens?.length && condutas?.length) {
      this.handleConfirmarPreenchimento();
    } else {
      const alert = await this.alertHelper.create({
        title: 'Preenchimento automático',
        text: 'Confirma todos os valores disponíveis em tela? Todos os valores serão sobrescritos da ficha original e as condutas serão todas substituídas.',
        okText: 'Sim',
        cancelText: 'Não',
        confirmButtonType: 'danger',
        cancelButtonType: 'neutral',
        handler: ({data}) => {
          if (data.confirm) {
            this.handleConfirmarPreenchimento();
          }
        }
      });
      await alert.present();
    }
  }

  handleConfirmarPreenchimento() {
    this.prepararFichaParaSalvar();
    loading(this.fichaService.preencherFichaAutomaticamente(this.ficha).subscribe(async (ficha) => {
      await this.handleCLickClose(true, { ficha })
    }));
  }

  prepararFichaParaSalvar() {
    const formValues = this.form.getRawValue();
    const itemsValues = formValues.itens;
    const condutasValues: any[] = formValues.condutas;

    const prepararDataType = (value: any) => {
      if (typeof value === 'boolean' || value === null || typeof value == 'string') {
        return value;
      } else if (!isNaN(value)) {
        return Number(value);
      } else if (/\d{2}\/\d{2}\/\d{4}/.test(value)) {
        return parseISO(value.split('/').reverse().join('-'));
      } else {
        return value;
      }
    };

    const prepararDataConduta = (data: string) => {
      return parseISO(data.split('/').reverse().join('-'));
    };

    const prepararItens = () => {
      if (this.ficha.itens?.length) {
        this.itensKeys.forEach((itemKey, itemIndex) => {
          const itemFichaIndex = this.ficha.itens.findIndex(item => item.chave === itemKey);
          if (itemFichaIndex >= 0) {
            const itens = this.ficha.itens;
            this.ficha.itens.splice(itemFichaIndex, 1, Object.assign({}, itens[itemFichaIndex], {
              valor: prepararDataType(itemsValues[itemIndex])
            }));
          } else {
            this.ficha.itens.push({
              chave: itemKey,
              valor: prepararDataType(itemsValues[itemIndex])
            });
          }
        });
      } else {
        this.ficha.itens = this.itensKeys.map((chave, index) => ({
          chave,
          valor: prepararDataType(itemsValues[index])
        }));
      }
    };

    const prepararCondutas = () => {
      if (condutasValues?.length) {
        this.ficha.condutas = condutasValues.map((conduta) => ({
          tipo: conduta.tipo,
          subtipo: conduta.subtipo,
          data: conduta.data ? prepararDataConduta(conduta.data) : null,
          dataFim: conduta.dataFim ? prepararDataConduta(conduta.dataFim) : null,
          drogas: conduta.drogas
        })) as any[];
      }
    };

    const prepararItensExtras = () => {
      if (this.resposta.itensExtras?.length) {
        this.resposta.itensExtras.forEach((item) => {
          this.ficha.itens.push({
            chave: item.chave,
            valor: prepararDataType(item.valor),
            data: item.data ? prepararDataType(item.data) : void 0,
          });
        });
      }
    };

    prepararItens();
    prepararCondutas();
    prepararItensExtras();
  }

  getProgressBarColor(precisao: number) {
    if (precisao >= 0 && precisao <= 60) return '#DB0000';
    else if (precisao >= 70 && precisao <= 80) return '#F2994A';
    else if (precisao >= 90 && precisao <= 100) return '#219653';
  }

  setCustomModalWidth() {
    this.setWidth(this.windowWidth - 10);
  }
}
