import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ResponsiveService } from '../../../../services/responsive.service';
import { ItemFichaService } from '../../../../services/item-ficha.service';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { ItemFicha } from '../../../../models/ficha.model';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';

@Component({
  selector: 'app-input-her2',
  templateUrl: './input-her.component.html',
})
export class InputHerComponent extends AbstractModal implements InputWithModal {
  
  fichaPaciente: any;
  id: string;
  offsetWidth = 10;
  fixedHeight = '440px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  
  
  form: FormGroup = new FormGroup({
    her2: new FormControl('')
  });
  
  her2Options = [
  '0',
  '+1',
  '+2, FISH -',
  '+2, FISH +',
  '+3',
  'Desconhecido'
  ];
  
  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService,
              public itemFichaService: ItemFichaService) {
    super(injector);
  }
  
  ngOnInit() {
    const { her2, id } = inputValueResolver('her2', this.fichaPaciente?.itens);
    this.id = id;
    if (her2 !== undefined) this.form.patchValue({ her2 });
  }
  
  async handleEndEditClick() {
    if (this.form.get('her2').pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: 'her2',
        valor: this.form.get('her2').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }

}
