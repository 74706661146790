import { ROUTE } from './route';
import { CLIENT_ROUTE_NAVIGATION } from './client-route';

export const webMenuOpenedWidth = 260;
export const webMenuClosedWidth = 80;

export interface MenuItem {
  title?: string;
  route?: string;
  icon?: string;
  class?: string;
  active?: boolean;
  replaceUrl?: boolean;
}

export const menuConfig: MenuItem[] = [
  {
    class: 'first-tab',
    route: ROUTE.MODULOS_CANCER,
    icon: 'c-home-outlined',
    active: true
  },
  {
    class: 'second-tab desktop:hidden',
    icon: 'c-charts-outlined',
    route: ROUTE.DASHBOARD,
    replaceUrl: true
  },
  {
    class: 'add-button',
  },
  {
    class: 'third-tab',
    icon: 'c-search-outlined',
    route: ROUTE.PESQUISAR_PACIENTES,
    replaceUrl: true
  },
  {
    class: 'mobile:hidden',
    icon: 'c-git-compare-outline',
    route: ROUTE.DISCUSSAO_CASO,
    replaceUrl: true
  },
  {
    class: 'mobile:hidden',
    icon: 'c-alert-outline',
    route: ROUTE.PATROCINADORES,
    replaceUrl: true
  },
  {
    class: 'mobile:hidden',
    icon: 'c-users',
    route: ROUTE.CONTA_INSTITUICIONAL,
    replaceUrl: true
  },
  {
    class: 'fourth-tab',
    icon: 'c-settings-outlined',
    route: ROUTE.CONFIGURACOES,
    replaceUrl: true
  }
];

export const adminMenuConfig: MenuItem[] = [
  {
    class: 'c-home-outlined',
    route: CLIENT_ROUTE_NAVIGATION.INICIO,
    icon: 'c-home-outlined',
    active: true
  },
  {
    class: 'c-medicine',
    route: CLIENT_ROUTE_NAVIGATION.MEDICO,
    icon: 'c-medicine',
  },
  {
    class: 'c-users',
    route: CLIENT_ROUTE_NAVIGATION.PACIENTE,
    icon: 'c-users',
  },
  {
    class: 'c-hospital',
    route: CLIENT_ROUTE_NAVIGATION.INSTITUICAO,
    icon: 'c-hospital',
  },
  {
    class: 'c-charts-outlined',
    icon: 'c-charts-outlined',
    route: CLIENT_ROUTE_NAVIGATION.DASHBOARD,
  },
  {
    class: 'c-chatbubbles-outline',
    icon: 'c-chatbubbles-outline',
    route: CLIENT_ROUTE_NAVIGATION.PROMPT,
  },
  {
    class: 'c-medkit-outline',
    icon: 'c-medkit-outline',
    route: CLIENT_ROUTE_NAVIGATION.CONTA_INSTITUICIONAL,
  },
  {
    class: 'c-settings-outlined',
    icon: 'c-settings-outlined',
    route: CLIENT_ROUTE_NAVIGATION.CONFIGURACOES,
  },
  {
    class: 'c-game',
    icon: 'c-game',
    route: CLIENT_ROUTE_NAVIGATION.GAMIFICACAO,
  },
];

[].concat(menuConfig, adminMenuConfig).forEach((item) => {
  if (!item.route) return;
  const route = String(item.route);
  let active: boolean;
  Object.defineProperties(item, {
    active: {
      get() {
        const { pathname } = location;
        return pathname.endsWith(route);
      },
      set(value) {
        active = value;
      }
    }
  });
});
