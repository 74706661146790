import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (): RangeConfig => ({
  options: [
    {
      label: 'Mx',
      value: 'Mx',
      hint: 'Desconhecido'
    },
    {
      label: 'M0',
      value: 'M0',
      hint: 'Nenhuma metástase a distância'
    },
    {
      label: 'M1',
      value: 'M1',
      hint: 'Metástase à distância',
      options: [
        {
          label: 'M1',
          value: 'M1',
          hint: 'Metástase à distância',
        },
        {
          label: 'M1a',
          value: 'M1a',
          hint: `Tumor com algum dos seguintes: ≥ 1 nódulos tumorais no pulmão contralateral Nódulos pleurais ou pericárdicos Derrame pleural ou pericárdico maligno`
        },
        {
          label: 'M1b',
          value: 'M1b',
          hint: 'Metástase extratorácica isolada em um único órgão'
        },
        {
          label: 'M1c',
          value: 'M1c',
          hint: 'Múltiplas metástases extratorácicas em um ou vários órgãos'
        }
      ]
    },
  ]
});
