import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemFichaService {

  static tiposHistopatologico = [
    'Lobular',
    'Ductal',
    'Prognóstico Favorável',
    'Outros',
  ];

  static tiposInputGrau = [
    'Gx',
    'G1',
    'G2',
    'G3'
  ];

  static tiposHer = [
    '0',
    '+1',
    '+2',
    '+3',
    'FISH+'
  ];

  static tiposT = [
    'Tx',
    'Tis',
    'T1mi',
    'T1a',
    'T1b',
    'T1c',
    'T2',
    'T3',
    'T4a',
    'T4b',
    'T4c',
    'T4d',
  ];

  static tiposN = [
    'cNx',
    'cN0',
    'cN1',
    'cN2a',
    'cN2b',
    'cN3a',
    'cN3b',
    'cN3c',
    'pNx',
    'pN0',
    'pN1mic',
    'pN1a',
    'pN1b',
    'pN1c',
    'pN2a',
    'pN2b',
    'pN3a',
    'pN3b',
    'pN3c'
  ];

  static tiposM = [
    'Mx',
    'M0',
    'M1',
  ];

  getTiposHistopatologico() {
    return of(ItemFichaService.tiposHistopatologico);
  }

  getTiposGrau() {
    return of(ItemFichaService.tiposInputGrau);
  }

  getTiposT() {
    return of(ItemFichaService.tiposT);
  }

  getTiposHer() {
    return of(ItemFichaService.tiposHer);
  }

  getTiposN() {
    return of(ItemFichaService.tiposN);
  }

  getTiposM() {
    return of(ItemFichaService.tiposM);
  }
}
