import { Component, Input, OnInit } from '@angular/core';
import { Comentario } from '../../models/publicacao.model';
import * as moment from 'moment';

@Component({
  selector: 'app-discussao-opiniao-card',
  templateUrl: './discussao-opiniao-card.component.html',
})
export class DiscussaoOpiniaoCardComponent implements OnInit {

  @Input()
  comentario: Comentario;
  dataFormatada: string;

  constructor() { }

  ngOnInit() {
    const data = new Date(String(this.comentario.createdAt)).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
    const hora = new Date(String(this.comentario.createdAt)).toLocaleTimeString('pt-BR', {});
    this.dataFormatada = `${data} às ${hora}`;
  }

  get brokenText(): string[] {
    return this.comentario.texto.split('\n');
  }
}
