import { AfterViewInit, Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { Ficha } from '../../models/ficha.model';
import * as moment from 'moment';
import { parseISO } from 'date-fns';
import { FichaService } from '../../services/ficha.service';
import { loading } from '../../util/loading';
import { getUltimaCondutaDePeriodo } from '../../util/ficha';

@Component({
  selector: 'app-confirmar-pendencia',
  templateUrl: './confirmar-pendencia.component.html',
})
export class ConfirmarPendenciaComponent extends AbstractModal implements AfterViewInit {

  ficha: Ficha;
  condutaAtual: any;
  fixedWidth = !this.responsiveService.isMobilePlatform ? '680px' : null;
  fixedHeight = '380px';
  mesesValues = [1, 2, 3, 4, 6, 9, 12, 18, 24, 36];
  selectedDate: any;
  defaultMessage = 'Este paciente ainda permanece com esta conduta ativa?';
  semCondutaMessage = 'Você deseja manter este paciente sem conduta?';

  constructor(public readonly injector: Injector,
              private fichaService: FichaService) {
    super(injector);
  }

  ngOnInit() {
    let ultimaCondutaDePeriodo;
    if (this.ficha?.condutas?.length === 1) {
      ultimaCondutaDePeriodo = this.ficha.condutas[0];
    } else {
      ultimaCondutaDePeriodo = getUltimaCondutaDePeriodo(this.ficha);
    }
    if (ultimaCondutaDePeriodo) this.condutaAtual = [ultimaCondutaDePeriodo];
  }

  ngAfterViewInit() {
    if (!this.condutaAtual[0]?.tipo) {
      this.setHeight(320);
    }
  }

  handlePeriodoSelect(event: any) {
    const date = moment().add(Number(event.target.value), 'month').format('YYYY-MM-DD');
    this.selectedDate = parseISO(date);
  }
  
  async handleClickConfirm() {
    if (!this.selectedDate) { return; }
    await loading(this.fichaService.atualizarDataValidade(this.ficha, this.selectedDate).subscribe((ficha) => {
      this.handleCLickClose(true, { ficha });
    }));
  }
}
