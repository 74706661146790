import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
})
export class CircularProgressBarComponent {

  @Input() progress: number = 0;
  @Input() remaining: number = 0;

  get strokeDashoffset() {
    const circumference = 2 * Math.PI * 43;
    return circumference - this.progress / 100 * circumference;
  }
}
