import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';

interface IFieldResponse {
  label: string;
  valor: string | number | { [key: string]: IFieldResponse };
  precisao?: number;
}

@Component({
  selector: 'app-detalhe-laudo',
  templateUrl: './detalhe-laudo.component.html',
  styles: [`
      progress[value]::-webkit-progress-value {
          background-color: var(--progress-custom-background-color);
      }
      progress[value]::-moz-progress-bar {
          background-color: var(--progress-custom-background-color);
      }
  `]
})
export class DetalheLaudoComponent extends AbstractModal implements OnInit {

  offsetHeight = 10;
  offsetWidth = 10;
  file: any;
  simpleFields: IFieldResponse[] = [];
  nestedFields: { label: string; fields: IFieldResponse[] }[] = [];

  resposta: { [key: string]: IFieldResponse } = {
    nomePaciente: {
      label: "Nome do paciente:",
      valor: "Cinor Maria Neves",
      precisao: 1
    },
    exameRealizado: {
      label: "Exame realizado:",
      valor: "TOMOGRAFIA COMPUTADORIZADA DE ABDOME TOTAL",
      precisao: 1
    },
    dataExame: {
      label: "Data do exame:",
      valor: "11/11/2022",
      precisao: 1
    },
    nomeClinica: {
      label: "Nome da clinica:",
      valor: "HRANHospitalRegionaldaAsa Norte",
      precisao: 0.8
    },
    temTumor: {
      label: "Tem tumoração (maligno ou benigno)?",
      valor: "SIM",
      precisao: 0.6
    },
    primeiroMaiorTumor: {
      label: "1º maior tumor:",
      valor: {
        tamanho: {
          label: "Tamanho (mm):",
          valor: 7.6,
          precisao: 0.7
        },
        localizacao: {
          label: "Sítio:",
          valor: "VESICULA BILIAR",
          precisao: 0.7
        },
        maligno: {
          label: "Maligno:",
          valor: "NÃO",
          precisao: 0.7
        }
      }
    },
    segundoMaiorTumor: {
      label: "2º maior tumor:",
      valor: {
        tamanho: {
          label: "Tamanho (mm):",
          valor: 1.1,
          precisao: 0.6
        },
        localizacao: {
          label: "Sítio:",
          valor: "RIM ESQUERDO",
          precisao: 0.6
        },
        maligno: {
          label: "Maligno:",
          valor: "NÃO",
          precisao: 0.6
        }
      }
    },
    terceiroMaiorTumor: {
      label: "3º maior tumor:",
      valor: {
        tamanho: {
          label: "Tamanho (mm):",
          valor: null,
          precisao: null
        },
        localizacao: {
          label: "Sítio:",
          valor: null,
          precisao: null
        },
        maligno: {
          label: "Maligno:",
          valor: null,
          precisao: null
        }
      }
    }
  };

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.loadPdfFile();
    this.buildFields();
  }

  async loadPdfFile() {
    const response = await fetch('https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf');
    const fileBlob = await response.blob();
    this.file = URL.createObjectURL(fileBlob);
  }

  buildFields() {
    for (let field of Object.values(this.resposta)) {
      if (typeof field.valor === 'object') {
        this.nestedFields.push({
          label: field.label,
          fields: Object.values(field.valor)
        });
      } else {
        this.simpleFields.push(field);
      }
    }
  }

  getProgressBarColor(precisao: number) {
    if (precisao >= 0 && precisao <= 60) return '#DB0000';
    else if (precisao >= 70 && precisao <= 80) return '#F2994A';
    else if (precisao >= 90 && precisao <= 100) return '#219653';
  }
}
