import { Component, OnChanges, OnInit } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';

@Component({
  selector: 'app-checkbox-group-input',
  templateUrl: './checkbox-group-input.component.html',
  styleUrls: ['./checkbox-group-input.component.scss'],
})
export class CheckboxGroupInputComponent extends BaseCustomInputComponent implements OnInit {

  _selected = {};

  constructor() {
    super();
  }

  ngOnInit() {
    const setSelectedValues = () => {
      this.fields.forEach((field) => {
        this._selected[field] = this.control?.value.some(selectedField => selectedField === field);
      });
    };
    if (this.control?.value && this.fields && Array.isArray(this.control?.value)) {
      setSelectedValues();
      this.control.valueChanges.subscribe((changes) => {
        setSelectedValues();
      });
    }
  }

  handleCheckBoxClick(field, event) {
    if (this.control) this.control.pristine = false;
    if (!event.target.checked) {
      const selectedFields = Object.assign([], (this.control?.value || []));
      const fieldIndex = selectedFields.findIndex(selectedField => selectedField === field);
      selectedFields.splice(fieldIndex, 1);
      this.control?.setValue(selectedFields);
    } else {
      const selectedFields = Object.assign([], (this.control?.value || []));
      selectedFields.push(field);
      this.control?.setValue(selectedFields);
    }
  }
}
