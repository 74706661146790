import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../components/_base/abstract-modal';
import { Clinica } from '../../../models/clinica.model';
import { AdminService } from '../../../services/admin.service';
import { toast } from '../../../util/toast';
import { loading } from '../../../util/loading';

@Component({
  selector: 'app-confirmar-mesclagem-clinicas',
  templateUrl: './confirmar-mesclagem-clinicas.component.html',
})
export class ConfirmarMesclagemClinicasComponent extends AbstractModal implements OnInit {

  clinicaId: string;
  clinicaDominante: Clinica;
  clinicaDominada: Clinica;
  fixedWidth = '490px';

  constructor(public readonly injector: Injector,
              private adminService: AdminService) {
    super(injector);
  }

  ngOnInit() {
    this.clinicaDominante = this.adminService.getClinicaMesclagem();
    this.loadClinicaDominada();
  }

  loadClinicaDominada() {
    loading(this.adminService.getInstituicaoById(this.clinicaId).subscribe((clinica) => {
      this.clinicaDominada = clinica;
    }));
  }

  async handleCancelarMesclagemClick() {
    this.adminService.clearClinicaMesclagem();
    await toast('Mesclagem cancelada, a clínica selecionada para mesclagem foi desconsiderada');
    await this.handleCLickClose();
  }

  async handleConfirmarMesclagemClick() {
    await loading(this.adminService.mesclarClinicas(this.clinicaDominada._id, this.clinicaDominante._id)
      .subscribe(async () => {
        this.adminService.clearClinicaMesclagem();
        await toast('Mesclagem de clínicas feita com sucesso', 'success');
        await this.handleCLickClose(true);
    }));
  }
}
