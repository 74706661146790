import { Component, OnInit } from '@angular/core';
import { Tab } from '../tabs-component/tabs-component.component';

@Component({
  selector: 'app-tabs-ficha-paciente',
  templateUrl: './tabs-ficha-paciente.component.html',
})
export class TabsFichaPacienteComponent implements OnInit {

  currentTab: Tab;
  tabsConfig: Tab[] = [
    {
      id: 'resumo-caso',
      name: 'RESUMO DO CASO',
      active: true
    },
    {
      id: 'anamnese',
      name: 'ANAMNESE'
    },
    {
      id: 'imagem-radiologica',
      name: 'IMAGEM RADIOLÓGICA',
    },
    {
      id: 'anatomo-patologia',
      name: 'ANÁTOMO-PATOLOGIA'
    },
    {
      id: 'imagem-direta',
      name: 'IMAGEM DIRETA'
    },
    {
      id: 'patologia-clinica',
      name: 'PATOLOGIA CLÍNICA'
    },
    {
      id: 'oncogenetica',
      name: 'ONCOGENÉTICA'
    },
    {
      id: 'condutas',
      name: 'CONDUTAS'
    },
  ];

  constructor() { }

  ngOnInit() {
    this.setCurrentTab();
  }

  handleTabClick(tab: Tab) {
    this.currentTab = tab;
  }

  setCurrentTab() {
    this.currentTab = this.tabsConfig.find(tab => tab.active);
  }
}
