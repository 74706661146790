export const CONFIGURACOES_BASE = 'configuracoes';
export const REDEFINIR_SENHA = 'redefinir-senha';
export const EDITAR_PERFIL = 'editar-perfil';
export const FALE_CONOSCO = 'fale-conosco';
export const POLITICA_PRIVACIDADE = 'politica-privacidade';
export const TERMOS_CONDICOES = 'termos-condicoes';

export interface IModuleRoute {
  LISTA_PACIENTES: string;
  CADASTRAR_PACIENTE: string;
  RESUMO_CASO_PACIENTE: string;
  COMPARTILHAR_FICHA_PREVIEW: string;
  FICHA_PACIENTE: string;
}

export const ROUTE = {
  ONBOARDING: 'onboarding',
  LOGIN: 'login',
  CADASTRO_USUARIO: 'cadastro-usuario',
  REDEFINIR_SENHA: REDEFINIR_SENHA,
  MODULOS_CANCER: 'modulos-cancer',
  PAGINA_EM_CONSTRUCAO: 'pagina-em-construcao',
  DISCUSSAO_CASO: 'discussao-caso',
  PATROCINADORES: 'patrocinadores',
  CONTA_INSTITUICIONAL: 'conta-institucional',
  CONFIGURACOES: CONFIGURACOES_BASE,
  CONFIGURACOES_EDITAR_PERFIL: `${CONFIGURACOES_BASE}/${EDITAR_PERFIL}`,
  CONFIGURACOES_REDEFINIR_SENHA: `${CONFIGURACOES_BASE}/${REDEFINIR_SENHA}`,
  CONFIGURACOES_FALE_CONOSCO: `${CONFIGURACOES_BASE}/${FALE_CONOSCO}`,
  CONFIGURACOES_POLITICA_PRIVACIDADE: `${CONFIGURACOES_BASE}/${POLITICA_PRIVACIDADE}`,
  CONFIGURACOES_TERMOS_CONDICOES: `${CONFIGURACOES_BASE}/${TERMOS_CONDICOES}`,
  DASHBOARD: 'dashboard',
  CAMPANHA_QUIZ: 'campanha-quiz',
  CONFIRMACAO_CADASTRO: 'confirmacao-cadastro/:id',
  TERMO_CONSENTIMENTO: 'termo-consentimento/:id',
  CONVITE: 'convite/:id',
  POLITICA_PRIVACIDADE: POLITICA_PRIVACIDADE,
  PESQUISAR_PACIENTES: 'pesquisar-pacientes',
  ADMIN: 'admin',
}

export const MODULO_ROUTE: IModuleRoute = {
  LISTA_PACIENTES: 'lista-pacientes',
  CADASTRAR_PACIENTE: 'cadastrar-paciente',
  FICHA_PACIENTE: 'ficha-paciente',
  RESUMO_CASO_PACIENTE: 'resumo-caso-paciente',
  COMPARTILHAR_FICHA_PREVIEW: 'compartilhar-ficha-preview',
}

export const SUB_ROUTE_NAME_MAPPING = {
  [MODULO_ROUTE.FICHA_PACIENTE]: 'Ficha Paciente',
  [MODULO_ROUTE.COMPARTILHAR_FICHA_PREVIEW]: 'Ficha paciente',
  [ROUTE.CAMPANHA_QUIZ]: 'Quiz',
};

export const getNavigateRoutePath = (moduleName): IModuleRoute => {
  return {
    CADASTRAR_PACIENTE: `${ROUTE.MODULOS_CANCER}/${moduleName}/${MODULO_ROUTE.CADASTRAR_PACIENTE}`,
    LISTA_PACIENTES: `${ROUTE.MODULOS_CANCER}/${moduleName}/${MODULO_ROUTE.LISTA_PACIENTES}`,
    RESUMO_CASO_PACIENTE: `${ROUTE.MODULOS_CANCER}/${moduleName}/${MODULO_ROUTE.RESUMO_CASO_PACIENTE}`,
    FICHA_PACIENTE: `${ROUTE.MODULOS_CANCER}/${moduleName}/${MODULO_ROUTE.FICHA_PACIENTE}`,
    COMPARTILHAR_FICHA_PREVIEW: `${ROUTE.MODULOS_CANCER}/${moduleName}/${MODULO_ROUTE.COMPARTILHAR_FICHA_PREVIEW}`,
  }
}

export const hasTabOnRoute = (moduleName, activatedRoute) => {
  const navigationPath = getNavigateRoutePath(moduleName);
  return [
    navigationPath.LISTA_PACIENTES,
    ROUTE.MODULOS_CANCER,
    ROUTE.CONFIGURACOES,
    ROUTE.PESQUISAR_PACIENTES,
    ROUTE.DASHBOARD,
    ROUTE.PAGINA_EM_CONSTRUCAO
  ]
    .includes(activatedRoute.replace('/', ''));
};
