import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { graficoBarraColors } from '../../constants/colors';
import { intervalToDurationString } from '../../util/utils';
import { Slp } from '../../models/conduta.model';

@Component({
  selector: 'app-grafico-casuistica',
  templateUrl: './grafico-casuistica.component.html',
})
export class GraficoCasuisticaComponent implements OnChanges {

  @Input() slps: Slp[] = [];
  @ViewChild('containerRef') containerRef;
  intervaloSobrevidaGlobal: string;

  containerHeight;

  constructor() {
  }

  ngOnChanges() {
    this.containerHeight = document.getElementById('grafico-casuistica').clientHeight;
    this.buildChartDataSource();
    this.buildSobrevidaGeral();
  }
  
  buildChartDataSource() {
    let lastColorIndex = 0;
    const totalPeriodos = this.slps.reduce((accumulated, slp) => accumulated + slp.medianPeriodo, 0);
    this.slps = this.slps.map((slp) => {
      const newObj: Slp = Object.assign({}, slp, {
        drogasResolved: slp.drogas.slice(0, 4).map(droga => `${droga.nome}: ${droga.countPaciente}`).join(`, `),
        colorResolved: graficoBarraColors[lastColorIndex],
        periodoResolved: intervalToDurationString(slp.medianPeriodo),
        alturaResolved: `${(this.containerHeight - 80) * (slp.medianPeriodo / totalPeriodos)}px`
      });
      lastColorIndex += 1;
      if (lastColorIndex === (graficoBarraColors.length)) lastColorIndex = 0;
      return newObj;
    });
  }

  buildSobrevidaGeral() {
    const totalPeriodos = this.slps.reduce((accumulated, slp) => accumulated + slp.medianPeriodo, 0);
    this.intervaloSobrevidaGlobal = intervalToDurationString(totalPeriodos);
  }
}
