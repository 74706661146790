import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConviteService extends AbstractService<any> {

  constructor(public http: HttpClient) {
    super('convite', http);
  }

  verificar(id) {
    return super.get(`verificar/${id}`);
  }

  aceitar(id) {
    return super.post({ idConvite: id },'aceitar');
  }
}

