import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface Tip {
  name: string;
  value: string;
}

@Component({
  selector: 'app-button-tip-selector',
  templateUrl: './button-tip-selector.component.html',
})
export class ButtonTipSelectorComponent {

  @Input()
  tips: Tip[];

  @Input()
  value: string;

  @Input()
  disabled: boolean = false;

  @Output()
  clicked: EventEmitter<Tip> = new EventEmitter<Tip>();

  constructor() { }

  handleTipCLick(tip: Tip) {
    if (this.disabled) { return; }
    this.value = tip.value;
    this.clicked.emit(tip);
  }
}
