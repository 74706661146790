import { Component, ComponentRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractButton, ButtonComponent } from '../_base/abstract-button';
import { DynamicComponentDirective } from '../../directives/dynamic-component.directive';
import { PrimaryButtonComponent } from './primary/primary.component';
import { DangerButtonComponent } from './danger/danger.component';
import { DefaultButtonComponent } from './default/default.component';
import { GhostButtonComponent } from './ghost/ghost.component';
import { OutlinedBrandButtonComponent } from './outlined-brand/outlined-brand.component';
import { BrandButtonComponent } from './brand/brand-button.component';
import { NeutralButtonComponent } from './neutral/neutral.component';
import { DangerLightButtonComponent } from './danger-light/danger-light.component';
import { OutlinedPrimaryButtonComponent } from './outlined-primary/outlined-primary.component';

export type ButtonType = 'default' | 'primary' | 'danger' | 'ghost' | 'outlined-brand' | 'brand' | 'neutral' | 'danger-light' | 'outlined-primary';

const buttonTypesMapping = {
  default: DefaultButtonComponent,
  primary: PrimaryButtonComponent,
  ['outlined-primary']: OutlinedPrimaryButtonComponent,
  danger: DangerButtonComponent,
  ghost: GhostButtonComponent,
  brand: BrandButtonComponent,
  neutral: NeutralButtonComponent,
  ['outlined-brand']: OutlinedBrandButtonComponent,
  ['danger-light']: DangerLightButtonComponent,
};

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html'
})
export class CustomButtonComponent extends AbstractButton implements OnInit, OnChanges {

  @Input()
  type: ButtonType;

  @ViewChild(DynamicComponentDirective, { static: true }) componentHost!: DynamicComponentDirective;

  componentRef: ComponentRef<ButtonComponent>;

  ngOnInit() {
    this.injectCurrentButton();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (!this.componentRef) {return;}
    Object.keys(changes).forEach(key => this.componentRef.instance[key] = changes[key].currentValue);
  }

  injectCurrentButton() {
    const component = buttonTypesMapping[this.type];
    const { viewContainerRef } = this.componentHost;

    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent<ButtonComponent>(component);
    this.componentRef.instance.id = this.id;
    this.componentRef.instance.text = this.text;
    this.componentRef.instance.iconStart = this.iconStart;
    this.componentRef.instance.iconEnd = this.iconEnd;
    this.componentRef.instance.rounded = this.rounded;
    this.componentRef.instance.minHeight = this.minHeight;
    this.componentRef.instance.customClasses = this.customClasses;
    this.componentRef.instance.iconPosition = this.iconPosition;
    this.componentRef.instance.clicked = this.clicked;
    this.componentRef.instance.border = this.border;
    this.componentRef.instance.disabled = this.disabled;
    this.componentRef.instance.outlined = this.outlined;
    this.componentRef.instance.customTextClasses = this.customTextClasses;
    this.componentRef.instance.customStyle = this.customStyle;
    this.componentRef.instance.badge = this.badge;
  }
}
