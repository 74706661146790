import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { getAppUrl } from '../../util/utils';

@Component({
  selector: 'app-file-previewer',
  templateUrl: './file-previewer.component.html',
})
export class FilePreviewerComponent implements OnChanges, OnInit {
  @Input() pdfSrc: string | ArrayBuffer;

  ngOnChanges(): void {
    // Método vazio, implementado para satisfação da interface OnChanges
  }

  ngOnInit(): void {
    this.loadPDF();
  }

  isPdf(): boolean {
    return this.pdfSrc?.toString().startsWith('blob:');
  }

  isImage(): boolean {
    return false;
  }

  async loadPDF() {
    // const response = await fetch('https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf');
    const response = await fetch(`${window.location.origin}/assets/TC TORAX 24.03.23.pdf`);
    const fileBlob = await response.blob();
    this.pdfSrc = URL.createObjectURL(fileBlob);
  }
}
