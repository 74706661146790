import { Component } from '@angular/core';

@Component({
  selector: 'app-politica-privacidade-component',
  templateUrl: './politica-privacidade.component.html',
})
export class PoliticaPrivacidadeComponent {

  constructor() { }
}
