import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTE, SUB_ROUTE_NAME_MAPPING } from '../../constants/route';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {

  @Input() root: string;
  @Output() onSubRouteClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() subRouteTitle: string;
  @Input() customSubRouteTitle: string;
  modulo: IMenuModuloConfig;

  constructor(private moduloService: ModuloService,
              private router: Router) {
    moduloService.$moduloAtual.subscribe(this.handeModuloChange.bind(this));
    router.events.subscribe(this.handleRouteChange.bind(this));
  }

  handleRouteChange(val) {
    if (val instanceof NavigationEnd) {
      this.subRouteTitle = '';
      const subRouteName = (new RegExp(`\\/${ROUTE.MODULOS_CANCER}\\/.+\\/(.+)\\?`).exec(val.urlAfterRedirects) || [])[1];
      if (subRouteName && !this.subRouteTitle) {
        this.subRouteTitle = SUB_ROUTE_NAME_MAPPING[subRouteName];
      } else {
        this.subRouteTitle = SUB_ROUTE_NAME_MAPPING[val.urlAfterRedirects?.replace('/', '')]
      }
    }
  }

  handeModuloChange(modulo: IMenuModuloConfig) {
    this.modulo = modulo;
  }

  async handleRootClick() {
    this.moduloService.clearModuloAtual();
    await this.router.navigate([ROUTE.MODULOS_CANCER], { replaceUrl: true });
  }

  async handleNomeModuloClick() {
    await this.router.navigate([ROUTE.MODULOS_CANCER], { replaceUrl : true })
  }

  handleSubRouteClick() {
    this.onSubRouteClick.emit();
  }
}
