import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';

interface CreateModal {
  component: any;
  componentProps?: {[key: string]: any};
  handler?: ({data}) => void;
  customClasses?: Array<string>;
  backdropDismiss?: boolean;
  keyboardClose?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export default class CustomModalHelper {

  constructor(private modalController: ModalController) { }

  async create({component, componentProps, handler, backdropDismiss = false, keyboardClose = false}: CreateModal) {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component,
      componentProps,
      backdropDismiss,
      keyboardClose,
      cssClass: 'app-oncomax-custom-modal',
      animated: true
    });
    modal.onWillDismiss().then(handler);
    return modal;
  }
}
