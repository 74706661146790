import { AdicionarCondutaComponent } from './adicionar-conduta/adicionar-conduta.component';
import { DataImunoHistoquimicaComponent } from './data-imuno-histoquimica/data-imuno-histoquimica.component';
import { GRAU_CHAVE, GRAU_OPTIONS, InputGrauComponent } from './input-grau/input-grau.component';
import { HER2_CHAVE, HER2_OPTIONS, InputHerComponent } from './input-her/input-her.component';
import { InputHistopatologicoComponent, HISTOPATOLOGICO_OPTIONS, HISTOPATOLOGICO_CHAVE } from './input-histopatologico/input-histopatologico.component';
import { InputKiComponent, KI67_CHAVE } from './input-ki/input-ki.component';
import { InputMComponent } from './input-m/input-m.component';
import { InputNComponent } from './input-n/input-n.component';
import { InputReRpComponent, RE_CHAVE, RP_CHAVE } from './input-re-rp/input-re-rp.component';
import { InputSitioImunoComponent, SITIO_CHAVE, SITIO_OPTIONS } from './input-sitio-imuno/input-sitio-imuno.component';
import { InputTComponent } from './input-t/input-t.component';
import { OutrosTestesComponent } from './outros-testes/outros-testes.component';
import { TestesPreditivosComponent } from './testes-preditivos/testes-preditivos.component';
import { FilterConfig, FilterAvancado } from '../../../interfaces/filter-config';
import { optionsResolver as tOptionsResolver } from './input-t/util';
import { optionsResolver as nOptionsResolver } from './input-n/util';
import { optionsResolver as mOptionsResolver } from './input-m/util';
import { rangeConfigToSelectionOption } from '../../../util/utils';
import { SelectionOption } from '../../../components/_base/abstract-input';
import { FiltrosTestesPreditivosComponent } from '../filtros/testes-preditivos/testes-preditivos.component';
import { FiltrosOutrosTestesComponent } from '../filtros/outros-testes/outros-testes.component';

export const MODALS = [
  AdicionarCondutaComponent,
  DataImunoHistoquimicaComponent,
  InputGrauComponent,
  InputHerComponent,
  InputHistopatologicoComponent,
  InputKiComponent,
  InputMComponent,
  InputNComponent,
  InputReRpComponent,
  InputSitioImunoComponent,
  InputTComponent,
  OutrosTestesComponent,
  TestesPreditivosComponent,
  FiltrosTestesPreditivosComponent,
  FiltrosOutrosTestesComponent
];

const RE_RP_OPTIONS: SelectionOption[] = [
  {
    nome: '0%',
    valor: {
      min: 0,
      max: 0
    }
  },
  {
    nome: '1% a 10%',
    valor: {
      min: 1,
      max: 10
    }
  },
  {
    nome: '11% a 90%',
    valor: {
      min: 11,
      max: 90
    }
  },
  {
    nome: '91% a 100%',
    valor: {
      min: 91,
      max: 100
    }
  },
  {
    nome: 'Desconhecido',
    valor: null
  }
];

const KI_67_OPTIONS: SelectionOption[] = [
  {
    nome: '≤10',
    valor: {
      min: 0,
      max: 10
    }
  },
  {
    nome: '>10',
    valor: {
      min: 11,
      max: 100
    }
  },
  {
    nome: 'Desconhecido',
    valor: null
  }
];

const comporOptionsDefault = (valores, chave, options): FilterAvancado => {
  const valoresMapping = {};
  const filtros = [];
  options.forEach(option => valoresMapping[option.nome] = option.valor);
  valores.forEach(valor => filtros.push(valoresMapping[valor]));
  return {
    tipo: 'item',
    detalhe: {
      chave,
      valores: filtros
    }
  }
};

export const FILTERS_CONFIG: FilterConfig[] = [
  {
    categoria: 'Dados de exames',
    titulo: 'Histopatológico',
    nome: HISTOPATOLOGICO_CHAVE,
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: HISTOPATOLOGICO_OPTIONS
  },
  {
    categoria: 'Dados de exames',
    titulo: 'Grau',
    nome: GRAU_CHAVE,
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(GRAU_OPTIONS)
  },
  {
    categoria: 'Dados de exames',
    titulo: 'T',
    alturaDinamica: false,
    nome: 't',
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: [].concat(
      rangeConfigToSelectionOption(tOptionsResolver('c')),
      rangeConfigToSelectionOption(tOptionsResolver('p'))
    )
  },
  {
    categoria: 'Dados de exames',
    titulo: 'N',
    alturaDinamica: false,
    nome: 'n',
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: [].concat(
      rangeConfigToSelectionOption(nOptionsResolver('clinico')),
      rangeConfigToSelectionOption(nOptionsResolver('patologico')),
      rangeConfigToSelectionOption(nOptionsResolver('patologico', 'y'))
    )
  },
  {
    categoria: 'Dados de exames',
    titulo: 'M',
    nome: 'm',
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(mOptionsResolver())
  },
  {
    categoria: 'Imuno-histoquímica',
    titulo: 'RE',
    nome: RE_CHAVE,
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: RE_RP_OPTIONS,
    compor: (valores) => comporOptionsDefault(valores, RE_CHAVE, RE_RP_OPTIONS),
  },
  {
    categoria: 'Imuno-histoquímica',
    titulo: 'RP',
    nome: RP_CHAVE,
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: RE_RP_OPTIONS,
    compor: (valores) => comporOptionsDefault(valores, RP_CHAVE, RE_RP_OPTIONS),
  },
  {
    categoria: 'Imuno-histoquímica',
    titulo: 'HER-2',
    nome: HER2_CHAVE,
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(HER2_OPTIONS, false),
  },
  {
    categoria: 'Imuno-histoquímica',
    titulo: 'Ki-67',
    nome: KI67_CHAVE,
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: KI_67_OPTIONS,
    compor: (valores) => comporOptionsDefault(valores, KI67_CHAVE, KI_67_OPTIONS),
  },
  {
    categoria: 'Imuno-histoquímica',
    titulo: 'Sítio',
    alturaDinamica: false,
    nome: SITIO_CHAVE,
    largura: '2',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: SITIO_OPTIONS
  },
  // {
  //   categoria: 'Testes',
  //   titulo: 'Testes Preditivos',
  //   nome: 'testes-preditivos',
  //   largura: '4',
  //   tipoFiltro: 'item',
  //   tipo: 'modal',
  // },
  // {
  //   categoria: 'Testes',
  //   titulo: 'Outros testes',
  //   nome: 'outros-testes',
  //   largura: '4',
  //   tipoFiltro: 'item',
  //   tipo: 'modal',
  // }
];
