import { Component } from '@angular/core';
import MenuService from '../../../../shared/services/menu.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DominioService } from '../../../../shared/services/dominio.service';
import { ValidatorsApp } from '../../../../shared/util/validators';
import { Usuario } from '../../../../shared/models/usuario.model';
import { loading } from '../../../../shared/util/loading';
import { ROUTE } from '../../../../shared/constants/route';
import UsuarioService from '../../../../shared/services/usuario.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConviteService } from '../../../../shared/services/convite.service';
import { ModuloService } from '../../../../shared/services/modulo.service';

@Component({
  selector: 'app-convite',
  templateUrl: './convite.page.html',
})
export class ConvitePage {

  conviteId: string;
  ufsOptions: any[];
  cadastrar: boolean = true;
  usuarioLogado: boolean;
  crmForm: FormGroup = new FormGroup({
    numero: new FormControl('', [Validators.required]),
    uf: new FormControl('', [Validators.required]),
  });
  formCadastro: FormGroup = new FormGroup({
    nome: new FormControl(''),
    email: new FormControl('', [Validators.required]),
    senha: new FormControl('', [Validators.required]),
  });
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('',  [Validators.required]),
    senha: new FormControl('',  [Validators.required]),
  });

  constructor(private menuService: MenuService,
              private dominioService: DominioService,
              private usuarioService: UsuarioService,
              private authService: AuthService,
              private router: Router,
              private conviteService: ConviteService,
              private route: ActivatedRoute,
              private moduloService: ModuloService) { }

  async ionViewWillEnter() {
    this.moduloService.clearModuloAtual();
    await this.menuService.disableMenu();
    this.dominioService.getUfs().subscribe((ufs) => {
      this.ufsOptions = ufs.sort((a, b) => a.nome.localeCompare(b.nome));
    });
    // this.formCadastro.get('nome').disable();
    this.verifyIfIsLogged();
    this.registryFormListeners();
  }

  async ionViewWillLeave() {
    await this.menuService.enableMenu();
  }

  async verifyIfIsLogged() {
    const token = this.authService.getToken();
    if (token) {
      this.usuarioLogado = true;
    }

    this.route.params.subscribe((params) => {
      this.conviteId = params.id;
      loading(this.conviteService.verificar(this.conviteId).subscribe((response: any) => {
        this.cadastrar = !response.temUsuario;
      }));
    });
  }

  registryFormListeners() {
    this.crmForm.valueChanges.subscribe(values => {
      const { numero, uf } = values;
      if (numero && uf) {
        // loading(this.usuarioService.consultarCRM(numero, uf).subscribe((response) => {
        //   console.log('response', response);
        // }, (response) => {
        //   toast(response.error.message);
        // }));
      }
    });
  }

  async handleAceitarConviteUsuarioLogado() {
    loading(this.conviteService.aceitar(this.conviteId).subscribe(async () => {
      this.usuarioService.executarRefreshToken();
      await this.router.navigate([ROUTE.MODULOS_CANCER], { replaceUrl: true });
    }));
  }

  async handleAceitarConviteUsuarioCadastradoClick() {
    if (ValidatorsApp.formularioValido(this.loginForm)) {
      const { email, senha } = this.loginForm.getRawValue();
      await loading(this.usuarioService.autenticar(email, senha).subscribe(async() => {
        this.conviteService.aceitar(this.conviteId).subscribe(async () => {
          this.usuarioService.executarRefreshToken();
          await this.router.navigate([ROUTE.MODULOS_CANCER], { replaceUrl: true });
        });
      }));
    }
  }

  async handleAceitarConviteSemUsuarioClick() {
    if (ValidatorsApp.formularioValido(this.formCadastro)) {
      const usuario: Usuario = {
        ...this.formCadastro.getRawValue(),
        crmList : [this.crmForm.getRawValue()],
        clinicaIdList: [],
        perfil: 'residente',
        conviteId: this.conviteId
      };
      usuario.nome = String(usuario.nome).toUpperCase();
      await loading(this.usuarioService.cadastrar(usuario).subscribe(async (usuarioCadastrado) => {
        this.usuarioService.confirmarCadastro(usuarioCadastrado._id).subscribe(() => {
          this.usuarioService.autenticar(usuario.email, usuario.senha).subscribe(() => {
            this.conviteService.aceitar(this.conviteId).subscribe(async () => {
              this.usuarioService.executarRefreshToken();
              await this.router.navigate([ROUTE.MODULOS_CANCER], { replaceUrl: true });
            });
          });
        });
      }));
    }
  }
}
