import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { Usuario } from '../../models/usuario.model';
import { AuthService } from '../../services/auth.service';
import { SelectionOption } from '../../components/_base/abstract-input';
import { FormControl } from '@angular/forms';
import { toast } from '../../util/toast';
import { ClinicaService } from '../../services/clinica.service';
import { ROUTE } from '../../constants/route';
import UsuarioService from '../../services/usuario.service';

@Component({
  selector: 'app-adicionar-conta-institucional',
  templateUrl: './adicionar-conta-institucional.component.html',
})
export class AdicionarContaInstitucionalComponent extends AbstractModal implements OnInit {

  instituicoesOptions: SelectionOption[];
  fixedHeight = '350px';
  instituicaoControl: FormControl = new FormControl('');

  constructor(public readonly injector: Injector,
              private authService: AuthService,
              private clinicaService: ClinicaService,
              private usuarioService: UsuarioService) {
    super(injector);
  }

  ngOnInit() {
    const usuario = this.authService.getUser();
    this.instituicoesOptions = usuario.clinicaList.map(instituicao => ({ nome: instituicao.nome, valor: instituicao._id }));
  }

  async handleClickConfirm() {
    if (!this.instituicaoControl.value) {
      await toast('É necessário escolher qual instituição você deseja criar a conta institucional');
      return;
    }
    this.clinicaService.criarContaInstitucional({ idInstituicao: this.instituicaoControl.value }).subscribe(async () => {
      this.usuarioService.executarRefreshToken();
      await this.modalController.dismiss();
      await toast('Solicitação de conta institucional criada com sucesso');
    });
  }
}
