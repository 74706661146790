import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import { ResponsiveService } from '../../../../services/responsive.service';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';

export const HISTOPATOLOGICO_CHAVE = 'histopatologico.area';
export const HISTOPATOLOGICO_OPTIONS: SelectionOption[] = [
  {
    nome: 'Lobular',
    valor: 'Lobular',
  },
  {
    nome: 'Ductal',
    valor: 'Ductal',
  },
  {
    nome: 'Prognóstico Favorável',
    valor: 'Prognóstico Favorável',
  },
  {
    nome: 'Outros',
    valor: 'Outros',
  },
];

@Component({
  selector: 'app-input-histopatologico',
  templateUrl: './input-histopatologico.component.html',
})
export class InputHistopatologicoComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '295px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;

  form: FormGroup = new FormGroup({
    area: new FormControl('')
  });

  areaOptions: SelectionOption[] = HISTOPATOLOGICO_OPTIONS;

  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService) {
    super(injector);
  }
  
  ngOnInit() {
    const { area, id } = inputValueResolver('histopatologico', this.ficha?.itens);
    this.id = id;
    if (area !== undefined) this.form.patchValue({ area });
  }

  async handleEndEditClick() {
    if (this.form.get('area').pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: HISTOPATOLOGICO_CHAVE,
        valor: this.form.get('area').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
