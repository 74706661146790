import { Component, OnInit } from '@angular/core';
import { BaseCustomInputComponent, SelectionOption } from '../../_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { removerAcentos } from '../../../util/utils';

@Component({
  selector: 'app-custom-autocomplete-virtaual-scroll-input',
  templateUrl: './custom-autocomplete-virtual-scroll-input.component.html',
  styleUrls: ['./custom-autocomplete-virtual-scroll-input.component.scss']
})
export class CustomAutocompleteVirtualScrollInputComponent extends BaseCustomInputComponent implements OnInit {

  isItemsAvailable = false;
  selectedOptions: SelectionOption[] = [];
  _filteredValues: SelectionOption[] = [];
  preventValueChange = false;
  form = new FormGroup({
    search: new FormControl('')
  });

  constructor() {
    super();
  }

  ngOnInit() {
    this.loadSelectedValues();
    this.registryControlListener();
  }

  registryControlListener() {
    if (this.control) {
      this.control.valueChanges.subscribe((newValue) => {
        this.loadSelectedValues(newValue);
      });
    }
  }

  loadSelectedValues(newValue?) {
    if (this.control) {
      if (!this.setValueOnInput) {
        this.selectedOptions = newValue ? newValue : this.control.value;
      } else {
        const interval = setInterval(() => {
          if (!this.loading) {
            clearInterval(interval);
            const option = this.selectOptions?.find(option => {
              return String(option.valor) === String(newValue ? newValue : this.control.value);
            });
            if (option) {
              this.preventValueChange = true;
              this.form.get('search').setValue(option.nome);
            } else if (this.control.value === '') {
              this.preventValueChange = true;
              this.form.get('search').setValue('');
            }
            return;
          }
          return;
        }, 1000);
      }
      this.control.disabled ? this.form.get('search').disable() : this.form.get('search').enable()
    }
  }

  handleInputFocus() {
    this.preventValueChange = true;
    const value = this.form.get('search').value;
    this.handleChangeSearch({
      detail: { value }
    });
    this._filteredValues = this.filterValuesByValue(value);
    if (!this._filteredValues) { return; }
    this.isItemsAvailable = true;
  }

  handleInputBlur() {
    // console.log('aqui')
    // setTimeout(() => {
    //   this.isItemsAvailable = false;
    // }, 100);
  }

  handleChangeSearch({detail}) {
    if (detail.value.length > 0) {
      this.handleSearchChanged.emit(detail.value);
    }
    if (this.preventValueChange) {
      this.preventValueChange = false;
      return;
    }
    const {value} = detail;
    let filteredValues;
    if (value) {
      filteredValues = this.filterValuesByValue(value);
    } else {
      filteredValues = this.selectOptions;
    }
    if (filteredValues?.length > 0) {
      this.isItemsAvailable = true;
      this._filteredValues = filteredValues;
    } else {
      this._filteredValues = [];
    }
  }

  filterValuesByValue(value) {
    if (!this.selectOptions) { return; }
    return this.selectOptions
      .filter(o => o.notFilterable || removerAcentos(o.nome).toLowerCase()
        .includes(removerAcentos(value).toLowerCase()) || o.valor.toString().toLowerCase()
        .includes(value.toLowerCase()));
  }

  selectCurrentOption() {
    if (this.firstSelected) {
      this.handleClickOption(this._filteredValues[0]);
    }
  }

  getFilteredValues() {
    return this._filteredValues;
  }

  handleClickOption(option: SelectionOption) {
    if (this.setValueOnInput) {
      this.preventValueChange = true;
      this.form.get('search').setValue(option.nome);
      this.isItemsAvailable = false;
      setTimeout(() => {
        this.handleChange(option.valor);
        this.updateFormControlValue(option.valor);
      }, 0);
      return;
    }
    if (!this.optionIsSelected(option)) {
      this.selectedOptions.push(option);
    } else {
      const index = this.selectedOptions.findIndex(o => o.valor === option.valor);
      this.selectedOptions.splice(index, 1);
    }
    option.selected = !option.selected;
    this.handleChange(this.selectedOptions);
    this.updateFormControlValue();
    this.isItemsAvailable = false;
    setTimeout(() => {
      this.form.get('search').setValue('');
    }, 0);
  }

  updateFormControlValue(value?) {
    if (this.control) {
     setTimeout(() => {
       if (this.control.value === value) { return; }
       this.control.setValue(!this.setValueOnInput ? this.selectedOptions : value);
       this.control.updateValueAndValidity();
     }, 100);
    }
  }

  optionIsSelected(option) {
    return this.selectedOptions.map(a => a.valor).includes(option.valor);
  }
}
