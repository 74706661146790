import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { IPaginacao } from '../paginacao/paginacao.component';
import * as moment from 'moment';
import { IonText } from '@ionic/angular';

export interface TableStyleConfig {
  boxShadow?: boolean;
  noBorderLines?: boolean;
  noHeader?: boolean;
}

export interface IconConfig {
  colorClass?: string;
  name: string;
}

export interface ActionButtonConfig<T> {
  iconResolver: IconConfig | ((data: T) => IconConfig);
  clickHandler?: ((data: T) => void);
}

export interface ColumnConfig<T> {
  key?: string;
  label?: string;
  suffix?: string;
  type?: string;
  config?: any;
  sortable?: boolean;
  wordBreak?: boolean;
  color?: string | ((data: T) => string);
  labelResolver?: | (() => string);
  textResolver?: | ((data: T) => string);
  valueResolver?: | ((data: T) => any);
  sortResolver?: | ((a: T, b: T, order?: 'ASC' | 'DESC') => number);
  textBold?: boolean;
  hiddable?: boolean;
  maxWidth?: number;
  breakLine?: boolean;
}

export interface TableConfig<T> {
  responsive?: boolean;
  style?: TableStyleConfig;
  columns: Array<ColumnConfig<T>>;
  actionButtons?: Array<ActionButtonConfig<T>>;
  pagination?: IPaginacao;
  id?: string;
}

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent<T> implements AfterViewChecked {

  @Input() tableConfig: TableConfig<T>;
  @Input() data: Array<T>;

  @Output() onRowClick: EventEmitter<T> = new EventEmitter<T>();
  @Output() pagina = new EventEmitter();
  @Output() proxima = new EventEmitter();
  @Output() anterior = new EventEmitter();

  @ViewChild('tableContainerElementRef') tableContainerElementRef: ElementRef;

  private tableOffsetTop = 0;
  private lastMaxHeight = 0;
  orderColumn: string;
  orderType: 'ASC' | 'DESC' = 'ASC';

  tableHiddenColumnsMapping = {};

  constructor(private responsiveService: ResponsiveService) {
    responsiveService.$screenHeight.subscribe(this.setTableMaxHeight.bind(this));
  }

  ngAfterViewChecked() {
    this.setTableMaxHeight();
  }

  // trackById(index: number, item: any): number {
  //   return item._id;
  // }

  setTableMaxHeight() {
    if (this.tableConfig && !this.tableConfig.responsive) {
      return;
    }
    if (!this.tableContainerElementRef) {
      return;
    }
    const {nativeElement} = this.tableContainerElementRef;
    this.setOffsetTop(nativeElement);
    this.setMaxHeight(nativeElement);
  }

  setOffsetTop(nativeElement) {
    if (nativeElement.offsetTop === this.tableOffsetTop) {
      return;
    } else {
      this.tableOffsetTop = nativeElement.offsetTop;
    }
  }

  setMaxHeight(nativeElement) {
    const {parentElement: containerRef} = nativeElement.parentElement;
    const paddingBottom = containerRef.computedStyleMap().get('--padding-bottom');
    if (!paddingBottom) {
      return;
    }
    const [bottomOffset] = paddingBottom;
    const offsetContainer = Number(/\d+/.exec(bottomOffset)[0]);
    const newMaxHeight = !!containerRef.offsetHeight ? (containerRef.offsetHeight - this.tableOffsetTop - offsetContainer) : 0;

    if (newMaxHeight === this.lastMaxHeight) {
      return;
    } else {
      this.lastMaxHeight = newMaxHeight;
      nativeElement.style.setProperty('max-height', `${this.lastMaxHeight}px`);
    }
  }

  getFieldColorClass(field, value) {
    const columnConfig: ColumnConfig<T> = this.tableConfig.columns.find(c => c.key === field);
    const resolver: string | ((data) => string) = columnConfig.color;
    if (typeof resolver === 'function') {
      return resolver(value);
    }
    return resolver;
  }

  getRowIconConfig(index, row: T) {
    const {actionButtons} = this.tableConfig;
    let resolver;
    if (actionButtons) {
      resolver = actionButtons[index].iconResolver;
    }
    if (typeof resolver === 'function') {
      return resolver(row);
    }
    return resolver;
  }

  handleActionButtonClick(index, row: T) {
    const {actionButtons} = this.tableConfig;
    let handler;
    if (actionButtons) {
      handler = actionButtons[index].clickHandler;
    }

    if (handler && typeof handler === 'function') {
      handler(row);
    }
  }

  handleRowClick(row: T, column: ColumnConfig<T>) {
    if (column.type === 'component' && column.config?.eventPreventDefault) return;
    this.onRowClick.emit(row);
  }

  getColumnLabel(column: ColumnConfig<T>) {
    return column.labelResolver ? column.labelResolver() : column.label;
  }

  getColumnValue(row: T, column: ColumnConfig<T>) {
    if (column.valueResolver && typeof column.valueResolver === 'function') {
      return column.valueResolver(row);
    }
    return column?.key ? row[column?.key] : row;
  }

  getColumnWidth(column: ColumnConfig<T>) {
    if (this.tableHiddenColumnsMapping[column.key]) {
      return `${this.tableHiddenColumnsMapping[column.key]}px`;
    }
    return column.maxWidth ? `${column.maxWidth}px` : 'unset';
  }

  getTDText(column: ColumnConfig<T>, row: T) {
    const tdValue = row[column?.key];
    if (column.textResolver && typeof column.textResolver === 'function') {
      return column.textResolver(row);
    } else if (column.wordBreak) {
      return `${tdValue.split(' ').join('<br>')}`;
    } else {
      return tdValue;
    }
  }

  handleClickOrderByColumn(column: ColumnConfig<T>) {
    if (column.label === this.orderColumn) {
      this.orderType = this.orderType === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.orderType = 'ASC';
    }
    this.orderColumn = column.label;
    if (column.sortResolver) {
      this.data = this.data.sort((tupleA, tupleB) => {
        return column.sortResolver(tupleA, tupleB, this.orderType);
      });
    } else {
      switch (column.type) {
        case 'date':
          this.orderByDate(column);
          break;
        case 'number':
          this.orderByNumber(column);
          break;
        case 'component':
          this.orderByComponent(column);
          break;
        default:
          this.orderByString(column);
      }
    }
  }

  orderByDate(column: ColumnConfig<T>) {
    this.data = this.data.sort((tupleA, tupleB) => {
      if (moment(tupleA[column.key]).isBefore(tupleB[column.key])) {
        return this.orderType === 'ASC' ? -1 : 1;
      } else if (moment(tupleA[column.key]).isAfter(tupleB[column.key])) {
        return this.orderType === 'ASC' ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  orderByString(column: ColumnConfig<T>) {
    this.data = this.data.sort((tupleA, tupleB) => {
      const textA = column.textResolver ? column.textResolver(tupleA) : tupleA[column.key];
      const textB = column.textResolver ? column.textResolver(tupleB) : tupleB[column.key];
      if (this.orderType === 'ASC') {
        return String(textA).localeCompare(String(textB));
      } else {
        return String(textB).localeCompare(String(textA));
      }
    });
  }

  orderByNumber(column: ColumnConfig<T>) {
    this.data = this.data.sort((tupleA, tupleB) => {
      if (this.orderType === 'ASC') {
        return Number(tupleA[column.key]) - Number(tupleB[column.key]);
      } else {
        return Number(tupleB[column.key]) - Number(tupleA[column.key]);
      }
    });
  }

  orderByComponent(column: ColumnConfig<T>) {
    this.data = this.data.sort((tupleA, tupleB) => {
      return column.sortResolver(tupleA, tupleB, this.orderType);
    });
  }

  handleHiddenColumnClick(textHeaderElement: any, columnKey: string) {
    if (!this.tableHiddenColumnsMapping[columnKey]) {
      this.tableHiddenColumnsMapping[columnKey] = (textHeaderElement.el.clientWidth + 38);
    } else {
      delete this.tableHiddenColumnsMapping[columnKey];
    }
  }

  irParaPagina(pagina) {
    this.pagina.emit(pagina);
  }

  voltarPagina() {
    this.anterior.emit();
  }

  proximaPagina() {
    this.proxima.emit();
  }
}
