import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../components/_base/abstract-modal';
import { PromptService } from '../../../services/prompt.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Prompt } from '../../../models/prompt.model';
import { SelectionOption } from '../../../components/_base/abstract-input';
import { Observable, Subject } from 'rxjs';
import { loading } from '../../../util/loading';
import { toast } from '../../../util/toast';
import { ValidatorsApp } from '../../../util/validators';

@Component({
  selector: 'app-criar-prompt',
  templateUrl: './criar-prompt.component.html',
})
export class CriarPromptComponent extends AbstractModal implements OnInit {

  id: string;
  offsetHeight = 20;
  fixedWidth = '800px';
  prompt: Prompt;
  draggingIndex: number;
  formCampos: FormArray;
  form: FormGroup = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    modelo: new FormControl('', [Validators.required]),
    modulo: new FormControl('', [Validators.required]),
    prompt: new FormGroup({
      inicio: new FormControl(''),
      fim: new FormControl(''),
    })
  });
  modeloOptions: SelectionOption[];
  moduloOptions: SelectionOption[];
  campos: string[];
  camposConfigs: any;

  constructor(public readonly injector: Injector,
              private promptService: PromptService) {
    super(injector);
  }

  get camposFormArray() {
    return this.formCampos as FormArray;
  }

  ngOnInit() {
    this.loadPromptsTemplate().subscribe(() => {
      if (this.prompt) {
        this.form.patchValue(this.prompt);
        this.id = this.prompt._id;
      }
    });
    this.loadFormListeners();
  }

  loadFormListeners() {
    this.form.get('modulo').valueChanges.subscribe((modulo) => this.buildCamposForm(modulo));
  }

  loadPromptsTemplate() {
    const subject: Subject<any> = new Subject<any>();
    loading(this.promptService.buscarPromptsTemplate().subscribe((template: any) => {
      this.modeloOptions = template.modelos.map(m => ({ nome: m, valor: m }));
      this.moduloOptions = template.modulos.map(m => ({ nome: m.title, valor: m.nome }));
      this.campos = template.campos;
      subject.next();
    }));
    return subject;
  }

  private _reorderItem(fromIndex: number, toIndex: number): void {
    const data: any = this.camposFormArray;
    const itemToBeReordered = data.controls.splice(fromIndex, 1)[0];
    data.controls.splice(toIndex, 0, itemToBeReordered);
    this.draggingIndex = toIndex;
    this.formCampos = data;
  }

  buildCamposForm(modulo: string) {
    const getCamposFormTemplate = () => {
      return new FormGroup({
        nome: new FormControl(''),
        alias: new FormControl(''),
        sufixo: new FormControl(''),
        habilitado: new FormControl(true),
      });
    };
    const camposTemplate = this.campos[modulo];
    let camposPromptMap = {};
    this.prompt?.campos.forEach((campo) => camposPromptMap[campo.nome] = campo);
    this.formCampos = new FormArray([]);
    camposTemplate?.forEach((campo) => {
      const camposForm: FormGroup = getCamposFormTemplate();
      const config = camposPromptMap[campo.nome] || { alias: campo.alias, nome: campo.nome, habilitado: true };
      camposForm.patchValue(config);
      this.formCampos.push(camposForm);
    });
    this.formCampos.valueChanges.subscribe((campos) => {
      this.camposConfigs = campos;
    });
  }

  onDragStart(index: number) {
    this.draggingIndex = index;
  }

  onDragEnter(index: number) {
    if (this.draggingIndex !== index) {
      this._reorderItem(this.draggingIndex, index);
    }
  }

  onDragEnd() {
    this.draggingIndex = undefined;
  }

  async handleConfirmClick() {
    if (!ValidatorsApp.formularioValido(this.form)) {
      await toast('Finalize o preenchimento da configuração do Prompt antes de salvar');
      return;
    }
    const formValues: any = this.form.getRawValue();
    formValues.config = { dadosMedico: true };
    let observable: Observable<any>;
    if (this.camposConfigs) formValues.campos = this.camposConfigs;
    else formValues.campos = this.formCampos.getRawValue();
    if (this.id) {
      formValues._id = this.id;
      observable = this.promptService.atualizarPrompt(this.id, formValues);
    } else {
      observable = this.promptService.post(formValues);
    }
    loading(observable.subscribe((prompt) => {
      toast('Configurações de prompt salvas com sucesso');
      this.handleCLickClose(true, { prompt })
    }));
  }
}
