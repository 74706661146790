import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { Ficha } from '../../models/ficha.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-anotacoes-paciente',
  templateUrl: './anotacoes-paciente.component.html',
})
export class AnotacoesPacienteComponent extends AbstractModal implements OnInit {

  ficha: Ficha;
  offsetWidth = 10;
  fixedHeight = '400px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;

  anotacoes: FormControl = new FormControl('');

  constructor(public override injector: Injector) {
    super(injector);
  }
  
  ngOnInit() {
    this.anotacoes.patchValue(this.ficha?.anotacoes);
  }
  
  handleEditEnd() {
    this.modalController.dismiss({confirm: false});
    
  }
  
  handleAdicionarAnotacaoClick() {
    this.modalController.dismiss(this.anotacoes.value);
  }
}

