import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SelectionOption } from '../components/_base/abstract-input';
import { Ficha } from '../models/ficha.model';

export type PassoAdicionarConduta =
  'selecao-tipo' |
  'subtipo-cirurgia' |
  'subtipo-radioterapia' |
  'subtipo-tratamento-sistemico' |
  'drogas' |
  'calendario' |
  'calendario-fim';

export const passoCalendario: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'calendario',
  altura: 430
};

export const passoDrogas: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'drogas',
  altura: 140
};

@Injectable({
  providedIn: 'root'
})
export class CondutaPulmaoSclcService {

  static condutasDePeriodo = [

  ];

  static tiposConduta: SelectionOption[] = [
    {
      nome: 'Cirurgia',
      valor: 'Cirurgia'
    },
    {
      nome: 'Radioterapia',
      valor: 'Radioterapia'
    },
    {
      nome: 'Tratamento sistêmico',
      valor: 'Tratamento sistêmico'
    },
    {
      nome: 'Seguimento',
      valor: 'Seguimento'
    },
  ];

  static subtiposCirurgia: SelectionOption[] = [
    {
      nome: 'Segmentectomia',
      valor: 'Segmentectomia'
    },
    {
      nome: 'Lobectomia',
      valor: 'Lobectomia'
    },
    {
      nome: 'Nodulectomia (cirurgia subótima)',
      valor: 'Nodulectomia (cirurgia subótima)'
    },
  ];

  static subtiposRadioterapia: SelectionOption[] = [
    {
      nome: 'Radioterapia isolada',
      valor: 'Radioterapia isolada'
    },
    {
      nome: 'Radioterapia concomitante a QT',
      valor: 'Radioterapia concomitante a QT'
    },
    {
      nome: 'Radioterapia para metástases',
      valor: 'Radioterapia para metástases'
    },
    {
      nome: 'Radioterapia crânio total profilática',
      valor: 'Radioterapia crânio total profilática'
    }
  ];

  static subtiposTratamentoSistemico: SelectionOption[] = [
    {
      nome: 'Paliativo',
      valor: 'Paliativo'
    },
  ];

  static drogasQuimioterapia = [
    'Cisplatina',
    'Carboplatina',
    'Etoposideo',
    'Paclitaxel',
    'Irinotecano',
    'Docetaxel',
    'Topotecano',
    'Doxorrubicina',
    'Ciclofosfamida',
    'Epirrubicina',
    'Vincristina',
    'Lurbinectedina',
    'Anrubicina',
    'Irinotecano lipossomal',
    'Temozolomida',
    'Nivolumabe',
    'Ipilimumabe',
    'Tremelimumabe',
  ];

  static drogasImunoterapia = [
    'Durvalumabe',
    'Atezolizumabe',
    'Pembrolizumabe',
  ];

  static passosCondutaMapping = {
    'Cirurgia' : {
      proximoPasso: 'subtipo-cirurgia',
      altura: 250
    },
    'Cirurgia Segmentectomia': passoCalendario,
    'Cirurgia Lobectomia': passoCalendario,
    'Cirurgia Nodulectomia (cirurgia subótima)': passoCalendario,
    'Radioterapia' : {
      proximoPasso: 'subtipo-radioterapia',
      altura: 305
    },
    'Radioterapia Radioterapia isolada': passoCalendario,
    'Radioterapia Radioterapia concomitante a QT': passoDrogas,
    'Radioterapia Radioterapia para metástases': passoCalendario,
    'Radioterapia Radioterapia crânio total profilática': passoCalendario,
    'Tratamento sistêmico': {
      proximoPasso: 'subtipo-tratamento-sistemico',
      altura: 150
    },
    'Tratamento sistêmico Paliativo': passoDrogas,
    'Seguimento': passoCalendario,
  };

  getTiposConduta(): Observable<SelectionOption[]> {
    return of(CondutaPulmaoSclcService.tiposConduta);
  }

  getSubtiposCirurgia(): Observable<SelectionOption[]> {
    return of(CondutaPulmaoSclcService.subtiposCirurgia);
  }

  getSubtiposRadioterapia(): Observable<SelectionOption[]> {
    return of(CondutaPulmaoSclcService.subtiposRadioterapia);
  }

  getSubtiposTratamentoSistemico(): Observable<SelectionOption[]> {
    return of(CondutaPulmaoSclcService.subtiposTratamentoSistemico);
  }

  getCondutasDePeriodo(): string[] {
    return CondutaPulmaoSclcService.condutasDePeriodo;
  }
}
