import { AdicionarCondutaComponent } from './adicionar-conduta/adicionar-conduta.component';
import { InputCurvaPsaComponent } from './input-curva-psa/input-curva-psa.component';
import { GLEASON_CHAVE, GLEASON_OPTIONS, InputGleasonComponent } from './input-gleason/input-gleason.component';
import { InputMComponent } from './input-m/input-m.component';
import { InputNComponent } from './input-n/input-n.component';
import { InputPsaComponent, PSA_CHAVE, PSA_OPTIONS } from './input-psa/input-psa.component';
import { InputTComponent } from './input-t/input-t.component';
import { OutrosTestesComponent } from './outros-testes/outros-testes.component';
import { FilterConfig } from '../../../interfaces/filter-config';
import {
  HISTOPATOLOGICO_CHAVE,
  HISTOPATOLOGICO_OPTIONS,
  InputHistopatologicoComponent
} from './input-histopatologico/input-histopatologico.component';
import { rangeConfigToSelectionOption } from '../../../util/utils';
import { optionsResolver as tOptionsResolver } from './input-t/util';
import { optionsResolver as nOptionsResolver } from './input-n/util';
import { optionsResolver as mOptionsResolver } from './input-m/util';

export const MODALS = [
  AdicionarCondutaComponent,
  InputCurvaPsaComponent,
  InputGleasonComponent,
  InputHistopatologicoComponent,
  InputMComponent,
  InputNComponent,
  InputPsaComponent,
  InputTComponent,
  OutrosTestesComponent
];

export const FILTERS_CONFIG: FilterConfig[] = [
  {
    categoria: 'Dados de exames',
    titulo: 'Histopatológico',
    nome: HISTOPATOLOGICO_CHAVE,
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: HISTOPATOLOGICO_OPTIONS
  },
  {
    categoria: 'Dados de exames',
    titulo: 'Gleason',
    nome: GLEASON_CHAVE,
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption({ options: GLEASON_OPTIONS }, true, true)
  },
  {
    categoria: 'Dados de exames',
    titulo: 'PSA',
    nome: PSA_CHAVE,
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(PSA_OPTIONS)
  },
  {
    categoria: 'Dados de exames',
    titulo: 'T',
    alturaDinamica: false,
    nome: 't',
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: [].concat(
      rangeConfigToSelectionOption(tOptionsResolver('clinico', 'c')),
      rangeConfigToSelectionOption(tOptionsResolver('patologico', 'p')),
    )
  },
  {
    categoria: 'Dados de exames',
    titulo: 'N',
    nome: 'n',
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(nOptionsResolver()),
  },
  {
    categoria: 'Dados de exames',
    titulo: 'M',
    nome: 'm',
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(mOptionsResolver())
  },
];

