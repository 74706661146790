import { Component, Input, OnInit } from '@angular/core';
import { FichaService } from '../../services/ficha.service';
import { Ficha } from '../../models/ficha.model';

@Component({
  selector: 'app-paciente-list-actions',
  templateUrl: './paciente-list-actions.component.html',
})
export class PacienteListActionsComponent implements OnInit {

  @Input() value: any;
  ficha: Ficha;
  marcacao: 'favorito' | 'contatoTelefonico' | 'reverCaso' | 'pendenciaConduta' | unknown = null;
  possibleValues = [
    null,
    'favorito',
    'contatoTelefonico',
    'reverCaso',
    'pendenciaConduta',
  ];

  valueChanged: any;

  constructor(private fichaService: FichaService) { }

  ngOnInit() {
    this.marcacao = !this.value.ficha.marcacao ? null : this.value.ficha.marcacao;
    this.ficha = this.value.ficha;
  }

  getIfIsActivated(property: string) {
    return this.marcacao === property;
  }

  changeValue() {
    const actualValueIndex = this.possibleValues.findIndex(value => value === this.marcacao);
    const nextIndex = actualValueIndex + 1;
    if (this.possibleValues[nextIndex] !== undefined) {
      this.marcacao = this.possibleValues[nextIndex];
    } else {
      this.marcacao = null;
    }
    this.fichaService.inserirMarcacaoFicha(this.ficha, this.marcacao).subscribe(() => {
      this.valueChanged({ id: this.ficha._id, marcacao: this.marcacao });
    });
  }
}
