import { ComponentRef, Directive, Input, OnChanges, OnInit, ViewContainerRef } from '@angular/core';
import { getAllRegisteredModules } from '../util/modules';

@Directive({
  selector: '[tohDynamicTableFieldComponentDirective]',
})
export class DynamicTableFieldComponentDirective implements OnInit, OnChanges {

  @Input() config: any;
  @Input() value: any;
  @Input() target: any;

  component: ComponentRef<any>;

  constructor(
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
    }
  }

  ngOnInit() {
    const allComponentsModules = getAllRegisteredModules();
    const { component, componentProps, componentPropsResolver } = this.config;
    const componentClass = allComponentsModules[component];
    this.component = this.viewContainerRef.createComponent(componentClass);
    Object.assign(this.component.instance, componentProps, {
      value : componentPropsResolver && typeof componentPropsResolver === 'function' ? componentPropsResolver(this.value) : this.value
    });
    this.component.instance.valueChanged = (value) => {
      if (!componentProps.valueChanged) return;
      componentProps.valueChanged({ target : this.target, value });
      if (componentProps.value) {componentProps.value = value;}
    };
  }
}
