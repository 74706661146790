import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { ClinicaService } from '../../services/clinica.service';
import { FormControl } from '@angular/forms';
import { SelectionOption } from '../../components/_base/abstract-input';
import { loading } from '../../util/loading';

@Component({
  selector: 'app-pesquisar-clinica',
  templateUrl: './pesquisar-clinica.component.html',
})
export class PesquisarClinicaComponent extends AbstractModal {

  offsetWidth = 10;
  fixedHeight = '720px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  name: FormControl = new FormControl('');
  isSolicitacaoCadastro: boolean = false;
  clinicasOptions: SelectionOption[];
  clinicaSelecionada: FormControl = new FormControl(null);

  constructor(public readonly injector: Injector,
              private clinicaService: ClinicaService) {
    super(injector);
  }

  ngOnInit() {
    this.name.valueChanges.subscribe((name) => {
     this.buscarInstituicoes(name);
    });
    this.buscarInstituicoes();
  }

  buscarInstituicoes(name = '') {
    loading(this.clinicaService.buscar(name).subscribe((clinicas) => {
      this.clinicasOptions = clinicas.map(clinica => ({ nome: clinica.nome, valor: clinica }));
    }));
  }

  get estadoVazioText() {
    return !this.isSolicitacaoCadastro ? (
      `Ops! Parece que não há nenhuma instituição com este nome. Para inserir uma nova instituição clique no botão abaixo.`
    ) : (
      `Ops! Parece que não há nenhuma instituição com este nome. Volte a tela anterior 
       e preencha todos os campos de cadastro e confirme para que o grupo empresarial e a sua instituição seja incluída 
       em nossa base de dados também.`
    );
  }

  async handleConfirmClick() {
    await this.handleCLickClose(true, { instituicao : this.clinicaSelecionada.value });
  }

  async handleSolicitarCadastroClick() {
    await this.handleCLickClose(true, { novaInstituicaoNome : this.name.value });
  }
}
