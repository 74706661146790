import { Injectable } from '@angular/core';
import { SelectionOption } from '../components/_base/abstract-input';
import { Observable, of } from 'rxjs';

export type PassoAdicionarConduta =
  'selecao-tipo' |
  'selecao-subtipo-tratamento-local' |
  'drogas-tratamento-paliativo' |
  'selecao-adt' |
  'calendario' |
  'calendario-fim';

export const passoCalendario: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'calendario',
  altura: 430
};

export const passoDrogasTratamentoPaliativo: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'drogas-tratamento-paliativo',
  altura: 620
};

@Injectable({
  providedIn: 'root'
})
export class CondutaProstataService {

  static condutasDePeriodo = [
    'Vigilância ativa',
    'Tratamento paliativo',
    'Radioterapia para metástases',
    'Seguimento',
  ];

  static tiposConduta: SelectionOption[] = [
    {
      nome: 'Vigilância ativa',
      valor: 'Vigilância ativa'
    },
    {
      nome: 'Tratamento local',
      valor: 'Tratamento local'
    },
    {
      nome: 'Radioterapia para metástases',
      valor: 'Radioterapia para metástases'
    },
    {
      nome: 'Tratamento paliativo',
      valor: 'Tratamento paliativo'
    },
    {
      nome: 'Seguimento',
      valor: 'Seguimento'
    }
  ];

  static subtiposTratamentoLocal: SelectionOption[] = [
    {
      nome: 'Prostatectomia radical',
      valor: 'Prostatectomia radical'
    },
    {
      nome: 'HIFU',
      valor: 'HIFU'
    },
    {
      nome: 'Ablação',
      valor: 'Ablação'
    },
    {
      nome: 'Quimioembolização',
      valor: 'Quimioembolização'
    },
    {
      nome: 'Radioterapia prostática sem ADT',
      valor: 'Radioterapia prostática sem ADT'
    },
    {
      nome: 'Radioterapia prostática com ADT',
      valor: 'Radioterapia prostática com ADT'
    }
  ];

  static tiposAdt: SelectionOption[] = [
    {
      nome: 'Sem ADT',
      valor: 'Sem ADT'
    },
    {
      nome: 'Com ADT por até 6 meses',
      valor: 'Com ADT por até 6 meses'
    },
    {
      nome: 'Com ADT por 18 meses',
      valor: 'Com ADT por 18 meses'
    },
    {
      nome: 'Com ADT por 24 meses',
      valor: 'Com ADT por 24 meses'
    },
    {
      nome: 'Com ADT por 36 meses',
      valor: 'Com ADT por 36 meses'
    }
  ];

  static passosCondutaMapping = {
    'Vigilância ativa' : passoCalendario,
    'Tratamento local' : {
      proximoPasso: 'selecao-subtipo-tratamento-local',
      altura: 405
    },
    'Tratamento local Prostatectomia radical' : passoCalendario,
    'Tratamento local HIFU' : passoCalendario,
    'Tratamento local Ablação' : passoCalendario,
    'Tratamento local Quimioembolização' : passoCalendario,
    'Radioterapia prostática' : {
      proximoPasso: 'selecao-adt',
      altura: 350
    },
    'Tratamento local Radioterapia prostática sem ADT': passoCalendario,
    'Tratamento local Radioterapia prostática com ADT': passoDrogasTratamentoPaliativo,
    'Radioterapia para metástases': passoCalendario,
    'Tratamento paliativo': passoDrogasTratamentoPaliativo,
    'Seguimento' : passoCalendario,
  };

  static drogasTratamentoPaliativo = [
    'Abiraterona',
    'Apalutamida',
    'Bicalutamida',
    'Cabazitaxel',
    'Carboplatina',
    'Cetoconazol',
    'Ciclofosfamida',
    'Cipionato de testosterona',
    'Cisplatina',
    'Darolutamida',
    'Degarelix',
    'Denosumabe 60',
    'Denosumabe 120',
    'Docetaxel',
    'Enzalutamida',
    'Etoposideo',
    'Goserrelina',
    'Irinotecano',
    'Leuprorrelina',
    'Lutécio-PSMA',
    'Mitoxantrona',
    'Orquiectomia',
    'Paclitaxel',
    'Prednisona',
    'Rádio-223',
    'Samário',
    'Triptorrelina',
    'Zoledronato',
    'Outros',
  ];

  getTiposConduta(): Observable<SelectionOption[]> {
    return of(CondutaProstataService.tiposConduta);
  }

  getSubtiposTratamentoLocal(): Observable<SelectionOption[]> {
    return of(CondutaProstataService.subtiposTratamentoLocal);
  }

  getDrogasTratamentoPaliativo(): Observable<string[]> {
    return of(CondutaProstataService.drogasTratamentoPaliativo);
  }

  getTiposAdt(): Observable<SelectionOption[]> {
    return of(CondutaProstataService.tiposAdt);
  }

  getCondutasDePeriodo(): string[] {
    return CondutaProstataService.condutasDePeriodo;
  }
}
