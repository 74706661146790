import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SelectionOption } from '../components/_base/abstract-input';
import { Ficha } from '../models/ficha.model';

export type PassoAdicionarConduta =
  'selecao-tipo' |
  'subtipo-cirurgia' |
  'subtipo-radioterapia' |
  'subtipo-tratamento-sistemico' |
  'drogas' |
  'calendario' |
  'calendario-fim';

export const passoCalendario: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'calendario',
  altura: 430
};

export const passoDrogas: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'drogas',
  altura: 140
};

@Injectable({
  providedIn: 'root'
})
export class CondutaPulmaoNsclcService {

  static condutasDePeriodo = [

  ];

  static tiposConduta: SelectionOption[] = [
    {
      nome: 'Cirurgia',
      valor: 'Cirurgia'
    },
    {
      nome: 'Radioterapia',
      valor: 'Radioterapia'
    },
    {
      nome: 'Tratamento sistêmico',
      valor: 'Tratamento sistêmico'
    },
    {
      nome: 'Seguimento',
      valor: 'Seguimento'
    },
  ];

  static subtiposCirurgia: SelectionOption[] = [
    {
      nome: 'Segmentectomia + dissecção linfonodal',
      valor: 'Segmentectomia + dissecção linfonodal'
    },
    {
      nome: 'Lobectomia + dissecção linfonodal',
      valor: 'Lobectomia + dissecção linfonodal'
    },
    {
      nome: 'Pneumectomia + dissecção linfonodal',
      valor: 'Pneumectomia + dissecção linfonodal'
    },
  ];

  static subtiposRadioterapia: SelectionOption[] = [
    {
      nome: 'Radiocirurgia/SBRT',
      valor: 'Radiocirurgia/SBRT'
    },
    {
      nome: 'Radioterapia isolada',
      valor: 'Radioterapia isolada'
    },
    {
      nome: 'Radioterapia concomitante a QT',
      valor: 'Radioterapia concomitante a QT'
    },
    {
      nome: 'Radioterapia para metástases',
      valor: 'Radioterapia para metástases'
    },
  ];

  static subtiposTratamentoSistemico: SelectionOption[] = [
    {
      nome: 'Neoadjuvante',
      valor: 'Neoadjuvante'
    },
    {
      nome: 'Adjuvante',
      valor: 'Adjuvante'
    },
    {
      nome: 'Paliativo',
      valor: 'Paliativo'
    },
  ];

  static drogasQuimioterapia = [
    'Cisplatina',
    'Etoposideo',
    'Carboplatina',
    'Paclitaxel',
    'Pemetrexate',
    'Vinorelbina',
    'Irinotecano',
    'Docetaxel',
    'Gencitabina',
    'Nab-paclitaxel'
  ];

  static drogasImunoterapia = [
    'Durvalumabe',
    'Atezolizumabe',
    'Pembrolizumabe',
    'Cemiplimabe',
    'Tremilimumabe',
    'Ipilimumabe',
    'Nivolumabe'
  ];

  static drogasTerapiaAlvo = [
    'Bevacizumabe',
    'Ramucirumabe',
  ];

  static passosCondutaMapping = {
    'Cirurgia' : {
      proximoPasso: 'subtipo-cirurgia',
      altura: 250
    },
    'Cirurgia Segmentectomia + dissecção linfonodal': passoCalendario,
    'Cirurgia Lobectomia + dissecção linfonodal': passoCalendario,
    'Cirurgia Pneumectomia + dissecção linfonodal': passoCalendario,
    'Radioterapia' : {
      proximoPasso: 'subtipo-radioterapia',
      altura: 305
    },
    'Radioterapia Radiocirurgia/SBRT': passoCalendario,
    'Radioterapia Radioterapia isolada': passoCalendario,
    'Radioterapia Radioterapia concomitante a QT': passoDrogas,
    'Radioterapia Radioterapia para metástases': passoCalendario,
    'Tratamento sistêmico': {
      proximoPasso: 'subtipo-tratamento-sistemico',
      altura: 250
    },
    'Tratamento sistêmico Neoadjuvante': passoDrogas,
    'Tratamento sistêmico Adjuvante': passoDrogas,
    'Tratamento sistêmico Paliativo': passoDrogas,
    'Seguimento': passoCalendario,
  };

  getTiposConduta(): Observable<SelectionOption[]> {
    return of(CondutaPulmaoNsclcService.tiposConduta);
  }

  getSubtiposCirurgia(): Observable<SelectionOption[]> {
    return of(CondutaPulmaoNsclcService.subtiposCirurgia);
  }

  getSubtiposRadioterapia(): Observable<SelectionOption[]> {
    return of(CondutaPulmaoNsclcService.subtiposRadioterapia);
  }

  getSubtiposTratamentoSistemico(): Observable<SelectionOption[]> {
    return of(CondutaPulmaoNsclcService.subtiposTratamentoSistemico);
  }

  getListaDeDrogasTerapiaAlvo(ficha: Ficha, filtro: boolean) {
    const drogasTerapiaAlvo = CondutaPulmaoNsclcService.drogasTerapiaAlvo;
    const item_EGFR_EXON_18_19_21 = ficha?.itens?.find((item) => item.chave === 'EGFR_EXON_18_19_21');
    const item_EGFR_EXON_20 = ficha?.itens?.find((item) => item.chave === 'EGFR_EXON_20');
    const item_ALK = ficha?.itens?.find((item) => item.chave === 'ALK');
    const item_ROS1 = ficha?.itens?.find((item) => item.chave === 'ROS1');
    const item_RET = ficha?.itens?.find((item) => item.chave === 'RET');
    const item_MET = ficha?.itens?.find((item) => item.chave === 'MET');
    const item_BRAF_V600E = ficha?.itens?.find((item) => item.chave === 'BRAF_V600E');
    const item_NK = ficha?.itens?.find((item) => item.chave === 'NK');
    const item_Erbb2_HER2 = ficha?.itens?.find((item) => item.chave === 'Erbb2_(HER2)');
    const item_KRAS_G12c = ficha?.itens?.find((item) => item.chave === 'KRAS_G12c');

    let drogasExtras = [];

    if (item_EGFR_EXON_18_19_21?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Osimertinibe',
        'Gefitinibe',
        'Erlotinibe',
        'Afatininbe',
      ]);
    }

    if (item_EGFR_EXON_20?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Amivantamabe',
        'Mobocertinibe',
      ]);
    }

    if (item_ALK?.valor || item_ROS1?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Alectininbe',
        'Brigatinibe',
        'Lorlatinibe',
        'Ceritinibe',
        'Crizotinibe',
        'Entrectinibe',
      ]);
    }

    if (item_RET?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Selpercatinibe',
        'Prausetinibe',
        'Vandetanibe',
        'Cabozantinibe',
        'Lenvatinibe',
        'Everolimo',
      ]);
    }

    if (item_BRAF_V600E?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Dabrafenibe',
        'Vemurafenibe',
        'Tramatinibe',
      ]);
    }

    if (item_NK?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Larotrectinibe',
        'Entrectinibe',
      ]);
    }

    if (item_MET?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Amivantamabe',
        'Tepotininbe',
        'Capmatinibe',
        'Crizotinibe',
      ]);
    }

    if (item_Erbb2_HER2?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Deruxtecano',
      ]);
    }

    if (item_KRAS_G12c?.valor || filtro) {
      drogasExtras = drogasExtras.concat([
        'Sotorasibe',
        'Adagrasibe',
      ]);
    }

    return [].concat(drogasTerapiaAlvo, drogasExtras);
  }

  getCondutasDePeriodo(): string[] {
    return CondutaPulmaoNsclcService.condutasDePeriodo;
  }
}
