import { GrupoTNM } from './grupo-t-n-m';

const LABEL_DESCONHECIDO = 'Desconhecido';

export class GrupoDAmico {

  static mapping = {
    I : 'Baixo',
    IIA : 'Intermediário',
    IIB : 'Intermediário',
    IIC : 'Intermediário',
    IIIA : 'Alto',
    IIIB : 'Alto',
    IIIC : 'Alto',
    IVA : 'Alto',
    IVB : 'Alto'
  };

  static calcular(tValue, nValue, mValue, psaValue, grupoISUP) {
    try {
      const grupoTNM = GrupoTNM.calcular(tValue, nValue, mValue, psaValue, grupoISUP);
      if (grupoTNM !== LABEL_DESCONHECIDO) {
        return GrupoDAmico.mapping[grupoTNM];
      } else {
        return LABEL_DESCONHECIDO;
      }
    } catch (e) {
      return LABEL_DESCONHECIDO;
    }
  }
}
