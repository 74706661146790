import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { ValidatorsApp } from '../../../../util/validators';
import { toast } from '../../../../util/toast';
import {
  calcSeveridadeTestesPreditivos,
  inputValueResolver
} from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { capitalizeFirst } from '../../../../util/utils';

@Component({
  selector: 'app-testes-preditivos',
  templateUrl: './testes-preditivos.component.html',
})
export class TestesPreditivosComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  offsetWidth = 10;
  idOncotype: string;
  idMamaprint: string;
  idProsignaN0: string;
  idProsignaN1: string;
  idBci: string;
  idEndopredict: string;
  fixedHeight = '695px';
  fullWhenKeyboardOpen = true;
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    oncotype: new FormControl(null, [
      (control) => ValidatorsApp.min(control, 0),
      (control) => ValidatorsApp.max(control, 100),
    ]),
    mamaprint: new FormControl(null),
    prosignaN0: new FormControl(null, [
      (control) => ValidatorsApp.min(control, 0),
      (control) => ValidatorsApp.max(control, 100),
    ]),
    prosignaN1: new FormControl(null, [
      (control) => ValidatorsApp.min(control, 0),
      (control) => ValidatorsApp.max(control, 100),
    ]),
    bci: new FormControl(null, [
      (control) => ValidatorsApp.min(control, 0),
      (control) => ValidatorsApp.max(control, 10),
    ]),
    endopredict: new FormControl(null, [
      (control) => ValidatorsApp.min(control, 0),
      (control) => ValidatorsApp.max(control, 10),
    ]),
  });

  mamaPrintOptions: SelectionOption[] = [
    {
      nome: 'Baixo Risco',
      valor: 'Baixo Risco'
    },
    {
      nome: 'Alto Risco',
      valor: 'Alto Risco'
    }
  ];

  constructor(public override injector: Injector) {
    super(injector);
  }

  get severidadeOncotype() {
    const oncotype = this.form.get('oncotype').value;
    return calcSeveridadeTestesPreditivos('oncotype', oncotype);
  }

  get severidadeProsignaN0() {
    const prosignaN0 = this.form.get('prosignaN0').value;
    return calcSeveridadeTestesPreditivos('prosignaN0', prosignaN0);
  }

  get severidadeProsignaN1() {
    const prosignaN1 = this.form.get('prosignaN1').value;
    return calcSeveridadeTestesPreditivos('prosignaN1', prosignaN1);
  }

  get severidadeBci() {
    const bci = this.form.get('bci').value;
    return calcSeveridadeTestesPreditivos('bci', bci);
  }

  get severidadeEndopredict() {
    const endopredict = this.form.get('endopredict').value;
    if (!endopredict) return;
    if (endopredict >= 0 && endopredict <= 3.3) return 'Baixo risco';
    if (endopredict >= 3.4 && endopredict <= 10) return 'Alto risco';
    return
  }

  ngOnInit() {
    const {
      mamaprint,
      oncotype,
      prosignaN0,
      prosignaN1,
      bci,
      endopredict,
      idOncotype,
      idMamaprint,
      idProsignaN0,
      idProsignaN1,
      idBci,
      idEndopredict,
    } = inputValueResolver('testesPreditivos', this.ficha?.itens);
    this.idOncotype = idOncotype;
    this.idMamaprint = idMamaprint;
    this.idProsignaN0 = idProsignaN0;
    this.idProsignaN1 = idProsignaN1;
    this.idBci = idBci;
    this.idEndopredict = idEndopredict;
    const formValues: any = {};
    if (mamaprint !== undefined) formValues.mamaprint = mamaprint;
    if (oncotype !== undefined) formValues.oncotype = oncotype;
    if (prosignaN0 !== undefined) formValues.prosignaN0 = prosignaN0;
    if (prosignaN1 !== undefined) formValues.prosignaN1 = prosignaN1;
    if (bci !== undefined) formValues.bci = bci;
    if (endopredict !== undefined) formValues.endopredict = endopredict;
    this.form.patchValue({ ...formValues });
  }

  async handleEndEditClick() {
    if (!ValidatorsApp.formularioValido(this.form)) {
      toast('É necessário preencher todos os campos para poder prosseguir', 'warning');
    } else {
      const formFields = Object.keys(this.form.controls);
      const items: ItemFicha[] = formFields.map((field) => {
        const idFieldName = `id${capitalizeFirst(field)}`;
        const newItem: ItemFicha = {
          chave: `testesPreditivos.${field}`,
          valor: this.form.get(field).value
        };
        if (this[idFieldName]) newItem.id = this[idFieldName];
        return newItem;
      }).filter(item => item.valor !== null);
      await this.modalController.dismiss(items);
    }
  }
}
