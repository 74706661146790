import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { Publicacao } from '../models/publicacao.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForumService extends AbstractService<Publicacao> {

  $novoCasoPublicado: Subject<any> = new Subject<any>();

  constructor(public http: HttpClient) {
    super('forum', http);
  }

  publicarCaso(data) {
    return super.post(data, 'publicar-caso');
  }

  buscarCasos() {
    return super.get('casos');
  }

  detalharCaso(idPublicacao): Observable<any> {
    return super.get(`caso/${idPublicacao}`) as Observable<any>;
  }

  deletarCaso(idPublicacao): Observable<any> {
    return super.delete(`caso/${idPublicacao}`) as Observable<any>;
  }

  arquivarCaso(idPublicacao): Observable<any> {
    return super.post({} as any,`caso/${idPublicacao}/arquivar`) as Observable<any>;
  }

  comentarCaso(idPublicacao, texto): Observable<any> {
    return super.post({ texto } as any,`caso/${idPublicacao}/comentar`) as Observable<any>;
  }

  setNovoCasoPublicado() {
    this.$novoCasoPublicado.next();
  }
}

