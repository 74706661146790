const CLIENT_BASE_PATH = 'admin';

export const CLIENT_ROUTE = {
  INICIO: 'inicio',
  MEDICO: 'medico',
  PACIENTE: 'paciente',
  INSTITUICAO: 'instituicao',
  DASHBOARD: 'dashboard',
  CONFIGURACOES: 'configuracoes',
  PROMPT: 'prompt',
  CONTA_INSTITUICIONAL: 'conta-institucional',
  GEMIFICACAO: 'gamificacao'
};

export const CLIENT_ROUTE_NAVIGATION = {
  INICIO: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.INICIO}`,
  MEDICO: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.MEDICO}`,
  MEDICO_VISUALIZAR: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.MEDICO}/visualizar`,
  PACIENTE: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.PACIENTE}`,
  PACIENTE_VISUALIZAR: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.PACIENTE}/visualizar`,
  INSTITUICAO: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.INSTITUICAO}`,
  DASHBOARD: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.DASHBOARD}`,
  PROMPT: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.PROMPT}`,
  CONFIGURACOES: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.CONFIGURACOES}`,
  CONTA_INSTITUICIONAL: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.CONTA_INSTITUICIONAL}`,
  GAMIFICACAO: `${CLIENT_BASE_PATH}/${CLIENT_ROUTE.GEMIFICACAO}`,
};
