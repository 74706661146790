import { Component, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { ValidatorsApp } from '../../../../util/validators';
import { numBRToUs, numUsToBR } from '../../../../util/utils';
import { loading } from '../../../../util/loading';
import { FichaService } from '../../../../services/ficha.service';
import { toast } from '../../../../util/toast';
import { parseISO } from 'date-fns';
import * as moment from 'moment';
import { CustomInputMoleculeComponent } from '../../../../components/custom-input/custom-input.component';

const NUMERO_FIXO_CAMPOS_PSA = 5;

const formPsaTemplate = () => {
  return new FormGroup({
    id: new FormControl(''),
    data: new FormControl('', Validators.compose([Validators.required, ValidatorsApp.dataCurvaPSA])),
    valor: new FormControl(null, [
      Validators.required,
      (control) => ValidatorsApp.min(control, 0),
      (control) => ValidatorsApp.max(control, 9999.999),
    ])
  });
};

@Component({
  selector: 'app-input-curva-psa',
  templateUrl: './input-curva-psa.component.html',
})
export class InputCurvaPsaComponent extends AbstractModal implements OnInit {
  
  @ViewChildren('inputRef') inputRefs: QueryList<CustomInputMoleculeComponent>;

  ficha: Ficha;
  offsetWidth = 10;
  fixedHeight = '430px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  itens: ItemFicha[];
  form: FormGroup = new FormGroup({
    values: new FormArray(Array(NUMERO_FIXO_CAMPOS_PSA).fill({}).map(formPsaTemplate))
  });

  constructor(public readonly injector: Injector,
              private fichaService: FichaService) {
    super(injector);
  }

  get values() {
    return this.form.controls["values"] as FormArray;
  }

  ngOnInit() {
    this.setFormValues();
  }

  setFormValues() {
    const itens = this.ficha.itens?.filter((item) => item.chave.startsWith('curvaPsa'));
    itens.sort((tupleA, tupleB) => {
      if (moment(tupleA.data).isBefore(tupleB.data)) return -1;
      else if (moment(tupleA.data).isAfter(tupleB.data)) return 1;
      else return 0;
    });
    if (itens?.length) {
      itens.forEach((item, index) => {
        let { id, data, valor }: any = item;
        if (valor !== undefined) valor = numUsToBR(valor);

        const formValue = {
          id,
          data: data ? moment(data).format('DD/MM/YY') : '',
          valor: valor ? valor : null
        };

        if (index < NUMERO_FIXO_CAMPOS_PSA) {
          this.values.at(index).patchValue(formValue);
        } else {
          const newFormPsa = formPsaTemplate();
          newFormPsa.patchValue(formValue);
          this.values.push(newFormPsa);
        }
      });
    }
  }

  handleChangeDatPsaInput(index: number) {
    if (this.values.at(index).get('data').valid) {
      const component = this.inputRefs.get(index)
      const element = component.componentRef.location.nativeElement.querySelector('ion-input');
      element.setFocus();
    } 
  }

  async handleAdicionarNovoPsa() {
    this.values.insert(0, formPsaTemplate());
  }

  async handleRemoverPsa(index: number) {
    const { id } = this.values.at(index).value;
    await loading(this.fichaService.removerItensficha(this.ficha, [id]).subscribe(async () => {
      await toast('Valor de PSA removido com sucesso');
      if (index < (NUMERO_FIXO_CAMPOS_PSA - 1)) {
        this.values.at(index).patchValue({ data: '', valor: null });
      } else {
        this.values.removeAt(index);
      }
    }));
  }

  async handleEndEditClick() {
    const values = this.values.getRawValue();
    const items: ItemFicha[] = values
      .map(({id, data, valor}) => {
        const formattedDate = moment(data, 'DD/MM/YY').format('DD/MM/YYYY');
        const newData = formattedDate.split('/').reverse().join('-')
        const newItem: ItemFicha = {
          chave: 'curvaPsa',
          data: parseISO(newData),
          valor: numBRToUs(valor)
        };
        if (!!id) newItem.id = id;
        return newItem;
      })
      .filter(({ data, valor }) => data && valor);
    await this.modalController.dismiss(items);
  }
}
