import { Pipe, PipeTransform } from '@angular/core';
import { calculateAge } from '../util/utils';

@Pipe({
  name: 'idade'
})
export class IdadePipe implements PipeTransform {
  transform(value: string | any): string {
    if (!value) {
      return;
    }
    return calculateAge(value);
  }
}
