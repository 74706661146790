import { Ficha, ItemFicha } from '../../../../../../shared/models/ficha.model';
import {
  LISTA_GENES,
  OUTROS_TESTES_THREE_WAY_TOGGLE_TEXT
} from '../../../../../../shared/modals/modulo-prostata/ficha-paciente/outros-testes/outros-testes.component';
import { GLEASON_OPTIONS } from '../../../../../../shared/modals/modulo-prostata/ficha-paciente/input-gleason/input-gleason.component';
import { GrupoTNM } from './grupo-t-n-m';
import { GrupoDAmico } from './grupo-d-amico';
import { numUsToBR } from '../../../../../../shared/util/utils';
import { AbstractLabelResolver, AbstractValueResolver } from '../../_base/abstract-resolver';

class LabelResolver extends AbstractLabelResolver {

  static histopatologico = (items: ItemFicha[]): string => {
    const itemHistopatologico = items?.find((item) => item.chave === 'histopatologico.area');
    if (itemHistopatologico?.valor) return `${itemHistopatologico.valor}`;
    else return '';
  };

  static gleason = (items: ItemFicha[]): string => {
    const itemGleason = items?.find((item) => item.chave === 'gleason');
    if (itemGleason?.valor) return `${itemGleason.valor}`;
    if (itemGleason?.valor === null) return 'Desconhecido';
    else return '';
  };

  static dataDiagnostico = (items: ItemFicha[]): string => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    if (itemDataDiagnostico?.valor)
      return `${new Date(String(itemDataDiagnostico.valor)).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}`;
    return '';
  };

  static psa = (items: ItemFicha[]): string => {
    const itemPsa = items?.find((item) => item.chave === 'psa');
    if (itemPsa?.valor) return `${itemPsa.valor}`;
    if (itemPsa?.valor === null) return 'Desconhecido';
    else return '';
  };

  static t = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemIntervencaoT = items?.find((item) => item.chave === 'intervencaoT');
    if (itemT?.valor && itemIntervencaoT?.valor) return `${itemIntervencaoT.valor}`;
    if (itemT?.valor) return `${itemT.valor}`;
    return '';
  };

  static n = (items: ItemFicha[]): string => {
    const itemN = items?.find((item) => item.chave === 'n');
    const itemIntervencaoN = items?.find((item) => item.chave === 'intervencaoN');
    if (itemN?.valor && itemIntervencaoN?.valor) return `${itemIntervencaoN.valor}`;
    if (itemN?.valor) return `${itemN.valor}`;
    return '';
  };

  static m = (items: ItemFicha[]): string => {
    const itemM = items?.find((item) => item.chave === 'm');
    const itemIntervencaoM = items?.find((item) => item.chave === 'intervencaoM');
    if (itemM?.valor && itemIntervencaoM?.valor) return `${itemIntervencaoM.valor}`;
    if (itemM?.valor) return `${itemM.valor}`;
    return '';
  };

  static grupoISUP = (items: ItemFicha[]): string => {
    const itemGleason = items?.find((item) => item.chave === 'gleason');
    if (itemGleason?.valor) {
      const option = GLEASON_OPTIONS.find(option => option.value === itemGleason.valor);
      if (option) return `${option.label}`;
    }
    if (itemGleason?.valor === null) return 'Desconhecido';
    return '';
  };

  static grupoTNM = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemN = items?.find((item) => item.chave === 'n');
    const itemM = items?.find((item) => item.chave === 'm');
    const itemIntervencaoT = items?.find((item) => item.chave === 'intervencaoT');
    const itemIntervencaoN = items?.find((item) => item.chave === 'intervencaoN');
    const itemIntervencaoM = items?.find((item) => item.chave === 'intervencaoM');
    const itemPsa = items?.find((item) => item.chave === 'psa');
    const itemGleason = items?.find((item) => item.chave === 'gleason');
    let grupoISUP;
    if (itemGleason?.valor) {
      const option = GLEASON_OPTIONS.find(option => option.value === itemGleason.valor);
      if (option) grupoISUP = option.label;
    }
    if (itemGleason?.valor === null) grupoISUP = 'Desconhecido';
    if (itemPsa?.valor === null) itemPsa.valor = 'Desconhecido';
    if (itemPsa?.valor && grupoISUP) {
      if (itemIntervencaoT?.valor && itemIntervencaoN?.valor && itemIntervencaoM?.valor) {
        return GrupoTNM.calcular(itemIntervencaoT?.valor, itemIntervencaoN?.valor, itemIntervencaoM?.valor, itemPsa?.valor, grupoISUP);
      }
      if (itemT?.valor && itemN?.valor && itemM?.valor) {
        return GrupoTNM.calcular(itemT?.valor, itemN?.valor, itemM?.valor, itemPsa?.valor, grupoISUP);
      }
    }

    return '';
  };

  static grupoDAmico = (items: ItemFicha[]): string => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemN = items?.find((item) => item.chave === 'n');
    const itemM = items?.find((item) => item.chave === 'm');
    const itemIntervencaoT = items?.find((item) => item.chave === 'intervencaoT');
    const itemIntervencaoN = items?.find((item) => item.chave === 'intervencaoN');
    const itemIntervencaoM = items?.find((item) => item.chave === 'intervencaoM');
    const itemPsa = items?.find((item) => item.chave === 'psa');
    const itemGleason = items?.find((item) => item.chave === 'gleason');
    let grupoISUP;
    if (itemGleason?.valor) {
      const option = GLEASON_OPTIONS.find(option => option.value === itemGleason.valor);
      if (option) grupoISUP = option.label;
    }
    if (itemGleason?.valor === null) grupoISUP = 'Desconhecido';
    if (itemPsa?.valor && grupoISUP) {
      if (itemIntervencaoT?.valor && itemIntervencaoN?.valor && itemIntervencaoM?.valor) {
        return GrupoDAmico.calcular(itemIntervencaoT?.valor, itemIntervencaoN?.valor, itemIntervencaoM?.valor, itemPsa?.valor, grupoISUP);
      }
      if (itemT?.valor && itemN?.valor && itemM?.valor) {
        return GrupoDAmico.calcular(itemT?.valor, itemN?.valor, itemM?.valor, itemPsa?.valor, grupoISUP);
      }
    }
    return '';
  };

  static outrosTestes = (items: ItemFicha[]): string => {
    const outrosTestes = items?.filter((item) => item.chave.startsWith('outrosTestes'));
    const outrosTestesMapping = {};
    const testesNames = [];

    outrosTestes?.forEach(teste => outrosTestesMapping[teste.chave] = teste.valor);

    if (outrosTestes?.length) {
      outrosTestes.forEach((item) => {
        const testeKey = item.chave.replace('outrosTestes.', '');
        let testeValue;
        if (item.valor === false) {
          testeValue = OUTROS_TESTES_THREE_WAY_TOGGLE_TEXT[testeKey]?.negativeValueText;
        }
        if (item.valor === true) {
          testeValue = OUTROS_TESTES_THREE_WAY_TOGGLE_TEXT[testeKey]?.positiveValueText;
        }
        let genesTestadosPositivo = [];
        if (testeKey === 'HRR15') {
          LISTA_GENES.forEach(gene => {
            if (outrosTestesMapping[`outrosTestes.${gene}`]) {
              genesTestadosPositivo.push(gene);
            }
          });
        }
        if (testeKey && testeValue)
          testesNames.push(`${testeKey}: ${testeValue} ${genesTestadosPositivo.length ? `(${genesTestadosPositivo.join(', ')})` : ''}`);
      });
    }
    return testesNames.length ? testesNames.join(', ') : null;
  };

  static antecedentesInformacoes = (items: ItemFicha[]): string => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    if (itemAntecedentesInformacoes?.valor) return `${itemAntecedentesInformacoes.valor}`;
    return '';
  };

  static dataPsa = (items: ItemFicha[], index: number): string => {
    const itemsCurvaPsa = items?.filter((item) => item.chave.startsWith('curvaPsa'));
    const itemCurvaPsa = itemsCurvaPsa[index];
    if (itemCurvaPsa?.data)
      return `${new Date(String(itemCurvaPsa.data)).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}`;
    return '';
  };

  static curvaPsa = (items: ItemFicha[], index: number): string => {
    const itemsCurvaPsa = items?.filter((item) => item.chave.startsWith('curvaPsa'));
    const itemCurvaPsa = itemsCurvaPsa[index];
    if (itemCurvaPsa?.valor && itemCurvaPsa?.data) {
      const data = `${new Date(String(itemCurvaPsa.data)).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}`;
      return `${data}: ${numUsToBR(itemCurvaPsa.valor)} ng/ml`;
    }
    return '';
  };
}

class ValueResolver extends AbstractValueResolver {

  static histopatologico = (items: ItemFicha[]): any => {
    const itemHistopatologico = items?.find((item) => item.chave === 'histopatologico.area');
    return {
      area: itemHistopatologico?.valor,
      id: itemHistopatologico?.id
    };
  };

  static gleason = (items: ItemFicha[]): any => {
    const itemGleason = items?.find((item) => item.chave === 'gleason');
    return {
      gleason: itemGleason?.valor,
      id: itemGleason?.id
    };
  };

  static dataDiagnostico = (items: ItemFicha[]): any => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    return {
      dataDiagnostico: itemDataDiagnostico?.valor,
      id: itemDataDiagnostico?.id
    };
  };

  static psa = (items: ItemFicha[]): any => {
    const itemPsa = items?.find((item) => item.chave === 'psa');
    return {
      psa: itemPsa?.valor,
      id: itemPsa?.id
    };
  };

  static t = (items: ItemFicha[]): any => {
    const itemT = items?.find((item) => item.chave === 't');
    const itemTntervencaoT = items?.find((item) => item.chave === 'intervencaoT');
    return {
      t: itemT?.valor,
      idT: itemT?.id,
      intervencaoT: itemTntervencaoT?.valor,
      idItervencaoT: itemTntervencaoT?.id,
    };
  };

  static n = (items: ItemFicha[]): any => {
    const itemN = items?.find((item) => item.chave === 'n');
    const itemTntervencaoN = items?.find((item) => item.chave === 'intervencaoN');
    return {
      n: itemN?.valor,
      idN: itemN?.id,
      intervencaoN: itemTntervencaoN?.valor,
      idIntervencaoN: itemTntervencaoN?.id
    };
  };

  static m = (items: ItemFicha[]): any => {
    const itemM = items?.find((item) => item.chave === 'm');
    const itemTntervencaoM = items?.find((item) => item.chave === 'intervencaoM');
    return {
      m: itemM?.valor,
      idM: itemM?.id,
      intervencaoM: itemTntervencaoM?.valor,
      idIntervencaoM: itemTntervencaoM?.id
    };
  };

  static outrosTestes = (items: ItemFicha[]): any => {
    const objItemsMapping: any = {};
    const objItemsIdsMapping: any = {};
    items?.filter((item) => item.chave.startsWith('outrosTestes'))
      .forEach((item) => {
        const itemKeyName = item.chave.split('.')[1];
        objItemsMapping[itemKeyName] = item.valor;
        objItemsIdsMapping[itemKeyName] = item.id;
      });

    return {
      values : {
        HRR15: objItemsMapping?.HRR15,
        dMMR: objItemsMapping?.dMMR,
        BRCA1: objItemsMapping?.BRCA1,
        BRCA2: objItemsMapping?.BRCA2,
        ATM: objItemsMapping?.ATM,
        BRIP1: objItemsMapping?.BRIP1,
        BARD1: objItemsMapping?.BARD1,
        CDK12: objItemsMapping?.CDK12,
        CHEK1: objItemsMapping?.CHEK1,
        CHEK2: objItemsMapping?.CHEK2,
        FANCL: objItemsMapping?.FANCL,
        PALB2: objItemsMapping?.PALB2,
        PPP2R2A: objItemsMapping?.PPP2R2A,
        RAD51B: objItemsMapping?.RAD51B,
        RAD51C: objItemsMapping?.RAD51C,
        RAD51D: objItemsMapping?.RAD51D,
        RAD54L: objItemsMapping?.RAD54L,
        ['MSI-H']: (objItemsMapping || {})['MSI-H'],
      },
      ids: {
        HRR15: objItemsIdsMapping?.HRR15,
        dMMR: objItemsIdsMapping?.dMMR,
        BRCA1: objItemsIdsMapping?.BRCA1,
        BRCA2: objItemsIdsMapping?.BRCA2,
        ATM: objItemsIdsMapping?.ATM,
        BRIP1: objItemsIdsMapping?.BRIP1,
        BARD1: objItemsIdsMapping?.BARD1,
        CDK12: objItemsIdsMapping?.CDK12,
        CHEK1: objItemsIdsMapping?.CHEK1,
        CHEK2: objItemsIdsMapping?.CHEK2,
        FANCL: objItemsIdsMapping?.FANCL,
        PALB2: objItemsIdsMapping?.PALB2,
        PPP2R2A: objItemsIdsMapping?.PPP2R2A,
        RAD51B: objItemsIdsMapping?.RAD51B,
        RAD51C: objItemsIdsMapping?.RAD51C,
        RAD51D: objItemsIdsMapping?.RAD51D,
        RAD54L: objItemsIdsMapping?.RAD54L,
        ['MSI-H']: (objItemsIdsMapping || {})['MSI-H'],
      }
    };
  };

  static antecedentesInformacoes = (items: ItemFicha[]): any => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    return {
      antecedentesInformacoes: itemAntecedentesInformacoes?.valor,
      id: itemAntecedentesInformacoes?.id,
    };
  };

  static dataPsa = (items: ItemFicha[], index: number): any => {
    const itemsCurvaPsa = items?.filter((item) => item.chave.startsWith('curvaPsa'));
    const itemCurvaPsa = (itemsCurvaPsa || [])[index];
    return {
      data: itemCurvaPsa?.data
    };
  };

  static curvaPsa = (items: ItemFicha[], index: number): any => {
    const itemsCurvaPsa = items?.filter((item) => item.chave.startsWith('curvaPsa'));
    const itemCurvaPsa = (itemsCurvaPsa || [])[index];
    return {
      curvaPsa: itemCurvaPsa?.valor,
      id: itemCurvaPsa?.id,
      data: itemCurvaPsa?.data
    };
  };
}

export const inputLabelResolver = (resolver, items: ItemFicha[], index?: number) => {
  return LabelResolver[resolver](items, index);
};

export const inputValueResolver = (resolver, items: ItemFicha[], index?: number) => {
  return ValueResolver[resolver](items, index);
};

export const verificarVigilanciaAtiva = (ficha: Ficha) => {
  return ficha?.condutas?.some(conduta => conduta.tipo === 'Vigilância ativa');
};
