export const graficoBarraColors = [
  '#21A40C',
  '#ffa700',
  '#d62d20',
  '#7c2c8c',
  '#c25b11',
  '#008744',
  '#0057e7',
  '#6628e5',
  '#da5935',
];

export const statusMedicoColors = {
  pendente: '#BC9F08',
  validado: '#21A40C',
  excluido: '#DA1111',
};

export const paletaCoresChart = {
  mama: [
    '#fc89c9',
    '#e660d3',
    '#ff7eec',
    '#cc4d8d',
    '#8a2858',
    '#671d42',
  ],
  prostata: [
    '#3E83EB',
    '#3318e9',
    '#2612c2',
    '#190c9c',
    '#0d0675',
    '#00004e',
  ]
};

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : '';
}
