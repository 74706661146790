import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import { ResponsiveService } from '../../../../services/responsive.service';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/pulmao-nsclc/util';

export const HISTOPATOLOGICO_CHAVE = 'histopatologico';
export const HISTOPATOLOGICO_OPTIONS: SelectionOption[] = [
  {
    nome: 'Adenocarcinoma',
    valor: 'Adenocarcinoma',
  },
  {
    nome: 'Carcinoma escamocelular',
    valor: 'Carcinoma escamocelular',
  },
  {
    nome: 'Células grandes',
    valor: 'Células grandes',
  },
  {
    nome: 'Adenoescamoso',
    valor: 'Adenoescamoso',
  },
  {
    nome: 'Indiferenciado',
    valor: 'Indiferenciado',
  },
  {
    nome: 'Outros',
    valor: 'Outros',
    customClasses: 'italic'
  },
];

@Component({
  selector: 'app-input-histopatologico',
  templateUrl: './input-histopatologico.component.html',
})
export class InputHistopatologicoComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '410px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;

  form: FormGroup = new FormGroup({
    histopatologico: new FormControl('')
  });

  histopatologicoOptions: SelectionOption[] = HISTOPATOLOGICO_OPTIONS;

  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService) {
    super(injector);
  }
  
  ngOnInit() {
    const { histopatologico, id } = inputValueResolver(HISTOPATOLOGICO_CHAVE, this.ficha?.itens);
    this.id = id;
    if (histopatologico !== undefined) this.form.patchValue({ histopatologico });
  }

  async handleEndEditClick() {
    if (this.form.get(HISTOPATOLOGICO_CHAVE).pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: HISTOPATOLOGICO_CHAVE,
        valor: this.form.get(HISTOPATOLOGICO_CHAVE).value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
