import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (): RangeConfig => ({
  options: [
    {
      label: 'Mx',
      value: 'Mx',
      hint: 'Desconhecido'
    },
    {
      label: 'M0',
      value: 'M0',
      hint: 'Sem metástase à distância (avaliação clínica).'
    },
    {
      label: 'M1',
      value: 'M1',
      hint: 'Metástase à distância.',
      options: [
        {
          label: 'M1',
          value: 'M1',
          hint: 'Metástase à distância.',
        },
        {
          label: 'M1a',
          value: 'M1a',
          hint: 'Metástase confinada a um órgão ou local (por exemplo, fígado, pulmão, ovário, nódulo não regional) sem metástase peritoneal.'
        },
        {
          label: 'M1b',
          value: 'M1b',
          hint: 'Metástases em dois ou mais órgãos sem metástase peritoneal.'
        },
        {
          label: 'M1c',
          value: 'M1c',
          hint: 'Metástases no peritônio, isoladas ou associada a metástase em outros órgãos.'
        }
      ]
    },
  ]
});
