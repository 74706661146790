import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/colorretal-colon/util';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { Tip } from '../../../../components/button-tip-selector/button-tip-selector.component';
import { optionsResolver } from './util';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';
import { toast } from '../../../../util/toast';
import { InputWithModal } from '../../../../interfaces/input-with-modal';

@Component({
  selector: 'app-input-t',
  templateUrl: './input-t.component.html',
})
export class InputTComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  idT: string;
  idYpt: string;
  offsetWidth = 10;
  fixedHeight = '530px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  posNeoAdjuvancia: boolean;
  $digesting: boolean = false;
  tipoEstadiamento: FormControl = new FormControl('clinico');
  tipoNeoAdjuvancia: FormControl = new FormControl(null);
  form: FormGroup = new FormGroup({
    t: new FormControl('cTx'),
    ypt: new FormControl('ypTx'),
  });

  tRangeOptions: RangeConfig = optionsResolver('c');
  tRangeOptionsPosNeo: RangeConfig = optionsResolver('yp');
  tRangeSubOptions: RangeConfig;
  tRangeSubOptionsPosNeo: RangeConfig;
  posNeoAdjuvanciaTips: Tip[] = [
    {
      name: 'Pré neoadvuvancia',
      value: 'pre'
    },
    {
      name: 'Pós neoadjuvancia',
      value: 'pos'
    }
  ];
  tipoEstadiamentoTips: Tip[] = [
    {
      name: 'Clínico',
      value: 'clinico'
    },
    {
      name: 'Patológico',
      value: 'patologico'
    }
  ];

  constructor(public override injector: Injector,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }

  ngOnInit() {
    const { t, ypt, idT, idYpt } = inputValueResolver('t', this.ficha?.itens);
    this.idT = idT;
    this.idYpt = idYpt;
    if (String(t).startsWith('pT')) {
      this.tipoEstadiamento.setValue('patologico');
      this.updateTRangeOptions(false);
    }
    if (this.ficha.hasNeoAdjuvancia) {
      this.atualizarValoresInputEModal(t, ypt);
      if (ypt) this.loadYptInputFormValues(ypt);
    }
    this.loadFormValues(t);
    this.form.get('t').valueChanges.subscribe((value) => {
      this.tRangeSubOptions = null;
      this.$digesting = true;
      const selectedOption = this.tRangeOptions.options.find(o => o.value === value);
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.tRangeSubOptions = {
            options: selectedOption.options
          };
        });
      } else {
        this.tRangeSubOptions = null;
      }
      this.$digesting = false;
    });

    this.form.get('ypt').valueChanges.subscribe((value) => {
      this.tRangeSubOptionsPosNeo = null;
      this.$digesting = true;
      const selectedOption = this.tRangeOptionsPosNeo.options.find(o => o.value === value);
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.tRangeSubOptionsPosNeo = {
            options: selectedOption.options
          };
        });
      } else {
        this.tRangeSubOptionsPosNeo = null;
      }
      this.$digesting = false;
    });
  }

  loadFormValues(tValue: string) {
    if (![null, undefined].includes(tValue)) {
      const tOption = this.tRangeOptions.options.find((option) => tValue.startsWith(option.value));
      this.form.patchValue({ t: tOption.value });
      if (tOption?.options?.length) {
        this.tRangeSubOptions = {
          options: tOption.options
        };
        this.form.addControl(tOption.value, new FormControl(tValue));
      }
    }
  }

  loadYptInputFormValues(yptValue: string) {
    const selectedOption = this.tRangeOptionsPosNeo.options.find(option => yptValue.startsWith(option.value));
    if (selectedOption?.options?.length) {
      this.tRangeSubOptionsPosNeo = { options :  selectedOption.options };
      this.form.patchValue({ ypt: selectedOption.value });
      this.form.addControl(selectedOption.value, new FormControl(yptValue));
    } else {
      this.form.patchValue({ ypt: selectedOption.value });
    }
  }

  atualizarValoresInputEModal(tValue, yptValue) {
    this.posNeoAdjuvancia = true;
    if (!tValue && !!yptValue) this.tipoNeoAdjuvancia.setValue('pos');
    else this.tipoNeoAdjuvancia.setValue('pre');
  }

  handleTipoNeoAdjuvanciaChange(event: any) {
    this.tipoNeoAdjuvancia.setValue(event.value);
  }

  handleTipoEstadiamentoChange(event: any) {
    this.tipoEstadiamento.setValue(event.value);
    this.updateTRangeOptions();
  }

  updateTRangeOptions(setValueOnInput = true) {
    const prefixo = String(this.tipoEstadiamento.value)[0];
    this.tRangeOptions = optionsResolver(prefixo);
    setTimeout(() => {
      if (setValueOnInput) this.form.get('t').setValue(`${prefixo}Tx`);
    }, 100);
  }

  removeUnusedFormFields() {
    const unusedFields = Object.keys(this.form.controls).filter(key => key !== 't');
    unusedFields.forEach((field) => this.form.removeControl(field));
  }

  async handleResetValuesClick() {
    const alert = await this.alertHelper.create({
      title: 'Redefinir valores',
      text: 'Tem certeza que deseja zerar os valores para este estadiamento?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          const items: ItemFicha[] = [];
          if (this.form.get('t').value && this.idT) items.push({ chave: 't', valor: null, id: this.idT });
          if (this.form.get('ypt').value && this.idYpt) items.push({ chave: 'ypt', valor: null, id: this.idYpt });
          setTimeout(async () => {
            await this.modalController.dismiss(items);
          }, 100);
        }
      }
    });
    await alert.present();
  }

  async handleEndEditClick() {
    const items: ItemFicha[] = [];
    let valor;
    if (this.posNeoAdjuvancia && !this.form.get('ypt').pristine) {
      if (this.tRangeSubOptionsPosNeo?.options?.length) {
        const fieldName = this.form.get('ypt').value;
        valor = this.form.get(fieldName).value;
      } else {
        valor = this.form.get('ypt').value;
      }
      if (['ypT4'].includes(valor)) {
        await toast('É necessário escolher a subclassificação');
        return;
      }
      const newItem: ItemFicha = { chave: 'ypt', valor };
      if (this.idYpt) newItem.id = this.idYpt;
      items.push(newItem);
    }
    if (this.tRangeSubOptions?.options?.length) {
      const fieldName = this.form.get('t').value;
      valor = this.form.get(fieldName).value;
      if (['cT4', 'pT4'].includes(valor)) {
        await toast('É necessário escolher a subclassificação');
        return;
      }
    } else {
      valor = this.form.get('t').value;
    }
    const newItem: ItemFicha = { chave: 't', valor };
    if (this.idT) newItem.id = this.idT;
    items.push(newItem);
    await this.modalController.dismiss(items);
  }
}
