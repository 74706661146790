import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../models/ficha.model';
import { FormControl } from '@angular/forms';
import { VALUE_RESOLVER_MAPPING } from '../../constants/label-resolver';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';

@Component({
  selector: 'app-input-antecedentes-informacoes',
  templateUrl: './antecedentes-informacoes.component.html',
})
export class AntecedentesInformacoesComponent extends AbstractModal implements OnInit {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '400px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  moduloAtual: IMenuModuloConfig;

  antecedentesInformacoes: FormControl = new FormControl('');

  constructor(public readonly injector: Injector,
              private moduloService: ModuloService) {
    super(injector);
    moduloService.$moduloAtual.subscribe((modulo) => this.moduloAtual = modulo);
  }

  ngOnInit() {
    const inputValueResolver = VALUE_RESOLVER_MAPPING[this.moduloAtual.name];
    const { antecedentesInformacoes, id } = inputValueResolver('antecedentesInformacoes', this.ficha?.itens);
    this.id = id;
    this.antecedentesInformacoes.setValue(antecedentesInformacoes);
  }

  async handleEndEditClick() {
    if (!this.antecedentesInformacoes.pristine) {
      const newItem: ItemFicha = {
        chave: 'antecedentesInformacoes',
        valor: this.antecedentesInformacoes.value
      };
      if (this.id) newItem.id = this.id;
      await this.modalController.dismiss([newItem]);
    } else {
      await this.modalController.dismiss({ confirm: false });
    }
  }
}
