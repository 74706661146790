import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (prefixo: string = ''): RangeConfig => ({
  options: [
    {
      label: `${prefixo}Nx`,
      value: `${prefixo}Nx`,
      hint: 'LNDS não avaliáveis.'
    },
    {
      label: `${prefixo}N0`,
      value: `${prefixo}N0`,
      hint: 'Sem metástase LND'
    },
    {
      label: `${prefixo}N1`,
      value: `${prefixo}N1`,
      hint: 'De 1 a 3 LND (Tu no linfonodo > 0.2mm) ou qualquer número de depósito tumoral presente com linfonodos negativos.',
      options: [
        {
          label: `${prefixo}N1`,
          value: `${prefixo}N1`,
          hint: 'De 1 a 3 LND (Tu no linfonodo > 0.2mm) ou qualquer número de depósito tumoral presente com linfonodos negativos.',
        },
        {
          label: `${prefixo}N1a`,
          value: `${prefixo}N1a`,
          hint: 'Um LND positivo.'
        },
        {
          label: `${prefixo}N1b`,
          value: `${prefixo}N1b`,
          hint: 'Dois a três LNDs positivos.'
        },
        {
          label: `${prefixo}N1c`,
          value: `${prefixo}N1c`,
          hint: 'Depósito (s) tumoral(is) na subserosa, mesentério ou tecidos pericolônicos não peritonizados sem metástase em LND.'
        }
      ]
    },
    {
      label: `${prefixo}N2`,
      value: `${prefixo}N2`,
      hint: 'Quatro ou mais LND positivos',
      options: [
        {
          label: `${prefixo}N2`,
          value: `${prefixo}N2`,
          hint: 'Quatro ou mais LND positivos',
        },
        {
          label: `${prefixo}N2a`,
          value: `${prefixo}N2a`,
          hint: 'Quatro a seis LND positivos'
        },
        {
          label: `${prefixo}N2b`,
          value: `${prefixo}N2b`,
          hint: 'Sete ou mais linfonodos LND positivos.'
        },
      ]
    },
  ]
});
