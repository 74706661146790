import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MedicoDto, Usuario } from '../../models/usuario.model';
import { ROUTE } from '../../constants/route';
import CustomAlertHelper from '../../helper/custom-alert-helper';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { EditarMedicoComponent } from '../../modals/admin/editar-medico/editar-medico.component';
import { loading } from '../../util/loading';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { toast } from '../../util/toast';
import { Router } from '@angular/router';
import { CLIENT_ROUTE_NAVIGATION } from '../../constants/client-route';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-ficha-medico',
  templateUrl: './ficha-medico.component.html',
})
export class FichaMedicoComponent implements OnChanges {

  @Input() loading: boolean = false;
  @Input() observacoes: string;
  @Input() medico: MedicoDto;

  @Output() onMedicoAtualizado: EventEmitter<Usuario> = new EventEmitter<Usuario>();

  observacoesMedicoControl: FormControl = new FormControl();

  constructor(private alertHelper: CustomAlertHelper,
              private modalHelper: CustomModalHelper,
              private adminService: AdminService,
              private router: Router) { }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.observacoes?.currentValue) {
      this.observacoesMedicoControl.setValue(changes.observacoes.currentValue);
    }
  }

  async handleEditarMedicoClick() {
    const modal = await this.modalHelper.create({
      component: EditarMedicoComponent,
      backdropDismiss: true,
      componentProps: {
        medicoId: this.medico.id
      },
      handler: ({data}) => {
        if (data?.confirm && data.payload?.usuario) {
          this.onMedicoAtualizado.emit(data.payload?.usuario);
        }
      }
    });

    await modal.present();
  }

  async handleExcluirMedicoClick() {
    const alert = await this.alertHelper.create({
      title: 'Exclusão de médico',
      text: 'Deseja remover permanentemente os dados deste médico, incluindo TODOS os seus pacientes?',
      okText: 'Excluir',
      cancelText: 'Cancelar',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      confirmButtonIcon: 'c-trash',
      handler: async ({data}) => {
        if (data.confirm) {
          loading(this.adminService.excluirMedico(this.medico.id).subscribe(async () => {
            await toast('O usuário foi excluído com sucesso', 'success');
            await this.router.navigate([CLIENT_ROUTE_NAVIGATION.INICIO], { replaceUrl: true });
          }));
        }
      }
    });
    await alert.present();
  }

  async handleMedicoValidadoChange(event) {
    await loading(this.adminService.validarMedico(this.medico.id, event.detail.checked).subscribe(async () => {
      await toast('Alteração feita com sucesso', 'success');
    }));
  }

  async handleSalvarObservacoesClick() {
    const observacoes = this.observacoesMedicoControl.value;
    await loading(this.adminService.salvarObservacoesMedico(this.medico.id, observacoes)
      .subscribe(async () => {
        await toast('As observações foram salvas com sucesso', 'success');
    }));
  }
}
