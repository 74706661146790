import { RangeConfig } from '../../../../../components/_base/abstract-input';

const clinicoRangeConfig = (prefixo: string):RangeConfig => ({
  options: [
    {
      label: `${prefixo}cNx`,
      value: `${prefixo}cNx`,
      hint: 'Linfonodos regionais não podem ser acessados'
    },
    {
      label: `${prefixo}cN0`,
      value: `${prefixo}cN0`,
      hint: 'Sem evidência de metástases para linfonodo regional'

    },
    {
      label: `${prefixo}cN1`,
      value: `${prefixo}cN1`,
      hint: 'Metástases móveis e ipsilaterais em nível I, II'
    },
    {
      label: `${prefixo}cN2`,
      value: `${prefixo}cN2`,
      hint: 'Linfonodos aderidos e ipsilaterais em nível I, II OU mamária interna SEM axilar.',
      options: [
        {
          label: `${prefixo}cN2`,
          value: `${prefixo}cN2`,
          hint: 'Linfonodos aderidos e ipsilaterais em nível I, II OU mamária interna SEM axilar.',
        },
        {
          label: `${prefixo}cN2a`,
          value: `${prefixo}cN2a`,
          hint: 'Linfonodos aderidos e ipsilaterais em nível I, II',
        },
        {
          label: `${prefixo}cN2b`,
          value: `${prefixo}cN2b`,
          hint: 'Mamária interna SEM axilar'
        }
      ]
    },
    {
      label: `${prefixo}cN3`,
      value: `${prefixo}cN3`,
      hint: 'Infraclavicular ipsilateral (nível III) OU mamária interna ipsilateral COM axila OU supraclavilcular ipsilateral',
      options: [
        {
          label: `${prefixo}cN3`,
          value: `${prefixo}cN3`,
          hint: 'Infraclavicular ipsilateral (nível III) OU mamária interna ipsilateral COM axila OU supraclavilcular ipsilateral',
        },
        {
          label: `${prefixo}cN3a`,
          value: `${prefixo}cN3a`,
          hint: 'Infraclavicular ipsilateral (nível III)'
        },
        {
          label: `${prefixo}cN3b`,
          value: `${prefixo}cN3b`,
          hint: 'Mamária interna ipsilateral COM axila'
        },
        {
          label: `${prefixo}cN3c`,
          value: `${prefixo}cN3c`,
          hint: 'Supraclavilcular ipsilateral'
        }
      ]
    }
  ]
});

const patologicoRangeConfig = (prefixo: string):RangeConfig => ({
  options: [
    {
      label: `${prefixo}pNx`,
      value: `${prefixo}pNx`,
      hint: 'Linfonodos regionais não podem ser acessados'
    },
    {
      label: `${prefixo}pN0`,
      value: `${prefixo}pN0`,
      hint: 'Sem evidência de metástases para linfonodo regional'
    },
    {
      label: `${prefixo}pN1`,
      value: `${prefixo}pN1`,
      hint: 'Metástases em 1-3 linfonodos axilares',
      options: [
        {
          label: `${prefixo}pN1`,
          value: `${prefixo}pN1`,
          hint: 'Metástases em 1-3 linfonodos axilares',
        },
        {
          label: `${prefixo}pN1mic`,
          value: `${prefixo}pN1mic`,
          hint: 'Micrometástases (≥0.2mm e ≤2mm)'
        },
        {
          label: `${prefixo}pN1a`,
          value: `${prefixo}pN1a`,
          hint: 'Metástases 1-3 linfonodos'
        },
        {
          label: `${prefixo}pN1b`,
          value: `${prefixo}pN1b`,
          hint: 'Metástases em mamária interna ipsilateral ao sentinela'
        },
        {
          label: `${prefixo}pN1c`,
          value: `${prefixo}pN1c`,
          hint: 'Mamária interna COM axila até 3 acometidos'
        }
      ]
    },
    {
      label: `${prefixo}pN2`,
      value: `${prefixo}pN2`,
      hint: 'Metástases 4-9 linfondoos axilares OU mamária interna positiva em imagem SEM axilar',
      options: [
        {
          label: `${prefixo}pN2`,
          value: `${prefixo}pN2`,
          hint: 'Metástases 4-9 linfondoos axilares OU mamária interna positiva em imagem SEM axilar',
        },
        {
          label: `${prefixo}pN2a`,
          value: `${prefixo}pN2a`,
          hint: 'Metástases 4-9 linfondos axilares',
        },
        {
          label: `${prefixo}pN2b`,
          value: `${prefixo}pN2b`,
          hint: 'Mamária interna positiva em imagem ou biópsia SEM axilar'
        }
      ]
    },
    {
      label: `${prefixo}pN3`,
      value: `${prefixo}pN3`,
      hint: 'Metástases em 10 ou mais linfodos axilares OU infraclavicular OU mamária interna positiva a imagem ou biópsia COM axila',
      options: [
        {
          label: `${prefixo}pN3`,
          value: `${prefixo}pN3`,
          hint: 'Metástases em 10 ou mais linfodos axilares OU infraclavicular OU mamária interna positiva a imagem ou biópsia COM axila',
        },
        {
          label: `${prefixo}pN3a`,
          value: `${prefixo}pN3a`,
          hint: 'Metástases em 10 ou mais linfodos axilares'
        },
        {
          label: `${prefixo}pN3b`,
          value: `${prefixo}pN3b`,
          hint: 'Mamária interna COM axila com mais de 10 acometidos'
        },
        {
          label: `${prefixo}pN3c`,
          value: `${prefixo}pN3c`,
          hint: 'Metástases para supraclavicular ipsilateral'
        }
      ]
    }
  ]
});

export const optionsResolver = (tipo: 'clinico' | 'patologico', prefixo: string = '') => {
  if (tipo === 'clinico') {
    return clinicoRangeConfig(prefixo);
  }
  if (tipo === 'patologico') {
    return patologicoRangeConfig(prefixo);
  }
};
