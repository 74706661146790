import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conteudo-relacionado',
  templateUrl: './conteudo-relacionado.component.html'
})
export class ConteudoRelacionadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
