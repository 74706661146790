import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { KtdGridLayout, ktdTrackById } from '@katoid/angular-grid-layout';
import { ResizableItem } from '../../directives/resizable-item.directive';

@Component({
  selector: 'app-painel-desktop-resizable-grid',
  templateUrl: './painel-desktop-resizable-grid.component.html',
})
export class PainelDesktopResizableGridComponent implements AfterContentInit {

  @ContentChildren(ResizableItem) resizableItemDirs: QueryList<ResizableItem>;
  resizableItems: ResizableItem[];
  cols: number = 1;
  rowHeight: number = 45;
  layout: KtdGridLayout = [
    { id: '0', x: 0, y: 0, w: 2, h: 6, maxH: 10, minH: 2 },
    { id: '1', x: 0, y: 4, w: 1, h: 5, maxH: 10, minH: 2 },
  ];

  trackById = ktdTrackById;

  ngAfterContentInit() {
    this.resizableItems = this.resizableItemDirs.toArray();
  }

  onLayoutUpdated(event) {
    // console.log('event', event);
  }

  onDragEnded(event) {
    // console.log('event', event);
  }
}
