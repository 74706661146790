import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (prefixo: string = ''): RangeConfig => ({
  options: [
    {
      label: `${prefixo}Tx`,
      value: `${prefixo}Tx`,
      hint: 'Tumor inacessível ou desconhecido'
    },
    {
      label: `${prefixo}Tis`,
      value: `${prefixo}Tis`,
      hint: 'Carcinoma in situ'
    },
    {
      label: `${prefixo}T1`,
      value: `${prefixo}T1`,
      hint: '≤ 3 cm, sem invasão mais proximal que o brônquio lobar',
      options: [
        {
          label: `${prefixo}T1`,
          value: `${prefixo}T1`,
          hint: '≤ 3 cm, sem invasão mais proximal que o brônquio lobar',
        },
        {
          label: `${prefixo}T1mi`,
          value: `${prefixo}T1mi`,
          hint: 'Adenocarcinoma minimamente invasivo'
        },
        {
          label: `${prefixo}T1a`,
          value: `${prefixo}T1a`,
          hint: '≤ 1 cm'
        },
        {
          label: `${prefixo}T1b`,
          value: `${prefixo}T1b`,
          hint: '> 1 cm, mas ≤ 2 cm'
        },
        {
          label: `${prefixo}T1c`,
          value: `${prefixo}T1c`,
          hint: '> 2 cm, mas ≤ 3 cm'
        }
      ]
    },
    {
      label: `${prefixo}T2`,
      value: `${prefixo}T2`,
      hint: `Tumor > 3 cm, mas ≤ 5 cm, ou com qualquer um dos seguintes:
Envolve brônquio principal ≥ 2 cm distalmente à carina
Invade a pleura visceral
Associado com atelectasia ou pneumonia obstrutiva que se estende à região hilar, mas envolve parte ou todo o pulmão`,
      options: [
        {
          label: `${prefixo}T2`,
          value: `${prefixo}T2`,
          hint: `> 3 cm, mas ≤ 5 cm, ou com qualquer um dos seguintes:
Envolve brônquio principal ≥ 2 cm distalmente à carina
Invade a pleura visceral
Associado com atelectasia ou pneumonia obstrutiva que se estende à região hilar, mas envolve parte ou todo o pulmão`,
        },
        {
          label: `${prefixo}T2a`,
          value: `${prefixo}T2a`,
          hint: '> 3 cm, mas ≤ 4 cm'
        },
        {
          label: `${prefixo}T2b`,
          value: `${prefixo}T2b`,
          hint: '> 4 cm, mas ≤ 5 cm'
        }
      ]
    },
    {
      label: `${prefixo}T3`,
      value: `${prefixo}T3`,
      hint: `> 5 cm mas ≤ 7 cm ou com algum dos seguintes:
Invade a parede torácica (incluindo tumores do sulco superior), nervo frênico, pericárdio parietal
Nódulos tumorais separados no mesmo lobo`
    },
    {
      label: `${prefixo}T4`,
      value: `${prefixo}T4`,
      hint: `Tumor > 7 cm ou com um dos seguintes
Invade o diafragma, o mediastino, o coração, os grandes vasos, a traqueia, o nervo laríngeo recorrente, o esôfago, o corpo vertebral ou a carina
≥ 1 nódulos tumorais satélites em um lobo ipsolateral diferente`
    }
  ]
});
