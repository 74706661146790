export const niveisGamification = {
  'Speaker' : {
    icon: 'speak.svg',
    color: '#2D5BFF'
  },
  'Residente' : {
    icon: 'residente.svg',
    color: '#AEAEEA'
  },
  'Acadêmico' : {
    icon: 'academico.svg',
    color: '#2D5BFF'
  },
  'Preceptor' : {
    icon: 'academico.svg',
    color: '#2D5BFF'
  },
  'Pesquisador' : {
    icon: 'academico.svg',
    color: '#2D5BFF'
  },
  'Lendário' : {
    icon: 'lendario.svg',
    color: '#E8C71E'
  },
  'Staff' : {
    icon: 'staff.svg',
    color: '#21A40C'
  },
};
