import { Component } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';

@Component({
  selector: 'app-select-input',
  templateUrl: './custom-select-input.component.html',
  styleUrls: ['./custom-select-input.component.scss']
})
export class CustomSelectInputComponent extends BaseCustomInputComponent {

}
