import { Component } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';
import { ResponsiveService } from '../../../services/responsive.service';
import { toast } from '../../../util/toast';

@Component({
  selector: 'app-custom-radio-input',
  templateUrl: './custom-radio-input.component.html',
  styleUrls: ['./custom-radio-input.component.scss']
})
export class CustomRadioInputComponent extends BaseCustomInputComponent {

  isMobile: boolean;

  constructor(private responsiveService: ResponsiveService) {
    super();
    this.isMobile = responsiveService.isMobilePlatform;
  }

  async handleHintClick(hint: string) {
    if (this.isMobile) await toast(hint, 'dark', 2000);
  }

  handleRadioChange( event: any) {
    const { value } = event.detail;
    this.value = value;
    this.handleChange(value);
  }
}
