import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_ROUTE_NAVIGATION } from '../../constants/client-route';
import { ModuloService } from '../../services/modulo.service';

@Component({
  selector: 'app-breadcrumb-admin',
  templateUrl: './breadcrumb-admin.component.html',
})
export class BreadcrumbAdminComponent {

  @Input() actualNode: string;
  @Input() detailNode: string;
  @Input() actualNodeRoute: string;
  @Input() previousNodeRoute: any;

  constructor(private router: Router,
              private moduloService: ModuloService) { }

  async handleHomeNodeClick() {
    this.moduloService.clearModuloAtual();
    await this.router.navigate([CLIENT_ROUTE_NAVIGATION.INICIO]);
  }

  async handleActualNodeClick() {
    this.moduloService.clearModuloAtual();
    await this.router.navigate([this.actualNodeRoute]);
  }
  
  async handlePreviousNodeClick() {
    const extras: any = {
      queryParams: {medico: this.previousNodeRoute.id},
      queryParamsHandling: 'replace',
      replaceUrl: true,
    };
    await this.router.navigate([CLIENT_ROUTE_NAVIGATION.MEDICO_VISUALIZAR], extras);
  }
}
