import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ISegment {
  name: string;
  value: string|boolean;
  active?: boolean;
}

@Component({
  selector: 'app-segment-selector',
  templateUrl: './segment-selector.component.html',
  styleUrls: ['./segment-selector.component.scss']
})
export class SegmentSelectorComponent {

  @Input()
  segments: ISegment[];

  @Output()
  onSegmentClick: EventEmitter<ISegment> = new EventEmitter<ISegment>();

  constructor() { }

  handleClickSegment(segment: ISegment) {
    const lastActive = this.segments.find(s => s.active);
    lastActive.active = false;
    segment.active = true;
    this.onSegmentClick.emit(segment);
  }
}
