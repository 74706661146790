import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';

@Component({
  selector: 'app-saiba-tempo-campanha',
  templateUrl: './saiba-tempo-campanha.component.html',
})
export class SaibaTempoCampanhaComponent extends AbstractModal {

  fixedHeight = '320px';

  constructor(public readonly injector: Injector) {
    super(injector);
  }
}
