import { Component, OnDestroy, OnInit } from '@angular/core';
import { loading } from '../../util/loading';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { CadastroPacienteComponent } from '../../modals/cadastro-paciente/cadastro-paciente.component';
import { Router } from '@angular/router';
import { getNavigateRoutePath, ROUTE } from '../../constants/route';
import { FichaService } from '../../services/ficha.service';
import { Ficha } from '../../models/ficha.model';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig, MODULO_CANCER } from '../../constants/modulos-cancer';
import { calculateAge, numUsToBR, removerAcentos } from '../../util/utils';
// import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { RelatorioService } from '../../services/relatorio.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Tab } from '../tabs-component/tabs-component.component';
import { ColumnConfig, TableConfig } from '../custom-table/custom-table.component';
import CondutaUtil from '../../../app/modules/client/modulos-cancer/util/conduta';
import * as moment from 'moment';
import { ImunoHistoquimica } from '../../../app/modules/client/modulos-cancer/mama/util/imuno-histoquimica';
import { inputLabelResolver as inputLabelResolverProstata } from '../../../app/modules/client/modulos-cancer/prostata/util';
import { LABEL_RESOLVER_MAPPING } from '../../constants/label-resolver';
import { SelectionOption, ThreeWayToggleConfig } from '../_base/abstract-input';
import { TelefonePipe } from '../../pipes/telefone.pipe';
import * as saveAs from 'file-saver';
import UsuarioService from '../../services/usuario.service';
import { AuthService } from '../../services/auth.service';
import { Usuario } from '../../models/usuario.model';
import { ConfirmarStaffComponent } from '../../modals/confirmar-staff/confirmar-staff.component';
import { FiltrosAvancadosComponent } from '../../modals/filtros-avancados/filtros-avancados.component';
import CustomAlertHelper from '../../helper/custom-alert-helper';

// SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-painel-principal-desktop',
  templateUrl: './painel-principal-desktop.component.html'
})
export class PainelPrincipalDesktopComponent implements OnInit, OnDestroy {

  staffs: any[];
  usuario: Usuario;
  moduloAtual: IMenuModuloConfig;
  fichas: Ficha[];
  fichasFiltradas: Ficha[];
  fichasFiltradasPendencias: Ficha[];
  $subscriptions: Subscription[] = [];
  ultimoModuloVisitadoFichas: string;
  tabVisivel: Tab;
  filtroColunasVisivel: boolean = false;
  hasAppliedFilters: boolean;

  tipoAtendimentoToggleConfig: ThreeWayToggleConfig = {
    nonValueText: 'Todos',
    negativeValueText: 'Público',
    positiveValueText: 'Privado'
  };

  colunasVisualizacaoFixa = [
    'acoes',
    'nome',
    'instituicao',
    'status'
  ];

  pacientesTableConfigDefault: TableConfig<Ficha> = {
    columns: [
      {
        key: 'acoes',
        type: 'component',
        maxWidth: 40,
        valueResolver: (ficha) => ficha,
        config: {
          component: 'app-paciente-list-actions',
          componentProps: {
            valueChanged: this.handleMarcacaoFichaChange.bind(this)
          },
          componentPropsResolver: (ficha) => ({ ficha }),
          eventPreventDefault: true
        }
      },
      {
        label: 'Nome',
        key: 'nome',
        sortable: true,
        hiddable: true,
        textBold: true,
        textResolver: (ficha) => `${ficha.paciente.nome}`
      },
      {
        label: 'Gênero',
        key: 'genero',
        sortable: true,
        hiddable: true,
        textResolver: (ficha) => {
          if (ficha.paciente.genero === 'M') return 'Masculino';
          if (ficha.paciente.genero === 'F') return 'Feminino';
        }
      },
      {
        label: 'Email',
        key: 'email',
        sortable: true,
        hiddable: true,
        maxWidth: 250,
        textResolver: (ficha) => `${ficha.paciente.email ? ficha.paciente.email : ''}`
      },
      {
        label: 'Telefone',
        key: 'telefone',
        sortable: true,
        hiddable: true,
        maxWidth: 250,
        textResolver: (ficha) => `${ficha.paciente.telefone ? TelefonePipe.transform(ficha.paciente.telefone) : ''}`
      },
      {
        label: 'Idade',
        key: 'idade',
        sortable: true,
        hiddable: true,
        textResolver: (ficha) => {
          return calculateAge(ficha.paciente.dataNascimento);
        }
      },
      {
        label: 'Convênio',
        key: 'convenio',
        sortable: true,
        hiddable: true,
        maxWidth: 200,
        textResolver: (ficha) => `${ficha.paciente.convenio ? ficha.paciente.convenio : 'Não informado'}`
      },
      {
        label: 'Instituição',
        key: 'instituicao',
        sortable: true,
        hiddable: true,
        textResolver: (ficha) => `${ficha.clinica?.nome ? ficha.clinica.nome : 'Não informada'}`
      },
      {
        label: 'Diagnóstico',
        key: 'diagnostico',
        sortable: true,
        hiddable: true,
        maxWidth: 80,
        textResolver: (ficha) => {
          const {itens} = ficha;
          if (itens?.length) {
            const itemDataDiagnostico = itens.find(item => item.chave === 'dataDiagnostico');
            if (itemDataDiagnostico?.valor) {
              const data = new Date(String(itemDataDiagnostico.valor)).toLocaleDateString('pt-BR', {
                timeZone: 'UTC',
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
              });
              return itemDataDiagnostico.valor ? data : '';
            }
          }
          return '';
        },
        sortResolver: (fichaA, fichaB, order) => {
          const itemDataDiagnosticoA = fichaA.itens.find(item => item.chave === 'dataDiagnostico');
          const itemDataDiagnosticoB = fichaB.itens.find(item => item.chave === 'dataDiagnostico');

          if (itemDataDiagnosticoA?.valor && itemDataDiagnosticoB?.valor) {
            if (moment(String(itemDataDiagnosticoA.valor)).isBefore(String(itemDataDiagnosticoB.valor))) {
              return order === 'ASC' ? -1 : 1;
            } else if (moment(String(itemDataDiagnosticoA.valor)).isAfter(String(itemDataDiagnosticoB.valor))) {
              return order === 'ASC' ? 1 : -1;
            } else {
              return 0;
            }
          } else if (!itemDataDiagnosticoA?.valor && itemDataDiagnosticoB?.valor) {
            return 1;
          } else if (itemDataDiagnosticoA?.valor && !itemDataDiagnosticoB?.valor) {
            return -1;
          }
        }
      },
      {
        label: 'Última conduta',
        key: 'ultimaConduta',
        sortable: true,
        hiddable: true,
        maxWidth: 250,
        textResolver: (ficha) => {
          const ultimaConduta: any = (ficha.condutas || [])[ficha.condutas?.length - 1];
          if (ultimaConduta) {
            const data = ultimaConduta.data ? new Date(String(ultimaConduta.data)).toLocaleDateString('pt-BR', {
              timeZone: 'UTC',
              day: '2-digit',
              month: '2-digit',
              year: '2-digit'
            }) : '';
            const label = CondutaUtil.getCondutaLabel(this.moduloAtual.name, ultimaConduta, ficha);
            return `${data}: ${label}`;
          }
          return '';
        },
        sortResolver: (fichaA, fichaB, order) => {
          const ultimaCondutaA: any = (fichaA.condutas || [])[fichaA.condutas?.length - 1];
          const ultimaCondutaB: any = (fichaB.condutas || [])[fichaB.condutas?.length - 1];

          if (ultimaCondutaA?.data && ultimaCondutaB?.data) {
            const labelCondutaA = CondutaUtil.getCondutaLabel(this.moduloAtual.name, ultimaCondutaA, fichaA);
            const labelCondutaB = CondutaUtil.getCondutaLabel(this.moduloAtual.name, ultimaCondutaB, fichaB);

            if (moment(ultimaCondutaA.data).isBefore(ultimaCondutaB.data)) {
              return order === 'ASC' ? -1 : 1;
            } else if (moment(ultimaCondutaA.data).isAfter(ultimaCondutaB.data)) {
              return order === 'ASC' ? 1 : -1;
            } else {
              if (labelCondutaA < labelCondutaB) {
                return order === 'ASC' ? -1 : 1;
              } else if (labelCondutaA > labelCondutaB) {
                return order === 'ASC' ? 1 : -1;
              } else {
                return 0;
              }
            }
          } else if (!ultimaCondutaA?.data && ultimaCondutaB?.data) {
            return 1;
          } else if (ultimaCondutaA?.data && !ultimaCondutaB?.data) {
            return -1;
          }
        }
      },
      {
        label: 'Última sobrevida',
        key: 'ultimaSobrevida',
        sortable: true,
        hiddable: true,
        textResolver: (ficha) => {
          const slps = Object.assign([], (ficha.resumoCaso?.slps || []));
          const sobrevida = slps?.reverse()?.find(slp => slp.label);
          if (sobrevida?.label) {
            const diffEmMeses = moment().diff(sobrevida.data, 'months', true);
            return `${sobrevida.label}: ${numUsToBR(Number(diffEmMeses).toFixed(2))} meses`;
          }
          return '';
        },
        sortResolver: (fichaA, fichaB, order) => {
          const slpsA = Object.assign([], (fichaA.resumoCaso?.slps || []));
          const slpsB = Object.assign([], (fichaB.resumoCaso?.slps || []));
          const sobrevidaA = slpsA?.reverse()?.find(slp => slp.label);
          const sobrevidaB = slpsB?.reverse()?.find(slp => slp.label);

          if (sobrevidaA?.data && sobrevidaB?.data) {
            const diffEmMesesA = moment().diff(sobrevidaA.data, 'months', true);
            const diffEmMesesB = moment().diff(sobrevidaB.data, 'months', true);
            if (sobrevidaA.label < sobrevidaB.label) {
              return order === 'ASC' ? -1 : 1;
            } else if (sobrevidaA.label > sobrevidaB.label) {
              return order === 'ASC' ? 1 : -1;
            } else {
              if (diffEmMesesA < diffEmMesesB) {
                return order === 'ASC' ? -1 : 1;
              } else if (diffEmMesesA > diffEmMesesB) {
                return order === 'ASC' ? 1 : -1;
              } else {
                return 0;
              }
            }
          } else if (!sobrevidaA?.data && sobrevidaB?.data) {
            return 1;
          } else if (sobrevidaA?.data && !sobrevidaB?.data) {
            return -1;
          }
        }
      },
      {
        label: 'Sobrevida global',
        key: 'sobrevidaGlobal',
        sortable: true,
        hiddable: true,
        textResolver: (ficha) => {
          if (ficha.resumoCaso?.sg) {
            const refDate = moment().subtract(ficha.resumoCaso.sg, 'days');
            const diffEmMeses = moment().diff(refDate, 'months', true);
            return `${numUsToBR(Number(diffEmMeses).toFixed(1))} meses`;
          }
        },
        sortResolver: (fichaA, fichaB, order) => {
          const sobrevidaA = fichaA.resumoCaso?.sg;
          const sobrevidaB = fichaB.resumoCaso?.sg;
          if (sobrevidaA && sobrevidaB) {
            if (sobrevidaA < sobrevidaB) {
              return order === 'ASC' ? -1 : 1;
            } else if (sobrevidaA > sobrevidaB) {
              return order === 'ASC' ? 1 : -1;
            } else {
              return 0;
            }
          } else if (!sobrevidaA && sobrevidaB) {
            return 1;
          } else if (sobrevidaA && !sobrevidaB) {
            return -1;
          }
        }
      },
      {
        label: 'Tipo tumoral',
        key: 'tipoTumoral',
        sortable: true,
        hiddable: true,
        textResolver: (ficha) => ImunoHistoquimica.get(ficha.itens)
      },
      {
        label: 'Grupo ISUP',
        key: 'grupoIsup',
        sortable: true,
        hiddable: true,
        textResolver: (ficha) => inputLabelResolverProstata('grupoISUP', ficha.itens)
      },
      {
        label: 'Outros testes',
        key: 'outrosTestes',
        sortable: true,
        hiddable: true,
        maxWidth: 300,
        textResolver: (ficha) => {
          const labelResolverFunc = LABEL_RESOLVER_MAPPING[ficha.modulo.nome];
          return labelResolverFunc('outrosTestes', ficha.itens);
        }
      },
      {
        label: 'Painel NGS',
        key: 'painelNGS',
        sortable: true,
        hiddable: true,
        maxWidth: 300,
        textResolver: (ficha) => {
          const labelResolverFunc = LABEL_RESOLVER_MAPPING[ficha.modulo.nome];
          return labelResolverFunc('painelNGS', ficha.itens);
        }
      },
      {
        label: 'Status',
        key: 'status',
        type: 'component',
        sortable: true,
        sortResolver: (fichaA, fichaB, order = 'ASC') => {
          if (order === 'ASC') {
            return String(fichaA.status?.texto).localeCompare(String(fichaB.status?.texto));
          } else {
            return String(fichaB.status?.texto).localeCompare(String(fichaA.status?.texto));
          }
        },
        config: {
          component: 'status-badge',
          componentProps: {},
          componentPropsResolver: (status) => {
            return {
              cor: status?.cor,
              texto: status?.texto
            }
          }
        }
      },
    ],
    actionButtons: [{iconResolver: {name: 'c-chevron-arrow-right-bold'}}]
  };

  pacientesTableConfig: TableConfig<Ficha>;

  filtroColunasForm: FormGroup = new FormGroup({
    genero: new FormControl(false),
    diagnostico: new FormControl(false),
    ultimaConduta: new FormControl(false),
    ultimaSobrevida: new FormControl(false),
    sobrevidaGlobal: new FormControl(false),
    outrosTestes: new FormControl(false),
    painelNGS: new FormControl(false),
    email: new FormControl(false),
    telefone: new FormControl(false),
    idade: new FormControl(false),
    convenio: new FormControl(false),
    grupoIsup: new FormControl(false),
    tipoTumoral: new FormControl(false),
  });

  colunasExtrasPacientes: SelectionOption[] = [
    {
      nome: 'Gênero',
      valor: 'genero'
    },
    {
      nome: 'Diagnóstico',
      valor: 'diagnostico'
    },
    {
      nome: 'Última conduta',
      valor: 'ultimaConduta'
    },
    {
      nome: 'Email',
      valor: 'email'
    },
    {
      nome: 'Telefone',
      valor: 'telefone'
    },
    {
      nome: 'Idade',
      valor: 'idade'
    },
    {
      nome: 'Convênio',
      valor: 'convenio'
    },
    // {
    //   nome: 'Última sobrevida',
    //   valor: 'ultimaSobrevida'
    // },
    // {
    //   nome: 'Sobrevida global',
    //   valor: 'sobrevidaGlobal'
    // },
    {
      nome: 'Outros testes',
      valor: 'outrosTestes',
      custom: {
        modulos: [MODULO_CANCER.MAMA, MODULO_CANCER.PROSTATA]
      }
    },
    {
      nome: 'Painel NGS',
      valor: 'painelNGS',
      custom: {
        modulos: [MODULO_CANCER.PULMAO_NSCLC]
      }
    },
    {
      nome: 'Tipo tumoral',
      valor: 'tipoTumoral',
      custom: {
        modulos: [MODULO_CANCER.MAMA]
      }
    },
    {
      nome: 'Grupo ISUP',
      valor: 'grupoIsup',
      custom: {
        modulos: [MODULO_CANCER.PROSTATA]
      }
    }
  ];

  colunasExtrasVisiveis = [];

  tabsConfig: Tab[] = [
    {
      id: 'pacientes',
      name: 'PACIENTES',
      active: true
    },
    {
      id: 'pendencias',
      name: 'PENDÊNCIAS'
    },
  ];

  constructor(private fichaService: FichaService,
              private modalHelper: CustomModalHelper,
              private moduloService: ModuloService,
              private router: Router,
              private relatorioService: RelatorioService,
              private usuarioService: UsuarioService,
              private authService: AuthService,
              private alertHelper: CustomAlertHelper) {
    this.usuario = this.authService.getUser();
  }

  ngOnInit() {
    this.loadStaffsIfResidente();
    this.loadTabsConfig();
    this.loadCurrentActiveTab();
    this.loadFiltrosColunaConfig();
    this.$subscriptions = [
      this.moduloService.$moduloAtual.subscribe((modulo) => {
        this.moduloAtual = modulo;
        this.clearFichaList();
        this.loadTable();
        this.loadFichasModulo.bind(this)();
      }),
      this.fichaService.$filtrosAvancados.subscribe(() => {
        this.clearFichaList();
        this.loadFichasModulo.bind(this)(true);
      })
    ];
    this.loadFormListeners();
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  loadStaffsIfResidente() {
    if (this.usuario.perfil === 'residente') {
      this.usuarioService.listarStaffs().subscribe((staffs) => this.staffs = staffs);
    }
  }

  loadTabsConfig() {
    if (this.usuario.perfil === 'medico') {
      this.tabsConfig = [].concat(this.tabsConfig, [
        {
          id: 'graficos',
          name: 'GRÁFICOS'
        },
        {
          id: 'campanhas',
          name: 'CAMPANHAS'
        },
        // {
        //   id: 'dashboard',
        //   name: 'DASHBOARD'
        // },
        {
          id: 'notificacoes',
          name: 'NOTIFICAÇÕES'
        }
      ]);
    }
  }

  loadCurrentActiveTab() {
    const tabId = this.usuarioService.getCurrentTabPainelPrincipal();
    if (tabId) this.tabsConfig.forEach((tab) => tab.active = tab.id === tabId);
    this.tabVisivel = this.tabsConfig.find(t => t.active);
  }

  loadFiltrosColunaConfig() {
    const config = this.usuarioService.buscarEstadoTabelaPainelPrincipal();
    this.filtroColunasForm.patchValue(config);
    this.colunasExtrasVisiveis = Object.entries(config)
      .filter(([chave, valor]) => valor)
      .map(([chave, valor]) => chave);
  }

  loadFormListeners() {
    this.filtroColunasForm.valueChanges.subscribe((values) => {
      this.usuarioService.salvarEstadoTabelaPainelPrincipal(values);
      this.colunasExtrasVisiveis = Object.entries(values)
        .filter(([coluna, value]) => value)
        .map(([coluna]) => coluna);
      this.loadTable();
    });
  }

  loadTable() {
    const columns: ColumnConfig<Ficha>[] = this.pacientesTableConfigDefault.columns.filter((columnConfig) => {
      if (this.colunasVisualizacaoFixa.includes(columnConfig.key)) {
        return true;
      } else if (this.colunasExtrasVisiveis.includes(columnConfig.key)) {
        return this.isOptionsAvailable(columnConfig.key);
      }
    });
    const newTableConfig: TableConfig<Ficha> = Object.assign({}, this.pacientesTableConfigDefault);
    newTableConfig.columns = columns;
    this.pacientesTableConfig = newTableConfig;
  }

  async handleResetFiltersClick() {
    this.fichaService.clearFiltrosAvancados(this.moduloAtual.name);
    this.fichaService.clearFiltrosAvancadosFormValues(this.moduloAtual.name);
  }

  handleTabClick(tab: Tab) {
    this.tabVisivel = tab;
    this.usuarioService.setCurrentTabPainelPrincipal(tab.id);
    this.handlePesquisaPacienteChange({});
  }

  async loadFichasModulo(forceUpdate?: boolean) {
    if (this.moduloAtual?.name === this.ultimoModuloVisitadoFichas && !forceUpdate) return;
    if (!this.moduloAtual) {
      return;
    }
    let filtro = this.fichaService.getFiltrosAvancados(this.moduloAtual.name);
    filtro = filtro?.length ? filtro : null;
    this.hasAppliedFilters = Boolean(filtro);
    const subscription = this.fichaService.getListaFichas( { moduloId: this.moduloAtual.id }, filtro)
      .subscribe((fichas: any) => {
        this.fichas = fichas.sort((a: Ficha, b: Ficha) => {
          return a.paciente.nome.localeCompare(b.paciente.nome);
        });
        this.fichasFiltradas = Object.assign([], this.fichas);
        this.fichasFiltradasPendencias = this.getFichasComPendencias(this.fichas);
        this.ultimoModuloVisitadoFichas = this.moduloAtual.name;
      });
    if (this.tabVisivel?.id !== 'graficos') await loading(subscription);
  }

  async handleCadastrarPacienteClick() {
    const cadastrarPacienteModal = await this.modalHelper.create({
      component: CadastroPacienteComponent,
      componentProps: {}
    });
    if (this.usuario.perfil === 'residente' && this.staffs?.length > 1) {
      const modalConfirmarStaff = await this.modalHelper.create({
        component: ConfirmarStaffComponent,
        componentProps: {},
        handler: async ({data}) => {
          if (data.confirm) {

          } else {
            await cadastrarPacienteModal.present();
          }
        }
      });
      await modalConfirmarStaff.present();
    } else {
      await cadastrarPacienteModal.present();
    }
  }

  async handlePacienteRowClick(ficha: Ficha, pendencia = false) {
    const navigationPath = getNavigateRoutePath(this.moduloAtual.name);
    const extras: any = {
      state: {pendencia},
      queryParams: { ficha: ficha._id },
      queryParamsHandling: 'replace',
      replaceUrl: true,
    };
    if (pendencia) extras.queryParams.pendencia = true;
    await this.router.navigate([navigationPath.FICHA_PACIENTE], extras);
  }

  async handleVerTodosClick(pendencias = false) {
    const extras: any = {
      queryParams: {},
      queryParamsHandling: 'replace',
      replaceUrl: true,
    };
    this.moduloService.clearModuloAtual();
    if (pendencias) extras.queryParams.pendencias = pendencias;
    await this.router.navigate([ROUTE.PESQUISAR_PACIENTES], extras);
  }

  handleFiltrosColunasToggle() {
    this.filtroColunasVisivel = !this.filtroColunasVisivel;
  }

  handlePesquisaPacienteChange(event) {
    const pesquisaTermo = String((event?.detail?.value || '')).toUpperCase();
    if (!pesquisaTermo) {
      this.fichasFiltradas = this.fichas;
      this.fichasFiltradasPendencias = this.getFichasComPendencias(this.fichas);
    } else {
      const termo = removerAcentos(String(pesquisaTermo));
      this.fichasFiltradas = this.fichas.filter(ficha => {
        const nomePaciente = removerAcentos(String(ficha.paciente.nome));
        return nomePaciente.includes(termo);
      });
      this.fichasFiltradasPendencias = this.getFichasComPendencias(this.fichasFiltradas);
    }
  }

  getFichasComPendencias(fichas: Ficha[]): Ficha[] {
    const statusFichaPendencias = [
      'Atualizar conduta',
      'Inserir data do diagnóstico',
      'Inserir instituição',
    ];
    return fichas.filter(ficha => {
      return (
        statusFichaPendencias.includes(ficha.status?.texto) ||
        !!ficha.marcacao
      );
    });
  }

  async handleExportarPlanilhaClick() {
   loading( this.relatorioService.getPlanilhaPacientes(this.moduloAtual.name).subscribe((blob: any) => {
     const date = new Date().toLocaleDateString().replace(/\//g, '_');
     const time = new Date().toLocaleTimeString().replace(/:/g, '_')
     const filename = `pacientes_${date}_${time}.xlsx`;
     saveAs(blob, filename);
   }));
  }

  isOptionsAvailable(optionName: string) {
    const config = this.colunasExtrasPacientes.find(coluna => coluna.valor === optionName);
    if (config.custom?.modulos) return config.custom.modulos.includes(this.moduloAtual?.name);
    return true;
  }

  clearFichaList() {
    this.fichas = [];
    this.fichasFiltradas = [];
    this.fichasFiltradasPendencias = [];
  }

  handleMarcacaoFichaChange({ value }) {
    const { id: idFicha, marcacao } = value;
    const atualizarMarcacaoFicha = (fichasAttrName: string) => {
      const fichas = this[fichasAttrName]
      const index = fichas.findIndex(ficha => ficha._id === idFicha);
      if (index >= 0) {
        const ficha = fichas[index];
        ficha.marcacao = marcacao;
        fichas.splice(index, 1, ficha);
        this[fichasAttrName] = [].concat(fichas);
      }
      this.fichasFiltradasPendencias = this.getFichasComPendencias(this.fichas);
    };
    [
      'fichas',
      'fichasFiltradas',
      'fichasFiltradasPendencias',
    ].forEach(atualizarMarcacaoFicha);
  }

  async handleFiltrosAvancadosClick() {
    const modal = await this.modalHelper.create({
      component: FiltrosAvancadosComponent,
      componentProps: {}
    });
    await modal.present();
  }
}
