import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-vertical-legend',
  templateUrl: './timeline-vertical-legend.component.html',
})
export class TimelineVerticalLegendComponent {

  @Input() sle: boolean = true;
  @Input() sld: boolean = true;
  @Input() slp: boolean = true;
  @Input() sg: boolean = true;

  constructor() { }
}
