import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { Grupo } from '../models/clinica.model';
import { Observable } from 'rxjs';
import { encodeCnpj } from '../util/utils';

@Injectable({
  providedIn: 'root'
})
export class GrupoService extends AbstractService<Grupo> {

  constructor(public http: HttpClient) {
    super('grupo', http);
  }

  getByCpnj(cnpj: string): Observable<Grupo|any> {
    return super.get(`cnpj/${encodeCnpj(cnpj)}`);
  }

  buscar(busca: string) {
    return super.get(null, { busca });
  }
}

