import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { Ficha } from '../../models/ficha.model';
import { IMenuModuloConfig, menuModulosConfig } from '../../constants/modulos-cancer';
import { ModuloService } from '../../services/modulo.service';

@Component({
  selector: 'app-paciente-row-list',
  templateUrl: './paciente-row-list.component.html',
  styleUrls: ['./paciente-row-list.component.scss']
})
export class PacienteRowListComponent implements AfterViewInit {

  isMobile: boolean;
  moduloConfig: IMenuModuloConfig;

  @Input() ficha: Ficha;
  @Input() pendencias: boolean;
  @Input() nomeModulo: string;
  @Output() rowClick: EventEmitter<Ficha> = new EventEmitter<Ficha>();
  @ViewChild('pacienteRowRef') pacienteRowRef: any;

  constructor(private responsiveService: ResponsiveService,
              private moduloService: ModuloService) {
    this.isMobile = responsiveService.isMobilePlatform;
  }

  ngOnInit() {
    this.moduloConfig = this.moduloService.findModuloByName(this.nomeModulo, menuModulosConfig);
  }

  ngAfterViewInit() {
    this.pacienteRowRef.nativeElement.style.setProperty('--before-status-color', this.ficha?.status?.cor);
  }

  handleRowClick() {
    this.rowClick.emit(this.ficha);
  }
}
