import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { Pergunta } from '../../models/pergunta';

@Component({
  selector: 'app-criar-nova-pergunta',
  templateUrl: './criar-nova-pergunta.component.html',
})
export class CriarNovaPerguntaComponent extends AbstractModal implements OnInit {

  offsetHeight = 10;
  fixedWidth = '1000px';
  modulo: string;
  pergunta: Pergunta;
  form: FormGroup = new FormGroup({
    id: new FormControl(null),
    tags: new FormControl([], Validators.required),
    pergunta: new FormControl('', [Validators.required]),
    explicacao: new FormControl('', [Validators.required]),
    opcao1: new FormControl('', Validators.required),
    opcao2: new FormControl('', Validators.required),
    opcao3: new FormControl('', Validators.required),
    opcao4: new FormControl('', Validators.required),
    opcao5: new FormControl('', Validators.required),
  });

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.form.patchValue({ ...this.pergunta });
  }

  async handleConfirmarClick() {
    const pergunta = this.form.getRawValue();
    await this.handleCLickClose(true, { pergunta });
  }
}
