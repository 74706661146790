export class ImunoHistoquimica {

  static get(itens) {
    const chavesImuniHistoquimica = ['re', 'rp', 'her2', 'ki67', 'fish'];
    if (!itens) { return; }
    const props = itens.reduce((acc, curr) => {
      if (chavesImuniHistoquimica.includes(curr.chave)) {
        if (curr.analitico !== false) {
          acc[curr.chave] = curr.valor;
        }
      } else {
        acc[curr.chave] = curr.valor;
      }
      return acc;
    }, {});
    const { re, rp, ki67, her2, fish } = props;
    if (
      (re < 10) &&
      (rp < 10) &&
      (ki67 >= 0) &&
      (
        ['0', '+1'].includes(her2) ||
        (fish === 'FISH-' && her2 === '+2')
      )
    ) {
      return 'Triplo negativo';
    }
    if (
      (
        her2 === '+3' ||
        (fish === 'FISH+' && her2 === '+2')
      ) ||
      (re >= 0 || re === 'positivo') &&
      (rp >= 0 || re === 'positivo') &&
      (ki67 >= 0) &&
      (
        her2 === '+3' ||
        (fish === 'FISH+' && her2 === '+2')
      )
    ) {
      return 'HER-2';
    }
    if (
      (re >= 90) &&
      (rp >= 90) &&
      (ki67 <= 10) &&
      (
        ['0', '+1'].includes(her2) ||
        (fish === 'FISH-' && her2 === '+2')
      )
    ) {
      return 'Luminal A';
    }
    if (
      (re >= 10 && re <= 90) ||
      (rp >= 10 && rp <= 90) &&
      (
        ['0', '+1'].includes(her2) ||
        (fish === 'FISH-' && her2 === '+2')
      ) ||
      (
        (re >= 90) ||
        (rp >= 90) &&
        (ki67 > 10)
      )
    ) {
      return 'Luminal B';
    }
    return 'Desconhecido';
  }
}
