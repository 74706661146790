import { Component, Injector } from '@angular/core';
import { ContaInstitucional, Staff } from '../../models/clinica.model';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { TableConfig } from '../../components/custom-table/custom-table.component';

@Component({
  selector: 'app-editar-conta-institucional',
  templateUrl: './editar-conta-institucional.component.html',
})
export class EditarContaInstitucionalComponent extends AbstractModal {

  instituicao: ContaInstitucional;
  tableConfig: TableConfig<Staff> = {
    columns: [
      {

      }
    ],
    actionButtons: [{ iconResolver: { name: 'c-chevron-arrow-right-bold'} }]
  };

  constructor(public readonly injector: Injector) {
    super(injector);
  }

}
