import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (): RangeConfig => ({
  options: [
    {
      label: 'Mx',
      value: 'Mx',
      hint: 'Metástases à distância não avaliadas'
    },
    {
      label: 'M0',
      value: 'M0',
      hint: 'Sem metástase à distância'
    },
    {
      label: 'M1',
      value: 'M1',
      hint: 'Metástase à distância',
      options: [
        {
          label: 'M1',
          value: 'M1',
          hint: 'Metástase à distância',
        },
        {
          label: 'M1a',
          value: 'M1a',
          hint: 'Linfonodos a distância (não regionais)',
        },
        {
          label: 'M1b',
          value: 'M1b',
          hint: 'Osso',
        },
        {
          label: 'M1c',
          value: 'M1c',
          hint: 'Outro sítio com ou sem doença óssea',
        }
      ]
    }
  ]
});
