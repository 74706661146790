const LABEL_DESCONHECIDO = 'Desconhecido';

export class GrupoTNM {

  static mapping = {
    Tis: {
      N0: {
        M0: '0'
      },
      N1: {
        M0: 'IIIA'
      },
      N1a: {
        M0: 'IIIA'
      },
      N1b: {
        M0: 'IIIA'
      },
      N1c: {
        M0: 'IIIA'
      },
      N2a: {
        M0: 'IIIA'
      },
      N2b: {
        M0: 'IIIB'
      }
    },
    T1: {
      N0: {
        M0: 'I'
      },
      N1: {
        M0: 'IIIA'
      },
      N1a: {
        M0: 'IIIA'
      },
      N1b: {
        M0: 'IIIA'
      },
      N1c: {
        M0: 'IIIA'
      },
      N2a: {
        M0: 'IIIA'
      },
      N2b: {
        M0: 'IIIB'
      }
    },
    T2: {
      N0: {
        M0: 'I'
      },
      N1: {
        M0: 'IIIA'
      },
      N1a: {
        M0: 'IIIA'
      },
      N1b: {
        M0: 'IIIA'
      },
      N1c: {
        M0: 'IIIA'
      },
      N2a: {
        M0: 'IIIB'
      },
      N2b: {
        M0: 'IIIB'
      }
    },
    T3: {
      N0: {
        M0: 'IIA'
      },
      N1: {
        M0: 'IIIB'
      },
      N1a: {
        M0: 'IIIB'
      },
      N1b: {
        M0: 'IIIB'
      },
      N1c: {
        M0: 'IIIB'
      },
      N2a: {
        M0: 'IIIB'
      },
      N2b: {
        M0: 'IIIC'
      }
    },
    T4a: {
      N0: {
        M0: 'IIB'
      },
      N1: {
        M0: 'IIIB'
      },
      N1a: {
        M0: 'IIIB'
      },
      N1b: {
        M0: 'IIIB'
      },
      N1c: {
        M0: 'IIIB'
      },
      N2a: {
        M0: 'IIIC'
      },
      N2b: {
        M0: 'IIIC'
      }
    },
    T4b: {
      N0: {
        M0: 'IIC'
      },
      N1: {
        M0: 'IIIC'
      },
      N1a: {
        M0: 'IIIC'
      },
      N1b: {
        M0: 'IIIC'
      },
      N1c: {
        M0: 'IIIC'
      },
      N2: {
        M0: 'IIIC'
      },
      N2a: {
        M0: 'IIIC'
      },
      N2b: {
        M0: 'IIIC'
      },
      N2c: {
        M0: 'IIIC'
      },
    },
  };

  static calcular(tValue, nValue, mValue) {
    tValue = tValue?.startsWith('yp') ? tValue?.substr(2) : tValue?.substr(1);
    nValue = nValue?.startsWith('yp') ? nValue?.substr(2) : nValue?.substr(1);
    try {
      const grupoTNMDefault = GrupoTNM.mapping[tValue][nValue][mValue];
      if (grupoTNMDefault) return grupoTNMDefault;
      else throw new Error();
    } catch (e) {
      if (tValue && nValue && mValue === 'M1a') {
        return 'IVA';
      } else if (tValue && nValue && mValue === 'M1b') {
        return 'IVB';
      } else if (tValue && nValue && mValue === 'M1c') {
        return 'IVC';
      } else {
        return LABEL_DESCONHECIDO;
      }
    }
  }
}
