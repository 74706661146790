import { ItemFicha } from '../../../../../shared/models/ficha.model';

export abstract class AbstractLabelResolver {

  static histopatologico = (items: ItemFicha[]): string => {
    const itemHistopatologico = items?.find((item) => item.chave === 'histopatologico');
    if (itemHistopatologico?.valor) return `${itemHistopatologico.valor}`;
    else return '';
  };

  static grau = (items: ItemFicha[]): string => {
    const itemGrau = items?.find((item) => item.chave === 'grau');
    if (itemGrau?.valor) return `${itemGrau?.valor}`;
    return '';
  };

  static dataDiagnostico = (items: ItemFicha[]): string => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    if (itemDataDiagnostico?.valor)
      return `${new Date(String(itemDataDiagnostico.valor)).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}`;
    return '';
  };

  static antecedentesInformacoes = (items: ItemFicha[]): string => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    if (itemAntecedentesInformacoes?.valor) return `${itemAntecedentesInformacoes.valor}`;
    return '';
  };

  static interacoesMedicamentosas = (items: ItemFicha[], index: number): string => {
    const itemInteracoesMedicamentosas = items?.find((item) => item.chave === 'interacoesMedicamentosas');
    if (itemInteracoesMedicamentosas?.valor) {
      const { medicamentosSelecionados } = itemInteracoesMedicamentosas.valor;
      return `${medicamentosSelecionados.map(m => m.nome).join(', ')}`;
    }
    return '';
  };
}

export abstract class AbstractValueResolver {

  static histopatologico = (items: ItemFicha[]): any => {
    const itemHistopatologico = items?.find((item) => item.chave === 'histopatologico');
    return {
      histopatologico: itemHistopatologico?.valor,
      id: itemHistopatologico?.id
    };
  };

  static grau = (items: ItemFicha[]): any => {
    const itemGrau = items?.find((item) => item.chave === 'grau');
    return {
      grau: itemGrau?.valor,
      id: itemGrau?.id
    };
  };

  static dataDiagnostico = (items: ItemFicha[]): any => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    return {
      dataDiagnostico: itemDataDiagnostico?.valor,
      id: itemDataDiagnostico?.id
    };
  };

  static antecedentesInformacoes = (items: ItemFicha[]): any => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    return {
      antecedentesInformacoes: itemAntecedentesInformacoes?.valor,
      id: itemAntecedentesInformacoes?.id,
    };
  };

  static interacoesMedicamentosas = (items: ItemFicha[], index: number): any => {
    const itemInteracoesMedicamentosas = items?.find((item) => item.chave === 'interacoesMedicamentosas');
    if (itemInteracoesMedicamentosas) {
      return Object.assign({}, {
        id: itemInteracoesMedicamentosas.id
      }, itemInteracoesMedicamentosas.valor);
    } else {
      return {};
    }
  };
}
