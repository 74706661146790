import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Usuario } from '../models/usuario.model';
import { AbstractService } from './abstract.service';
import { ONCOMAX_INSTITUICAO_ATUAL } from './clinica.service';

export const TOKEN_AUTH = '_tk_auth_oncomax';
export const ONCOMAX_USER = '_user_oncomax';
export const ONCOMAX_LAST_USER = '_last_user_oncomax';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractService<Usuario> {

  $userData: BehaviorSubject<Usuario> = new BehaviorSubject(null);
  jwtHelper = new JwtHelperService();

  constructor(public http: HttpClient) {
    super('auth-admin', http);
  }

  isAuthenticated() {
    const token = this.getToken();
    return !!token;
  }

  async setUser(user: Usuario) {
    await localStorage.setItem(ONCOMAX_USER, JSON.stringify(user));
    await localStorage.setItem(ONCOMAX_LAST_USER, user.email);
    this.$userData.next(user)
  }

  getUser(): Usuario {
    if (!localStorage.getItem(ONCOMAX_USER)) {
      return null;
    }
    return JSON.parse(localStorage.getItem(ONCOMAX_USER));
  }

  getLastUserMail() {
    return localStorage.getItem(ONCOMAX_LAST_USER);
  }

  setToken(token: string) {
    return localStorage.setItem(TOKEN_AUTH, token);
  }

  getToken(): any {
    return localStorage.getItem(TOKEN_AUTH);
  }

  async clearAuthorizationToken() {
    localStorage.removeItem(TOKEN_AUTH);
    localStorage.removeItem(ONCOMAX_USER);
    localStorage.removeItem(ONCOMAX_INSTITUICAO_ATUAL);
  }

  static getUserInstituicoes() {
    if (!localStorage.getItem(ONCOMAX_USER)) {
      return [];
    }
    const user: Usuario = JSON.parse(localStorage.getItem(ONCOMAX_USER));
    return user.clinicaList;
  }
}

