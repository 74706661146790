import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (): RangeConfig => ({
  options: [
    {
      label: 'Mx',
      value: 'Mx',
      hint: 'Metástases desconhecido'
    },
    {
      label: 'M0',
      value: 'M0',
      hint: 'Ausência de metástases'
    },
    {
      label: 'M1',
      value: 'M1',
      hint: 'Metástases a distância'
    }
  ]
});
