import { EChartsOption } from 'echarts';
import { CHART_LABEL_ALIASES } from '../constants/label-resolver';

export const getDoughtnutChartOptions = (source, color, tooltipPosition: any = 'right', title?: string, animation: boolean = true, options?: any): EChartsOption => {
  const documentStyle = getComputedStyle(document.documentElement);
  const chartLabelTextColor = documentStyle.getPropertyValue('--om-grey-primary').trim();
  let legend;

  if (options?.hasOwnProperty('legend')) {
    legend = options.legend;
  } else {
    legend = true;
  }

  const chartConfig: any = {
    title: {
      text: title,
      left: 'center',
      textStyle: {
        color: chartLabelTextColor
      }
    },
    dataset: {
      source,
    },
    tooltip: {
      position: tooltipPosition,
      formatter: (params) => {
        return `${params.data.label}: <b>${params.value.value}</b> (${params.percent}%)`
      }
    },
    color,
    grid: {
      top: 0,
      containLabel: false,
    },
    series: [
      {
        animation: false,
        name: 'Casos',
        type: 'pie',
        radius: ['58%%', '85%'],
        avoidLabelOverlap: false,
        height: legend ? '70%' : '100%',
        top: 10,
        itemStyle: {
          borderRadius: 6,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: true,
          position: 'center',
          fontSize: 16,
          fontFamily: 'Roboto',
          width: 90,
          overflow: 'break',
          color: chartLabelTextColor,
          formatter: () => {
            const value = source.reduce((acumulattor, tuple) => acumulattor + tuple.value, 0);
            return `{count|${value}}`;
          },
          rich: {
            count: {
              color: chartLabelTextColor,
              lineHeight: 48,
              fontSize: 30,
              height: 100,
              fontFamily: 'Roboto',
              fontWeight: 600
            }
          }
        },
        labelLine: {
          show: false
        }
      }
    ]
  };

  if (legend) {
    chartConfig.legend = {
      top: '70%',
      left: 'center',
      orient: 'vertical',
      itemWidth: 12,
      itemHeight: 12,
      padding: [10, 0],
      textStyle: {
        fontSize: 12
      },
      formatter: name => CHART_LABEL_ALIASES[name] ? CHART_LABEL_ALIASES[name] : name
    };
  }
  return chartConfig;
};
