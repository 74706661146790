import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { SelectionOption } from '../../components/_base/abstract-input';
import { AuthService } from '../../services/auth.service';
import { Usuario } from '../../models/usuario.model';
import { FormControl } from '@angular/forms';
import { Clinica } from '../../models/clinica.model';
import { ClinicaService } from '../../services/clinica.service';

@Component({
  selector: 'app-selecionar-clinica-atual',
  templateUrl: './selecionar-clinica-atual.component.html',
})
export class SelecionarClinicaAtualComponent extends AbstractModal implements OnInit {

  fixedHeight = '270px';
  fixedWidth = '420px';
  instituicoesOptions: SelectionOption[];
  usuario: Usuario;
  instituicaoAtual: Clinica;
  control: FormControl = new FormControl({});

  constructor(public readonly injector: Injector,
              private authService: AuthService,
              private clinicaService: ClinicaService) {
    super(injector);
  }

  ngOnInit() {
    this.usuario = this.authService.getUser();
    this.instituicaoAtual = this.clinicaService.getInstituicaoAtual();
    this.instituicoesOptions = this.usuario.clinicaList.map(clinica => ({
      nome: clinica.nome,
      valor: clinica._id
    }));
    this.control.setValue(this.instituicaoAtual._id);
  }

  handleConfirmClick() {
    if (this.control.value !== this.instituicaoAtual._id) {
      const instituicaoSelecionada = this.usuario.clinicaList.find(clinica => clinica._id === this.control.value);
      this.clinicaService.setInstituicaoAtual(instituicaoSelecionada);
      this.modalController.dismiss();
    }
  }
}
