import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../interfaces/input-with-modal';
import { ResponsiveService } from '../../../services/responsive.service';
import { inputValueResolver } from '../../../../app/modules/client/modulos-cancer/mama/util';
import { ItemFicha } from '../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-sexo',
  templateUrl: './input-sexo.component.html',
})
export class InputSexoComponent extends AbstractModal implements InputWithModal {
  
  fichaPaciente: any;
  id: string;
  offsetWidth = 10;
  fixedHeight = '245px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  
  
  form: FormGroup = new FormGroup({
    sexo: new FormControl('')
  });
  
  
  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService) {
    super(injector);
  }
  
  ngOnInit() {
    const { sexo, id } = inputValueResolver('sexo', this.fichaPaciente?.itens);
    this.id = id;
    if (sexo !== undefined) this.form.patchValue({ sexo });
  }
  
  async handleEndEditClick() {
    if (this.form.get('sexo').pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: 'sexo',
        valor: this.form.get('sexo').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
