import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (prefixo: string = ''): RangeConfig => ({
  options: [
    {
      label: `${prefixo}Tx`,
      value: `${prefixo}Tx`,
      hint: 'Tumor primário não avaliado'
    },
    {
      label: `${prefixo}T0`,
      value: `${prefixo}T0`,
      hint: 'Sem evidência de tumor primário'
    },
    {
      label: `${prefixo}T1`,
      value: `${prefixo}T1`,
      hint: 'Tumor com até 2 cm na maior dimensão'
    },
    {
      label: `${prefixo}T2`,
      value: `${prefixo}T2`,
      hint: 'Tumor maior que 2 cm, mas até 5 cm na maior dimensão'
    },
    {
      label: `${prefixo}T3`,
      value: `${prefixo}T3`,
      hint: 'Tumor maior que 5 cm na maior dimensão'
    },
    {
      label: `${prefixo}T4`,
      value: `${prefixo}T4`,
      hint: 'Tumor de qualquer tamanho invade órgãos adjacentes, como vagina, uretra ou bexiga'
    }
  ]
});
