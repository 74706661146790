import { Component, OnInit } from '@angular/core';
import { CollapseItem } from '../../../arvore-exames/arvore-exames.component';
import AbstractTabFichaPaciente from '../../_base/abstract-tab-ficha-paciente';
import CustomModalHelper from '../../../../helper/custom-modal-helper';

@Component({
  selector: 'app-tab-anatomo-patologia',
  templateUrl: './tab-anatomo-patologia.component.html',
})
export class TabAnatomoPatologiaComponent extends AbstractTabFichaPaciente implements OnInit {

  exames: CollapseItem[] = [
    {
      label: 'Anatomopatológico (Biopsia)',
      count: 1,
      items: []
    },
    {
      label: 'Exame Imuno-histoquímico',
      count: 8,
      items: []
    },
    {
      label: 'Anatomopatológico (Cirurgia)',
      count: 2,
      items: []
    },
    {
      label: 'Citopatologia',
      count: 5,
      items: []
    }
  ];

  constructor(public modalHelper: CustomModalHelper) {
    super(modalHelper);
  }

  ngOnInit() {}
}
