import { Component, OnInit } from '@angular/core';
import AbstractTabFichaPaciente from '../../_base/abstract-tab-ficha-paciente';
import CustomModalHelper from '../../../../helper/custom-modal-helper';
import { CollapseItem } from '../../../arvore-exames/arvore-exames.component';

@Component({
  selector: 'app-tab-imagem-direta',
  templateUrl: './tab-imagem-direta.component.html',
})
export class TabImagemDiretaComponent extends AbstractTabFichaPaciente implements OnInit {

  exames: CollapseItem[] = [
    {
      label: 'Endoscopia',
      count: 4,
      items: [
        {
          label: 'Título 1',
          count: 4,
        },
        {
          label: 'Título 2',
          count: 0,
        },
        {
          label: 'Título 3',
          count: 0,
        }
      ]
    },
    {
      label: 'Colonoscopia',
      count: 2,
      items: []
    },
    {
      label: 'Broncoscopia',
      count: 0,
      items: []
    },
    {
      label: 'CPRE',
      count: 0,
      items: []
    },
    {
      label: 'Citoscopia',
      count: 0,
      items: []
    },
    {
      label: 'Laparoscopia',
      count: 0,
      items: []
    },
    {
      label: 'Histeroscopia',
      count: 0,
      items: []
    },
    {
      label: 'Colposcopia',
      count: 0,
      items: []
    },
    {
      label: 'Nasofibrolaringoscopia',
      count: 0,
      items: []
    },
    {
      label: 'Ultrassonografica endoscópica',
      count: 0,
      items: []
    },
    {
      label: 'Anuscopia',
      count: 0,
      items: []
    }
  ];

  constructor(public modalHelper: CustomModalHelper) {
    super(modalHelper);
  }

  ngOnInit() {}

}
