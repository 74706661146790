import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InteracaoMedicamentosaService extends AbstractService<any> {

  constructor(public http: HttpClient) {
    super('interacao-medicamentosa', http);
  }

  buscaPorTermo(termo: string) {
    return super.get(`busca-termo`, { termo });
  }

  interacaoListaMedicamentos(identificadores: string[]) {
    return super.get(`interacao-lista-medicamentos`, { identificadores });
  }


}

