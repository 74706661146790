import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ficha } from '../models/ficha.model';
import { RelatorioResult } from '../models/relatorio.model';
import { getAppUrl } from '../util/utils';
import { FilterAvancado } from '../interfaces/filter-config';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService extends AbstractService<any> {

  constructor(public http: HttpClient) {
    super('relatorio', http);
  }

  getRelatorioCasuistica(filters): Observable<RelatorioResult> {
    return super.post(filters, 'casuistica');
  }

  getCountCasuistica(filters) {
    return super.post(filters, 'casuistica/count');
  }

  getResumoCaso(ficha: Ficha) {
    return super.get(`ficha/${ficha._id}/resumo`);
  }

  getDadosMiniDashboard(modulo: string, filtro?: FilterAvancado[]) {
    const body = filtro ? { filtro } : null;
    return super.post(body,`pie/${modulo}`);
  }

  getPlanilhaPacientes(modulo: string) {
    const options: any = {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    };
    return this.http.get<any>(`${getAppUrl()}/relatorio/pacientes/${modulo}`, options);
  }

  verifyUrlDashboard(modulo: string) {
    return this.http.get<any>(`https://intelli.brintell.com/dashboard-oncomax/${modulo}`);
  }
}

