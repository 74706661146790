import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ResponsiveService } from './responsive.service';

@Injectable({
  providedIn: 'root',
})
export default class MenuService {

  $toggleMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private $menuReference: HTMLElement;

  constructor(private responsiveService: ResponsiveService) {
    let menuReference: HTMLElement = document.querySelector('app-menu');
    if (!menuReference) {
      const interval = setInterval(() => {
        menuReference = document.querySelector('app-menu');
        if (menuReference) {
          clearInterval(interval);
          this.$menuReference = menuReference;
        }
      }, 100);
    }
  }

  async disableMenu() {
    const interval = setInterval(() => {
      if (this.$menuReference) {
        clearInterval(interval);
        return this.$menuReference.style.display = 'none';
      }
    });
  }

  async enableMenu() {
    const isMobile = this.responsiveService.isMobilePlatform;
    if (isMobile) return;
    const interval = setInterval(() => {
      if (this.$menuReference) {
        clearInterval(interval);
        return this.$menuReference.style.display = 'block';
      }
    });
  }
}
