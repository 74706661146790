import { Component, OnInit } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-custom-chips-input',
  templateUrl: './custom-chips-input.component.html',
  styleUrls: ['./custom-chips-input.component.scss']
})
export class CustomChipsInputComponent extends BaseCustomInputComponent implements OnInit {

  textControl: FormControl = new FormControl('');

  constructor() {
    super();
  }

  ngOnInit() {}

  handleKeyUpEnter() {
    if (this.control?.value && Array.isArray(this.control?.value)) {
      const value = this.control.value;
      value.push(this.textControl.value.trim())
      this.control.setValue(value);
      this.textControl.setValue('');
    }
  }

  handleDeleteChipClick(index: number) {
    const value = this.control.value;
    value.splice(index, 1);
    this.control.setValue(value);
  }
}
