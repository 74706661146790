import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { AjudeMelhorarComponent } from '../../modals/ajude-melhorar/ajude-melhorar.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  @Input() pageTitle;
  @Input() backButton: boolean;
  @Input() background: boolean;
  @Input() previousRoute: string;
  @Input() navigationParams: any;

  @Output() backButtonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
              private modalHelper: CustomModalHelper) {}

  async handleBackButtonClick() {
    if (!this.previousRoute) {return;}
    const extras: any = {
      queryParams: { ...this.navigationParams },
      queryParamsHandling: 'replace',
      replaceUrl: true,
    };
    this.backButtonClick.emit();
    await this.router.navigate([this.previousRoute], extras);
  }

  async handleAjudeMelhorarClick() {
    const modal = await this.modalHelper.create({
      component: AjudeMelhorarComponent
    });

    await modal.present();
  }
}
