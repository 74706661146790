import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IPaginacao {
  total: number;
  totalPages: number;
  actualPage: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  pagina?: EventEmitter<number>;
  proxima?: EventEmitter<void>;
  anterior?: EventEmitter<void>;
}

@Component({
  selector: 'app-paginacao',
  templateUrl: './paginacao.component.html',
})
export class PaginacaoComponent implements IPaginacao, OnInit {

  @Input() total: number;
  @Input() totalPages: number;
  @Input() actualPage: number;
  @Input() hasPrevPage: boolean;
  @Input() hasNextPage: boolean;
  @Output() pagina = new EventEmitter();
  @Output() proxima = new EventEmitter();
  @Output() anterior = new EventEmitter();

  _pageNumbers = [];

  isSplitted = false;

  constructor() { }

  ngOnInit() {
    this.isSplitted = this.totalPages > 10;
    this.loadPageNumbers()
  }

  loadPageNumbers() {
    this._pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  irParaPagina(pagina) {
    this.pagina.emit(pagina);
  }

  voltarPagina() {
    this.anterior.emit();
  }

  proximaPagina() {
    this.proxima.emit();
  }
}
