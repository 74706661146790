import { Component, Input, OnInit } from '@angular/core';

export interface CollapseItem {
  label: string;
  count: number;
  items?: CollapseItem[];
}

@Component({
  selector: 'app-arvore-exames',
  templateUrl: './arvore-exames.component.html',
})
export class ArvoreExamesComponent implements OnInit {

  @Input() items: CollapseItem[];

  constructor() { }

  ngOnInit() {}

}
