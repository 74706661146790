import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CalendarComponentOptions } from 'ion2-calendar';
import * as moment from 'moment';

moment.locale('pt-br');

moment.updateLocale('pt-br', {
  months : moment.months().map((name) => name.charAt(0).toUpperCase() + name.slice(1))
});

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
})
export class CustomCalendarComponent {

  markedDayClass = 'custom-calendar-marked-day';

  date: string[] = [];

  options: CalendarComponentOptions = {
    from: new Date(2018, 0, 1),
    weekdays:[...moment.weekdaysShort()].map(a => a[0].toUpperCase()),
    pickMode: 'multi',
    showMonthPicker: false,
    weekStart: 1,
    monthFormat: 'MMMM YYYY',
    daysConfig: [
      { date : new Date(2022, 5, 9), cssClass : this.markedDayClass, marked : true },
      { date : new Date(2022, 5, 21), cssClass : this.markedDayClass, marked : true },
      { date : new Date(2022, 5, 8), cssClass : this.markedDayClass, marked : true },
      { date : new Date(2022, 5, 24), cssClass : this.markedDayClass, marked : true },
      { date : new Date(2022, 5, 3), cssClass : this.markedDayClass, marked : true },
    ]
  };

  constructor(public modalCtrl: ModalController) {}

  onChange($event) {
    console.log($event);
  }
}
