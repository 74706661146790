import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { luhnChk, soDigitos } from './utils';
import * as  moment from 'moment';

// eslint-disable-next-line @typescript-eslint/naming-convention
const SameDigitsCPF = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999'
];

// eslint-disable-next-line @typescript-eslint/naming-convention
const SameDigitsCNPJ = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999'
];

export class ValidatorsApp {

  static cpf(control: AbstractControl): ValidationErrors | null {

    if (!control.value || !control.value.trim()) {
      return null;
    }

    let digitosIguais = 1;

    const cpf = control.value.replace(/\D/gi, '');

    if (cpf.length < 11) {
      return {length: true};
    }

    if (SameDigitsCPF.indexOf(cpf) >= 0) {
      return {cpf: true};
    }

    for (let i = 0; i < cpf.length - 1; i++) {
      if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
        digitosIguais = 0;
        break;
      }
    }

    if (digitosIguais) {
      return {cpf: true};
    }

    let numeros = cpf.substring(0, 9);
    let soma = 0;
    const digitos = cpf.substring(9);

    for (let i = 10; i > 1; i--) {
      soma += numeros.charAt(10 - i) * i;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado.toString() !== digitos.charAt(0)) {
      return {cpf: true};
    }

    numeros = cpf.substring(0, 10);
    soma = 0;

    for (let i = 11; i > 1; i--) {
      soma += numeros.charAt(11 - i) * i;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado.toString() !== digitos.charAt(1)) {
      return {cpf: true};
    }

    return null;
  }

  static cnpj(control: AbstractControl): ValidationErrors | null {

    if (!control.value || !control.value.trim()) {
      return null;
    }

    const cnpj = control.value.replace(/\D/gi, '');

    if (cnpj.length < 14) {
      return {length: true};
    }

    // Elimina CNPJs invalidos conhecidos
    if (SameDigitsCNPJ.indexOf(cnpj) >= 0) {
      return {cnpj: true};
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let soma = 0;
    let pos = tamanho - 7;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(0)) {
      return {cnpj: true};
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado.toString() !== digitos.charAt(1)) {
      return {cpnj: true};
    }

    return null;
  }

  static nome(control: AbstractControl): ValidationErrors | null {

    if (!control.value) {
      return null;
    }
    const nome = control.value;
    if (isNaN((nome).trim().replace(/\s+/g, '')) && nome.length > 1) {
      return null;
    }
    return {nome: true};
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const email = control.value;
    if ((/\S+@\S+\.\S+/.test(email))) {
      return null;
    }
    return {email: true};
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static formularioValido(form: FormGroup,
                          keys?: Array<string>,
                          options?: { emitEvent?: boolean }) {
    this.updateFormControl(form.controls, keys, options);
    if (!form.valid && !keys) {
      return false;
    }
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static updateFormControl(formControl, keys?: Array<string>, options?: any) {
    const controls = Object.keys(formControl);
    for (const control of controls) {
      const formCtrl = formControl[control];
      if (formCtrl instanceof FormGroup) {
        this.updateFormControl(formCtrl.controls, keys, options);
        continue;
      }
      if (keys) {
        const hasKey = keys.some(
          x => x === control);
        if (hasKey) {
          formCtrl.updateValueAndValidity(options);
        }
      } else {
        formCtrl.updateValueAndValidity(options);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static uf(control: AbstractControl): ValidationErrors | null {
    if (!control.value || typeof control.value === 'object') {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return {invalid_uf: true};
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static dataBr(control: AbstractControl): ValidationErrors | null {
    if (control?.value?.length !== 10) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return {invalid_date: true};
    }
    const data = moment(control.value, 'DD/MM/YYYY');
    if (!control.value || !data.isValid()) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return {invalid_date: true};
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static dataCurvaPSA(control: AbstractControl): ValidationErrors | null {
    const [day, month, year] = control.value.split('/');
    if (control?.value?.length !== 8) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return {invalid_psa_date: true};
    }
    const data = moment(control.value, 'DD/MM/YY');
    if (!control.value || !data.isValid()) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return {invalid_psa_date: true};
    }
    if (Number(year) >= 90 && Number(year) <= 99) {
      return null;
    } else if (Number(year) >= 0 && Number(year) <= Number(moment().format('YY'))) {
      return null;
    } else {
      return {invalid_psa_date: true};
    }
    return null;
  }

  static min(control: AbstractControl, min: number) {
    if (!control.value) return null;
    let { value } = control;
    value = String(value);
    if (value) value = value.replaceAll('.', '').replace(',', '.');
    const satisfying = min <= Number(value);
    return satisfying ? null : { min : true };
  }

  static max(control: AbstractControl, max: number) {
    if (!control.value) return null;
    let { value } = control;
    value = String(value);
    if (value) value = value.replaceAll('.', '').replace(',', '.');
    const satisfying = Number(value) <= max;
    return satisfying ? null : { max : true };
  }

  static confirmPassword(control: AbstractControl, confirmPass: AbstractControl) {
    if (String(control?.value) !== String(confirmPass?.value)) return { pass_notequals : true };
    else return null;
  }

  static oldAndNewPasswordAreDifferents(control: AbstractControl, oldPassword: AbstractControl) {
    if (String(control?.value) === String(oldPassword?.value)) return { same_password : true };
    else return null;
  }
}
