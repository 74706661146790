import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';

@Component({
  selector: 'app-outros-testes-mama',
  templateUrl: './outros-testes.component.html',
})
export class FiltrosOutrosTestesComponent extends AbstractModal {

  fixedHeight = '525px';
  fixedWidth = '420px';

  constructor(public readonly injector: Injector) {
    super(injector);
  }

}
