import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
// @ts-ignore
import { BrMaskModel } from 'br-mask';
import { RangeOption } from '../custom-input/custom-range-input/custom-range-input.component';

export type InputType = 'select' |
  'text' |
  'password' |
  'toggle' |
  'radio' |
  'range' |
  'textarea' |
  'checkbox' |
  'checkgroup' |
  'chips' |
  'three-way-toggle' |
  'autocomplete';

export interface SelectionOption {
  nome: string;
  valor: any;
  hint?: string;
  notFilterable?: boolean;
  customClasses?: string;
  custom?: any;
  selected?: boolean;
}

export interface RangeConfig {
  options?: RangeOption[];
  max?: number;
  min?: number;
  step?: number;
  firstStep?: number;
  dualKnobs?: boolean;
  showTooltipValues?: boolean;
  showValueOnLeft?: boolean;
  showPercentageSteps?: boolean;
  value?: { lower: number; upper: number };
}

export interface ThreeWayToggleConfig {
  nonValueText: string;
  negativeValueText: string;
  positiveValueText: string;
}

export interface InputComponent {
  label: string;
  value: string;
  placeholder: string;
  selectOptions: SelectionOption[];
  customClasses: string;
  inputIconName: string;
  control: AbstractControl | FormControl | any;
  maxlength: number | string;
  debounce: number;
  rowsNumber: number;
  direction: 'row' | 'column';
  fontSize: 'normal' | 'small';
  icon: string;
  disabled: boolean;
  maskConfig: BrMaskModel;
  handleChange: ((value) => void);
  valueChanged: EventEmitter<any> | ((value) => void);
  handleSearchChanged: EventEmitter<any> | ((value) => void);
  keyUpEnter: EventEmitter<any> | ((value) => void);
  onIonFocus: EventEmitter<any> | ((value) => void);
  onIonBlur: EventEmitter<any> | ((value) => void);
  controlName: string;
  type: string;
  fields: string[];
  error?: { messages: string[], hasError: boolean };
  rangeConfig?: RangeConfig;
  threeWayToggleConfig: ThreeWayToggleConfig;
  listPosition: string;
  loading: boolean;
  setValueOnInput: boolean;
  firstSelected: boolean;
  hooverCheck: boolean;
}

@Component({
  template: ''
})
export abstract class BaseCustomInputComponent implements InputComponent {

  @Input() type: InputType;
  @Input() selectOptions: SelectionOption[];
  @Input() label: string;
  @Input() value: any;
  @Input() placeholder: string;
  @Input() customClasses: string;
  @Input() icon: string;
  @Input() inputIconName: string;
  @Input() control: AbstractControl | FormControl | any;
  @Input() maskConfig: BrMaskModel;
  @Input() maxlength: number | string;
  @Input() rowsNumber: number = 10;
  @Input() controlName: string;
  @Input() direction: 'row' | 'column' = 'row';
  @Input() fontSize: 'normal' | 'small' = 'normal';
  @Input() disabled: boolean;
  @Input() debounce: number;
  @Input() uncontrolled: boolean = false;
  @Input() fields: string[];
  @Input() rangeConfig: RangeConfig;
  @Input() threeWayToggleConfig: ThreeWayToggleConfig;
  @Input() setValueOnInput: boolean = false;
  @Input() loading: boolean;
  @Input() firstSelected: boolean;
  @Input() listPosition: 'top' | 'bottom' = 'bottom';
  @Input() hooverCheck: boolean = false;

  @Output() valueChanged: EventEmitter<any> | any = new EventEmitter<any>();
  @Output() handleSearchChanged: EventEmitter<any> | any = new EventEmitter<any>();
  @Output() keyUpEnter: EventEmitter<any> | any = new EventEmitter<any>();
  @Output() onIonFocus: EventEmitter<any> | any = new EventEmitter<any>();
  @Output() onIonBlur: EventEmitter<any> | any = new EventEmitter<any>();

  error: { messages: string[], hasError: boolean };

  setError(error) {
    this.error = error;
  }

  handleChange(value) {
    if (!this.valueChanged) {
      return;
    }
    if (this.valueChanged instanceof EventEmitter) {
      this.valueChanged.emit(value);
    } else {
      this.valueChanged(value);
    }
  }
}
