import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { SelectionOption } from '../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../interfaces/input-with-modal';
import { Censura, SubtipoCensuraType, TipoCensuraType } from '../../models/censura.model';
import { loading } from '../../util/loading';
import { toast } from '../../util/toast';
import CustomAlertHelper from '../../helper/custom-alert-helper';
import { FichaService } from '../../services/ficha.service';
import { Ficha } from '../../models/ficha.model';
import * as moment from 'moment';

@Component({
  selector: 'app-censurar',
  templateUrl: './censurar.component.html',
})
export class CensurarComponent extends AbstractModal implements OnInit, InputWithModal {

  ficha: Ficha;
  censura: Censura;
  passoAtual: 'selecao-tipo' | 'selecao-subtipo' |'calendario' = 'selecao-tipo';
  offsetWidth = 10;
  fixedHeight = '250px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  maxDate = moment().format('YYYY-MM-DD');
  form: FormGroup = new FormGroup({
    tipo: new FormControl(''),
    subtipo: new FormControl(''),
    data: new FormControl(moment().set({ date: 1 }).format('YYYY-MM-DD')),
  });

  motivoCensuraOptions: SelectionOption[] = [
    {
      nome: 'Perda de seguimento',
      valor: 'perda'
    },
    {
      nome: 'Óbito',
      valor: 'obito'
    },
    {
      nome: 'Alta médica',
      valor: 'alta'
    }
  ];

  motivoObitoOptions: SelectionOption[] = [
    {
      nome: 'Causa oncológica',
      valor: 'oncologica'
    },
    {
      nome: 'Causa não oncológica',
      valor: 'nao-oncologica'
    }
  ];

  constructor(public readonly injector: Injector,
              private alertHelper: CustomAlertHelper,
              private fichaService: FichaService) {
    super(injector);
  }

  ngOnInit() {
    this.registerFormListeners();
    this.setFormDefaultValues();
  }

  setFormDefaultValues() {
    this.passoAtual = 'selecao-tipo';
    this.form.patchValue({ ...this.censura }, { emitEvent : false});
  }

  registerFormListeners() {
    this.form.get('tipo').valueChanges.subscribe((motivo: TipoCensuraType) => {
      if (motivo !== 'obito') {
        this.abrirCalendario();
      } else {
        this.passoAtual = 'selecao-subtipo';
      }
    });

    this.form.get('subtipo').valueChanges.subscribe((subtipo: SubtipoCensuraType) => {
      if (subtipo) {
        this.abrirCalendario();
      }
    });
  }

  abrirCalendario() {
    this.setHeight(430);
    this.passoAtual = 'calendario';
  }

  async handleDeletarCensuraClick() {
    const alert = await this.alertHelper.create({
      title: 'Excluir censura',
      text: 'Tem certeza que deseja excluir a censura pra este paciente?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          loading(this.fichaService.excluirCensura(this.ficha).subscribe((ficha) => {
            this.handleCLickClose(false, { excluirCensura: true, ficha });
            toast('Censura excluída com sucesso');
          }));
        }
      }
    });
    await alert.present();
  }

  async handleEndEditClick() {
    if (this.passoAtual === 'selecao-tipo') {
      if (this.form.get('tipo').value === 'obito') {
        this.passoAtual = 'selecao-subtipo';
      } else {
        this.abrirCalendario();
      }
    } else if (this.passoAtual === 'selecao-subtipo') {
      this.abrirCalendario();
    } else {
      const { tipo, subtipo, data } = this.form.getRawValue();
      const censura: Censura = { tipo, data };
      if (tipo === 'obito') censura.subtipo = subtipo;
      await this.modalController.dismiss({ confirm: true, censura });
    }
  }
}
