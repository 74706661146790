import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-collapse',
  templateUrl: './custom-collapse.component.html',
  styleUrls: ['./custom-collapse.component.scss'],
})
export class CustomCollapseComponent implements AfterContentInit {

  @Input() title: string;
  @Input() detail: string;
  @Input() border: boolean = true;
  @Input() fontBold: boolean = true;
  @Input() highlighted: boolean = false;
  @Input() truncateTitle: boolean = false;
  @Input() chevronSize: 'normal' | 'small' = 'normal';

  @ViewChild('collapseTitleRef') collapseTitleRef: any;

  constructor() { }

  ngAfterContentInit() {
    if (this.chevronSize === 'small') {
      const interval = setInterval(() => {
        if (this.collapseTitleRef?.nativeElement?.style) {
          this.collapseTitleRef.nativeElement.style.setProperty('--chevron-transform', 'translateY(-50%) scale(.75)');
          clearInterval(interval);
        }
      }, 300);
    }
  }
}
