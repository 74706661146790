import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { adminMenuConfig, menuConfig, MenuItem } from '../../constants/menu';
import { AuthService } from '../../services/auth.service';
import { ModuloService } from '../../services/modulo.service';
import { Usuario } from '../../models/usuario.model';
import { ROUTE } from '../../constants/route';
import { ForumService } from '../../services/forum.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements AfterViewChecked {

  @ViewChild('menuComponentRef') menuComponentRef: any;
  moduloAtual: string;
  menuConfig: MenuItem[];
  usuario: Usuario;
  bagdesConfig: any = {};

  constructor(private router: Router,
              public authService: AuthService,
              private moduloService: ModuloService,
              private forumService: ForumService) {
    moduloService.$moduloAtual.subscribe(this.handleChangeModuloAtual.bind(this));
    authService.$userData.subscribe(this.handleUserDataChange.bind(this));
    this.usuario = this.authService.getUser();
    this.registerSocketListeners();
  }

  ngAfterViewChecked() {
    this.removeMenuInnerClass();
  }

  registerSocketListeners() {
    this.forumService.$novoCasoPublicado.subscribe(() => {
      this.bagdesConfig[ROUTE.DISCUSSAO_CASO] = true;
    });
  }

  clearBadge(routeName: string) {
    this.bagdesConfig[routeName] = false;
  }

  handleUserDataChange(usuario: Usuario) {
    if (!usuario) { return; }
    this.menuConfig = usuario.perfil === 'admin' ? adminMenuConfig : menuConfig;
    this.usuario = usuario;
  }

  removeMenuInnerClass() {
    const nativeElement: HTMLElement = this.menuComponentRef.el;
    const menuInner = nativeElement.shadowRoot.querySelector('.menu-inner');
    if (menuInner && menuInner.classList.contains('menu-inner')) {
      menuInner.classList.remove('menu-inner');
    }
  }

  async navigate(config: MenuItem) {
    this.moduloService.clearModuloAtual();
    const lastActive = this.menuConfig.find(i => i.active);
    if (config.route && (lastActive && config.route !== lastActive.route)) {
      config.active = true;
      lastActive.active = false;
      this.clearBadge(config.route);
      await this.router.navigate([config.route],{ replaceUrl: !!config.replaceUrl });
    } else {
      config.active = true;
      this.clearBadge(config.route);
      await this.router.navigate([config.route],{ replaceUrl: !!config.replaceUrl });
    }
  }

  handleChangeModuloAtual(modulo: string) {
    this.moduloAtual = modulo;
  }

  getIsAvailableItem(config: MenuItem) {
    switch (config.route) {
      case ROUTE.CONTA_INSTITUICIONAL:
        if (this.usuario?.isResidente) {
          return false;
        } else {
          return this.usuario?.clinicaList?.some(clinica => !!clinica.contaInstituicao);
        }
    }
    return true;
  }
}
