import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { Ficha } from '../../models/ficha.model';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig, menuModulosConfig } from '../../constants/modulos-cancer';
import { SelectionOption } from '../../components/_base/abstract-input';
import { FormControl } from '@angular/forms';
import { toast } from '../../util/toast';
import { Subscription, zip } from 'rxjs';
import { FichaService } from '../../services/ficha.service';
import { DominioService } from '../../services/dominio.service';
import { loading } from '../../util/loading';

@Component({
  selector: 'app-adicionar-outra-neoplasia',
  templateUrl: './adicionar-outra-neoplasia.component.html',
})
export class AdicionarOutraNeoplasiaComponent extends AbstractModal implements OnInit, OnDestroy {

  ficha: Ficha;
  modulosOptions: SelectionOption[];
  control: FormControl = new FormControl({});
  fixedHeight = '275px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  $subscriptions: Subscription[] = [];
  neoplasiasPaciente: any[];

  constructor(public readonly injector: Injector,
              private moduloService: ModuloService,
              private fichaService: FichaService,
              private dominioService: DominioService) {
    super(injector);
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.fichaService.$neoplasiasPaciente.subscribe((neoplasias) => {
        this.neoplasiasPaciente = neoplasias;
        this.loadModulosOptions();
      })
    );
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  loadModulosOptions() {
    const modulosDisponiveisList = [];
    const addModulos = (modulos: IMenuModuloConfig[]) => {
      modulos.forEach((modulo) => {
        if (modulo.modules?.length) addModulos(modulo.modules);
        else if (modulo.available && !modulo.building)
          modulosDisponiveisList.push(modulo);
      });
    };
    addModulos(menuModulosConfig);
    const observables = modulosDisponiveisList.map(modulo => this.dominioService.getModuleByName(modulo.name));
    loading(zip(...observables).subscribe((modulos) => {
      modulos = modulos.map(modulo => Object.assign({}, modulo, { id: modulo._id, name: modulo.nome }));
      this.modulosOptions = modulos
        .filter((modulo: IMenuModuloConfig) => {
          return !this.neoplasiasPaciente.map(neoplasia => neoplasia.modulo.nome).includes(modulo.name);
        })
        .map((modulo: IMenuModuloConfig) => ({
            nome: modulo.title,
            valor: modulo,
          })
        );
    }));
  }

  async handleConfirmarClick() {
    if (!Object.keys(this.control.value).length) {
      await toast('Para confirmar é necessário escolher um módulo antes');
      return;
    }
    await this.handleCLickClose(true, { modulo: this.control.value });
  }
}
