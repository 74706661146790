import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';

@Component({
  selector: 'app-data-imuno-histoquimica',
  templateUrl: './data-imuno-histoquimica.component.html',
})
export class DataImunoHistoquimicaComponent extends AbstractModal implements OnInit {

  ids: any;
  ficha: Ficha;
  index: number;
  offsetWidth = 10;
  fixedHeight = '380px';
  maxDate = moment().format('YYYY-MM-DD');
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  itens: ItemFicha[];
  form: FormGroup = new FormGroup({
    dataImunoHistoquimica: new FormControl(moment().set({ date: 1 }).format('YYYY-MM-DD'))
  });

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { dataImunoHistoquimica } = inputValueResolver('dataImunoHistoquimica', this.ficha?.itens, this.index);
    const { re, rp, idRe, idRp } = inputValueResolver('reRp', this.ficha?.itens, this.index);
    const { her2, fish, idHer2, idFish } = inputValueResolver('her2', this.ficha?.itens, this.index);
    const { ki67, id: idKi67 } = inputValueResolver('ki67', this.ficha?.itens, this.index);
    this.ids = { idRe, idRp, idHer2, idFish, idKi67 };
    this.itens = [
      { chave: 're', valor: re, id: idRe },
      { chave: 'rp', valor: rp, id: idRp },
      { chave: 'her2', valor: her2, id: idHer2 },
      { chave: 'fish', valor: fish, id: idFish },
      { chave: 'ki67', valor: ki67, id: idKi67 },
    ];
    if (dataImunoHistoquimica) {
      this.form.patchValue({ dataImunoHistoquimica });
    }
  }

  handleClearInputClick() {
    setTimeout(async () => {
      const items: ItemFicha[] = this.itens.map(item => Object.assign({}, item, { data: void 0 }));
      await this.modalController.dismiss(items);
    }, 0);
  }

  handleEndEditClick() {
    setTimeout(async () => {
      const data = this.form.get('dataImunoHistoquimica').value;
      const items: ItemFicha[] = this.itens.map(item => Object.assign({}, item, { data }));
      await this.modalController.dismiss(items);
    }, 0);
  }
}
