import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { FormGroup, FormControl } from '@angular/forms';
import { ResponsiveService } from '../../../../services/responsive.service';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { ItemFicha } from '../../../../models/ficha.model';
import { ItemFichaService } from '../../../../services/item-ficha.service';
import { zip } from 'rxjs';

@Component({
	selector: 'app-input-histopatologico',
	templateUrl: './input-histopatologico.component.html',
})
export class InputHistopatologicoComponent extends AbstractModal implements InputWithModal {
	
	fichaPaciente: any;
	id: string;
	offsetWidth = 10;
	fixedHeight = '340px';
	fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
	
	
	form: FormGroup = new FormGroup({
		area: new FormControl('')
	});
	
	// TODO: Buscar esses valores de algum lugar fixo...
	area: string[] = [];
	
	constructor(public override injector: Injector,
	            public responsiveService: ResponsiveService,
	            public itemFichaService: ItemFichaService) {
		super(injector);
	}
	
	ngOnInit() {
		this.loadDominios();
		const {area, id} = inputValueResolver('histopatologico', this.fichaPaciente?.itens);
		this.id = id;
		if (area !== undefined) this.form.patchValue({area});
	}
	
	async handleEndEditClick() {
		if (this.form.get('area').pristine) {
			await this.modalController.dismiss({confirm: false});
		} else {
			const newItem: ItemFicha = {
				chave: 'histopatologico.area',
				valor: this.form.get('area').value
			};
			if (this.id) newItem.id = this.id;
			const items: ItemFicha[] = [newItem];
			await this.modalController.dismiss(items);
		}
	}
	
	loadDominios() {
		zip(
		this.itemFichaService.getTiposHistopatologico(),
		).subscribe((
		[
			tiposHistopatologico,
		]
		) => {
			this.area = tiposHistopatologico;
		});
	}
}
