import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../components/_base/abstract-modal';
import { Clinica, Residente } from '../../../models/clinica.model';
import { TableConfig } from '../../../components/custom-table/custom-table.component';
import { AdminService } from '../../../services/admin.service';
import { loading } from '../../../util/loading';

@Component({
  selector: 'app-visualizar-conta-institucional',
  templateUrl: './visualizar-conta-institucional.component.html',
})
export class VisualizarContaInstitucionalComponent extends AbstractModal {

  instituicao: Clinica;
  tableConfig: TableConfig<Residente> = {
    columns: [
      {
        key: 'email',
        label: 'E-mail'
      },
      {
        label: 'Status convite',
        textResolver: (residente) => {
          return `${{
            utilizado: 'Utilizado',
            pendente: 'Pendente',
            invalido: 'Inválido',
          }[residente.convite.status]}`;
        }
      },
      {
        label: 'Nome',
        textResolver: (residente) => {
          return `${residente.usuario?.nome ? residente.usuario.nome : 'Não disponível'}`
        }
      }
    ],
    actionButtons: []
  };

  constructor(public readonly injector: Injector,
              private adminService: AdminService) {
    super(injector);
  }

  handleAprovarContaInstitucionalClick() {
    loading(this.adminService.aprovarContaInstitucional(this.instituicao._id).subscribe(async () => {
      await this.handleCLickClose(true);
    }));
  }

  handleReprovarContaInstitucionalClick() {
    loading(this.adminService.reprovarContaInstitucional(this.instituicao._id).subscribe(async () => {
      await this.handleCLickClose(true);
    }));
  }
}
