import { DetalheLaudoComponent } from '../../../modals/detalhe-laudo/detalhe-laudo.component';
import CustomModalHelper from '../../../helper/custom-modal-helper';

export default abstract class AbstractTabFichaPaciente {

  protected constructor(public modalHelper: CustomModalHelper) {
  }

  async handleDataExameClick() {
    const modal = await this.modalHelper.create({
      component: DetalheLaudoComponent,
      componentProps: {},
    });
    await modal.present();
  }
}
