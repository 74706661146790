import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (prefixo: string = ''): RangeConfig => ({
  options: [
    {
      label: `${prefixo}Tx`,
      value: `${prefixo}Tx`,
      hint: 'Tu primário não avaliável.'
    },
    {
      label: `${prefixo}Tis`,
      value: `${prefixo}Tis`,
      hint: 'Carcinoma in situ: intraepitelial (invasão da lâmina própria sem extensão muscular da mucosa).'
    },
    {
      label: `${prefixo}T1`,
      value: `${prefixo}T1`,
      hint: 'Tu invade a submucosa.'
    },
    {
      label: `${prefixo}T2`,
      value: `${prefixo}T2`,
      hint: 'Tu invade a muscular própria.'
    },
    {
      label: `${prefixo}T3`,
      value: `${prefixo}T3`,
      hint: 'Tu invade através da muscular própria até tecido pericólico.'
    },
    {
      label: `${prefixo}T4`,
      value: `${prefixo}T4`,
      hint: 'Tu penetra peritônio ou órgãos',
      options: [
        {
          label: `${prefixo}T4`,
          value: `${prefixo}T4`,
          hint: 'Tu penetra peritônio ou órgãos',
        },
        {
          label: `${prefixo}T4a`,
          value: `${prefixo}T4a`,
          hint: 'Tu penetra na superfície do peritônio visceral (inclui perfuração grosseira e invasão continua por inflamação).'
        },
        {
          label: `${prefixo}T4b`,
          value: `${prefixo}T4b`,
          hint: 'Tu invade diretamente ou é aderente a outros órgãos ou estruturas.'
        }
      ]
    }
  ]
});
