import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Usuario } from '../../models/usuario.model';
import { ROUTE } from '../../constants/route';
import { Router } from '@angular/router';
import CustomAlertHelper from '../../helper/custom-alert-helper';
import { ModuloService } from '../../services/modulo.service';
import { AjudeMelhorarComponent } from '../../modals/ajude-melhorar/ajude-melhorar.component';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { Clinica } from '../../models/clinica.model';
import { SelecionarClinicaAtualComponent } from '../../modals/selecionar-clinica-atual/selecionar-clinica-atual.component';
import { ClinicaService } from '../../services/clinica.service';

@Component({
  selector: 'app-desktop-header',
  templateUrl: './desktop-header.component.html',
})
export class DesktopHeaderComponent implements OnInit {

  usuario: Usuario;
  instituicaoAtual: Clinica;
  subRoutePath: string;
  @Input() root: string;
  @Input() customSubRouteTitle: string;
  @Input() profile: boolean = true;
  @Output() onSubRouteClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private authService: AuthService,
              private router: Router,
              private alertHelper: CustomAlertHelper,
              private modalHelper: CustomModalHelper,
              private moduloService: ModuloService,
              private clinicaService: ClinicaService) {
    clinicaService.$clinicaAtual.subscribe((clinica) => this.handleClinicaAtualChange.bind(this)(clinica));
  }

  ngOnInit() {
    this.usuario = this.authService.getUser();
    this.instituicaoAtual = this.clinicaService.getInstituicaoAtual();
  }

  handleClinicaAtualChange(clinica: Clinica) {
    this.instituicaoAtual = clinica;
  }

  async handleLogoutClick() {
    const alert = await this.alertHelper.create({
      title: 'Logout',
      text: 'Tem certeza que deseja sair do oncomax?',
      okText: 'Sair',
      cancelText: 'Cancelar',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          this.moduloService.clearModuloAtual();
          await this.authService.clearAuthorizationToken();
          await this.router.navigate([ROUTE.LOGIN], { replaceUrl: true });
        }
      }
    });
    await alert.present();
  }

  async handleSubRouteClick() {
    this.onSubRouteClick.emit();
  }

  async handleAjudeMelhorarClick() {
    const modal = await this.modalHelper.create({
      component: AjudeMelhorarComponent
    });
    await modal.present();
  }

  async handleInstituicaoAtualClick() {
    const modal = await this.modalHelper.create({
      component: SelecionarClinicaAtualComponent
    });
    await modal.present();
  }
}
