import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../../../interfaces/input-with-modal';

export const HER2_CHAVE = 'her2';
export const HER2_OPTIONS: RangeConfig = {
  options: [
    {
      label: '0',
      value: '0'
    },
    {
      label: '+1',
      value: '+1'
    },
    {
      label: '+2',
      value: '+2',
      options: [
        {
          label: '+2',
          value: '+2',
        },
        {
          label: 'FISH-',
          value: 'FISH-'
        },
        {
          label: 'FISH+',
          value: 'FISH+'
        }
      ]
    },
    {
      label: '+3',
      value: '+3'
    }
  ]
}

@Component({
  selector: 'app-input-her',
  templateUrl: './input-her.component.html',
})
export class InputHerComponent extends AbstractModal implements InputWithModal, OnInit {

  ficha: Ficha;
  index: number;
  idHer2: string;
  idFish: string;
  analitico: boolean;
  data: Date;
  offsetWidth = 10;
  fixedHeight = '485px';
  $digesting: boolean = false;
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    her2: new FormControl('0'),
    fish: new FormControl('+2'),
  });

  desconhecidoForm: FormGroup = new FormGroup({
    her2: new FormControl(false),
  });

  her2Options = HER2_OPTIONS;
  her2SubOptions: RangeConfig;

  ngOnInit() {
    const { her2, fish, idHer2, idFish, data, analitico } = inputValueResolver(HER2_CHAVE, this.ficha?.itens, this.index);
    this.idHer2 = idHer2;
    this.idFish = idFish;
    this.analitico = analitico;
    this.data = data;
    this.loadFormValues(her2, fish);
    this.registerFormListeners();
  }

  constructor(public override injector: Injector) {
    super(injector);
  }

  registerFormListeners() {
    const her2FormControl = this.form.get(HER2_CHAVE);
    const her2DesconhecidoFormControl = this.desconhecidoForm.get(HER2_CHAVE);
    her2DesconhecidoFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.her2.setValue('0');
        this.form.controls.fish.setValue(null);
      }
    });
    her2FormControl.valueChanges.subscribe((value) => {
      if (Number(value) !== 0) {
        her2DesconhecidoFormControl.setValue(false, { emitEvent : false });
      }

      this.her2SubOptions = null;
      this.$digesting = true;
      const selectedOption = this.her2Options.options.find(o => o.value === value);
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.her2SubOptions = {
            options: selectedOption.options
          };
        });
      } else {
        this.her2SubOptions = null;
      }
      this.$digesting = false;
    });
  }

  loadFormValues(herValue: string, fishValue: string) {
    if (!!herValue) {
      const her2Option = this.her2Options.options.find((option) => herValue.startsWith(option.value));
      if (her2Option?.options?.length) {
        this.form.patchValue({ her2: her2Option.value });
        this.her2SubOptions = {
          options: her2Option.options
        };
        this.form.patchValue({
          her2: herValue,
          fish: fishValue
        });
      } else {
        this.form.patchValue({ her2: herValue });
      }
    } else if ([null].includes(herValue)) {
      this.desconhecidoForm.patchValue({ her2 : true });
    }
  }

  handleClearInputClick() {
    const items: ItemFicha[] = [
      { chave: 'her2', valor: void 0, id: this.idHer2 },
      { chave: 'fish', valor: void 0, id: this.idFish },
    ];
    setTimeout(async () => {
      await this.modalController.dismiss(items);
    }, 100);
  }

  async handleEndEditClick() {
    const hasItemHer2 = this.ficha.itens?.some(item => item.chave === HER2_CHAVE);
    const hasItemFish = this.ficha.itens?.some(item => item.chave === 'fish');
    const newItemHer2: ItemFicha = {
      chave: HER2_CHAVE,
      valor: !this.desconhecidoForm.get(HER2_CHAVE).value ? this.form.get(HER2_CHAVE).value : null
    };
    if (this.idHer2) newItemHer2.id = this.idHer2;
    let items: ItemFicha[] = [newItemHer2];
    let fishValue;
    if (this.her2SubOptions?.options?.length) {
      fishValue = this.form.get('fish').value;
    } else {
      fishValue = null;
    }
    const newItemFish: ItemFicha = { chave: 'fish', valor: fishValue };
    if (this.idFish) newItemFish.id = this.idFish;
    items.push(newItemFish);
    items = items.map(item => Object.assign({}, item, { data: this.data }));
    if ((!hasItemHer2 && !hasItemFish) || this.analitico) items.forEach(item => item.analitico = true);
    await this.modalController.dismiss(items);
  }
}
