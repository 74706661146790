import { AfterViewChecked, AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { menuConfig, MenuItem, webMenuClosedWidth, webMenuOpenedWidth } from '../shared/constants/menu';
import { ResponsiveService } from '../shared/services/responsive.service';
import MenuService from '../shared/services/menu.service';
import { IonTabs, Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { IMenuModuloConfig, MODULO_CANCER, MODULO_CORES } from '../shared/constants/modulos-cancer';
import { hasTabOnRoute } from '../shared/constants/route';
import { ModuloService } from '../shared/services/modulo.service';
import CustomModalHelper from '../shared/helper/custom-modal-helper';
import { CadastroPacienteComponent } from '../shared/modals/cadastro-paciente/cadastro-paciente.component';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from '@capacitor/text-zoom';
import { AuthService } from '../shared/services/auth.service';
import { AVAILABLE_MESSAGE_LISTENERS, SocketIoService } from '../shared/services/socket.io.service';
import { ForumService } from '../shared/services/forum.service';
import { ConsentimentoService } from '../shared/services/consentimento.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, AfterViewChecked {

  @ViewChild('splitPaneRef') splitPaneRef: any;
  @ViewChild('ionRouterOutletElement') ionRouterOutletElement: ElementRef;
  @ViewChild('ionTabsElement') ionTabsElement: IonTabs;

  moduloAtual: IMenuModuloConfig;
  isMobile: boolean;
  hasTabOnRoute: boolean;
  hasAddButtonOnRoute: boolean;
  menuConfig: MenuItem[] = menuConfig;

  desktopSkipPagesDefaultClass = [
    'app-onboarding',
    'app-login',
    'app-cadastro-usuario',
    'app-dashboard',
    'app-discussao-caso',
    'app-convite',
    'app-termo-consentimento'
  ];

  mobileSkipPagesDefaultClass = [
    'app-onboarding'
  ];

  constructor(private menuService: MenuService,
              private responsiveService: ResponsiveService,
              private router: Router,
              private authService: AuthService,
              private moduloService: ModuloService,
              private modalHelper: CustomModalHelper,
              private platform: Platform,
              private forumService: ForumService,
              private socketIoService: SocketIoService,
              private consentimentoService: ConsentimentoService) {
    this.isMobile = this.responsiveService.isMobilePlatform;
    moduloService.init(this.isMobile);
    moduloService.$moduloAtual.subscribe(this.handleChangeModuloAtual.bind(this));
    menuService.$toggleMenu.subscribe(this.handleToggleMenu.bind(this));
    router.events.subscribe(this.handleRouteChange.bind(this));
    platform.ready().then(this.setStatusBarBackgroundColor.bind(this));
    platform.ready().then(this.setTextZoomToDefault.bind(this));
    this.registerSocketListeners();
  }

  ngAfterViewInit(): void {
    this.setMenuWidth(webMenuClosedWidth);
    this.setLoggedUser();
  }

  ngAfterViewChecked() {
    if ((this.isMobile && !this.ionTabsElement) || (!this.isMobile && !this.ionRouterOutletElement)) { return; }
    let children;
    if (this.isMobile) {
      children = this.ionTabsElement.outlet.nativeEl.children;
    } else {
      children = this.ionRouterOutletElement.nativeElement.children;
    }
    this.setDefaultClassOnPage(children);
  }

  async setLoggedUser() {
    const token = this.authService.getToken();
    if (token) {
      const user = this.authService.jwtHelper.decodeToken(token);
      await this.authService.setUser(user);
    }
  }

  handleToggleMenu(toggle) {
    if (toggle === null) { return; }
    this.setMenuWidth(toggle ? webMenuOpenedWidth : webMenuClosedWidth);
  }

  handleRouteChange(val) {
    if (val instanceof NavigationEnd) {
      const moduleName = Object.values(MODULO_CANCER).find(moduleName => val.urlAfterRedirects.includes(moduleName.toLowerCase()));
      this.moduloService.setModuloAtual(moduleName);
      this.hasTabOnRoute = hasTabOnRoute(moduleName, val.urlAfterRedirects);
      this.hasAddButtonOnRoute = !!moduleName;
      this.verifyAndChangeModuleColors(moduleName);
    }
  }

  async setStatusBarBackgroundColor() {
    if (!this.platform.is('hybrid')) { return; }
    await SplashScreen.hide({ fadeOutDuration: 300 });
  }

  async setTextZoomToDefault() {
    if (!this.platform.is('hybrid')) { return; }
    await TextZoom.set({ value: 1 });
  }

  setMenuWidth(width) {
    if (!this.splitPaneRef) { return; }
    const nativeElement: HTMLElement = this.splitPaneRef.el;
    nativeElement.style.setProperty('--side-max-width', `${width}px`);
    nativeElement.style.setProperty('--side-min-width', `${width}px`);
    nativeElement.style.setProperty('--side-width', `${width}px`);
  }

  setDefaultClassOnPage(children) {
    const isMobile = this.responsiveService.isMobilePlatform;
    const defaultPageClass = (
      isMobile
        ? 'ion-oncomax-mobile-page'
        : 'ion-oncomax-default-page'
    );

    const skippedPages = isMobile ? this.mobileSkipPagesDefaultClass : this.desktopSkipPagesDefaultClass;

    if (children.length) {
      Array.from(children).forEach((child: HTMLElement) => {
        if (!child.classList.contains(defaultPageClass) && !skippedPages.includes(child.localName)) {
          child.classList.add(defaultPageClass);
        }
      });
    }
  }

  verifyAndChangeModuleColors(moduleName) {
    if (moduleName) {
      const { primary, secondary } = MODULO_CORES[moduleName];
      this.setPrimaryColor(primary);
      this.setSecondaryColor(secondary);
    } else {
      setTimeout(() => {
        if (!this.moduloAtual) {
          this.setPrimaryColor();
          this.setSecondaryColor();
        }
      });
    }
  }

  setPrimaryColor(primary?) {
    const root = document.documentElement;
    if (primary) {
      root.style.setProperty('--om-module-primary-color', primary);
    } else {
      root.style.setProperty('--om-module-primary-color', 'var(--om-brand-500)');
    }
  }

  setSecondaryColor(secondary?) {
    const root = document.documentElement;
    if (secondary) {
      root.style.setProperty('--om-module-secondary-color', secondary);
    } else {
      root.style.setProperty('--om-module-secondary-color', 'var(--om-brand-300)');
    }
  }

  handleChangeModuloAtual(modulo: IMenuModuloConfig) {
    this.moduloAtual = modulo;
    this.verifyAndChangeModuleColors(this.moduloAtual?.name);
  }

  registerSocketListeners() {
    this.socketIoService.registerMessageListener(AVAILABLE_MESSAGE_LISTENERS.NOVO_CASO_PUBLICADO, () => {
      this.forumService.setNovoCasoPublicado();
    });
    this.socketIoService.registerMessageListener(AVAILABLE_MESSAGE_LISTENERS.TERMO_CONSENTIMENTO_ENVIADO, (payload) => {
      this.consentimentoService.setTermoConsentimentoEnviado(payload);
    });
    this.socketIoService.registerMessageListener(AVAILABLE_MESSAGE_LISTENERS.TERMO_CONSENTIMENTO_ACEITO, (payload) => {
      this.consentimentoService.setTermoConsentimentoAceito(payload);
    });
    this.socketIoService.registerMessageListener(AVAILABLE_MESSAGE_LISTENERS.TERMO_CONSENTIMENTO_RECUSADO, (payload) => {
      this.consentimentoService.setTermoConsentimentoRecusado(payload);
    });
  }

  async handleClickAddButton() {
    const modal = await this.modalHelper.create({
      component: CadastroPacienteComponent,
      componentProps: {
        moduloAtual: this.moduloAtual
      }
    });

    await modal.present();
  }

  async handleClickTabButton(route: MenuItem) {
    if (!route.route) { return; }
    this.moduloService.clearModuloAtual();
    await this.router.navigate([route.route], { replaceUrl: true });
  }
}
