import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Publicacao } from '../../models/publicacao.model';
import { MODULO_CORES } from '../../constants/modulos-cancer';
import { AuthService } from '../../services/auth.service';
import { Usuario } from '../../models/usuario.model';

@Component({
  selector: 'app-publicacao-caso-row',
  templateUrl: './publicacao-caso-row.component.html',
})
export class PublicacaoCasoRowComponent implements OnInit {

  MODULO_CORES = MODULO_CORES;
  usuario: Usuario;
  @Input() publicacao: Publicacao;
  @Input() publicacaoSelecionada: Publicacao;

  @Output() onDetalharCasoClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onArquivarCasoClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onExcluirCasoClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.usuario = this.authService.getUser();
  }

  handleDetalharCasoClick(id) {
    this.onDetalharCasoClick.emit(id);
  }

  handleArquivarCasoClick(id) {
    this.onArquivarCasoClick.emit(id);
  }

  handleExcluirCasoClick(id) {
    this.onExcluirCasoClick.emit(id);
  }
}
