import { Component, OnInit } from '@angular/core';
import { ROUTE } from '../../constants/route';
import CustomAlertHelper from '../../helper/custom-alert-helper';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Usuario } from '../../models/usuario.model';
import { ModuloService } from '../../services/modulo.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
})
export class AdminHeaderComponent implements OnInit {

  usuario: Usuario;

  constructor(private alertHelper: CustomAlertHelper,
              private authService: AuthService,
              private router: Router,
              private moduloService: ModuloService) { }

  ngOnInit() {
    this.usuario = this.authService.getUser();
  }

  async handleLogoutClick() {
    const alert = await this.alertHelper.create({
      title: 'Logout',
      text: 'Tem certeza que deseja sair do oncomax?',
      okText: 'Sair',
      cancelText: 'Cancelar',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          this.moduloService.clearModuloAtual();
          await this.authService.clearAuthorizationToken();
          await this.router.navigate([ROUTE.LOGIN], { replaceUrl: true });
        }
      }
    });
    await alert.present();
  }
}
