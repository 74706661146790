import { AbstractService } from './abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RedefinirSenhaDto, Usuario } from '../models/usuario.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { getAppUrl } from '../util/utils';
import { Observable, Subject } from 'rxjs';
import { ClinicaService } from './clinica.service';

const ONBOARDING = '_onboarding';
const TABELA_PAINEL_PRINCIPAL = '_main_pane_table_state';
const CURRENT_ACTIVE_TAB = '_current_active_tab';

@Injectable({providedIn: 'root'})
export default class UsuarioService extends AbstractService<Usuario> {
  jwtHelper = new JwtHelperService();

  constructor(public http: HttpClient,
              private autenticacaoService: AuthService,
              private clinicaService: ClinicaService) {
    super('usuario', http);
  }

  public setOnboarding() {
    localStorage.setItem(ONBOARDING, JSON.stringify(true));
  }

  public getOnboarding() {
    return JSON.parse(localStorage.getItem(ONBOARDING));
  }

  public cadastrar(usuario: Usuario) {
    return this.post(usuario);
  }

  public atualizar(usuario: Usuario) {
    return this.post(usuario, 'atualizar');
  }

  public deletar() {
    return this.http.delete(`${environment.apiUrl}/usuario`);
  }

  public autenticar(email: string, senha: string) {
    const authData: any = {
      email: email,
      senha: senha
    };
    return this.post(authData, 'autenticar')
      .pipe(map(({token}: any) => {
        const usuario: Usuario = this.jwtHelper.decodeToken(token);
        this.autenticacaoService.setUser(usuario);
        this.autenticacaoService.setToken(token);
        if (usuario.clinicaList?.length) this.clinicaService.setInstituicaoAtual(usuario.clinicaList[0]);
      }));
  }

  public recuperarSenha(email: string) {
    return this.http.post(`${environment.apiUrl}/usuario/recuperar-senha`, { email });
  }

  public redefinirSenha(dto: RedefinirSenhaDto) {
    return this.http.post(`${environment.apiUrl}/usuario/redefinir-senha`, dto);
  }

  public validarCRM(crm: string, uf: string) {
    return this.http.post(`${environment.apiUrl}/usuario/validar-crm`, { crm, uf });
  }

  public consultarCRM(crm: string, uf: string) {
    return this.http.post(`${environment.apiUrl}/usuario/consultar-crm`, { crm, uf });
  }

  public getAllCsv(params) {
    const options: any = {
      responseType: 'blob',
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    };
    return this.http.get<any>(`${getAppUrl()}/usuario/csv`, options);
  }

  public executarRefreshToken() {
    this.refreshToken().subscribe(async (response: any) => {
      const usuario = this.autenticacaoService.jwtHelper.decodeToken(response.token);
      await this.autenticacaoService.setUser(usuario);
      await this.autenticacaoService.setToken(response.token);
    });
  }

  public refreshToken() {
    return super.get('refresh-token') as Observable<any>;
  }

  public setCurrentTabPainelPrincipal(tab: string) {
    localStorage.setItem(CURRENT_ACTIVE_TAB, tab);
  }

  public getCurrentTabPainelPrincipal() {
    return localStorage.getItem(CURRENT_ACTIVE_TAB);
  }

  public salvarEstadoTabelaPainelPrincipal(config: any) {
    localStorage.setItem(TABELA_PAINEL_PRINCIPAL, JSON.stringify(config));
  }

  public buscarEstadoTabelaPainelPrincipal() {
    const state = localStorage.getItem(TABELA_PAINEL_PRINCIPAL);
    if (state) return JSON.parse(state);
    return {};
  }

  public confirmarCadastro(idUsuario) {
    const dto: any = { idUsuario };
    return super.post(dto, 'confirmar-cadastro');
  }

  public listarStaffs() {
    return super.get('listar-staffs')
      .pipe(map((res) => this.mapDomainToOption(res, 'nome', '_id'))) as Observable<any>;
  }

  public setCurrentStaff(idMedico: string) {
    return super.post({ idMedico } as any, 'set-current-staff');
  }
}
