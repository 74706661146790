import { Component, OnInit } from '@angular/core';
import { BaseCustomInputComponent, InputType } from '../../_base/abstract-input';

@Component({
  selector: 'app-custom-text-input',
  templateUrl: './custom-text-input.component.html',
  styleUrls: ['./custom-text-input.component.scss'],
})
export class CustomTextInputComponent extends BaseCustomInputComponent implements OnInit {

  lastType: InputType;

  get iconName() {
    return this.lastType === 'password' ? 'c-eye-outline' : 'c-eye-off-outline';
  }

  ngOnInit() {
    this.lastType = this.type;
  }

  handleEyeButtonClick() {
    if (this.lastType === 'password') {
      this.lastType = 'text';
    } else {
      this.lastType = 'password';
    }
  }

  handleKeyUpEnter() {
    this.keyUpEnter.emit();
  }

  handleIonFocus(event) {
    this.onIonFocus.emit();
    event.preventDefault();
  }

  handleIonBlur() {
    this.onIonBlur.emit();
  }
}
