import { Component } from '@angular/core';
import { Router } from '@angular/router';
import MenuService from '../../../../shared/services/menu.service';
import UsuarioService from '../../../../shared/services/usuario.service';
import { ROUTE } from '../../../../shared/constants/route';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.page.html',
})
export class OnboardingPage {

  constructor(private menuService: MenuService,
              private usuarioService: UsuarioService,
              private router: Router) { }

  async ionViewWillEnter() {
    await this.menuService.disableMenu();
  }

  async ionViewWillLeave() {
    await this.menuService.enableMenu();
  }

  async handleClickLogin() {
    this.usuarioService.setOnboarding();
    await this.router.navigate([ROUTE.LOGIN], { replaceUrl: true });
  }

  async handleClickCriarConta() {
    this.usuarioService.setOnboarding();
    await this.router.navigate([ROUTE.CADASTRO_USUARIO], { replaceUrl: true });
  }
}
