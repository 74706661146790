import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { FormControl, FormGroup } from '@angular/forms';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/prostata/util';
import { ThreeWayToggleConfig } from '../../../../components/_base/abstract-input';

export const LISTA_GENES = [
  'BRCA1', 'BRCA2', 'ATM', 'BRIP1',
  'BARD1', 'CDK12', 'CHEK1', 'CHEK2',
  'FANCL', 'PALB2', 'PPP2R2A', 'RAD51B',
  'RAD51C', 'RAD51D', 'RAD54L',
];

export const OUTROS_TESTES_THREE_WAY_TOGGLE_TEXT: { [key: string]: ThreeWayToggleConfig }  = {
  HRR15: {
    nonValueText: 'Não testado',
    negativeValueText: 'Negativo',
    positiveValueText: 'Positivo'
  },
  ['dMMR']: {
    nonValueText: 'Não testado',
    negativeValueText: 'Negativo',
    positiveValueText: 'Positivo'
  },
  ['MSI-H']: {
    nonValueText: 'Não testado',
    negativeValueText: 'Negativo',
    positiveValueText: 'Positivo'
  }
};

@Component({
  selector: 'app-outros-testes-prostata',
  templateUrl: './outros-testes.component.html',
})
export class OutrosTestesComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  offsetWidth = 10;
  ids: any;
  fixedHeight = '260px'; //500px
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    HRR15: new FormControl(),
    dMMR: new FormControl(),
    ['MSI-H']: new FormControl(),
    //
    BRCA1: new FormControl(false),
    BRCA2: new FormControl(false),
    ATM: new FormControl(false),
    BRIP1: new FormControl(false),
    BARD1: new FormControl(false),
    CDK12: new FormControl(false),
    CHEK1: new FormControl(false),
    CHEK2: new FormControl(false),
    FANCL: new FormControl(false),
    PALB2: new FormControl(false),
    PPP2R2A: new FormControl(false),
    RAD51B: new FormControl(false),
    RAD51C: new FormControl(false),
    RAD51D: new FormControl(false),
    RAD54L: new FormControl(false),
  });

  threeWayToggleConfig: { [key: string]: ThreeWayToggleConfig } = OUTROS_TESTES_THREE_WAY_TOGGLE_TEXT;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { values, ids } = inputValueResolver('outrosTestes', this.ficha?.itens);
    this.ids = ids;
    if (values.HRR15) this.setHeight(500);
    this.form.patchValue({...values});
    this.registerFormListerners();
  }

  registerFormListerners() {
    this.form.get('HRR15').valueChanges.subscribe((value) => {
      const height = value ? 500 : 260;
      this.setHeight(height);
    });
  }

  async handleEndEditClick() {
    if (!this.form.pristine) {
      const formFields = Object.keys(this.form.controls);
      const items: ItemFicha[] = formFields
        .filter(field => this.form.get(field).dirty)
        .map((field) => {
          const newItem: ItemFicha = {
            chave: `outrosTestes.${field}`,
            valor: this.form.get(field).value,
          };
          if (this.ids[field]) newItem.id = this.ids[field];
          return newItem;
        });
      if (!this.form.get('HRR15').value) {
        LISTA_GENES.forEach(gene => {
          if (this.form.get(gene).value) {
            items.push({
              chave: `outrosTestes.${gene}`,
              valor: false
            });
          }
        });
      }
      await this.modalController.dismiss(items);
    } else {
      await this.modalController.dismiss({ confirm: false });
    }
  }
}
