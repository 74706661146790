import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/prostata/util';

export const PSA_CHAVE = 'psa';
export const PSA_OPTIONS: RangeConfig = {
  options: [
    {
      label: '<10 ng/ml',
      value: '<10 ng/ml'
    },
    {
      label: '10 a 19,99 ng/ml',
      value: '10 a 19,99 ng/ml'
    },
    {
      label: '≥ 20 ng/ml',
      value: '≥ 20 ng/ml'
    }
  ]
};

@Component({
  selector: 'app-input-psa',
  templateUrl: './input-psa.component.html',
})
export class InputPsaComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  $digestingDesconhecido: boolean = false;
  fixedHeight = '515px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    psa: new FormControl('<10 ng/ml')
  });
  desconhecidoForm: FormGroup = new FormGroup({
    psa: new FormControl(true),
  });

  rangeConfig = PSA_OPTIONS;

  constructor(public override injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { psa, id } = inputValueResolver(PSA_CHAVE, this.ficha?.itens);
    this.id = id;
    if (psa !== undefined) {
      this.form.patchValue({
        psa: psa ? psa : '<10 ng/ml'
      });
    }
    this.desconhecidoForm.patchValue({ psa : psa === null });
    this.registerFormListeners();
  }

  registerFormListeners() {
    const psaFormControl = this.form.get(PSA_CHAVE);
    const psaDesconhecidoFormControl = this.desconhecidoForm.get(PSA_CHAVE);
    psaDesconhecidoFormControl.valueChanges.subscribe((value) => {
      this.$digestingDesconhecido = true;
      if (value) {
        this.form.controls.psa.setValue('<10 ng/ml');
      }
    });
    psaFormControl.valueChanges.subscribe(() => {
      if (this.$digestingDesconhecido) {
        return this.$digestingDesconhecido = false;
      }
      psaDesconhecidoFormControl.setValue(false, { emitEvent : false });
    });
  }

  async handleEndEditClick() {
    const newItem: ItemFicha = {
      chave: PSA_CHAVE,
      valor: !this.desconhecidoForm.get(PSA_CHAVE).value ? this.form.get(PSA_CHAVE).value : null
    };
    if (this.id) newItem.id = this.id;
    const items: ItemFicha[] = [newItem];
    await this.modalController.dismiss(items);
  }
}
