import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FALE_CONOSCO, POLITICA_PRIVACIDADE, ROUTE, TERMOS_CONDICOES } from '../shared/constants/route';
import { AuthGuard } from '../shared/guards/auth.guard';
import { OnboardingGuard } from '../shared/guards/onboarding.guard';
import { OnboardingPage } from './modules/client/onboarding/onboarding.page';
import { LoginPage } from './modules/client/login/login.page';
import { CadastroUsuarioPage } from './modules/client/cadastro-usuario/cadastro-usuario.page';
import { TermoCondicoesPage } from './modules/client/termo-condicoes/termo-condicoes.page';
import { PoliticaPrivacidadePage } from './modules/client/politica-privacidade/politica-privacidade.page';
import { FaleConoscoPage } from './modules/client/fale-conosco/fale-conosco.page';
import { AdminGuard } from '../shared/guards/admin-guard';
import { ConvitePage } from './modules/client/convite/convite.page';
import { ConfirmacaoCadastroPage } from './modules/client/confirmacao-cadastro/confirmacao-cadastro.page';
import { ContaInstitucionalGuard } from '../shared/guards/conta-institucional.guard';
import { TermoConsentimentoPage } from './modules/client/termo-consentimento/termo-consentimento.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: ROUTE.MODULOS_CANCER,
    pathMatch: 'full'
  },
  {
    path: ROUTE.ONBOARDING,
    component: OnboardingPage
  },
  {
    path: ROUTE.LOGIN,
    component: LoginPage,
    canActivate: [OnboardingGuard],
    canLoad: [OnboardingGuard]
  },
  {
    path: ROUTE.CADASTRO_USUARIO,
    component: CadastroUsuarioPage,
    canActivate: [OnboardingGuard],
    canLoad: [OnboardingGuard]
  },
  {
    path: TERMOS_CONDICOES,
    component: TermoCondicoesPage
  },
  {
    path: POLITICA_PRIVACIDADE,
    component: PoliticaPrivacidadePage
  },
  {
    path: FALE_CONOSCO,
    component: FaleConoscoPage
  },
  {
    path: ROUTE.CONVITE,
    component: ConvitePage
  },
  {
    path: ROUTE.CONFIRMACAO_CADASTRO,
    component: ConfirmacaoCadastroPage
  },
  {
    path: ROUTE.TERMO_CONSENTIMENTO,
    component: TermoConsentimentoPage
  },
  {
    path: ROUTE.REDEFINIR_SENHA,
    loadChildren: () => import('./modules/client/redefinir-senha/redefinir-senha.module').then(m => m.RedefinirSenhaPageModule)
  },
  {
    path: ROUTE.PATROCINADORES,
    loadChildren: () => import('./modules/client/patrocinadores/patrocinadores.module').then(m => m.PatrocinadoresPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTE.CONTA_INSTITUICIONAL,
    loadChildren: () => import('./modules/client/conta-institucional/conta-institucional.module').then(m => m.ContaInstitucionalPageModule),
    canActivate: [AuthGuard, ContaInstitucionalGuard],
    canLoad: [ContaInstitucionalGuard]
  },
  {
    path: ROUTE.CONFIGURACOES,
    loadChildren: () => import('./modules/client/configuracoes/configuracoes.module').then(m => m.ConfiguracoesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTE.DASHBOARD,
    loadChildren: () => import('./modules/client/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTE.PESQUISAR_PACIENTES,
    loadChildren: () => import('./modules/client/pesquisar-pacientes/pesquisar-pacientes.module').then(m => m.PesquisarPacientesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTE.MODULOS_CANCER,
    loadChildren: () => import('./modules/client/modulos-cancer/modulos-cancer.module').then(m => m.ModulosCancerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTE.DISCUSSAO_CASO,
    loadChildren: () => import('./modules/client/discussao-caso/discussao-caso.module').then(m => m.DiscussaoCasoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTE.CAMPANHA_QUIZ,
    loadChildren: () => import('./modules/client/campanha-quiz/campanha-quiz.module').then(m => m.CampanhaQuizModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTE.PAGINA_EM_CONSTRUCAO,
    loadChildren: () => import('./modules/client/pagina-em-construcao/pagina-em-construcao.module').then(m => m.PaginaEmConstrucaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTE.ADMIN,
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminPageModule),
    canLoad: [AuthGuard, AdminGuard],
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: '**',
    redirectTo: ROUTE.MODULOS_CANCER
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
