import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-publicacao-caso',
  templateUrl: './publicacao-caso.component.html',
})
export class PublicacaoCasoComponent extends AbstractModal {

  fixedHeight = '256px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  step: 'confirmar-publicacao' | 'finalizar-publicacao' = 'confirmar-publicacao';
  form: FormGroup = new FormGroup({
    anonimo: new FormControl(false),
    texto: new FormControl('')
  });

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  handleConfirmarPublicacaoClick() {
    this.step = 'finalizar-publicacao';
    this.setHeight(450);
    if (!this.responsiveService.isMobilePlatform) {
      this.setWidth(550);
    }
  }

  async handlePublicarClick() {
    await this.handleCLickClose(true, { ...this.form.getRawValue() });
  }
}
