import { MODULO_CANCER } from './modulos-cancer';
import {
  inputLabelResolver as inputLabelResolverMama,
  inputValueResolver as inputValueResolverMama
} from '../../app/modules/client/modulos-cancer/mama/util';

import {
  inputLabelResolver as inputLabelResolverProstata,
  inputValueResolver as inputValueResolverProstata
} from '../../app/modules/client/modulos-cancer/prostata/util';

import {
  inputLabelResolver as inputLabelResolverPulmaoNSCLC,
  inputValueResolver as inputValueResolverPulmaoNSCLC
} from '../../app/modules/client/modulos-cancer/pulmao-nsclc/util';

import {
  inputLabelResolver as inputLabelResolverPulmaoSCLC,
  inputValueResolver as inputValueResolverPulmaoSCLC
} from '../../app/modules/client/modulos-cancer/pulmao-sclc/util';

import {
  inputLabelResolver as inputLabelResolverColorretalColon,
  inputValueResolver as inputValueResolverColorretalColon
} from '../../app/modules/client/modulos-cancer/colorretal-colon/util';

import {
  inputLabelResolver as inputLabelResolverColorretalReto,
  inputValueResolver as inputValueResolverColorretalReto
} from '../../app/modules/client/modulos-cancer/colorretal-reto/util';

import {
  inputLabelResolver as inputLabelResolverColorretalCanalAnal,
  inputValueResolver as inputValueResolverColorretalCanalAnal
} from '../../app/modules/client/modulos-cancer/colorretal-canal-anal/util';

export const LABEL_RESOLVER_MAPPING = {
  [MODULO_CANCER.MAMA]: inputLabelResolverMama,
  [MODULO_CANCER.PROSTATA]: inputLabelResolverProstata,
  [MODULO_CANCER.PULMAO_NSCLC]: inputLabelResolverPulmaoNSCLC,
  [MODULO_CANCER.PULMAO_SCLC]: inputLabelResolverPulmaoSCLC,
  [MODULO_CANCER.COLORRETAL_COLON]: inputLabelResolverColorretalColon,
  [MODULO_CANCER.COLORRETAL_RETO]: inputLabelResolverColorretalReto,
  [MODULO_CANCER.COLORRETAL_CANAL_ANAL]: inputLabelResolverColorretalCanalAnal,
};

export const VALUE_RESOLVER_MAPPING = {
  [MODULO_CANCER.MAMA]: inputValueResolverMama,
  [MODULO_CANCER.PROSTATA]: inputValueResolverProstata,
  [MODULO_CANCER.PULMAO_NSCLC]: inputValueResolverPulmaoNSCLC,
  [MODULO_CANCER.PULMAO_SCLC]: inputValueResolverPulmaoSCLC,
  [MODULO_CANCER.COLORRETAL_COLON]: inputValueResolverColorretalColon,
  [MODULO_CANCER.COLORRETAL_RETO]: inputValueResolverColorretalReto,
  [MODULO_CANCER.COLORRETAL_CANAL_ANAL]: inputValueResolverColorretalCanalAnal,
};

export const CHART_LABEL_ALIASES = {
  'Hormonioterapia Adjuvante': 'HT Adjuvante',
  'Quimioterapia Adjuvante': 'QT Adjuvante',
  'Quimioterapia Paliativa': 'QT Pal.',
  'Hormonioterapia Paliativa': 'HT Pal.',
  'Hormonioterapia Neoadjuvante': 'HT Neo.',
  'Quimioterapia Neoadjuvante': 'QT Neo.',
};
