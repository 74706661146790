import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { GrupoService } from '../../services/grupo.service';
import { FormControl } from '@angular/forms';
import { SelectionOption } from '../../components/_base/abstract-input';
import { loading } from '../../util/loading';

@Component({
  selector: 'app-pesquisar-grupo',
  templateUrl: './pesquisar-grupo.component.html',
})
export class PesquisarGrupoComponent extends AbstractModal implements OnInit {

  nome: string = '';
  isSolicitacaoCadastro: boolean = false;
  offsetWidth = 10;
  fixedHeight = '585px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  name: FormControl = new FormControl('');
  gruposOptions: SelectionOption[];
  grupoSelecionado: FormControl = new FormControl(null);

  constructor(public readonly injector: Injector,
              private grupoService: GrupoService) {
    super(injector);
  }

  ngOnInit() {
    this.name.setValue(this.nome, { emitEvent: false });
    this.name.valueChanges.subscribe((name) => {
      this.buscarGrupos(name);
    });
    this.buscarGrupos(this.name.value);
  }

  buscarGrupos(name = '') {
    loading(this.grupoService.buscar(name).subscribe((grupos) => {
      this.gruposOptions = grupos.map(grupo => ({ nome: grupo.nome, valor: grupo }));
    }));
  }

  get estadoVazioText() {
    return !this.isSolicitacaoCadastro ? (
      `Ops! Parece que não há nenhum grupo com este nome. Para inserir um novo grupo clique no botão abaixo.`
    ) : (
      `Ops! Parece que não há nenhum grupo com este nome. Mas, Não tem problema, basta voltar para a tela anterior e 
      preencher os campos de Nome do Grupo e CNPJ da Matriz que um novo grupo será inserido em nossa base de dados.`
    );
  }

  async handleConfirmClick() {
    await this.handleCLickClose(true, { grupo : this.grupoSelecionado.value });
  }

  async handleSolicitarCadastroClick() {
    await this.handleCLickClose(true, { novoGrupoNome : this.name.value });
  }
}
