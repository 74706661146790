import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionOption } from '../../components/_base/abstract-input';
import { Ficha } from '../../models/ficha.model';
import { FichaService } from '../../services/ficha.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { toast } from '../../util/toast';
import { PromptService } from '../../services/prompt.service';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { copyFormattedText, copyToClipboard } from '../../util/utils';

@Component({
  selector: 'app-consultar-inteligencia-artificial',
  templateUrl: './consultar-inteligencia-artificial.component.html',
})
export class ConsultarInteligenciaArtificialComponent extends AbstractModal implements OnInit {

  @ViewChild('editorComponentRef') editorComponentRef;
  moduloAtual: IMenuModuloConfig;
  fixedHeight = '300px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  loading: boolean = false;
  passo: 'selecao-tipo' | 'preenchimento-adicional' | 'resposta' = 'selecao-tipo';
  ficha: Ficha;
  form: FormGroup = new FormGroup({
    consulta: new FormControl(''),
    sintomas: new FormControl(''),
  });
  resposta: string;
  loadingTexts: string[] = [
    'A consulta está sendo processada..',
    'Consultando...',
    'Buscando melhor resultado...',
    'Refinando a busca com a inteligência artificial...',
    'Em instantes a resposta estará disponível...',
  ];
  loadingText: string;
  loadingValue: number = 0;
  loadingBuffer: number = 0;
  loadingInterval: number = 0;
  consultasOptions: SelectionOption[] = [];
  consultas: any[];
  editorToolbarConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        'subscript',
        'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        // 'heading',
        'fontName'
      ],
      [
        // 'fontSize',
        // 'textColor',
        // 'backgroundColor',
        // 'customClasses',
        'link',
        // 'unlink',
        'insertImage',
        'insertVideo',
        // 'insertHorizontalRule',
        // 'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  constructor(public readonly injector: Injector,
              private fichaService: FichaService,
              private promptService: PromptService,
              private moduloService: ModuloService) {
    super(injector);
    moduloService.$moduloAtual.subscribe(modulo => this.moduloAtual = modulo);
  }

  ngOnInit() {
    this.form.get('consulta').valueChanges.subscribe((id) => {
      const consulta = this.consultas.find(c => c._id === id);
      if (consulta?.config?.sintomas) {
        this.passo = 'preenchimento-adicional';
        this.setHeight(410);
      }
    });
    this.loadPrompts();
  }

  loadPrompts() {
    this.promptService.buscarConsultasPorModulo(this.moduloAtual.name).subscribe((consultas) => {
      this.consultas = consultas;
      this.consultasOptions = consultas.map(consulta => ({
        nome: consulta.nome,
        valor: consulta._id
      }));
    });
  }

  turnOnLoading() {
    let textIndex = 0;
    this.loadingText = this.loadingTexts[textIndex];
    this.loadingInterval = setInterval(() => {
      if (textIndex === this.loadingTexts.length) { textIndex = 0; }
      this.loadingText = this.loadingTexts[textIndex];
      if (this.loadingValue < 8) {
        this.loadingValue += 0.15;
        this.loadingBuffer += 0.2;
      }
      textIndex += 1;
    }, 7000);
  }

  turnOffLoading() {
    clearInterval(this.loadingInterval);
  }

  async handleCopyToClipboardClick() {
    try {
      await copyFormattedText(this.resposta);
      await toast('Copiado para área de transferência');
    } catch (e) {
      await toast('Erro ao copiar para área de transferência');
    }
  }

  async handleConfirmarClick() {
    if (!this.resposta) {
      this.changeModalSize();
      this.loading = true;
      this.turnOnLoading();
      const extras = this.getExtras();
      this.promptService.executar(this.form.get('consulta').value, this.ficha, extras).subscribe((response: any) => {
        this.passo = 'resposta';
        this.resposta = response.resposta
          .replace(/<\/div>\n/g, '</div>')
          .replace(/\n/g, '<br>');
        this.loading = false;
        this.turnOffLoading();
      }, async () => {
        this.loading = false;
        await toast('Não foi possível obter o relatório, tente novamente mais tarde');
      });
    } else {
      // await this.handleCLickClose();
      console.log('resposta', this.resposta);
    }
  }

  getExtras() {
    const consultaId = this.form.get('consulta').value;
    const consulta = this.consultas.find(consulta => consulta._id === consultaId);
    if (consulta.config?.sintomas) {
      return [
        {
          chave: 'Sintomas no momento',
          valor: this.form.get('sintomas').value
        }
      ];
    }
  }

  changeModalSize() {
    this.setWidth(800);
    const newHeight = (this.windowHeight - this.offsetHeight);
    this.setHeight(newHeight);
  }
}
