import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ROUTE } from '../constants/route';

@Injectable({
  providedIn: 'root'
})
export class ContaInstitucionalGuard implements CanLoad, CanActivate {

  constructor(
    private authService: AuthService,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {
    const usuario = this.authService.getUser();
    return !usuario?.isResidente;
  }

  canLoad(route: Route): boolean {
    const usuario = this.authService.getUser();
    return !usuario?.isResidente;
  }
}
