import { ItemFicha } from '../../../../../../shared/models/ficha.model';
import { AbstractLabelResolver, AbstractValueResolver } from '../../_base/abstract-resolver';

class LabelResolver extends AbstractLabelResolver {

  static histopatologico = (items: ItemFicha[]): string => {
    const itemArea = items?.find((item) => item.chave === 'histopatologico');
    if (itemArea?.valor) return `${itemArea.valor}`;
    else return '';
  };

  static dataDiagnostico = (items: ItemFicha[]): string => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    if (itemDataDiagnostico?.valor)
      return `${new Date(String(itemDataDiagnostico.valor)).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}`;
    return '';
  };

  static estadiamento = (items: ItemFicha[]): string => {
    const itemEstadiamento = items?.find((item) => item.chave === 'estadiamento');
    if (itemEstadiamento?.valor) return `${itemEstadiamento.valor}`;
    return '';
  };

  static pdl1 = (items: ItemFicha[]): string => {
    const itemPdl1 = items?.find((item) => item.chave === 'pdl1');
    if (itemPdl1?.valor === null) return 'Não testado';
    if (itemPdl1?.valor) return `${itemPdl1.valor}`;
    return '';
  };

  static tmb = (items: ItemFicha[]): string => {
    const itemTmb = items?.find((item) => item.chave === 'tmb');
    if (itemTmb?.valor === null) return 'Não testado';
    if (itemTmb?.valor === true) return '> ou = 10';
    if (itemTmb?.valor === false) return '<10';
  };

  static antecedentesInformacoes = (items: ItemFicha[]): string => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    if (itemAntecedentesInformacoes?.valor) return `${itemAntecedentesInformacoes.valor}`;
    return '';
  };
}

class ValueResolver extends AbstractValueResolver {

  static histopatologico = (items: ItemFicha[]): any => {
    const itemArea = items?.find((item) => item.chave === 'histopatologico');
    return {
      histopatologico: itemArea?.valor,
      id: itemArea?.id
    };
  };

  static dataDiagnostico = (items: ItemFicha[]): any => {
    const itemDataDiagnostico = items?.find((item) => item.chave === 'dataDiagnostico');
    return {
      dataDiagnostico: itemDataDiagnostico?.valor,
      id: itemDataDiagnostico?.id
    };
  };

  static estadiamento = (items: ItemFicha[]): any => {
    const itemEstadiamento = items?.find((item) => item.chave === 'estadiamento');
    return {
      estadiamento: itemEstadiamento?.valor,
      id: itemEstadiamento?.id,
    };
  };

  static pdl1 = (items: ItemFicha[]): any => {
    const itemPdl1 = items?.find((item) => item.chave === 'pdl1');
    return {
      pdl1: itemPdl1?.valor,
      id: itemPdl1?.id,
    };
  };

  static tmb = (items: ItemFicha[]): any => {
    const itemTmb = items?.find((item) => item.chave === 'tmb');
    return {
      tmb: itemTmb?.valor,
      id: itemTmb?.id,
    };
  };

  static antecedentesInformacoes = (items: ItemFicha[]): any => {
    const itemAntecedentesInformacoes = items?.find((item) => item.chave.startsWith('antecedentesInformacoes'));
    return {
      antecedentesInformacoes: itemAntecedentesInformacoes?.valor,
      id: itemAntecedentesInformacoes?.id,
    };
  };
}

export const inputLabelResolver = (resolver, items: ItemFicha[], index?: number) => {
  return LabelResolver[resolver](items, index);
};

export const inputValueResolver = (resolver, items: ItemFicha[], index?: number) => {
  return ValueResolver[resolver](items, index);
};
