import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../components/_base/abstract-modal';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsApp } from '../../../util/validators';
import { DominioService } from '../../../services/dominio.service';
import { AuthService } from '../../../services/auth.service';
import { ClinicaService } from '../../../services/clinica.service';
import CustomModalHelper from '../../../helper/custom-modal-helper';
import { Router } from '@angular/router';
import { ROUTE } from '../../../constants/route';
import { Subject, Subscription, zip } from 'rxjs';
import { loading } from '../../../util/loading';
import { toast } from '../../../util/toast';
import { Usuario } from '../../../models/usuario.model';
import { Clinica } from '../../../models/clinica.model';
import { SelectionOption } from '../../../components/_base/abstract-input';
import { AdminService } from '../../../services/admin.service';

const formInstituicoesTemplate = () => {
  return new FormGroup({
    estado: new FormControl(''),
    cidade: new FormControl(''),
    instituicao: new FormControl('', [Validators.required]),
  });
};

const formCrmTemplate = () => {
  return new FormGroup({
    numero: new FormControl('', [Validators.required]),
    uf: new FormControl('', [Validators.required]),
  });
};

@Component({
  selector: 'app-editar-medico',
  templateUrl: './editar-medico.component.html',
})
export class EditarMedicoComponent extends AbstractModal {

  medicoId: string;
  usuario: Usuario;
  fixedHeight = '660px';
  $subscriptions: Subscription[] = [];
  ufsOptions: any[];
  cidades: any = { 0 : [] };
  clinicas: any = { 0 : [] };
  ufsClinicas: SelectionOption[];
  estados: SelectionOption[];
  filteredUfsClinicas = [];
  loadingClinicas: any = { 0 : false };
  loadingCidades: any = { 0 : false };
  preventCidadeChange: boolean = false;
  formDadosPessoais: FormGroup = new FormGroup({
    nome: new FormControl('', [Validators.compose([Validators.required, ValidatorsApp.nome])]),
    email: new FormControl('', [Validators.compose([Validators.required, ValidatorsApp.email])]),
    telefone: new FormControl('')
  });

  formDetalhesPefil: FormGroup = new FormGroup({
    crms: new FormArray([
      formCrmTemplate()
    ]),
    instituicoes: new FormArray([
      formInstituicoesTemplate()
    ]),
  });

  constructor(public readonly injector: Injector,
              private dominioService: DominioService,
              private authService: AuthService,
              private clinicaService: ClinicaService,
              private modalHelper: CustomModalHelper,
              private router: Router,
              private adminService: AdminService) {
    super(injector);
  }

  async ngOnInit() {
    this.registerFormListeners();
    this.loadDadosMedico().subscribe(() => {
      this.loadDominios().subscribe(() => {
        this.setFormDefaultValues();
      });
    });
  }

  async handleClickVoltar() {
    await this.router.navigate([ROUTE.CONFIGURACOES], {replaceUrl: true});
  }

  get crms() {
    return this.formDetalhesPefil.controls["crms"] as FormArray;
  }

  get instituicoes() {
    return this.formDetalhesPefil.controls["instituicoes"] as FormArray;
  }

  setFilteredUfsClinicas() {
    const selectedUfs = this.crms.getRawValue();
    const selectedUfsMapping = {};
    selectedUfs.forEach(a => selectedUfsMapping[a.uf] = true);
    this.filteredUfsClinicas = Object.keys(selectedUfsMapping).map(uf => ({ nome: uf, valor: uf }));
    this.resetNecessaryFormsFields();
  }

  resetNecessaryFormsFields() {
    const { controls } = this.instituicoes;
    controls.forEach((control) => {
      const itsAvailableInSelectedUfs = this.filteredUfsClinicas.some(uf => uf.nome === control.get('estado').value);
      if (!itsAvailableInSelectedUfs) {
        control.patchValue({
          estado: '',
          cidade: ''
        }, { emitEvent: false });
      }
    });
  }

  setFormDefaultValues() {
    if (!this.usuario) { return; }
    const { nome, email, telefone } = this.usuario;
    this.formDadosPessoais.patchValue({ nome, email, telefone });
    this.usuario.crmList.forEach((crm, index) => {
      if (index !== 0) this.handleClickAdicionarCrm();
      this.crms.at(index).patchValue({ ...crm });
    });
    this.setFilteredUfsClinicas();
    this.usuario.clinicaList.forEach((clinica, index) => {
      if (index !== 0) this.handleClickAdicionarInstituicao();
      this.setClinicaCadastrada(clinica, index);
    });
  }

  loadDominios() {
    const subject = new Subject();
    const observables = [
      this.dominioService.getUfs(),
      this.dominioService.getEstados(),
    ];

    loading(zip(...observables).subscribe(([ufs, estados]) => {
      this.ufsOptions = ufs.sort((a, b) => a.nome.localeCompare(b.nome));
      this.estados = estados.sort((a, b) => a.nome.localeCompare(b.nome));
      subject.next();
    }));
    return subject;
  }

  registerFormListeners() {
    this.$subscriptions.forEach(s => s.unsubscribe());
    this.instituicoes.controls.forEach((control, index) => {
      this.$subscriptions.push(
        control.get('estado').valueChanges.subscribe((uf) => {
          this.getCidadesByUfToFormControl(uf, control, index);
        }),
        control.get('cidade').valueChanges.subscribe((cidade) => {
          if (this.preventCidadeChange) {
            this.preventCidadeChange = false;
            return;
          }
          this.resetNecessaryFormsFields();
          const uf = control.get('estado').value;
          this.getClinicasByUfCidadeToFormControl(uf, cidade, control, index);
        })
      );
    })
  }

  loadDadosMedico() {
    const subject: Subject<any> = new Subject<any>();
    loading(this.adminService.getDadosMedicoById(this.medicoId).subscribe((usuario) => {
      this.usuario = usuario;
      subject.next();
    }));
    return subject;
  }

  getClinicasByUfCidadeToFormControl(uf, cidade, control, index, setUniqueValue = true) {
    this.loadingClinicas[index] = true;
    const subject = new Subject();
    loading(this.clinicaService.getClinicas(uf, cidade).subscribe((clinicas) => {
      this.clinicas[index] = clinicas.map(clinica => ({ nome: clinica.nome, valor: clinica._id }));
      if (clinicas.length === 1 && setUniqueValue) {
        control.get('instituicao').setValue(this.clinicas[index][0].valor);
      }
      subject.next();
      this.loadingClinicas[index] = false;
    }));
    return subject;
  }

  getCidadesByUfToFormControl(uf, control, index, setOnControl = true) {
    this.loadingCidades[index] = true;
    const subject = new Subject();
    loading(this.dominioService.getCidadesByUf(uf).subscribe((cidades) => {
      ['cidade', 'instituicao'].forEach(c => control.get(c).setValue('', { emitEvent: false }));
      this.cidades[index] = cidades;
      if (cidades.length === 1 && setOnControl) {
        control.get('cidade').setValue(cidades[0].valor);
      }
      subject.next();
      this.loadingCidades[index] = false;
    }));
    return subject;
  }

  handleClickAdicionarCrm() {
    if (this.crms.length < 5) {
      this.crms.push(formCrmTemplate());
    } else {
      toast('Atingida a quantidade máxima de registros para CRM', 'warning');
    }
  }

  handleClickDeleteCrm() {
    this.crms.removeAt(this.crms.length - 1);
  }

  handleClickAdicionarInstituicao() {
    if (this.instituicoes.length < 5) {
      const lastCidadesOptionsIndex = Math.max(...Object.keys(this.cidades).map(n => Number(n)));
      this.cidades[lastCidadesOptionsIndex + 1] = [];
      this.instituicoes.push(formInstituicoesTemplate());
      this.registerFormListeners();
    } else {
      toast('Atingida a quantidade máxima de registros para Clínicas de atendimento', 'warning');
    }
  }

  handleClickDeleteInstituicao() {
    this.instituicoes.removeAt(this.crms.length - 1);
  }

  async setClinicaCadastrada(clinica: Clinica, controlIndex) {
    const selectedUfs = this.filteredUfsClinicas.map(c => c.nome);
    const hasClinicaOnUfCrm = selectedUfs.includes(clinica.uf);
    if (hasClinicaOnUfCrm) {
      this.clinicas[controlIndex] = clinica.grupo?.clinicas?.map(clinica => ({ nome: clinica.nome, valor: clinica._id }));
      const control = this.instituicoes.at(controlIndex);
      control.get('estado').setValue(clinica.uf, { emitEvent: false });
      this.getCidadesByUfToFormControl(clinica.uf, control, controlIndex, false).subscribe(() => {
        this.preventCidadeChange = true;
        control.get('cidade').setValue(clinica.cidade);
        this.getClinicasByUfCidadeToFormControl(clinica.uf, clinica.cidade, control, controlIndex, false)
          .subscribe(() => {
            control.get('instituicao').setValue(clinica._id);
          });
      });
    } else {
      await toast('A instituição selecionada não está presente nas ufs dos CRMs informados, cadastre um CRM na UF da instituição desejada');
    }
  }

  handleSalvarAlteracoesClick() {
    if (ValidatorsApp.formularioValido(this.formDadosPessoais) && this.crms.valid && this.instituicoes.valid) {
      const usuario: Usuario = {
        ...this.formDadosPessoais.getRawValue(),
        crmList : this.crms.getRawValue(),
        clinicaIdList: this.instituicoes.getRawValue().map((value) => value.instituicao)
      };
      usuario._id = this.usuario._id;
      usuario.nome = String(usuario.nome).toUpperCase();
      if (usuario.telefone) usuario.telefone = String(usuario.telefone).replace(/\D/g, '');
      loading(this.adminService.editarUsuario(usuario)
        .subscribe(async (usuario) => {
          await toast('Alterações salvas com sucesso', 'success');
          await this.handleCLickClose(true, { usuario });
        })
      );
    } else {
      toast('Por favor, preencha todos os campos corretamente antes de confirmar');
    }
  }
}
