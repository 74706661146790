import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-campanhas-card-detail',
  templateUrl: './campanhas-card-detail.component.html',
})
export class CampanhasCardDetailComponent {

  @Input() color: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() titleClasses: string;

  constructor() { }
}
