import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, zip } from 'rxjs';
import { loading } from '../../util/loading';
import { toast } from '../../util/toast';
import { ClinicaService } from '../../services/clinica.service';
import UsuarioService from '../../services/usuario.service';
import { AuthService } from '../../services/auth.service';
import { ValidatorsApp } from '../../util/validators';

const residenteFormTemplate = () => {
  return new FormGroup({
    emailResidente: new FormControl('', [Validators.required, ValidatorsApp.email]),
  });
};

@Component({
  selector: 'app-adicionar-residente',
  templateUrl: './adicionar-residente.component.html',
})
export class AdicionarResidenteComponent extends AbstractModal {

  idInstituicao: string;
  idMedico: string;
  form: FormGroup = new FormGroup({
    residentes: new FormArray([
      residenteFormTemplate()
    ])
  });

  constructor(public readonly injector: Injector,
              private clinicaService: ClinicaService,
              private usuarioService: UsuarioService) {
    super(injector);
  }

  get residentes() {
    return this.form.controls["residentes"] as FormArray;
  }

  handleAdicionarNovoStaff() {
    this.residentes.push(residenteFormTemplate());
  }

  handleRemoverStaff(index: number) {
    this.residentes.removeAt(index);
  }

  async handleConfirmClick() {
    if (!ValidatorsApp.formularioValido(this.form)) {
      toast('Preencha corretamente os dados antes de confirmar');
      return;
    }
    const residentes = this.residentes.getRawValue().map(residente => Object.assign({}, residente, {
      idInstituicao: this.idInstituicao,
      idMedico: this.idMedico
    }));
    const observables: Observable<any>[] = residentes.map((residente) => this.clinicaService.adicionarResidente(residente));
    await loading(zip(...observables).subscribe(async () => {
      this.usuarioService.executarRefreshToken();
      await toast('Residente adicionado(a) com sucesso');
      await this.handleCLickClose(true);
    }));
  }
}
