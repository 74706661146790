import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { ResponsiveService } from '../../services/responsive.service';
import { ModuloService } from '../../services/modulo.service';

@Component({
  selector: 'app-menu-modulos-icon',
  templateUrl: './menu-modulos-icon.component.html',
  styleUrls: ['./menu-modulos-icon.component.scss']
})
export class MenuModulosIconComponent implements OnInit {

  isSubModulesPopoverOpen: boolean = false;
  isSelected: boolean = false;
  moduloAtual: IMenuModuloConfig;

  @ViewChild('subModulesPopover') subModulesPopover;

  @Input() config: IMenuModuloConfig;
  @Input() iconsSize: 'large' | 'small' = 'large';
  @Input() moduloCountPacientes: { [key: string]: number };

  @Output() onModuloClick: EventEmitter<IMenuModuloConfig> = new EventEmitter<IMenuModuloConfig>();

  constructor(private responsiveService: ResponsiveService,
              private moduloService: ModuloService) { }

  getModuloChildName(config: IMenuModuloConfig) {
    if (config.modules?.some(module => module.name === this.moduloAtual?.name)) {
      return this.moduloAtual.title;
    } else {
      return config.title;
    }
  }

  ngOnInit() {
    this.moduloService.$moduloAtual.subscribe((modulo) => {
      this.moduloAtual = modulo;
      this.setIfIsSelectedModulo();
    });
  }

  setIfIsSelectedModulo() {
    if (this.config?.name === this.moduloAtual?.name && this.config?.available) {
      this.isSelected = true;
    } else {
      this.isSelected = !!this.config?.modules?.map(module => module.name).includes(this.moduloAtual?.name);
    }
  }

  handlePopoverWillPresent() {
    const isMobile = this.responsiveService.isMobilePlatform;
    const iconWidth = isMobile ? 130 : 106;
    const subModulesCount = (this.config.modules || []).length;
    this.subModulesPopover.el.style.setProperty('--width', `calc(${iconWidth}px * ${subModulesCount} + 2rem)`);
    this.subModulesPopover.el.style.setProperty('--backdrop-opacity', '.4');
  }

  handleModuloIconClick(event) {
    if (this.config.modules?.length) {
      this.presentSubModulesPopover(event);
    } else {
      this.onModuloClick.emit(this.config);
    }
  }

  handleSubModuloClick(config: IMenuModuloConfig) {
    this.isSubModulesPopoverOpen = false;
    setTimeout(() => {
      this.onModuloClick.emit(config);
    }, 100)
  }

  presentSubModulesPopover(event: Event) {
    this.subModulesPopover.event = event;
    this.isSubModulesPopoverOpen = true;
  }

  getPacientesCount(config: IMenuModuloConfig) {
    if (this.moduloCountPacientes) {
      if (config.modules?.length) {
        return config.modules.reduce((acc, module) => {
          return acc += this.moduloCountPacientes[module.name] ? this.moduloCountPacientes[module.name] : 0;
        }, 0);
      } else {
        return this.moduloCountPacientes[config.name] ? this.moduloCountPacientes[config.name] : 0;
      }
    }
  }
}
