import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  static transform(value: string, ...args: unknown[]): string {
    const telefoneRegex = /^(\d{2})(\d{8}|\d{9})$/;
    const matches = telefoneRegex.exec(value);

    if (matches) {
      const ddd = matches[1];
      const telefone = matches[2];

      if (telefone.length === 8) {
        return `(${ddd}) ${telefone.substr(0, 4)}-${telefone.substr(4)}`;
      } else {
        return `(${ddd}) ${telefone.substr(0, 5)}-${telefone.substr(5)}`;
      }
    }
    return value;
  }

  transform(value: any, ...args): any {
    return TelefonePipe.transform(value, args);
  }
}
