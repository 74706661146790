import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getBarChartOptions } from '../../util/bar-chart-options';
import { graficoBarraColors } from '../../constants/colors';
import { getKaplanMeierChartOptions } from '../../util/kaplan-meier-chart-options';
import { getKaplanMeierData } from '../../util/utils';
import { getDoughtnutChartOptions } from '../../util/doughtnut-chart-options';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { FichaService } from '../../services/ficha.service';
import { RelatorioService } from '../../services/relatorio.service';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-graficos',
  templateUrl: './tab-graficos.component.html',
  styles: [`
      swiper-container {
        --swiper-navigation-size: 16px;
        --swiper-navigation-color: var(--om-grey-secondary)
     }
  `]
})
export class TabGraficosComponent implements OnInit, OnDestroy {

  @ViewChild('swiperContainerRef') swiperContainerRef: any;

  moduloAtual: IMenuModuloConfig;
  chartsOptions: EChartsOption[];
  loadingGraficos: boolean = false;
  graficoVisivel: number;
  $subscriptions: Subscription[];

  constructor(private moduloService: ModuloService,
              private fichaService: FichaService,
              private relatorioService: RelatorioService) {
  }

  ngOnInit() {
    this.$subscriptions = [
      this.moduloService.$moduloAtual.subscribe((modulo) => {
        this.moduloAtual = modulo;
        this.loadGraficos();
      }),
      this.fichaService.$filtrosAvancados.subscribe(() => {
        this.loadGraficos();
      })
    ];
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  loadGraficos() {
    this.loadingGraficos = true;
    let filtro = this.fichaService.getFiltrosAvancados(this.moduloAtual.name);
    filtro = filtro?.length ? filtro : null;
    this.relatorioService.getDadosMiniDashboard(this.moduloAtual.name, filtro)
      .subscribe((graficos: any) => {
        const prepareDataTemp = (datasource) => {
          return datasource.map(tuple => {
            const newTuple = Object.assign({}, tuple, {value: tuple.valor});
            delete newTuple.valor;
            return newTuple;
          });
        };
        const datasources = Object.entries(graficos)
          .map(([label, data]) => ({label, data}))
          .sort((a: any, b: any) => a.label.localeCompare(b.label));

        const kaplanMeierKey = 'Kaplan-Meier SG';

        // Esse trecho de código garante que o Gráfico de Kaplan meier seja sempre o segundo gráfico
        const index = datasources.findIndex((item) => item.label === kaplanMeierKey);
        if (index > -1) {
          const item = datasources.splice(index, 1)[0];
          datasources.splice(1, 0, item);
        }
        //-------------------------------------------------------------

        this.chartsOptions = datasources.map((datasource: any, index) => {
          if (['Novos últimos 30 dias'].includes(datasource.label)) {
            return getBarChartOptions(
              prepareDataTemp(datasource.data),
              graficoBarraColors,
              'bottom',
              datasource.label,
              null,
              'pacientes'
            );
          } else if ([kaplanMeierKey].includes(datasource.label)) {
            return getKaplanMeierChartOptions(
              datasource.label,
              getKaplanMeierData(datasource.data)
            );
          } else {
            return getDoughtnutChartOptions(
              prepareDataTemp(datasource.data),
              graficoBarraColors,
              'bottom',
              datasource.label
            );
          }
        });
        this.loadingGraficos = false;
        setTimeout(this.initSwiper.bind(this), 100);
      });
  }

  initSwiper() {
    const element = this.swiperContainerRef?.nativeElement;
    if (!element) setTimeout(this.initSwiper, 100);
    else element.initialize();
  }
}
