import { Component } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'cna-image-cropper',
  templateUrl: './image-cropper.component.html',
  styles: [`ion-content { --background: black }`]
})
export class ImageCropperComponent {

  image: string;
  croppedImage: string;
  roundCropper: boolean = false;
  maintainAspectRatio: boolean = true;
  aspectRatio: number = 4 / 4;

  constructor(private modalController: ModalController) { }

  handleCropImageSuccess(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  handleCropImageFailure() {

  }

  async handleClickConfirm() {
    await this.modalController.dismiss({ confirm: true, base64: this.croppedImage });
  }

  async handleClickCancel() {
    await this.modalController.dismiss({ confirm: false });
  }
}
