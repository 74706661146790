import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import MenuService from '../../../../shared/services/menu.service';
import { ROUTE } from '../../../../shared/constants/route';
import { ConsentimentoService } from '../../../../shared/services/consentimento.service';
import { loading } from '../../../../shared/util/loading';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Subscription } from 'rxjs';
import { Consentimento } from '../../../../shared/models/ficha.model';

@Component({
  selector: 'app-termo-consentimento',
  templateUrl: './termo-consentimento.page.html',
})
export class TermoConsentimentoPage {

  idTermo: string;
  consentimento: Consentimento;
  assinaturaBase64: string;
  $subscriptions: Subscription[] = [];
  feedbackConfig;

  @ViewChild('signature') signaturePad: SignaturePadComponent|any;

  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 0.5,
    maxWidth: 2,
    canvasWidth: 400,
    canvasHeight: 150,
    backgroundColor: '#EBEBFA'
  };

  constructor(private router: Router,
              private menuService: MenuService,
              private route: ActivatedRoute,
              private consentimentoService: ConsentimentoService) { }

  async ionViewWillEnter() {
    await this.menuService.disableMenu();
    this.loadRouteParams();
  }

  async handleClickVoltar() {
    await this.router.navigate([ROUTE.LOGIN], { replaceUrl: true });
  }
  
  ngAfterViewInit() {
    const customizeCanvas = () => {
      const canvas = this.signaturePad?.elementRef?.nativeElement?.querySelector('canvas');
      if (canvas) canvas.style.border = 'unset';
      else setTimeout(customizeCanvas, 300);
    };
    customizeCanvas();
  }

  loadRouteParams() {
    this.$subscriptions.push(
      this.route.params.subscribe(async params => {
        if (params && params.id) {
          this.idTermo = params.id;
          this.loadTermoConsentimento(params.id);
        }
      })
    );
  }

  loadTermoConsentimento(id: string) {
    loading(this.consentimentoService.getById(id).subscribe((consentimento) => {
      this.consentimento = consentimento;
    }));
  }

  handleApagarClick() {
    this.signaturePad.clear();
    this.assinaturaBase64 = '';
  }

  handleDrawSignatureEnd() {
    this.assinaturaBase64 = this.signaturePad.toDataURL();
  }

  async handleRecusarClick() {
    await loading(this.consentimentoService.recusar(this.idTermo).subscribe((consentimento) => {
      this.feedbackConfig = {
        icon: 'close-circle-outline',
        text: 'Compreendo!'
      };
      this.consentimento = consentimento;
    }));
  }

  async handleConfirmarClick() {
    await loading(this.consentimentoService.aceitar(this.idTermo).subscribe((consentimento) => {
      this.feedbackConfig = {
        icon: 'c-check',
        text: 'Obrigado!'
      };
      this.consentimento = consentimento;
    }));
  }
}
