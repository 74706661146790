import { FilterAvancado, FilterConfig } from '../../../interfaces/filter-config';
import { SelectionOption } from '../../../components/_base/abstract-input';
import { AuthService } from '../../../services/auth.service';
import { parseISO } from 'date-fns';

const CENSURA_OPTIONS: SelectionOption[] = [
  {
    nome: 'Não censurado',
    valor: null
  },
  {
    nome: 'Perda de seguimento',
    valor: {
      tipo: 'perda'
    }
  },
  {
    nome: 'Óbito causa oncológica',
    valor: {
      tipo: 'obito',
      subtipo: 'oncologica'
    }
  },
  {
    nome: 'Óbito causa não-oncológica',
    valor: {
      tipo: 'obito',
      subtipo: 'nao-oncologica'
    }
  },
  {
    nome: 'Alta médica',
    valor: {
      tipo: 'alta'
    }
  }
];

const INSTITUICOES_OPTIONS: SelectionOption[] = AuthService.getUserInstituicoes().map((clinica) => ({
  nome: clinica.nome,
  valor: clinica._id
}));

const GENERO_OPTIONS: SelectionOption[] = [
  {
    nome: 'Masculino',
    valor: 'M'
  },
  {
    nome: 'Feminino',
    valor: 'F'
  }
];

const comporOptionsAvancado = (valores, chave, options, tipo): FilterAvancado => {
  const valoresMapping = {};
  const filtros = [];
  options.forEach(option => valoresMapping[option.nome] = option.valor);
  valores.forEach(valor => filtros.push(valoresMapping[valor]));
  return {
    tipo,
    detalhe: {
      chave,
      valores: filtros
    }
  }
};

const comporFiltroDataDefault = (valores, chave, tipo): FilterAvancado => {
  return {
    tipo,
    detalhe: {
      chave,
      valores: [
        {
          min: parseISO(valores.min?.split('/').reverse().join('-')),
          max: parseISO(valores.max?.split('/').reverse().join('-')),
        }
      ]
    }
  };
};

export const BASE_FILTER_CONFIG: FilterConfig[]  = [
  {
    titulo: 'Data diagnóstico',
    nome: 'dataDiagnostico',
    largura: '3',
    tipoFiltro: 'item',
    tipo: 'range-data',
    compor: (valores) => comporFiltroDataDefault(valores, 'dataDiagnostico', 'item')
  },
  {
    titulo: 'Data da ficha',
    nome: 'dataCriacao',
    largura: '3',
    tipoFiltro: 'ficha',
    tipo: 'range-data',
    compor: (valores) => comporFiltroDataDefault(valores, 'dataCriacao', 'ficha')
  },
  {
    titulo: 'Gênero',
    nome: 'genero',
    largura: '3',
    tipoFiltro: 'ficha',
    tipo: 'selecao-multipla',
    opcoes: GENERO_OPTIONS,
    compor: (valores) => comporOptionsAvancado(valores, 'paciente.genero', GENERO_OPTIONS, 'ficha'),
  },
  {
    titulo: 'Censura',
    nome: 'censura',
    largura: '3',
    tipoFiltro: 'censura',
    tipo: 'selecao-multipla',
    opcoes: CENSURA_OPTIONS,
    compor: (valores) => comporOptionsAvancado(valores, 'censura', CENSURA_OPTIONS, 'censura'),
  },
  {
    titulo: 'Instituição',
    nome: 'clinica',
    largura: '3',
    tipoFiltro: 'ficha',
    tipo: 'selecao-multipla',
    opcoes: INSTITUICOES_OPTIONS,
    compor: (valores) => comporOptionsAvancado(valores, 'clinica', INSTITUICOES_OPTIONS, 'ficha'),
  }
];
