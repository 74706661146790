import { Component } from '@angular/core';
import { BaseCustomInputComponent } from '../../_base/abstract-input';

@Component({
  selector: 'app-custom-textarea-input',
  templateUrl: './custom-textarea-input.component.html',
  styleUrls: ['./custom-textarea-input.component.scss'],
})
export class CustomTextareaInputComponent extends BaseCustomInputComponent {

  constructor() {
    super();
  }
}
