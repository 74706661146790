import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { ThreeWayToggleConfig } from '../../../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/pulmao-nsclc/util';

export const PAINEL_NGS_THREE_WAY_TOGGLE_CONFIG = {
  nonValueText: 'Não testado',
  negativeValueText: 'Testado sem alterações acionáveis',
  positiveValueText: 'Testado com alterações acionáveis'
};

export const INPUT_TOGGLE_LIST = [
  {
    label: 'EGFR (exon 18, 19 e 21)',
    name: 'EGFR_EXON_18_19_21',
    aliases: {
      false: 'selvagem',
      true: 'mutado',
    }
  },
  {
    label: 'EGFR (exon 20)',
    name: 'EGFR_EXON_20',
    aliases: {
      false: 'selvagem',
      true: 'mutado',
    }
  },
  {
    label: 'ALK',
    name: 'ALK',
    aliases: {
      false: 'sem rearranjo',
      true: 'com rearranjo',
    }
  },
  {
    label: 'ROS1',
    name: 'ROS1',
    aliases: {
      false: 'sem rearranjo',
      true: 'com rearranjo',
    }
  },
  {
    label: 'BRAF V600E',
    name: 'BRAF_V600E',
    aliases: {
      false: 'selvagem',
      true: 'mutado',
    }
  },
  {
    label: 'MET',
    name: 'MET',
    aliases: {
      false: 'selvagem',
      true: 'mutado',
    }
  },
  {
    label: 'RET',
    name: 'RET',
    aliases: {
      false: 'sem fusão',
      true: 'com fusão',
    }
  },
  {
    label: 'NK',
    name: 'NK',
    aliases: {
      false: 'sem fusão',
      true: 'com fusão',
    }
  },
  {
    label: 'NTRK',
    name: 'NTRK',
    aliases: {
      false: 'selvagem',
      true: 'mutado',
    }
  },
  {
    label: 'KRAS G12c',
    name: 'KRAS_G12c',
    aliases: {
      false: 'selvagem',
      true: 'mutado',
    }
  },
  {
    label: 'dMMR (MSI-H)',
    name: 'dMMR_(MSI-H)',
    aliases: {
      false: 'negativo',
      true: 'positivo',
    }
  },
  {
    label: 'TMB',
    name: 'TMB',
    aliases: {
      false: '<10',
      true: '> ou = 10',
    }
  },
  {
    label: 'Erbb2 (HER2)',
    name: 'Erbb2_(HER2)',
    aliases: {
      false: 'selvagem',
      true: 'mutado',
    }
  },
];

@Component({
  selector: 'app-input-painel-ngs',
  templateUrl: './input-painel-ngs.component.html',
})
export class InputPainelNgsComponent extends AbstractModal implements OnInit {

  ids: string;
  ficha: Ficha;
  offsetWidth = 10;
  fixedHeight = '180px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  inputToggleList = INPUT_TOGGLE_LIST;
  inputToggleMapping = {};
  form: FormGroup = new FormGroup({
    painelNGS: new FormControl()
  });
  painelNGSthreeWayToggleConfig: ThreeWayToggleConfig = PAINEL_NGS_THREE_WAY_TOGGLE_CONFIG;

  constructor(public readonly injector: Injector) {
    super(injector);
    this.initFormControls();
  }

  getInputLabel(inputName: string) {
    const formValue = this.form.get(inputName).value;
    const inputConfig = this.inputToggleMapping[inputName];
    return `${inputConfig.label} ${inputConfig.aliases[`${formValue}`]}`;
  }

  ngOnInit() {
    this.form.get('painelNGS').setValue(undefined);
    this.registerFormListeners();
    const { ids, values } = inputValueResolver('painelNGS', this.ficha.itens);
    if (ids) this.ids = ids;
    if (values) this.form.patchValue(values);
  }

  registerFormListeners() {
    this.form.get('painelNGS').valueChanges.subscribe((value) => {
      if (value) this.setHeight(735);
      else this.setHeight(180);
    });
  }

  initFormControls() {
    this.inputToggleList.forEach((input) => {
      this.form.addControl(input.name, new FormControl(false));
      this.inputToggleMapping[input.name] = input;
    });
  }

  async handleEndEditClick() {
    const itens: ItemFicha[] = Object.entries(this.form.getRawValue())
      .map(([key, value]) => {
      const item: ItemFicha = {
        chave: key,
        valor: value as any
      };
      if (this.ids[key]) item.id = this.ids[key];
      if (this.form.get('painelNGS').value !== true && key !== 'painelNGS') {
        item.valor = false;
      }
      return item;
    });
    await this.modalController.dismiss(itens);
  }

}
