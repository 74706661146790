import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { Ficha, ItemFicha } from '../../models/ficha.model';
import { LABEL_RESOLVER_MAPPING } from '../../constants/label-resolver';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';

@Component({
  selector: 'app-input-with-modal',
  templateUrl: './input-with-modal.component.html',
})
export class InputWithModalComponent implements OnChanges {

  @Input() label: string;
  @Input() truncateLabel: boolean = false;
  @Input() doubleHeight: boolean = false;
  @Input() breakText: boolean = false;
  @Input() truncateText: boolean = true;
  @Input() labelLength: number = 8;
  @Input() fontSize: 'small' | 'normal' = 'normal';
  @Input() options: { modalComponent: any };
  @Input() modalComponent: any;
  @Input() resolver: any;
  @Input() index: number;
  @Input() ficha: Ficha;
  @Input() textAlign: 'start' | 'center' = 'center';
  @Input() loading: boolean;
  @Input() padding: boolean = true;
  @Input() dynamicHeight: boolean = false;
  @Input() shouldModalOpen: () => Promise<boolean>;
  @Output() onCloseModalComponent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInputClick: EventEmitter<any> = new EventEmitter<any>();

  actualModule: IMenuModuloConfig;

  value: string;

  constructor(private modalHelper: CustomModalHelper,
              private moduloService: ModuloService) {
    moduloService.$moduloAtual.subscribe((actualModule) => {
      this.actualModule = actualModule;
    });
  }

  get _label() {
    if (this.truncateLabel) {
      return `${this.label.substr(0, this.labelLength)}.`;
    }
    return this.label;
  }

  async handleInputClick() {
    let executeOpenModal = true;
    if (this.shouldModalOpen) executeOpenModal = await this.shouldModalOpen();
    if (!this.modalComponent) {
      this.onInputClick.emit();
      return;
    }
    if (!executeOpenModal) { return; }
    const modal = await this.modalHelper.create({
      component: this.modalComponent,
      componentProps: {
        ficha: this.ficha,
        index: this.index
      },
      handler: (data) => {
        this.onCloseModalComponent.emit(data);
      },
      keyboardClose: true,
      backdropDismiss: true
    });

    await modal.present();
  }

  get brokenText(): string[] {
    return this.value.split('\n');
  }
  
  ngOnChanges(changes: SimpleChanges) {
    let items: ItemFicha[];
    if (changes?.ficha?.currentValue) this.ficha = changes.ficha.currentValue;
    if (changes?.resolver?.currentValue) this.resolver = changes.resolver.currentValue;
    if (this.ficha?.itens) items = this.ficha.itens;
    else items = [];
    if (this.resolver) {
      if (typeof this.resolver === 'function') {
        this.value = this.resolver();
      } else if (this.actualModule?.name) {
        const labelResolverFunction = LABEL_RESOLVER_MAPPING[this.actualModule.name];
        this.value = labelResolverFunction(this.resolver, items, this.index);
      }
    }
  }
}
