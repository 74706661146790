import { Component, OnInit } from '@angular/core';
import CustomModalHelper from '../../../../helper/custom-modal-helper';
import { DetalheLaudoComponent } from '../../../../modals/detalhe-laudo/detalhe-laudo.component';
import { CollapseItem } from '../../../arvore-exames/arvore-exames.component';
import AbstractTabFichaPaciente from '../../_base/abstract-tab-ficha-paciente';

@Component({
  selector: 'app-tab-imagem-radiologica',
  templateUrl: './tab-imagem-radiologica.component.html',
})
export class TabImagemRadiologicaComponent extends AbstractTabFichaPaciente implements OnInit {

  exames: CollapseItem[] = [
    {
      label: 'Abdome e Pelve',
      count: 10,
      items: [
        {
          label: 'Tomografia de abdome',
          count: 8,
          items: [
            {
              label: 'Superior com contraste',
              count: 0,
            },
            {
              label: 'Total sem contraste',
              count: 0,
            },
            {
              label: 'Total com contraste',
              count: 8,
            },
            {
              label: 'Superior sem contraste',
              count: 0,
            }
          ]
        },
        {
          label: 'Ultrassonografia de abdome',
          count: 2,
          items: [
            {
              label: 'Total',
              count: 2,
            },
            {
              label: 'Superior',
              count: 0,
            }
          ]
        },
        {
          label: 'Ressonância de abdome',
          count: 0,
          items: [
            {
              label: 'Superior com contraste',
              count: 0,
            },
            {
              label: 'Total sem contraste',
              count: 0,
            },
            {
              label: 'Total com contraste',
              count: 0,
            },
            {
              label: 'Superior sem contraste',
              count: 0,
            }
          ]
        }
      ],
    },
    {
      label: 'Tórax',
      count: 1,
      items: []
    },
    {
      label: 'Mamas',
      count: 0,
      items: []
    },
    {
      label: 'Crânio e Pescoço',
      count: 0,
      items: []
    },
    {
      label: 'Membros e articulações',
      count: 0,
      items: []
    },
    {
      label: 'Exames de corpo total',
      count: 0,
      items: []
    },
    {
      label: 'Outros',
      count: 0,
      items: []
    },
  ];

  constructor(public modalHelper: CustomModalHelper) {
    super(modalHelper);
  }

  ngOnInit() {}
}
