import { Component, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AbstractValueResolver } from '../../../app/modules/client/modulos-cancer/_base/abstract-resolver';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { InputWithModal } from '../../interfaces/input-with-modal';
import { Ficha, ItemFicha } from '../../models/ficha.model';

@Component({
  selector: 'app-data-diagnostico',
  templateUrl: './data-diagnostico.component.html',
})
export class DataDiagnosticoComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '360px';
  maxDate = moment().format('YYYY-MM-DD');
  fixedWidth = !this.responsiveService.isMobilePlatform ? '400px' : null;
  form: FormGroup = new FormGroup({
    dataDiagnostico: new FormControl(moment().set({ date: 1 }).format('YYYY-MM-DD'))
  });

  constructor(public override injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { dataDiagnostico, id } = AbstractValueResolver.dataDiagnostico(this.ficha.itens)
    this.id = id;
    if (dataDiagnostico !== undefined && dataDiagnostico !== null) this.form.patchValue({ dataDiagnostico });
  }

  handleClearInputClick() {
    const items: ItemFicha[] = [{ chave: 'dataDiagnostico', valor: void 0, id: this.id }];
    setTimeout(async () => {
      await this.modalController.dismiss(items);
    }, 100);
  }

  handleEndEditClick() {
    setTimeout(() => {
      const newItem: ItemFicha = {
        chave: 'dataDiagnostico',
        valor: this.form.get('dataDiagnostico').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      this.modalController.dismiss(items);
    }, 0);
  }
}


