import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, zip } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { parseISO } from 'date-fns';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { CriarFichaDto, Ficha } from '../../models/ficha.model';
import { Usuario } from '../../models/usuario.model';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { SelectionOption } from '../../components/_base/abstract-input';
import { ValidatorsApp } from '../../util/validators';
import { ModuloService } from '../../services/modulo.service';
import { AuthService } from '../../services/auth.service';
import { DominioService } from '../../services/dominio.service';
import { PacienteService } from '../../services/paciente.service';
import { FichaService } from '../../services/ficha.service';
import CustomAlertHelper from '../../helper/custom-alert-helper';
import { loading } from '../../util/loading';
import { toast } from '../../util/toast';
import { getNavigateRoutePath } from '../../constants/route';
import { fromPromise } from 'rxjs/internal-compatibility';
import { ConsentimentoService } from '../../services/consentimento.service';
import { Clinica } from '../../models/clinica.model';
import { ClinicaService } from '../../services/clinica.service';

@Component({
  selector: 'app-cadastro-paciente',
  templateUrl: './cadastro-paciente.component.html',
})
export class CadastroPacienteComponent extends AbstractModal {

  ficha: Ficha;
  usuario: Usuario;
  instituicaoAtual: Clinica;
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  fullWhenKeyboardOpen = true;
  $subscriptions: Subscription[];
  moduloAtual: IMenuModuloConfig;
  offsetWidth = 10;
  fixedHeight = '720px';
  localAtendimentoOptions: SelectionOption[];
  convenios: SelectionOption[];
  loadingConvenios: boolean = false;
  clinicasOptions: SelectionOption[];
  form: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.compose([Validators.required, ValidatorsApp.nome])),
    cpf: new FormControl('', [Validators.required, ValidatorsApp.cpf]),
    dataNascimento: new FormControl('', Validators.compose([Validators.required, ValidatorsApp.dataBr])),
    genero: new FormControl('', Validators.required),
    email: new FormControl('', [ValidatorsApp.email]),
    telefone: new FormControl(''),
    convenio: new FormControl(''),
    clinicaAtendimento: new FormControl(''),
    tipoAtendimento: new FormControl('', Validators.required),
  });

  constructor(public override injector: Injector,
              private router: Router,
              private moduloService: ModuloService,
              private authService: AuthService,
              private dominioService: DominioService,
              private pacienteService: PacienteService,
              private fichaService: FichaService,
              private alertHelper: CustomAlertHelper,
              private consentimentoService: ConsentimentoService,
              private clinicaService: ClinicaService) {
    super(injector);
    pacienteService.$nomePacienteTermoPesquisa.subscribe(this.setNomePesquisado.bind(this));
  }

  get confirmButtonText(): string {
    const emailControl = this.form.get('email');
    const telefoneControl = this.form.get('telefone');
    if (this.ficha?._id) return '';
    if (emailControl.value && emailControl.valid) return 'Enviar termo de consentimento';
    if (telefoneControl.value && telefoneControl.valid) return 'Enviar termo de consentimento';
    return '';
  }

  ngOnInit() {
    this.$subscriptions = [
      this.moduloService.$moduloAtual.subscribe(this.handleChangeModuloAtual.bind(this)),
    ];
    this.usuario = this.authService.getUser();
    this.instituicaoAtual = this.clinicaService.getInstituicaoAtual();
    this.verifyModalHeight();
    this.verifyFormValidators();
    this.loadDominios();
    if (this.ficha) {
      this.loadPaciente();
    }
    this.setClinicasOptions();
    this.registerFormListeners();
  }

  ngOnDestroy() {
    this.$subscriptions.forEach((sub) => sub.unsubscribe());
  }

  verifyModalHeight(forceUpdate?: boolean) {
    if (this.instituicaoAtual?.tipoAtendimento?.nome === 'Público') {
      const height = 525;
      this.fixedHeight = `${height}px`;
      if (forceUpdate) this.setHeight(height);
    } else if (this.instituicaoAtual?.tipoAtendimento?.nome === 'Privado') {
      const height = 580;
      this.fixedHeight = `${height}px`;
      if (forceUpdate) this.setHeight(height);
    } else {
      if (forceUpdate) this.setHeight(720);
    }
  }

  verifyFormValidators() {
    if (['Público', 'Privado'].includes(this.instituicaoAtual?.tipoAtendimento?.nome)) {
      this.form.get('tipoAtendimento').removeValidators(Validators.required);
    } else {
      this.form.get('tipoAtendimento').addValidators(Validators.required);
    }
  }

  registerFormListeners() {
    this.form.get('nome').disable();
    this.form.get('dataNascimento').disable();
    this.form.get('genero').disable();
    const cpfControl = this.form.get('cpf');
    const clinicaAtendimentoControl =  this.form.get('clinicaAtendimento');
    cpfControl.valueChanges.subscribe(async (cpf) => {
      const replacedValue = cpf?.replace(/\D/g, '');
      if (replacedValue?.length === 11 && !this.ficha?._id && cpfControl.valid) {
        await loading(this.fichaService.consultarFichaExistente({ cpf, moduloId: this.moduloAtual.id }).subscribe(async (response: any) => {
          if (response.idFicha) {
            await this.openFichaExistenteAlert(response.idFicha);
          } else {
            await loading(fromPromise(this.pacienteService.consultarCpf(replacedValue, this.moduloAtual.name)).subscribe((pessoa: any) => {
              if (pessoa?.nome) {
                const nomeControl = this.form.get('nome');
                nomeControl.setValue(pessoa.nome);
                nomeControl.disable();
              }
              if (pessoa?.nascimento) {
                const dataNascimentoControl = this.form.get('dataNascimento');
                dataNascimentoControl.setValue(pessoa.nascimento);
                dataNascimentoControl.disable();
              }
              if (pessoa?.genero) {
                const generoControl = this.form.get('genero');
                generoControl.setValue(pessoa.genero);
                generoControl.disable();
              }
              if (!!pessoa.erro) {
                toast('O CPF informado não existe', 'danger');
                this.form.get('nome').setValue('');
                this.form.get('dataNascimento').setValue('');
                this.form.get('nome').disable();
                this.form.get('dataNascimento').disable();
              }
            }));
          }
        }));
      }
    });
    clinicaAtendimentoControl.valueChanges.subscribe((clinicaId) => {
      this.instituicaoAtual = this.usuario.clinicaList.find(clinica => clinica._id === clinicaId);
      this.verifyModalHeight(true);
      this.verifyFormValidators();
    });
  }

  async openFichaExistenteAlert(idFicha: string) {
    const alert = await this.alertHelper.create({
      title: 'Ficha existente',
      text: 'Foi identificado que o paciente já possui ficha cadastrada nesse módulo, deseja abrir a ficha?',
      cancelText: 'Não',
      okText: 'Sim',
      confirmButtonType: 'brand',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          await this.handleCLickClose();
          const navigationPath = getNavigateRoutePath(this.moduloAtual.name);
          const extras: any = {
            queryParams: { ficha: idFicha },
            queryParamsHandling: 'replace',
            replaceUrl: true,
          };
          await this.router.navigate([navigationPath.FICHA_PACIENTE], extras);
        } else {
          this.form.get('cpf').setValue('');
        }
      }
    });
    await alert.present();
  }

  setNomePesquisado(nome) {
    this.form.patchValue({nome});
  }

  loadPaciente() {
    this.setHeight(775);
    const { paciente, clinica, tipoAtendimento } = this.ficha;
    const { nome, cpf, telefone, convenio, email, genero } = paciente;
    const newDataNascimento = new Date(paciente.dataNascimento).toLocaleDateString('pt-BR', {timeZone: 'UTC'});
    this.form.patchValue({
      cpf,
      nome,
      tipoAtendimento,
      convenio,
      genero,
      email,
      dataNascimento: newDataNascimento,
      clinicaAtendimento: clinica?._id
    });
    if (telefone) {
      setTimeout(() => {
        this.form.get('telefone').setValue(telefone)
      }, 100);
    }
    this.form.get('cpf').disable({ emitEvent: false });
    this.form.get('genero').disable({ emitEvent: false });
  }

  loadDominios() {
    this.loadingConvenios = true;
    loading(zip(
      this.dominioService.getTipoAtendimento(),
      // this.dominioService.getConvenios(),
    ).subscribe(([tiposAtendimento]) => {
      this.localAtendimentoOptions = tiposAtendimento;
      // this.convenios = convenios;
      this.loadingConvenios = false;
      this.setLocalAtendimentoDefault();
    }));
  }

  setClinicasOptions() {
    this.clinicasOptions = this.usuario.clinicaList.map(clinica => ({
      nome: clinica.nome,
      valor: clinica._id
    }));
    if (this.clinicasOptions.length === 1) {
      const clinicaOption: SelectionOption = this.clinicasOptions[0];
      this.form.get('clinicaAtendimento').setValue(clinicaOption.valor);
    } else if (this.instituicaoAtual) {
      this.form.get('clinicaAtendimento').setValue(this.instituicaoAtual._id);
    }
  }

  setLocalAtendimentoDefault() {
    const localAtendimentoOption: SelectionOption = this.localAtendimentoOptions[1];
    if (!this.ficha) this.form.get('tipoAtendimento').setValue(localAtendimentoOption.valor);
  }

  handleChangeModuloAtual(modulo: IMenuModuloConfig) {
    this.moduloAtual = modulo;
  }

  async handleExcluirPacienteClick() {
    const alert = await this.alertHelper.create({
      title: 'Exclusão de paciente',
      text: 'Deseja remover permanentemente os dados deste paciente?',
      cancelText: 'Cancelar',
      okText: 'Excluir',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      confirmButtonIcon: 'trash-outline',
      handler: ({data}) => {
        if (data.confirm) {
          this.fichaService.excluir(this.ficha).subscribe(async () => {
            await this.handleCLickClose(false, {excluirFicha: true});
          });
        }
      }
    });

    await alert.present();
  }

  async handleClickConfirm() {
    const values = this.form.getRawValue();
    const { nome, dataNascimento } = values;
    if (!ValidatorsApp.formularioValido(this.form, null, { emitEvent: false }) || !nome || !dataNascimento) {
      toast('Preencher todos os campos obrigatórios');
      return;
    }

    values.nome = String(nome).toUpperCase();
    values.dataNascimento = parseISO(dataNascimento?.split('/').reverse().join('-'));

    const criarFichaDto = this.getCriarFichaDto(values);

    const clinicaId = this.form.get('clinicaAtendimento').value;
    if (clinicaId) criarFichaDto.clinicaId = clinicaId;

    if (criarFichaDto.pacienteDto.telefone) {
      criarFichaDto.pacienteDto.telefone = String(criarFichaDto.pacienteDto.telefone).replace(/\D/g, '');
    }

    if (this.ficha?._id) {
      loading(this.fichaService.atualizarFicha(criarFichaDto, this.ficha).subscribe(async (ficha) => {
        await this.modalController.dismiss({ confirm: true, ficha });
      }));
      return;
    }

    loading(this.fichaService.criar(criarFichaDto).subscribe(async (ficha) => {
      await this.handleCLickClose(true);
      if (criarFichaDto.pacienteDto?.email) this.consentimentoService.enviar(ficha._id).subscribe();
      const navigationPath = getNavigateRoutePath(this.moduloAtual.name);
      this.verifyInstituicaoAtualUpdate();
      setTimeout(async () => {
        const extras: any = {
          queryParams: { ficha: ficha._id },
          queryParamsHandling: 'replace',
          replaceUrl: true,
        };
        await this.router.navigate([navigationPath.FICHA_PACIENTE], extras);
      }, 100);
    }));
  }

  verifyInstituicaoAtualUpdate() {
    const instituicaoAtualSelecionadaStorage: Clinica = this.clinicaService.getInstituicaoAtual();
    if (instituicaoAtualSelecionadaStorage?._id !== this.instituicaoAtual?._id) {
      this.clinicaService.setInstituicaoAtual(this.instituicaoAtual);
    }
  }

  getCriarFichaDto(pacienteDto): CriarFichaDto {
    const criarFichaDto: any = {
      pacienteDto,
      moduloId: this.moduloAtual?.id,
    };
    if (['Público', 'Privado'].includes(this.instituicaoAtual?.tipoAtendimento?.nome)) {
      criarFichaDto.clinicaId = this.instituicaoAtual._id;
      criarFichaDto.tipoAtendimentoId = this.instituicaoAtual.tipoAtendimento._id;
    } else {
      criarFichaDto.clinicaId = this.form.get('clinicaAtendimento').value;
      criarFichaDto.tipoAtendimentoId = this.form.get('tipoAtendimento').value;
    }
    return criarFichaDto;
  }
}
