import { Component, OnInit, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { FormGroup, FormControl } from '@angular/forms';
import { ResponsiveService } from '../../../../services/responsive.service';
import { ItemFichaService } from '../../../../services/item-ficha.service';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { ItemFicha } from '../../../../models/ficha.model';

@Component({
  selector: 'app-input-ki67',
  templateUrl: './input-ki.component.html',
})
export class InputKiComponent extends AbstractModal implements InputWithModal {
  
  fichaPaciente: any;
  id: string;
  offsetWidth = 10;
  fixedHeight = '290px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  
  
  form: FormGroup = new FormGroup({
    ki67: new FormControl('')
  });
  
  ki67Options = [
    '≤10',
    '>10',
    'Desconhecido'
  ];
  
  constructor(public override injector: Injector,
              public responsiveService: ResponsiveService,
              public itemFichaService: ItemFichaService) {
    super(injector);
  }
  
  ngOnInit() {
    const { ki67, id } = inputValueResolver('ki67', this.fichaPaciente?.itens);
    this.id = id;
    if (ki67 !== undefined) this.form.patchValue({ ki67 });
  }
  
  async handleEndEditClick() {
    if (this.form.get('ki67').pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: 'ki67',
        valor: this.form.get('ki67').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
