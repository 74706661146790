import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (prefixo: string = ''): RangeConfig => ({
  options: [
    {
      label: `${prefixo}Nx`,
      value: `${prefixo}Nx`,
      hint: 'Linfonodos regionais não podem ser avaliados'
    },
    {
      label: `${prefixo}N0`,
      value: `${prefixo}N0`,
      hint: 'Sem envolvimento tumoral dos linfonodos regionais'
    },
    {
      label: `${prefixo}N1`,
      value: `${prefixo}N1`,
      hint: 'Envolvimento tumoral dos linfonodos regionais',
      options: [
        {
          label: `${prefixo}N1`,
          value: `${prefixo}N1`,
          hint: 'Envolvimento tumoral dos linfonodos regionais',
        },
        {
          label: `${prefixo}N1a`,
          value: `${prefixo}N1a`,
          hint: 'Envolvimento tumoral dos linfonodos inguinais, mesorretais, superior do reto, ilíaco interno ou obturador'
        },
        {
          label: `${prefixo}N1b`,
          value: `${prefixo}N1b`,
          hint: 'Envolvimento tumoral do linfonodo ilíaco externo'
        },
        {
          label: `${prefixo}N1c`,
          value: `${prefixo}N1c`,
          hint: 'Envolvimento tumoral de N1b (ilíaco externo) com qualquer linfonodo N1a'
        }
      ]
    }
  ]
});
