import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from '../shared';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthorizationInterceptor } from '../shared/interceptors/authorization.interceptor';
import { NgxEchartsModule } from 'ngx-echarts';
import { OnboardingPage } from './modules/client/onboarding/onboarding.page';
import { LoginPage } from './modules/client/login/login.page';
import { CadastroUsuarioPage } from './modules/client/cadastro-usuario/cadastro-usuario.page';
import { TermoCondicoesPage } from './modules/client/termo-condicoes/termo-condicoes.page';
import { FaleConoscoPage } from './modules/client/fale-conosco/fale-conosco.page';
import { PoliticaPrivacidadePage } from './modules/client/politica-privacidade/politica-privacidade.page';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConvitePage } from './modules/client/convite/convite.page';
import { ConfirmacaoCadastroPage } from './modules/client/confirmacao-cadastro/confirmacao-cadastro.page';
import { TermoConsentimentoPage } from './modules/client/termo-consentimento/termo-consentimento.page';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

@NgModule({
  declarations: [
    AppComponent,
    OnboardingPage,
    LoginPage,
    CadastroUsuarioPage,
    TermoCondicoesPage,
    FaleConoscoPage,
    PoliticaPrivacidadePage,
    ConvitePage,
    ConfirmacaoCadastroPage,
    TermoConsentimentoPage
  ],
  entryComponents: [],
  imports: [
    IonicModule.forRoot({
      animated: false,
      navAnimation: (): any => ({})
    }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    VirtualScrollerModule,
    AngularEditorModule,
    AngularSignaturePadModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    })
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor, multi: true
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
