import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { register } from 'swiper/element/bundle';

register();

export let injector: Injector;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {
    injector = ref.injector;
    window[`injector`] = injector;
  })
  .catch(err => console.log(err));
