import * as moment from 'moment';

export const inputErrors = {
  default: 'Valor inválido',
  invalid: 'Valor inválido',
  not_num: 'Número inválido',
  required: 'Campo obrigatório',
  length: 'Quantidade de caracteres inválida',
  min: 'Valor não permitido',
  max: 'Valor não permitido',
  minlength: 'Quantidade de caracteres inválida',
  maxlength: 'Quantidade de caracteres inválida',
  cpf: 'CPF inválido',
  cnpj: 'CNPJ inválido',
  invalid_date: 'Data inválida',
  invalid_hour: 'Hora inválida',
  invalid_cep: 'CEP inválido',
  invalid_uf: 'UF inválida',
  invalid_municipio: 'Município inválido',
  invalid_tel: 'Número de telefone inválido',
  max_date: 'Data início não pode ser maior que data fim',
  invalid_date_more_1_year: 'O ano de não pode ser maior que ',
  tipo_marcacao: 'Selecione o tipo',
  nome: 'Deve iniciar com letras e conter mais de 2 caracteres',
  email: 'E-mail inválido',
  url: 'Url inválida',
  confirmarEmail: 'E-mail não é igual',
  dataBetween: 'Data não pode ser menor',
  invalid_card: 'Número de cartão inválido',
  invalid_card_date: 'Data de vencimento inválida',
  invalid_age: 'Você precisa ter mais de 18 anos para participar',
  pass_notequals: '"Nova senha" e "Repetir senha" devem ser iguais',
  same_password: '"Senha antiga" e "Nova senha" não podem ser iguais',
  invalid_psa_date: `Só são permitidas datas entre 1990 e ${moment().format('YYYY')}`,
};
