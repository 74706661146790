import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { FormControl, FormGroup } from '@angular/forms';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';

export const RE_CHAVE = 're';
export const RP_CHAVE = 'rp';

@Component({
  selector: 'app-input-re-rp',
  templateUrl: './input-re-rp.component.html',
})
export class InputReRpComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  index: number;
  idRe: string;
  idRp: string;
  analitico: boolean;
  data: Date;
  offsetWidth = 10;
  fixedHeight = '525px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    re: new FormControl(0),
    rp: new FormControl(0)
  });

  desconhecidoForm: FormGroup = new FormGroup({
    re: new FormControl(false),
    rp: new FormControl(false)
  });

  positivoForm: FormGroup = new FormGroup({
    re: new FormControl(false),
    rp: new FormControl(false)
  });

  reRangeOptions: RangeConfig = {
    max: 100,
    step: 5,
    showPercentageSteps: true,
    firstStep: 1
  };

  rpRangeOptions: RangeConfig = {
    max: 100,
    step: 5,
    showPercentageSteps: true,
    firstStep: 1
  };

  constructor(public override injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    let { re, rp, idRe, idRp, data, analitico } = inputValueResolver('reRp', this.ficha?.itens, this.index);
    this.idRe = idRe;
    this.idRp = idRp;
    this.data = data;
    this.analitico = analitico;
    if (re !== undefined && rp !== undefined) {
      this.form.patchValue({
        re : re && re !== 'positivo' ? re : 0,
        rp : rp && rp !== 'positivo' ? rp : 0
      });
    }

    const desconhecidoValues: any = {};
    const positivoValues: any = {};
    desconhecidoValues.re = re === null;
    desconhecidoValues.rp = rp === null;
    positivoValues.re = re === 'positivo';
    positivoValues.rp = rp === 'positivo';
    this.desconhecidoForm.patchValue({...desconhecidoValues});
    this.positivoForm.patchValue({...positivoValues});
    this.registerFormListeners();
  }

  registerFormListeners() {
    const reFormControl = this.form.get(RE_CHAVE);
    const rpFormControl = this.form.get(RP_CHAVE);
    const reDesconhecidoFormControl = this.desconhecidoForm.get(RE_CHAVE);
    const rpDesconhecidoFormControl = this.desconhecidoForm.get(RP_CHAVE);
    const rePositivoFormControl = this.positivoForm.get(RE_CHAVE);
    const rpPositivoFormControl = this.positivoForm.get(RP_CHAVE);
    rePositivoFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.re.setValue(0);
        reDesconhecidoFormControl.setValue(false, { emitEvent : false });
      }
    });
    rpPositivoFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.rp.setValue(0);
        rpDesconhecidoFormControl.setValue(false, { emitEvent : false });
      }
    });
    reDesconhecidoFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.re.setValue(0);
        rePositivoFormControl.setValue(false, { emitEvent : false });
      }
    });
    rpDesconhecidoFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.rp.setValue(0);
        rpPositivoFormControl.setValue(false, { emitEvent : false });
      }
    });
    reFormControl.valueChanges.subscribe((value) => {
      if (Number(value) !== 0) {
        reDesconhecidoFormControl.setValue(false, { emitEvent : false });
        rePositivoFormControl.setValue(false, { emitEvent : false });
      }
    });
    rpFormControl.valueChanges.subscribe((value) => {
      if (Number(value) !== 0) {
        rpDesconhecidoFormControl.setValue(false, { emitEvent : false });
        rpPositivoFormControl.setValue(false, { emitEvent : false });
      }
    });
  }

  handleClearInputClick() {
    const items: ItemFicha[] = [
      { chave: 're', valor: void 0, id: this.idRe },
      { chave: 'rp', valor: void 0, id: this.idRp },
    ];
    setTimeout(async () => {
      await this.modalController.dismiss(items);
    }, 100);
  }

  async handleEndEditClick() {
    let newItemRe: ItemFicha;
    let newItemRp: ItemFicha;
    if (this.positivoForm.get(RE_CHAVE).value) {
      newItemRe = { chave: RE_CHAVE,  valor: 'positivo' };
    } else {
      newItemRe = {
        chave: RE_CHAVE,
        valor: !this.desconhecidoForm.get(RE_CHAVE).value ? Number(this.form.get(RE_CHAVE).value) : null
      };
    }

    if (this.positivoForm.get(RP_CHAVE).value) {
      newItemRp = { chave: RP_CHAVE, valor: 'positivo' };
    } else {
      newItemRp = {
        chave: RP_CHAVE,
        valor: !this.desconhecidoForm.get(RP_CHAVE).value ? Number(this.form.get(RP_CHAVE).value) : null
      };
    }

    const hasItemRe = this.ficha.itens?.some(item => item.chave === RE_CHAVE);
    const hasItemRp = this.ficha.itens?.some(item => item.chave === RP_CHAVE);

    if ((!hasItemRe && !hasItemRp) || this.analitico) {
      newItemRe.analitico = true;
      newItemRp.analitico = true;
    }
    if (this.idRe) newItemRe.id = this.idRe;
    if (this.idRp) newItemRp.id = this.idRp;
    if (this.data) {
      newItemRe.data = this.data;
      newItemRp.data = this.data;
    }
    const items = [].concat(newItemRe, newItemRp);
    await this.modalController.dismiss(items);
  }
}
