import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { Ficha } from '../../models/ficha.model';
import { toast } from '../../util/toast';
import { ConsentimentoService } from '../../services/consentimento.service';
import { loading } from '../../util/loading';

@Component({
  selector: 'app-termo-consentimento',
  templateUrl: './termo-consentimento.component.html',
})
export class TermoConsentimentoComponent extends AbstractModal {

  ficha: Ficha;
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  fixedHeight = '300px';

  constructor(public readonly injector: Injector,
              private consentimentoService: ConsentimentoService) {
    super(injector);
  }

  handleEnviarEmailClick() {
    if (!this.ficha.paciente.email) {
      toast('O preenchimento do E-mail do paciente é necessário para realizar essa solicitação', 'warning');
      return;
    }
    loading(this.consentimentoService.enviar(this.ficha._id).subscribe(() => {
      toast('Termo de consentimento enviado com sucesso');
      this.modalController.dismiss();
      this.consentimentoService.setConsentimentoAtualizado(this.ficha.consentimento?._id, this.ficha._id);
    }));
  }
}
