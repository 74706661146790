import { CustomInputMoleculeComponent } from '../components/custom-input/custom-input.component';
import { CustomTextInputComponent } from '../components/custom-input/custom-text-input/custom-text-input.component';
import { StatusBadgeComponent } from '../components/status-badge/status-badge.component';
import { PacienteListActionsComponent } from '../components/paciente-list-actions/paciente-list-actions.component';

export const getAllRegisteredModules = () => {
  return {
    'app-custom-input': CustomInputMoleculeComponent,
    'app-custom-text-input': CustomTextInputComponent,
    'status-badge': StatusBadgeComponent,
    'app-paciente-list-actions': PacienteListActionsComponent
  };
};

