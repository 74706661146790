import { TabsFichaPacienteComponent } from './tabs-ficha-paciente.component';
import { TabImagemRadiologicaComponent } from './components/tab-imagem-radiologica/tab-imagem-radiologica.component';
import { TabImagemDiretaComponent } from './components/tab-imagem-direta/tab-imagem-direta.component';
import { TabAnatomoPatologiaComponent } from './components/tab-anatomo-patologia/tab-anatomo-patologia.component';

export const COMPONENTS = [
  TabsFichaPacienteComponent,
  TabImagemRadiologicaComponent,
  TabImagemDiretaComponent,
  TabAnatomoPatologiaComponent
];
