import { DataDiagnosticoComponent } from './data-diagnostico/data-diagnostico.component';
import { InputSexoComponent } from './input-sexo/input-sexo.component';
import { InputTipoAtendimentoComponent } from './input-tipo-atendimento/input-tipo-atendimento.component';
import { InputGrauComponent } from './modulo-mama/input-grau/input-grau.component';
import { InputGrupoEstadiamentoComponent } from './modulo-mama/input-grupo-estadiamento/input-grupo-estadiamento.component';
import { InputHerComponent } from './modulo-mama/input-her/input-her.component';
import { InputHistopatologicoComponent } from './modulo-mama/input-histopatologico/input-histopatologico.component';
import { InputKiComponent } from './modulo-mama/input-ki/input-ki.component';
import { InputMComponent } from './modulo-mama/input-m/input-m.component';
import { InputNComponent } from './modulo-mama/input-n/input-n.component';
import { InputReRpComponent } from './modulo-mama/input-re-rp/input-re-rp.component';
import { InputTComponent } from './modulo-mama/input-t/input-t.component';
import { InputTipoTumoralComponent } from './modulo-mama/input-tipo-tumoral/input-tipo-tumoral.component';

export const MODALS = [
  DataDiagnosticoComponent,
  InputSexoComponent,
  InputTipoAtendimentoComponent,
  InputGrauComponent,
  InputGrupoEstadiamentoComponent,
  InputHerComponent,
  InputHistopatologicoComponent,
  InputKiComponent,
  InputMComponent,
  InputNComponent,
  InputReRpComponent,
  InputTComponent,
  InputTipoTumoralComponent
];
