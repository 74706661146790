import { AfterContentInit, Component, Injector, OnInit } from '@angular/core';
import { FilterConfig } from '../../interfaces/filter-config';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { FormControl, FormGroup } from '@angular/forms';
import { RangeConfig } from '../../components/_base/abstract-input';
import * as moment from 'moment';

@Component({
  selector: 'app-filtro-dinamico',
  templateUrl: './filtro-dinamico.component.html',
})
export class FiltroDinamicoComponent extends AbstractModal implements OnInit, AfterContentInit {

  offsetWidth = 10;
  fixedHeight = '400px';
  fixedWidth = '420px';
  config: FilterConfig;
  modalHeaderHeight = 85;
  modalActionButtonsHeight = 90;
  rangeConfig: RangeConfig;
  form: FormGroup = new FormGroup({});
  value: any;
  passoSelecaoData: 'data-inicio' | 'data-fim' = 'data-inicio';

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  get multipleSelectionOptions(): string[] {
    return this.config?.opcoes?.map(opcao => opcao.nome);
  }

  ngOnInit() {
    this.setRangeConfig();
    this.buildFormControls();
  }

  setRangeConfig() {
    this.rangeConfig = {
      dualKnobs: true,
      min: this.config?.configuracaoRangeNumerico?.min,
      max: this.config?.configuracaoRangeNumerico?.max,
      value: {
        lower: this.config?.configuracaoRangeNumerico?.min,
        upper: this.config?.configuracaoRangeNumerico?.max
      }
    }
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
    this.setModalCustomHeight();
  }

  buildFormControls() {
    if (this.config.tipo === 'range-numerico') {
      this.form.addControl('filtros', new FormControl({
        lower: 0,
        upper: 100,
      }));
    }
    if (this.config.tipo === 'selecao-multipla') {
      const value = this.value ? this.value : [];
      this.form.addControl('filtros', new FormControl(value));
    }
    if (this.config.tipo === 'selecao-unica') {
      const value = this.value ? this.value : '';
      this.form.addControl('filtros', new FormControl(value));
    }
    if (this.config.tipo === 'range-data') {
      const dataAtual = moment().set({ date: 1 }).format('YYYY-MM-DD');
      this.form.addControl('min', new FormControl(this.value?.min || dataAtual));
      this.form.addControl('max', new FormControl(this.value?.max || dataAtual));
    }
  }

  setModalCustomHeight() {
    if (this.config.alturaDinamica === false) { return; }
    switch (this.config.tipo) {
      case 'selecao-unica':
        this.setSelecaoUnicaHeight();
        break;
      case 'selecao-multipla':
        this.setSelecaoMultiplaHeight();
        break
      case 'range-numerico':
        this.setHeight(250);
        break;
      case 'range-data':
        this.setHeight(390);
    }
  }

  setSelecaoUnicaHeight() {
    const optionsHeight = this.config?.opcoes?.length * 50;
    this.setHeight(this.modalHeaderHeight + this.modalActionButtonsHeight + optionsHeight);
  }

  setSelecaoMultiplaHeight() {
    const optionsHeight = this.config?.opcoes?.length * 50;
    this.setHeight(this.modalHeaderHeight + this.modalActionButtonsHeight + optionsHeight);
  }

  async handleConfirmClick() {
    if (this.config.tipo === 'range-data') {
      if (this.passoSelecaoData === 'data-inicio') {
        this.passoSelecaoData = 'data-fim';
      } else {
        const min = this.form.get('min').value;
        const max = this.form.get('max').value;
        const filtros = { min, max };
        await this.handleCLickClose(true, { filtros });
      }
    } else {
      const filtros = this.form.get('filtros').value;
      await this.handleCLickClose(true, { filtros });
    }
  }
}
