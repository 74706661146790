import { Injectable } from '@angular/core';
import { SelectionOption } from '../components/_base/abstract-input';
import { Observable, of, Subject } from 'rxjs';

export type PassoAdicionarConduta =
  'selecao-tipo' |
  'subtipo-cirurgia' |
  'subtipo-hormonioterapia' |
  'subtipo-quimioterapia' |
  'drogas-hormonioterapia' |
  'drogas-quimioterapia' |
  'calendario' |
  'calendario-fim';

export const passoCalendario: { proximoPasso: PassoAdicionarConduta; altura: number; } = {
  proximoPasso: 'calendario',
  altura: 430
};

@Injectable({
  providedIn: 'root'
})
export class CondutaMamaService {

  $primeiraCondutaPontual: Subject<string> = new Subject<string>();

  static condutasDePeriodo = [
    'Hormonioterapia',
    'Quimioterapia',
  ];

  static condutasSemDrogas = [
    'Cirurgia',
    'Radioterapia adjuvante',
    'Seguimento',
  ];

  static passosCondutaMapping = {
    'Cirurgia' : {
      proximoPasso: 'subtipo-cirurgia',
      altura: 510
    },
    'Hormonioterapia' : {
      proximoPasso: 'subtipo-hormonioterapia',
      altura: 250
    },
    'Quimioterapia' : {
      proximoPasso: 'subtipo-quimioterapia',
      altura: 250
    },
    'Radioterapia adjuvante' : passoCalendario,
    'Seguimento' : passoCalendario,
    'Cirurgia Conservadora + LFS' : passoCalendario,
    'Cirurgia Conservadora + EA' : passoCalendario,
    'Cirurgia Mastectomia + EA' : passoCalendario,
    'Cirurgia Adenomastectomia + LFS': passoCalendario,
    'Cirurgia Adenomastectomia + EA': passoCalendario,
    'Cirurgia Mastectomia bilateral + LFS unilateral': passoCalendario,
    'Cirurgia Adenomastectomia bilateral + LFS unilateral': passoCalendario,
    'Cirurgia Mastectomia + LFS' : passoCalendario,
    'Hormonioterapia Neoadjuvante' : {
      proximoPasso: 'drogas-hormonioterapia',
      altura: 485
    },
    'Hormonioterapia Adjuvante' : {
      proximoPasso: 'drogas-hormonioterapia',
      altura: 485
    },
    'Hormonioterapia Paliativa' : {
      proximoPasso: 'drogas-hormonioterapia',
      altura: 510
    },
    'Quimioterapia Neoadjuvante' : {
      proximoPasso: 'drogas-quimioterapia',
      altura: 570
    },
    'Quimioterapia Adjuvante' : {
      proximoPasso: 'drogas-quimioterapia',
      altura: 600
    },
    'Quimioterapia Paliativa' : {
      proximoPasso: 'drogas-quimioterapia',
      altura: 605
    },
  };

  static hormonioQuimioSubtipos: SelectionOption[] = [
    {
      nome: 'Neoadjuvante',
      valor: 'Neoadjuvante'
    },
    {
      nome: 'Adjuvante',
      valor: 'Adjuvante'
    },
    {
      nome: 'Paliativa',
      valor: 'Paliativa'
    }
  ];

  static tiposConduta: SelectionOption[] = [
    {
      nome: 'Cirurgia',
      valor: 'Cirurgia'
    },
    {
      nome: 'Hormonioterapia',
      valor: 'Hormonioterapia'
    },
    {
      nome: 'Radioterapia adjuvante',
      valor: 'Radioterapia adjuvante'
    },
    {
      nome: 'Quimioterapia',
      valor: 'Quimioterapia'
    },
    {
      nome: 'Seguimento',
      valor: 'Seguimento'
    }
  ];

  static tiposCirurgia: SelectionOption[] = [
    {
      nome: 'Conservadora + LFS',
      valor: 'Conservadora + LFS'
    },
    {
      nome: 'Conservadora + EA',
      valor: 'Conservadora + EA'
    },
    {
      nome: 'Mastectomia + LFS',
      valor: 'Mastectomia + LFS'
    },
    {
      nome: 'Mastectomia + EA',
      valor: 'Mastectomia + EA'
    },
    {
      nome: 'Adenomastectomia + LFS',
      valor: 'Adenomastectomia + LFS'
    },
    {
      nome: 'Adenomastectomia + EA',
      valor: 'Adenomastectomia + EA'
    },
    {
      nome: 'Mastectomia bilateral + LFS unilateral',
      valor: 'Mastectomia bilateral + LFS unilateral'
    },
    {
      nome: 'Adenomastectomia bilateral + LFS unilateral',
      valor: 'Adenomastectomia bilateral + LFS unilateral',
    }
  ];

  static drogasHormonioterapiaPaliativa = [
    'Tamoxifeno',
    'Alpelisibe',
    'Ablação ovariana',
    'Palbociclibe',
    'Letrozol',
    'Trastuzumabe',
    'Abemaciclibe',
    'Everolimo',
    'Lapatinibe',
    'Anastrozol',
    'Exemestano',
    'Ribociclibe',
    'Fulvestranto',
    'Pertuzumabe',
    'Megestrol',
    'Análogos de GNRH',
    'Ooforectomia',
    'Pamidronato',
    'Denosumabe 60',
    'Zoledronato',
    'Denosumabe 120'
  ];

  static drogasQuimioterapiaPaliativa = [
    'Doxorrubicina',
    'Trastuzumabe',
    'Ciclofosfamida',
    'Pertuzumabe',
    'Paclitaxel',
    'Deruxtecano',
    'Docetaxel',
    'TDM-1',
    'Carboplatina',
    'Lapatininbe',
    'Cisplatina',
    'Bevacizumabe',
    'Capecitabina',
    'Pembrolizumabe',
    'Gencitabina',
    'Atezolizumabe',
    'Eribulina',
    'Albumina-paclitaxel',
    'Vinorelbine',
    'Ixabepilona',
    'Neratinibe',
    'Doxo lipossomal',
    'Epirrubicina',
    'Filgrastima',
    'Pegfilgrastima',
    'Pamidronato',
    'Govitecano',
    'Denosumabe 120'
  ];

  getTiposConduta(): Observable<SelectionOption[]> {
    return of(CondutaMamaService.tiposConduta);
  }

  getTiposCirurgia(): Observable<SelectionOption[]> {
    return of(CondutaMamaService.tiposCirurgia);
  }

  getHormonioQuimioSubtipos(): Observable<SelectionOption[]> {
    return of(CondutaMamaService.hormonioQuimioSubtipos);
  }

  getDrogasHormonioterapiaPaliativa():Observable<string[]> {
    return of(CondutaMamaService.drogasHormonioterapiaPaliativa.sort((a, b) => a.localeCompare(b)));
  }

  getDrogasQuimioterapiaPaliativa():Observable<string[]> {
    return of(CondutaMamaService.drogasQuimioterapiaPaliativa.sort((a, b) => a.localeCompare(b)));
  }

  getCondutasDePeriodo(): string[] {
    return CondutaMamaService.condutasDePeriodo;
  }
}
