import { AdicionarCondutaComponent } from './adicionar-conduta/adicionar-conduta.component';
import {
  ESTADIAMENTO_CHAVE,
  ESTADIAMENTO_OPTIONS,
  InputEstadiamentoComponent
} from './input-estadiamento/input-estadiamento.component';
import {
  HISTOPATOLOGICO_CHAVE,
  HISTOPATOLOGICO_OPTIONS,
  InputHistopatologicoComponent
} from './input-histopatologico/input-histopatologico.component';
import { InputPdl1Component, PDL1_CHAVE, PDL1_OPTIONS } from './input-pdl1/input-pdl1.component';
import { InputTmbComponent } from './input-tmb/input-tmb.component';
import { FilterConfig } from '../../../interfaces/filter-config';
import { rangeConfigToSelectionOption } from '../../../util/utils';

export const MODALS = [
  AdicionarCondutaComponent,
  InputEstadiamentoComponent,
  InputHistopatologicoComponent,
  InputPdl1Component,
  InputTmbComponent
];

export const FILTERS_CONFIG: FilterConfig[] = [
  {
    categoria: 'Dados de exames',
    titulo: 'Histopatológico',
    nome: HISTOPATOLOGICO_CHAVE,
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: HISTOPATOLOGICO_OPTIONS
  },
  {
    categoria: 'Dados de exames',
    titulo: 'Estadiamento',
    nome: ESTADIAMENTO_CHAVE,
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: ESTADIAMENTO_OPTIONS
  },
  {
    categoria: 'Dados de exames',
    titulo: 'PDL-1',
    nome: PDL1_CHAVE,
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(PDL1_OPTIONS)
  },
];

