import { Component, OnInit, Injector } from '@angular/core';
import { AbstractModal } from '../../../components/_base/abstract-modal';

@Component({
	selector: 'app-relatorio-dados-medicos',
	templateUrl: './relatorio-dados-medicos.component.html'
})
export class RelatorioDadosMedicosComponent extends AbstractModal implements OnInit {
	
	relatorioMedico;
	fixedWidth = '490px';
	relatorios = [];
	
	constructor(public readonly injector: Injector) {
		super(injector);
	}
	
	ngOnInit() {
		this.setDadosRelatorio();
	}

	setDadosRelatorio() {
		for (const chave in this.relatorioMedico) {
			if (this.relatorioMedico.hasOwnProperty(chave)) {
				const elemento = {
					label: chave,
					value: this.relatorioMedico[chave]
				};
				this.relatorios.push(elemento);
			}
		}
	}
	
}
