import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Consentimento } from '../../models/ficha.model';
import { ConsentimentoService } from '../../services/consentimento.service';
import { FichaService } from '../../services/ficha.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-termo-consentimento-status',
  templateUrl: './termo-consentimento-status.component.html',
})
export class TermoConsentimentoStatusComponent implements OnInit, OnDestroy {

  @Input() consentimento: Consentimento;

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  $subscriptions: Subscription[] = [];

  constructor(private consentimentoService: ConsentimentoService,
              private fichaService: FichaService) { }

  get hint() {
    if (!this.consentimento || this.consentimento.status === 'pendente') {
      return 'Os dados estão pendentes no servidor oncomax'
    } else if (this.consentimento?.status === 'aceito') {
      return 'Os dados estão disponibilizados no servidor oncomax';
    } else if (this.consentimento?.status === 'recusado') {
      return 'Os dados não estão disponibilizados no servidor oncomax';
    }
  }

  ngOnInit() {
    this.consentimentoService.$consentimentoAtualizado.subscribe(({ idTermo, idFicha }) => {
      if (idTermo) {
        this.consentimentoService.getById(idTermo).subscribe((consentimento) => {
          this.consentimento = consentimento;
        });
      } else {
        this.fichaService.getById(idFicha).subscribe((ficha) => {
          this.consentimento = ficha.consentimento;
        });
      }
    });

    const callback = (consentimento: Consentimento) => {
      if (this.consentimento._id === consentimento._id) {
        this.consentimento = consentimento;
      }
    };

    this.$subscriptions = [
      this.consentimentoService.$termoConsentimentoEnviado.subscribe(callback),
      this.consentimentoService.$termoConsentimentoAceito.subscribe(callback),
      this.consentimentoService.$termoConsentimentoRecusado.subscribe(callback)
    ];
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(sub => sub.unsubscribe());
  }

  handleTermoConsentimentoClick() {
    this.onClick.emit();
  }
}
