import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (): RangeConfig => ({
  options: [
    {
      label: 'Nx',
      value: 'Nx',
      hint: 'Não pode ser avaliado'
    },
    {
      label: 'N0',
      value: 'N0',
      hint: 'Nenhum nódulo regional positivo'
    },
    {
      label: 'N1',
      value: 'N1',
      hint: 'Metástase(s) em nódulo(s) regional(is)'
    }
  ]
});
