import { Component, Injector, OnInit } from '@angular/core';
import { AbstractModal } from '../../../components/_base/abstract-modal';
import { FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { ValidatorsApp } from '../../../util/validators';
import { SelectionOption } from '../../../components/_base/abstract-input';
import { Clinica, ClinicaDto } from '../../../models/clinica.model';
import { AdminService } from '../../../services/admin.service';
import { loading } from '../../../util/loading';
import { Subject } from 'rxjs';
import { DominioService } from '../../../services/dominio.service';
import { toast } from '../../../util/toast';

const TIPO_ATENDIMENTO_MISTO: any = 'misto';

@Component({
  selector: 'app-mesclar-clinicas',
  templateUrl: './mesclar-clinicas.component.html',
})
export class MesclarClinicasComponent extends AbstractModal implements OnInit {

  fixedWidth = '490px';
  fixedHeight = '775px';
  loadingCidades: boolean = false;
  preventValidadoChange: boolean = false;
  hasValidationChange: boolean = false;
  ufsOptions: SelectionOption[];
  gruposOptions: SelectionOption[];
  cidadeOptions: SelectionOption[];
  tiposAtendimentoOptions: SelectionOption[];
  clinicaId: string;
  clinica: Clinica;
  form: FormGroup = new FormGroup({
    uf: new FormControl('', Validators.required),
    cidade: new FormControl('', Validators.required),
    grupoCnpj: new FormControl('', Validators.compose([ValidatorsApp.cnpj])),
    grupoNome: new FormControl(''),
    cnpj: new FormControl('', Validators.compose([ValidatorsApp.cnpj, Validators.required])),
    nome: new FormControl('', Validators.required),
    tipoAtendimento: new FormControl(void 0),
    validado: new FormControl(false),
  });

  constructor(public readonly injector: Injector,
              private adminService: AdminService,
              private dominioService: DominioService) {
    super(injector);
  }

  ngOnInit() {
    this.loadDominioUfs().subscribe(() => {
      this.dominioService.getTipoAtendimento().subscribe((tiposAtendimento) => {
        const tiposAtendimentoOptions = tiposAtendimento;
        tiposAtendimentoOptions.push({
          nome: 'Misto',
          valor: TIPO_ATENDIMENTO_MISTO
        });
        this.tiposAtendimentoOptions = tiposAtendimentoOptions;
        this.loadClinica();
        this.registryListeners();
      });
    });
  }

  registryListeners() {
    this.form.get('validado').valueChanges.subscribe((validado) => {
      if (this.preventValidadoChange) {
        this.preventValidadoChange = false;
        return;
      }
      this.adminService.validarInstituicao(this.clinicaId, validado).subscribe(async () => {
        await toast('Alteração feita com sucesso', 'success');
        this.hasValidationChange = true;
      });
    });
  }

  loadDominioUfs() {
    const subject = new Subject();
    this.dominioService.getUfs().subscribe((ufs) => {
      this.ufsOptions = ufs.sort((a, b) => a.nome.localeCompare(b.nome));
      subject.next();
    });
    return subject;
  }

  loadCidadesByUf(uf, setUniqueValue = true) {
    this.loadingCidades = true;
    const subject = new Subject();
    loading(this.dominioService.getCidadesByUf(uf).subscribe((cidades) => {
      this.cidadeOptions = cidades;
      if (cidades.length === 1 && setUniqueValue) {
        this.form.get('cidade').setValue(cidades[0].valor);
      }
      subject.next();
      this.loadingCidades = false;
    }));
    return subject;
  }

  loadClinica() {
    loading(this.adminService.getInstituicaoById(this.clinicaId).subscribe((clinica: Clinica) => {
      this.clinica = clinica;
      this.preventValidadoChange = true;
      this.form.patchValue({
        uf: clinica.uf,
        cnpj: clinica.cnpj,
        nome: clinica.nome,
        grupoCnpj: clinica.cnpj !== clinica.grupo.cnpj ? clinica.grupo.cnpj : '',
        grupoNome: clinica.cnpj !== clinica.grupo.cnpj ? clinica.grupo.nome : '',
        tipoAtendimento: clinica.tipoAtendimento ? clinica.tipoAtendimento : TIPO_ATENDIMENTO_MISTO,
        validado: clinica.validado
      });
      this.loadCidadesByUf(clinica.uf).subscribe(() => {
        this.form.get('cidade').setValue(clinica.cidade);
      });
    }));
  }

  async handleMesclarInstituicoesClick() {
    this.adminService.setClinicaMesclagem(this.clinica);
    await toast('Clínica selecionada para mesclagem, selecione qual a clinica a ser substituída');
    await this.handleCLickClose(false);
  }

  async handleRetornarClick() {
    await this.handleCLickClose(true, { hasValidationChange: this.hasValidationChange });
  }

  async handleSaveClick() {
    if (this.form.invalid) {
      await toast('Por favor, preencha todos os campos corretamente antes de confirmar')
      return;
    }

    const clinica: Clinica = this.form.getRawValue();
    clinica.nome = String(clinica.nome).toUpperCase();
    clinica.grupoNome = String(clinica.grupoNome).toUpperCase();
    clinica._id = this.clinica._id;
    if (clinica.tipoAtendimento === TIPO_ATENDIMENTO_MISTO) clinica.tipoAtendimento = undefined;

    await loading(this.adminService.atualizarInstituicao({...clinica}).subscribe(async (clinica) => {
      await this.handleCLickClose(true);
    }));
  }
}
