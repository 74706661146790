import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genero'
})
export class GeneroPipe implements PipeTransform {
  transform(value: string | any): string {
    switch (value) {
      case 'F':
        return 'Feminino';
      case 'M':
        return 'Masculino';
    }
  }
}
