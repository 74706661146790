import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { hexToRgb } from '../../constants/colors';

@Component({
  selector: 'app-timeline-vertical-three-d-cube',
  templateUrl: './timeline-vertical-three-d-cube.component.html',
  styleUrls: ['./timeline-vertical-three-d-cube.component.scss'],
})
export class TimelineVerticalThreeDCubeComponent implements AfterViewInit {

  constructor() { }

  @Input() height;
  @Input() color;
  @Input() label;
  @ViewChild('cubeRef') cubeRef;
  @ViewChild('cubeWrapRef') cubeWrapRef;

  ngAfterViewInit() {
    // this.cubeRef.nativeElement.style.setProperty('height', this.height);
    const heightDigits = Number(this.height.replace('px', ''));
    this.cubeWrapRef.nativeElement.style.setProperty('--translate', `calc(${this.height} + ${heightDigits / 100}px)`);
    this.cubeRef.nativeElement.style.setProperty('--negative-translate', this.color);
    this.cubeRef.nativeElement.style.setProperty('--color-rgb', hexToRgb(this.color));
  }

}
