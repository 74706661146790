import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { ValidatorsApp } from '../../../../util/validators';
import { toast } from '../../../../util/toast';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';

export const GRAU_CHAVE = 'grau';
export const GRAU_OPTIONS = {
  options: [
    {
      label: 'Gx',
      value: 'Gx'
    },
    {
      label: 'G1',
      value: 'G1'
    },
    {
      label: 'G2',
      value: 'G2'
    },
    {
      label: 'G3',
      value: 'G3'
    },
  ]
};

@Component({
  selector: 'app-input-grau',
  templateUrl: './input-grau.component.html',
})
export class InputGrauComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  id: string;
  offsetWidth = 10;
  fixedHeight = '460px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    grau: new FormControl('Gx')
  });

  rangeConfig: RangeConfig = GRAU_OPTIONS;

  constructor(public override injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { grau, id } = inputValueResolver(GRAU_CHAVE, this.ficha?.itens);
    this.id = id;
    if (grau !== undefined) this.form.patchValue({ grau });
  }

  async handleEndEditClick() {
    if (!ValidatorsApp.formularioValido(this.form)) {
      toast('É necessário preencher todos os campos para poder prosseguir', 'warning');
    } else  {
      const newItem: ItemFicha = {
        chave: GRAU_CHAVE,
        valor: this.form.get(GRAU_CHAVE).value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
