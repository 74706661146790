import { EChartsOption } from 'echarts';

export const getKaplanMeierChartOptions = (title: string, data: any[]): EChartsOption => {
  const documentStyle = getComputedStyle(document.documentElement);
  const chartLabelTextColor = documentStyle.getPropertyValue('--om-grey-primary').trim();
  const modulePrimaryColor = documentStyle.getPropertyValue('--om-module-primary-color').trim();

  return {
    doubleWidth: true,
    title: {
      text: title,
      left: 'center',
      textStyle: {
        color: chartLabelTextColor
      }
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const probabilidadeSobrevivencia = Number(params[0].data[1]).toFixed(2);
        const meses = Number(params[0].data[0] / 30).toFixed(2);
        return `Período: ${meses} meses<br/>${params[0].marker} Prob. Sobrevivência: ${Number(probabilidadeSobrevivencia) * 100}%`;
      }
    },
    xAxis: {
      type: 'value',
      name: 'Meses',
      axisLabel: {
        formatter: (value) => {
          return `${Number(value / 30).toFixed(2)}`
        }
      }
    },
    graphic: {
      type: 'text',
      left: 'center',
      top: '70%',
      style: {
        text: '(Em desenvolvimento)',
        fontSize: 12,
        fill: '#333',
        fontWeight: 'bold'
      }
    },
    yAxis: {
      type: 'value',
      name: '',
      max: 1,
      axisLabel: {
        formatter: (value) => {
          return `${value * 100}%`;
        }
      }
    },
    grid: {
      top: '10%',
      left: '3%',
      right: 60,
      containLabel: true,
      height: '52%'
    },
    series: [
      {
        name: 'Probabilidade de Sobrevivência',
        type: 'line',
        data,
        symbol: 'none',
        itemStyle: {
          color: modulePrimaryColor
        }
      }
    ]
  }
};
