import { RangeConfig } from '../../../../../components/_base/abstract-input';

const clinicoRangeConfig = (prefixo: string):RangeConfig => ({
  options: [
    {
      label: `${prefixo}Tx`,
      value: `${prefixo}Tx`,
      hint: 'Tumor não pode ser acessado'
    },
    {
      label: `${prefixo}T0`,
      value: `${prefixo}T0`,
      hint: 'Sem evidência de tumor'
    },
    {
      label: `${prefixo}T1`,
      value: `${prefixo}T1`,
      hint: 'Tumor inaparente e impalpável',
      options: [
        {
          label: `${prefixo}T1`,
          value: `${prefixo}T1`,
          hint: 'Tumor inaparente e impalpável',
        },
        {
          label: `${prefixo}T1a`,
          value: `${prefixo}T1a`,
          hint: 'Tumor ≤5%  do tecido ressecado'
        },
        {
          label: `${prefixo}T1b`,
          value: `${prefixo}T1b`,
          hint: 'Tumor ≥5%  do tecido ressecado'
        },
        {
          label: `${prefixo}T1c`,
          value: `${prefixo}T1c`,
          hint: 'Tumor em um ou ambos os lados porém impalpável'
        },
      ],
    },
    {
      label: `${prefixo}T2`,
      value: `${prefixo}T2`,
      hint: 'Tumor é palpável e confinado a próstata',
      options: [
        {
          label: `${prefixo}T2`,
          value: `${prefixo}T2`,
          hint: 'Tumor é palpável e confinado a próstata',
        },
        {
          label: `${prefixo}T2a`,
          value: `${prefixo}T2a`,
          hint: 'Tumor ≤ metade de um lado'
        },
        {
          label: `${prefixo}T2b`,
          value: `${prefixo}T2b`,
          hint: '≥ metade de um lado, mas não ambos'
        },
        {
          label: `${prefixo}T2c`,
          value: `${prefixo}T2c`,
          hint: 'Tumor em ambos os lados'
        },
      ]
    },
    {
      label: `${prefixo}T3`,
      value: `${prefixo}T3`,
      hint: 'Tumor extraprostático que não é fixo ou não invade estruturas adjacentes',
      options: [
        {
          label: `${prefixo}T3`,
          value: `${prefixo}T3`,
          hint: 'Tumor extraprostático que não é fixo ou não invade estruturas adjacentes',
        },
        {
          label: `${prefixo}T3a`,
          value: `${prefixo}T3a`,
          hint: 'Extensão extraprostática'
        },
        {
          label: `${prefixo}T3b`,
          value: `${prefixo}T3b`,
          hint: 'Invade a vesícula seminal'
        },
      ]
    },
    {
      label: `${prefixo}T4`,
      value: `${prefixo}T4`,
      hint: 'Tumor fixo ou invade estruturas adjacentes que não sejam vesículas seminais, como esfíncter externo, reto, bexiga, músculos elevadores e/ou parede pélvica'
    }
  ]
});

const patologicoRangeConfig = (prefixo: string):RangeConfig => ({
  options: [
    {
      label: `${prefixo}T2`,
      value: `${prefixo}T2`,
      hint: 'Tumor confinado ao órgão'
    },
    {
      label: `${prefixo}T3`,
      value: `${prefixo}T3`,
      hint: 'Extensão extraprostática',
      options: [
        {
          label: `${prefixo}T3`,
          value: `${prefixo}T3`,
          hint: 'Extensão extraprostática',
        },
        {
          label: `${prefixo}T3a`,
          value: `${prefixo}T3a`,
          hint: 'Extensão extraprostática (unilateral ou bilateral) ou invasão microscópica do colo vesical'
        },
        {
          label: `${prefixo}T3b`,
          value: `${prefixo}T3b`,
          hint: 'Tumor invade a vesícula seminal'
        }
      ]
    },
    {
      label: `${prefixo}T4`,
      value: `${prefixo}T4`,
      hint: 'Tumor fixo ou invade estruturas adjacentes que não sejam vesículas seminais, como esfíncter externo, reto, bexiga, músculos elevadores e/ou parede pélvica'
    }
  ]
});

export const optionsResolver = (tipo: 'clinico' | 'patologico', prefixo: string = ''): RangeConfig => {
  if (tipo === 'clinico') {
    return clinicoRangeConfig(prefixo);
  }
  if (tipo === 'patologico') {
    return patologicoRangeConfig(prefixo);
  }
};
