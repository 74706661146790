import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { ModuloService } from '../../services/modulo.service';
import { Ficha } from '../../models/ficha.model';
import { AnotacoesPacienteComponent } from '../../modals/anotacoes-paciente/anotacoes-paciente.component';
import { getNavigateRoutePath } from '../../constants/route';
import { IMenuModuloConfig, menuModulosConfig, MODULO_CANCER } from '../../constants/modulos-cancer';
import { Router } from '@angular/router';
import { FichaService } from '../../services/ficha.service';
import { loading } from '../../util/loading';
import { calcularStatusFicha, StatusCondutaType } from '../../util/ficha';
import { ResponsiveService } from '../../services/responsive.service';
import { toast } from '../../util/toast';
import { PublicacaoCasoComponent } from '../../modals/publicacao-caso/publicacao-caso.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ficha-paciente-detalhe',
  templateUrl: './ficha-paciente.component.html',
})
export class FichaPacienteComponent implements OnChanges, OnInit, OnDestroy {

  MODULO_CANCER = MODULO_CANCER;

  isMobile: boolean;
  moduloAtual: IMenuModuloConfig;
  statusFicha: StatusCondutaType;
  hasAnotacoes: boolean;
  @Input() ficha: Ficha;
  @Input() loading: boolean;
  @Input() deletable: boolean = false;
  @Input() editable: boolean = true;
  @Input() annotations: boolean = true;
  @Input() artificialIntelligence: boolean = true;
  @Input() publishable: boolean = true;

  @Output() compartilharFicha: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeletePacienteClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPublicarCasoConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStatusFichaClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() onConsultarInteligenciaArtificial: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTermoConsentimentoClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPreenchimentoAutomaticoClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAdicionarOutraNeoplasiaClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditarPacienteClick: EventEmitter<any> = new EventEmitter<any>();

  atualizarCondutaHint: string = 'Clique para adicionar um lembrete de atualizar conduta novo';

  $subscriptions: Subscription[] = [];
  neoplasiasPaciente: any[];

  constructor(private modalHelper: CustomModalHelper,
              private moduloService: ModuloService,
              private fichaService: FichaService,
              private responsiveService: ResponsiveService,
              private router: Router) {
    this.isMobile = responsiveService.isMobilePlatform;
    moduloService.$moduloAtual.subscribe(this.handleChangeModuloAtual.bind(this))
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.fichaService.$neoplasiasPaciente.subscribe((neoplasias) => {
        this.neoplasiasPaciente = neoplasias.filter(
          (neoplasia) => neoplasia.modulo.nome !== this.moduloAtual?.name
        ).map((neoplasia) => {
          const moduloConfig: IMenuModuloConfig = this.moduloService.findModuloByName(neoplasia.modulo.nome, menuModulosConfig);
          neoplasia.modulo = Object.assign({}, { ...neoplasia.modulo }, { cor: moduloConfig.color });
          return neoplasia;
        });
      })
    );
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    this.hasAnotacoes = !!changes.ficha?.currentValue?.anotacoes?.length;
    this.statusFicha = calcularStatusFicha(this.ficha);
  }

  handleStatusFichaClick(status) {
    this.onStatusFichaClick.emit(status);
  }

  async handleClickEditarPaciente() {
    this.onEditarPacienteClick.emit();
  }

  async handleClickAnotacoesPaciente() {
    const modal = await this.modalHelper.create({
      component: AnotacoesPacienteComponent,
      componentProps: {
        ficha: this.ficha
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data.confirm !== false) {
      loading(this.fichaService.atualizarAnotacoesFicha(data, this.ficha).subscribe((ficha) => {
        this.hasAnotacoes = !!ficha.anotacoes.length;
        this.ficha = ficha;
      }));
    }
  }

  handleChangeModuloAtual(modulo: IMenuModuloConfig) {
    this.moduloAtual = modulo;
  }

  async handleVerResumoPacienteClick() {
    const navigationPath = getNavigateRoutePath(this.moduloAtual.name);
    const extras: any = {
      queryParams: { ficha: this.ficha._id },
      queryParamsHandling: 'replace',
      replaceUrl: true,
    };
    await this.router.navigate([navigationPath.RESUMO_CASO_PACIENTE], extras);
  }

  async handleClickUnavailableFunctionality() {
    await toast('Essa funcionalidade logo mais estará disponível', 'warning');
  }

  handleCompartilharFichaPacienteClick() {
    this.compartilharFicha.emit();
  }

  handleDeletePacienteClick() {
    this.onDeletePacienteClick.emit();
  }

  async handlePublicarCasoClick() {
    const modal = await this.modalHelper.create({
      component: PublicacaoCasoComponent,
      handler: ({data}) => {
        if (data.confirm) {
          this.onPublicarCasoConfirm.emit(data.payload);
        }
      }
    });

    await modal.present();
  }

  handleConsultarInteligenciaArtificialClick() {
    // toast('A funcionalidade encontra-se em manutenção')
    this.onConsultarInteligenciaArtificial.emit();
  }

  handleTermoConsentimentoClick() {
    this.onTermoConsentimentoClick.emit();
  }

  handlePreenchimentoAutomaticoFicha() {
    this.onPreenchimentoAutomaticoClick.emit();
  }

  handleAdicionarOutraNeoplasiaClick() {
    this.onAdicionarOutraNeoplasiaClick.emit();
  }
  
  async handleNeoplasiaClick(neoplasia: any) {
    const navigationPath = getNavigateRoutePath(neoplasia.modulo.nome);
    const extras: any = {
      queryParams: { ficha: neoplasia._id },
      queryParamsHandling: 'replace',
      replaceUrl: true,
    };
    this.moduloService.setModuloAtual(neoplasia.modulo.nome);
    await this.router.navigate([navigationPath.FICHA_PACIENTE], extras);
  }
}
