import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { DynamicComponentDirective } from './directives/dynamic-component.directive';
import { CustomTextInputComponent } from './components/custom-input/custom-text-input/custom-text-input.component';
import { CustomSelectInputComponent } from './components/custom-input/custom-select-input/custom-select-input.component';
import { CustomInputMoleculeComponent } from './components/custom-input/custom-input.component';
import { MenuComponent } from './components/menu/menu.component';
import { CustomCollapseComponent } from './components/custom-collapse/custom-collapse.component';
import { PrimaryButtonComponent } from './components/custom-button/primary/primary.component';
import { HeaderComponent } from './components/header/header.component';
import { BrMaskerModule } from 'br-mask';
import { CustomCalendarComponent } from './components/custom-calendar/custom-calendar.component';
import { CalendarModule } from 'ion2-calendar';
import { FormControlExceptionDirective } from './directives/form-control-exception.directive';
import { EstadoVazioComponent } from './components/estado-vazio/estado-vazio.component';
import { CustomToggleInputComponent } from './components/custom-input/custom-toggle-input/custom-toggle-input.component';
import { ModalCloseButtonComponent } from './components/_base/abstract-modal';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { DynamicTableFieldComponentDirective } from './directives/dynamic-table-field-component.directive';
import { CustomAlertComponent } from './components/custom-alert/custom-alert.component';
import { DangerButtonComponent } from './components/custom-button/danger/danger.component';
import { PaginacaoComponent } from './components/paginacao/paginacao.component';
import { DefaultButtonComponent } from './components/custom-button/default/default.component';
import { GhostButtonComponent } from './components/custom-button/ghost/ghost.component';
import { PacienteRowListComponent } from './components/paciente-row-list/paciente-row-list.component';
import { SegmentSelectorComponent } from './components/segment-selector/segment-selector.component';
import { ExtendedHeaderComponent } from './components/extended-header/extended-header.component';
import { OutlinedBrandButtonComponent } from './components/custom-button/outlined-brand/outlined-brand.component';
import { FichaPacienteComponent } from './components/ficha-paciente/ficha-paciente.component';
import { InputWithModalComponent } from './components/input-with-modal/input-with-modal.component';
import { BrandButtonComponent } from './components/custom-button/brand/brand-button.component';
import { CustomRadioInputComponent } from './components/custom-input/custom-radio-input/custom-radio-input.component';
import { CadastroPacienteComponent } from './modals/cadastro-paciente/cadastro-paciente.component';
import { NeutralButtonComponent } from './components/custom-button/neutral/neutral.component';
import { ButtonTipSelectorComponent } from './components/button-tip-selector/button-tip-selector.component';
import { CustomRangeInputComponent } from './components/custom-input/custom-range-input/custom-range-input.component';
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker.component';
import { OnboardingSlidesComponent } from './components/onboarding-slides/onboarding-slides.component';
import { MenuModulosComponent } from './components/menu-modulos/menu-modulos.component';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PainelPrincipalDesktopComponent } from './components/painel-principal-desktop/painel-principal-desktop.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { IdadePipe } from './pipes/idade.pipe';
import { AnotacoesPacienteComponent } from './modals/anotacoes-paciente/anotacoes-paciente.component';
import { CustomTextareaInputComponent } from './components/custom-input/custom-textarea/custom-textarea-input.component';
import { TimelineVerticalCondutasComponent } from './components/timeline-vertical-condutas/timeline-vertical-condutas.component';
import { CensurarComponent } from './modals/censurar/censurar.component';
import { DangerLightButtonComponent } from './components/custom-button/danger-light/danger-light.component';
import { CheckboxGroupInputComponent } from './components/custom-input/checkbox-group-input/checkbox-group-input.component';
import { ListaCondutasComponent } from './components/lista-condutas/lista-condutas.component';
import { CustomCheckboxInputComponent } from './components/custom-input/custom-checkbox-input/custom-checkbox-input.component';
import { CensuraComponent } from './components/censura/censura.component';
import { GraficoCasuisticaComponent } from './components/grafico-casuistica/grafico-casuistica.component';
import { TermosCondicoesComponent } from './components/termos-condicoes/termos-condicoes.component';
import { PoliticaPrivacidadeComponent } from './components/politica-privacidade/politica-privacidade.component';
import { PainelDesktopResizableGridComponent } from './components/painel-desktop-resizable-grid/painel-desktop-resizable-grid.component';
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { ResizableItem } from './directives/resizable-item.directive';
import { TimelineVerticalLegendComponent } from './components/timeline-vertical-legend/timeline-vertical-legend.component';
import { TimelineVerticalThreeDCubeComponent } from './components/timeline-vertical-three-d-cube/timeline-vertical-three-d-cube.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { SolicitarCadastroClinicaComponent } from './modals/solicitar-cadastro-clinica/solicitar-cadastro-clinica.component';
import { AjudeMelhorarComponent } from './modals/ajude-melhorar/ajude-melhorar.component';
import { PesquisarGrupoComponent } from './modals/pesquisar-grupo/pesquisar-grupo.component';
import { PesquisarClinicaComponent } from './modals/pesquisar-clinica/pesquisar-clinica.component';
import { CustomAutocompleteVirtualScrollInputComponent } from './components/custom-input/custom-autocomplete-virtual-scroll-input/custom-autocomplete-virutal-scroll-input.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { FichaMedicoComponent } from './components/ficha-medico/ficha-medico.component';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { EditarMedicoComponent } from './modals/admin/editar-medico/editar-medico.component';
import { MesclarClinicasComponent } from './modals/admin/mesclar-clinicas/mesclar-clinicas.component';
import { ConfirmarMesclagemClinicasComponent } from './modals/admin/confirmar-mesclagem-clinicas/confirmar-mesclagem-clinicas.component';
import { BreadcrumbAdminComponent } from './components/breadcrumb-admin/breadcrumb-admin.component';
import { ThreeWayToggleComponent } from './components/custom-input/three-way-toggle/three-way-toggle.component';
import { NotificationCarouselComponent } from './components/notification-carousel/notification-carousel.component';
import { AntecedentesInformacoesComponent } from './modals/antecedentes-informacoes/antecedentes-informacoes.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ConfirmarPendenciaComponent } from './modals/confirmar-pendencia/confirmar-pendencia.component';
import { DiscussaoOpiniaoCardComponent } from './components/discussao-opiniao-card/discussao-opiniao-card.component';
import { PublicacaoCasoComponent } from './modals/publicacao-caso/publicacao-caso.component';
import { PublicacaoCasoRowComponent } from './components/publicacao-caso-row/publicacao-caso-row.component';
import { TabsComponentComponent } from './components/tabs-component/tabs-component.component';
import { OutlinedPrimaryButtonComponent } from './components/custom-button/outlined-primary/outlined-primary.component';
import { ConsultarInteligenciaArtificialComponent } from './modals/consultar-inteligencia-artificial/consultar-inteligencia-artificial.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TelefonePipe } from './pipes/telefone.pipe';
import { CriarPromptComponent } from './modals/admin/criar-prompt/criar-prompt.component';
import { GeneroPipe } from './pipes/genero.pipe';
import { AdicionarContaInstitucionalComponent } from './modals/adicionar-conta-institucional/adicionar-conta-institucional.component';
import { EditarContaInstitucionalComponent } from './modals/editar-conta-institucional/editar-conta-institucional.component';
import { AdicionarStaffComponent } from './modals/adicionar-staff/adicionar-staff.component';
import { AdicionarResidenteComponent } from './modals/adicionar-residente/adicionar-residente.component';
import { VisualizarContaInstitucionalComponent } from './modals/admin/visualizar-conta-institucional/visualizar-conta-institucional.component';
import { PacienteListActionsComponent } from './components/paciente-list-actions/paciente-list-actions.component';
import { ConfirmarStaffComponent } from './modals/confirmar-staff/confirmar-staff.component';
import { ExportarFichaComponent } from './modals/exportar-ficha/exportar-ficha.component';
import { TermoConsentimentoComponent } from './modals/termo-consentimento/termo-consentimento.component';
import { TermoConsentimentoStatusComponent } from './components/termo-consentimento-status/termo-consentimento-status.component';
import { MenuModulosIconComponent } from './components/menu-modulos-icon/menu-modulos-icon.component';
import { ConteudoRelacionadoComponent } from './components/conteudo-relacionado/conteudo-relacionado.component';
import { CircularProgressBarComponent } from './components/circular-progress-bar/circular-progress-bar.component';
import { RelatorioDadosMedicosComponent } from './modals/admin/relatorio-dados-medicos/relatorio-dados-medicos.component';
import { InteracoesMedicamentosasComponent } from './modals/interacoes-medicamentosas/interacoes-medicamentosas.component';
import { FiltrosAvancadosComponent } from './modals/filtros-avancados/filtros-avancados.component';
import { FiltroDinamicoComponent } from './modals/filtro-dinamico/filtro-dinamico.component';
import { CampanhasCardDetailComponent } from './components/campanhas-card-detail/campanhas-card-detail.component';
import { TabCampanhasComponent } from './components/tab-campanhas/tab-campanhas.component';
import { TabGraficosComponent } from './components/tab-graficos/tab-graficos.component';
import { CriarNovaPerguntaComponent } from './modals/criar-nova-pergunta/criar-nova-pergunta.component';
import { CustomChipsInputComponent } from './components/custom-input/custom-chips-input/custom-chips-input.component';
import { CriarNovoConteudoRelacionadoComponent } from './modals/criar-novo-conteudo-relacionado/criar-novo-conteudo-relacionado.component';
import { ImageCropperComponent } from './modals/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SelecionarClinicaAtualComponent } from './modals/selecionar-clinica-atual/selecionar-clinica-atual.component';
import { SaibaMaisNiveisComponent } from './modals/saiba-mais-niveis/saiba-mais-niveis.component';
import { SaibaTempoCampanhaComponent } from './modals/saiba-tempo-campanha/saiba-tempo-campanha.component';
import { DataDiagnosticoComponent } from './modals/data-diagnostico/data-diagnostico.component';
import { PreenchimentoAutomaticoFichaComponent } from './modals/preenchimento-automatico-ficha/preenchimento-automatico-ficha.component';

import { MODALS as SMART_DATA_MODALS } from './modals/smart-data';
import { MODALS as MAMA_MODALS } from './modals/modulo-mama/ficha-paciente';
import { MODALS as PROSTATA_MODALS } from './modals/modulo-prostata/ficha-paciente';
import { MODALS as PULMAO_NSCLC_MODALS } from './modals/modulo-pulmao-nsclc/ficha-paciente';
import { MODALS as PULMAO_SCLC_MODALS } from './modals/modulo-pulmao-sclc/ficha-paciente';
import { MODALS as COLLORETAL_COLON_MODALS } from './modals/modulo-colorretal-colon/ficha-paciente';
import { MODALS as COLLORETAL_RETO_MODALS } from './modals/modulo-colorretal-reto/ficha-paciente';
import { MODALS as COLLORETAL_CANAL_ANAL_MODALS } from './modals/modulo-colorretal-canal-anal/ficha-paciente';

import { COMPONENTS as TabsFichaPacienteComponent } from './components/tabs-ficha-paciente';
import { DetalheLaudoComponent } from './modals/detalhe-laudo/detalhe-laudo.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FilePreviewerComponent } from './components/file-previewer/file-previewer.component';
import { ListaCondutasFichaComponent } from './components/lista-condutas-ficha/lista-condutas-ficha.component';
import { AdicionarOutraNeoplasiaComponent } from './modals/adicionar-outra-neoplasia/adicionar-outra-neoplasia.component';
import { ArvoreExamesComponent } from './components/arvore-exames/arvore-exames.component';

export const COMPONENTS = [
  CustomButtonComponent,
  CustomTextInputComponent,
  CustomSelectInputComponent,
  CustomCollapseComponent,
  CustomInputMoleculeComponent,
  CustomChipsInputComponent,
  PrimaryButtonComponent,
  DefaultButtonComponent,
  NeutralButtonComponent,
  OutlinedPrimaryButtonComponent,
  HeaderComponent,
  CustomCalendarComponent,
  EstadoVazioComponent,
  CustomToggleInputComponent,
  MenuComponent,
  ModalCloseButtonComponent,
  CustomTableComponent,
  CustomAlertComponent,
  DangerButtonComponent,
  PaginacaoComponent,
  GhostButtonComponent,
  PacienteRowListComponent,
  SegmentSelectorComponent,
  ExtendedHeaderComponent,
  OutlinedBrandButtonComponent,
  FichaPacienteComponent,
  InputWithModalComponent,
  BrandButtonComponent,
  CustomRadioInputComponent,
  ButtonTipSelectorComponent,
  CustomRangeInputComponent,
  CustomDatepickerComponent,
  OnboardingSlidesComponent,
  MenuModulosComponent,
  DesktopHeaderComponent,
  BreadcrumbComponent,
  PainelPrincipalDesktopComponent,
  AnotacoesPacienteComponent,
  CustomTextareaInputComponent,
  TimelineVerticalCondutasComponent,
  DangerLightButtonComponent,
  CheckboxGroupInputComponent,
  ListaCondutasComponent,
  CustomCheckboxInputComponent,
  CensuraComponent,
  GraficoCasuisticaComponent,
  TermosCondicoesComponent,
  PoliticaPrivacidadeComponent,
  PainelDesktopResizableGridComponent,
  TimelineVerticalLegendComponent,
  TimelineVerticalThreeDCubeComponent,
  StatusBadgeComponent,
  CustomAutocompleteVirtualScrollInputComponent,
  FichaMedicoComponent,
  AdminHeaderComponent,
  BreadcrumbAdminComponent,
  ThreeWayToggleComponent,
  NotificationCarouselComponent,
  DiscussaoOpiniaoCardComponent,
  PublicacaoCasoComponent,
  PublicacaoCasoRowComponent,
  TabsComponentComponent,
  PacienteListActionsComponent,
  MenuModulosIconComponent,
  ConteudoRelacionadoComponent,
  CircularProgressBarComponent,
  CampanhasCardDetailComponent,
  TabCampanhasComponent,
  TabGraficosComponent,
  FilePreviewerComponent,
  ListaCondutasFichaComponent,
  ArvoreExamesComponent,
  ...TabsFichaPacienteComponent
];

const DIRECTIVES = [
  DynamicComponentDirective,
  ResizableItem,
  FormControlExceptionDirective,
  DynamicTableFieldComponentDirective
];

const MODALS = [
  CadastroPacienteComponent,
  CensurarComponent,
  SolicitarCadastroClinicaComponent,
  AjudeMelhorarComponent,
  PesquisarGrupoComponent,
  PesquisarClinicaComponent,
  EditarMedicoComponent,
  MesclarClinicasComponent,
  ConfirmarMesclagemClinicasComponent,
  AntecedentesInformacoesComponent,
  ConfirmarPendenciaComponent,
  ConsultarInteligenciaArtificialComponent,
  CriarPromptComponent,
  AdicionarContaInstitucionalComponent,
  EditarContaInstitucionalComponent,
  AdicionarStaffComponent,
  AdicionarResidenteComponent,
  VisualizarContaInstitucionalComponent,
  ConfirmarStaffComponent,
  ExportarFichaComponent,
  TermoConsentimentoComponent,
  TermoConsentimentoStatusComponent,
  InteracoesMedicamentosasComponent,
  RelatorioDadosMedicosComponent,
  FiltrosAvancadosComponent,
  FiltroDinamicoComponent,
  CriarNovaPerguntaComponent,
  CriarNovoConteudoRelacionadoComponent,
  ImageCropperComponent,
  SelecionarClinicaAtualComponent,
  SaibaMaisNiveisComponent,
  SaibaTempoCampanhaComponent,
  DataDiagnosticoComponent,
  PreenchimentoAutomaticoFichaComponent,
  DetalheLaudoComponent,
  AdicionarOutraNeoplasiaComponent,
  ...SMART_DATA_MODALS,
  ...MAMA_MODALS,
  ...PROSTATA_MODALS,
  ...PULMAO_NSCLC_MODALS,
  ...PULMAO_SCLC_MODALS,
  ...COLLORETAL_COLON_MODALS,
  ...COLLORETAL_RETO_MODALS,
  ...COLLORETAL_CANAL_ANAL_MODALS,
];

const PIPES = [
  IdadePipe,
  SafeHtmlPipe,
  TelefonePipe,
  GeneroPipe
];

@NgModule({
  declarations: [COMPONENTS, MODALS, DIRECTIVES, PIPES],
  imports: [
    IonicModule,
    BrMaskerModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    NgxEchartsModule,
    KtdGridModule,
    VirtualScrollerModule,
    AngularEditorModule,
    ImageCropperModule,
    PdfViewerModule
  ],
  exports: [COMPONENTS, MODALS, DIRECTIVES, PIPES]
})
export class ComponentsModule {

}
