import { Directive, ElementRef, OnInit } from '@angular/core';
import { CustomInputMoleculeComponent } from '../components/custom-input/custom-input.component';
import { inputErrors } from '../constants/input';

@Directive({
  selector: '[control]',
})
export class FormControlExceptionDirective implements OnInit {

  constructor(
    private element: ElementRef,
    private component: CustomInputMoleculeComponent
  ) {}

  ngOnInit() {
    const { control: formControl } = this.component;
    if (!formControl) { return; }
    formControl.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        const error = { hasError : true, messages: [] };
        Object.keys(formControl.errors).forEach((key) => {
          error.messages.push(inputErrors[key] ? inputErrors[key] : inputErrors.default);
        });
        this.component.setError(error);
      } else {
        this.component.setError(null);
      }
    });
  }
}
