import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Notification, SystemNotificationService } from '../../services/system-notification.service';
// import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-notification-carousel',
  templateUrl: './notification-carousel.component.html',
})
export class NotificationCarouselComponent implements OnInit, OnDestroy, AfterViewInit {

  notifications: Notification[];
  // @ViewChild('swiperRef') sliderRef: SwiperComponent;

  constructor(private systemNotificationService: SystemNotificationService) { }

  ngOnInit() {
    this.loadSystemNotifications();
  }

  ngOnDestroy() {
    // if (this.sliderRef?.swiperRef)
    //   this.sliderRef.swiperRef = undefined;
  }

  ngAfterViewInit() {
    let lastExecution = Date.now();
    const changeSlide = () => {
      // if (!this.sliderRef?.swiperRef) { return; }
      if (Date.now() - lastExecution > 6000) {
        lastExecution = Date.now();
        // this.sliderRef?.swiperRef?.slideNext();
        changeSlide();
      } else {
        setTimeout(changeSlide, 1000);
      }
    };
    changeSlide();
  }

  loadSystemNotifications() {
    this.notifications = this.systemNotificationService.get();
  }
}
