import { Component, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';

@Component({
  selector: 'app-input-re-rp',
  templateUrl: './input-re-rp.component.html',
})
export class InputReRpComponent extends AbstractModal implements InputWithModal {
  
  fichaPaciente: any;
  idRp: string;
  offsetWidth = 10;
  fixedHeight = '525px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  
  form: FormGroup = new FormGroup({
    re: new FormControl(0),
    rp: new FormControl(0)
  });
  
  
  constructor(public override injector: Injector) {
    super(injector);
  }
  
  ngOnInit() {
  }
  
  async handleEndEditClick() {
  }

}
