import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { FormControl } from '@angular/forms';
import { AjudeMelhorarService } from '../../services/ajude-melhorar.service';
import { toast } from '../../util/toast';

@Component({
  selector: 'app-ajude-melhorar',
  templateUrl: './ajude-melhorar.component.html',
})
export class AjudeMelhorarComponent extends AbstractModal {

  text: FormControl = new FormControl('');
  offsetWidth = 10;
  fixedHeight = '515px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  
  constructor(public readonly injector: Injector,
              private ajudeMelhorarService: AjudeMelhorarService) {
    super(injector);
  }
  
  async handleEditEnd() {
    await this.handleCLickClose();
  }
  
  handleAdicionarAnotacaoClick() {
    this.ajudeMelhorarService.bugReport(this.text.value).subscribe(async () => {
      await this.handleCLickClose();
      await toast('Sua mensagem foi encaminhada com sucesso, obrigado por compartilhar sua opinião');
    });
  }
}
