import { RangeConfig } from '../../../../../components/_base/abstract-input';

export const optionsResolver = (): RangeConfig => ({
  options: [
    {
      label: 'Mx',
      value: 'Mx',
      hint: 'Desconhecido'
    },
    {
      label: 'M0',
      value: 'M0',
    },
    {
      label: 'M1',
      value: 'M1',
    },
  ]
});
