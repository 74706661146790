export const CONDUTA_1 = [
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias',
  'Docetaxel 80 mg/m2 EV D1 21/21 dias',
 '',
  'Pertuzumabe 840 mg seguido de 420 mg	EV D1	21/21 dias',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV	D1 21/21 dias',
  'Paclitaxel	175 mg/m2	EV D1	21/21 dias',
 '',
  'TDM1	3,6 mg/kg	EV D1	21/21 dias',
 '',
  'TDxD	5,4 mg/kg	EV D1	21/21 dias',
 '',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias',
  'Vinorelbina 25 mg/m2	EV D1	semanal',
 '',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV	D1 21/21 dias',
  'Capecitabina 1250 mg/m2 12/12h	VO D1-D14	21/21 dias',
 '',
  'Lapatinibe 250 mg/m2	VO D1-D21 21/21 dias',
  'Capecitabina 1000 mg/m2 12/12h	VO D1-D14	21/21 dias',
 '',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg	EV D1 21/21 dias',
  'Lapatinibe 1250 mg/m2 VO	D1-D2 21/21 dias',
];

export const CONDUTA_2 = [
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias',
  'Docetaxel 80 mg/m2 EV D1 21/21 dias',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias',
  'Paclitaxel 175 mg/m2 EV D1 21/21 dias',
  '',
  'TDM1	3,6 mg/kg	EV D1 21/21 dias',
  '',
  'TDxD	5,4 mg/kg	EV D1 21/21 dias',
  '',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias',
  'Vinorelbina 25 mg/m2 EV D1 semanal',
  '',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias',
  'Capecitabina 1250 mg/m2 12/12h VO D1-D14 21/21 dias',
  '',
  'Lapatinibe 1250 mg/m2 VO D1-D21 21/21 dias',
  'Capecitabina 1000 mg/m2 12/12h VO D1-D14 21/21 dias',
  '',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias',
  'Lapatinibe 1250 mg/m2 VO	D1-D21 21/21 dias',
];

export const CONDUTA_3 = [
  'Doxorrubicina 60 mg/m2	EV D1 21/21 dias',
  'Doxorrubicina lipossomal	50 mg/m2 EV	D1 28/28 dias',
  'Paclitaxel	80 mg/m2 EV	D1 semanal',
  'Paclitaxel	175 mg/m2	EV D1 21/21 dias',
  'Capecitabina 1250 mg/m2 12/12h	VO D1-D14	21/21 dias',
  'Gencitabina 1000 mg/m2 EV D1, D8 e D15	28/28 dias',
  'Vinorelbina 25 mg/m2 EV D1 semanal',
  'Eribulina 1,4 mg/m2 EV D1 e D8	21/21 dias',
  'Docetaxel 80 mg/m2	EV D1 21/21 dias',
  'Carboplatina AUC 6	EV D1 21/21 dias'
];

export const CONDUTA_4 = [
  'Doxorrubicina 60 mg/m2	EV D1 21/21 dias',
  'Doxorrubicina lipossomal	50 mg/m2 EV	D1 28/28 dias',
  'Paclitaxel	80 mg/m2 EV	D1 semanal',
  'Paclitaxel	175 mg/m2	EV D1 21/21 dias',
  'Capecitabina 1250 mg/m2 12/12h	VO D1-D14	21/21 dias',
  'Gencitabina 1000 mg/m2	EV D1, D8 e D15	28/28 dias',
  'Vinorelbina 25 mg/m2 EV	D1 semanal',
  'Eribulina 1,4 mg/m2 EV D1 e D8	21/21 dias',
  'Docetaxel 80 mg/m2	EV D1 21/21 dias',
  'Carboplatina AUC 6	EV D1 21/21 dias'
];

export const CONDUTA_5 = [
  '(continuação de regime na neoadjuvância)',
  'Pertuzumabe 420 mg EV D1 21/21 dias C7-C17',
  'Trastuzumabe 6 mg/kg EV D1 21/21 dias C7-C17',
  '',
  '(continuação de regime na neoadjuvância)',
  'Trastuzumabe 6 mg/kg EV D1 21/21 dias C7-C17',
  '',
  'Doxorrubicina 60 mg/m2	EV D1 14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1 14/14 dias 4 ciclos',
  'seguido de',
  'Paclitaxel 80 mg/m2 EV D1 semanal 12 semanas',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C17',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C17',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  'Docetaxel 80 mg/m2	EV D1 21/21 dias C1-C6',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C17',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  'Paclitaxel 175 mg/m2 EV D1 21/21 dias C1-C6',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C17',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  'Docetaxel 75 mg/m2	EV D1 21/21 dias C1-C6',
  'Carboplatina AUC 6	EV D1 21/21 dias C1-C6',
  '',
  'Paclitaxel 80 mg/m2 EV D1 semanal 12 semanas',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  '',
  'Doxorrubicina 60 mg/m2	EV D1 14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2 EV D1 14/14 dias 4 ciclos',
  'seguido de',
  'Paclitaxel 80 mg/m2 EV D1 semanal 12 semanas',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  '',
  '(Se doença residual pós-neoadjuvância)',
  'TDM1 3,6 mg/kg EV D1 21/21 dias C7-C17',
];

export const CONDUTA_6 = [
  '(continuação de regime na neoadjuvância)',
  'Pertuzumabe 420 mg	EV D1	21/21 dias C7-C17',
  'Trastuzumabe	6 mg/kg	EV D1	21/21 dias C7-C17',
  '',
  '(continuação de regime na neoadjuvância)',
  'Trastuzumabe	6 mg/kg	EV D1	21/21 dias C7-C17',
  '',
  'Doxorrubicina 60 mg/m2	EV D1	14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1	14/14 dias 4 ciclos',
  'seguido de',
  'Paclitaxel	80 mg/m2 EV	D1 semanal 12 semanas',
  'Pertuzumabe 840 mg seguido de 420 mg	EV D1	21/21 dias C1-C17',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV	D1 21/21 dias	C1-C17',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg	EV D1 21/21 dias C1-C17',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  'Docetaxel 80 mg/m2	EV D1 21/21 dias C1-C6',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C17',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  'Paclitaxel	175 mg/m2	EV D1 21/21 dias C1-C6',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C17',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  'Docetaxel 75 mg/m2	EV D1 21/21 dias C1-C6',
  'Carboplatina AUC 6	EV D1 21/21 dias C1-C6',
  '',
  'Paclitaxel 80 mg/m2 EV D1 semanal 12 semanas',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C17',
  '',
  'Doxorrubicina 60 mg/m2 EV D1 14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1 14/14 dias 4 ciclos',
  'seguido de',
  'Paclitaxel	80 mg/m2 EV D1 semanal	12 semanas',
  'Trastuzumabe	8 mg/kg seguido de 6 mg/kg	EV	D1	21/21 dias	C1-C17',
  '',
  '(Se doença residual pós-neoadjuvância)',
  'TDM1	3,6 mg/kg	EV	D1	21/21 dias	C7-C17',
];

export const CONDUTA_7 = [
  'Doxorrubicina 60 mg/m2	EV D1 14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2 EV D1 14/14 dias 4 ciclos',
  'pegfilgrastima 6 mg SC	D2 14/14 dias 4 ciclos',
  'seguido de',
  'Paclitaxel 80 mg/m2 EV D1 semanal 12 semanas',
  '',
  'Docetaxel 75 mg/m2	EV D1 21/21 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1 21/21 dias 4 ciclos',
  '',
  'Pembrolizumabe 200 mg EV  D1 21/21 dias 4 ciclos',
  'Paclitaxel 80 mg/m2 EV D1, D8 e D15 21/21 dias 4 ciclos',
  'Carboplatina AUC 5	EV D1 21/21 dias 4 ciclos',
  '(continua na adjuvância)',
  '',
  'Capecitabina 1250 mg/m2 12/12h	VO D1-D14 21/21 dias',
  '',
  'Docetaxel 75 mg/m2 EV D1 21/21 dias 4-6 ciclos',
  'Carboplatina AUC 5	EV D1 21/21 dias 4-6 ciclos',
  '',
  'Carboplatina AUC 5	EV D1 21/21 dias 6 ciclos',
  'Paclitaxel	175 mg/m2	EV D1 21/21 dias 6 ciclos',
  '',
  '(continuação de regime na neoadjuvância)',
  'Pembrolizumabe	200 mg/m2	EV D1 21/21 dias C9-C17',
];

export const CONDUTA_8 = [
  'Doxorrubicina 60 mg/m2	EV D1 14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2 EV D1 14/14 dias 4 ciclos',
  'pegfilgrastima 6 mg SC	D2 14/14 dias 4 ciclos',
  'seguido de',
  'Paclitaxel 80 mg/m2 EV D1 semanal 12 semanas',
  '',
  'Docetaxel 75 mg/m2 EV D1 21/21 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1 21/21 dias 4 ciclos',
  '',
  'Capecitabina 1250 mg/m2 12/12h	VO D1-D14 21/21 dias',
  '',
  'Docetaxel 75 mg/m2 EV D1 21/21 dias 4-6 ciclos',
  'Carboplatina AUC 5	EV D1 21/21 dias 4-6 ciclos',
  '',
  'Carboplatina AUC 5	EV D1 21/21 dias 6 ciclos',
  'Paclitaxel	175 mg/m2	EV D1 21/21 dias 6 ciclos',
];

export const CONDUTA_9 = [
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU',
  'Tamoxifeno  20 mg VO ao dia OU	+	trastuzumabe 6 mg/kg EV 21/21 dias	+	pertuzumabe 420 mg EV 21/21 dias',
  'Exemestano 25 mg VO ao dia		por 17 ciclos		por 17 ciclos',
  'por 5-10 anos',
  '',
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU',
  'Tamoxifeno  20 mg VO ao dia OU	+	trastuzumabe 6 mg/kg EV 21/21 dias',
  'Exemestano 25 mg VO ao dia		por 17 ciclos',
  'por 5-10 anos',
];

export const CONDUTA_10 = [
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU por 5-10 anos',
  'Exemestano 25 mg VO ao dia',
  '',
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU	+	Abemaciclibe',
  'Exemestano 25 mg VO ao dia	por 2 anos',
  'por 5-10 anos',
  'Obs.: 1-3 linfonodos + ou T ≥ 5 cm ou G3 ou Ki-67 ≥ 20%',
];

export const CONDUTA_11 = [
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Abemaciclibe 150 mg 1 cp 12/12h OU Anastrozol 1 mg VO ao dia OU',
  'Ribociclibe 200 mg 3 cps ao dia D1 a D21 a cada 28 dias OU	+	Letrozol 2,5 mg VO ao dia OU',
  'Palbociclibe 125 mg 1 cp VO ao dia D1 a D21 a cada 28 dias OU Fulvestranto 500 mg IM D1, D15 e D28 e a cada 28 dias após OU',
  '',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU',
  'Everolimo + Fulvestranto 500 mg IM D1, D15 e D28 e a cada 28 dias após OU',
  'Tamoxifeno 20 mg VO ao dia OU',
  'Exemestano 25 mg VO ao dia OU',
  '',
  'Anastrozol 1 mg VO ao dia',
  '',
  'Letrozol 2,5 mg VO ao dia',
  '',
  'Fulvestranto 500 mg IM D1, D15 e D28 e a cada 28 dias após',
  '',
  'Tamoxifeno 20 mg VO ao dia',
  '',
  'Exemestano 25 mg VO ao dia',
  '',
  'Goserelina 10,8 mg SC 3/3 meses',
  '',
  'Abemaciclibe 150 mg 1 cp 12/12h',
];

export const CONDUTA_12 = [
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU',
  'Fulvestranto 500 mg IM D1, D15 e D28 e a cada 28 dias após OU + trastuzumabe 6 mg/kg EV 21/21 dias',
  'Tamoxifeno 20 mg VO ao dia OU por 17 ciclos',
  'Exemestano 25 mg VO ao dia',
  '',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU	+	lapatinibe 250 mg 5 cps ao dia',
  'Exemestano 25 mg VO ao dia',
  '',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU	+	lapatinibe 250 mg 5 cps ao dia + trastuzumabe 6 mg/kg EV 21/21 dias',
  'Exemestano 25 mg VO ao dia por 17 ciclos',
];

export const CONDUTA_13 = [
  'Doxorrubicina 60 mg/m2	EV D1	14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1	14/14 dias 4 ciclos',
  'pegfilgrastima 6 mg SC	D2 14/14 dias	4 ciclos',
  'Seguido de',
  'Paclitaxel	80 mg/m2 EV	D1 semanal 12 semanas',
  '',
  'Docetaxel 75 mg/m2	EV D1	21/21 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1	21/21 dias 4 ciclos',
  '',
  'Capecitabina 1250 mg/m2 12/12h	VO D1-D14	21/21 dias',
  '',
  'Docetaxel 75 mg/m2	EV D1	21/21 dias 4-6 ciclos',
  'Carboplatina AUC 5	EV D1 21/21 dias 4-6 ciclos',
  '',
  'Carboplatina AUC 5	EV D1 21/21 dias 6 ciclos',
  'Paclitaxel	175 mg/m2	EV D1	21/21 dias 6 ciclos',
];

export const CONDUTA_14 = [
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C6',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C6',
  'Docetaxel 80 mg/m2 EV D1 21/21 dias C1-C6',
  '(continua na adjuvância)',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C6',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C6',
  'Paclitaxel 175 mg/m2 EV D1 21/21 dias C1-C6',
  '(continua na adjuvância)',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C6',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C6',
  'Docetaxel 75 mg/m2 EV D1 21/21 dias C1-C6',
  'Carboplatina AUC 6 EV D1 21/21 dias C1-C6',
  '(continua na adjuvância)'
];

export const CONDUTA_15 = [
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C6',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C6',
  'Docetaxel 80 mg/m2 EV D1 21/21 dias C1-C6',
  '(continua na adjuvância)',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C6',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C6',
  'Paclitaxel 175 mg/m2 EV D1 21/21 dias C1-C6',
  '(continua na adjuvância)',
  '',
  'Pertuzumabe 840 mg seguido de 420 mg EV D1 21/21 dias C1-C6',
  'Trastuzumabe 8 mg/kg seguido de 6 mg/kg EV D1 21/21 dias C1-C6',
  'Docetaxel 75 mg/m2 EV D1 21/21 dias C1-C6',
  'Carboplatina AUC 6 EV D1 21/21 dias C1-C6',
  '(continua na adjuvância)'
];

export const CONDUTA_16 = [
  'Doxorrubicina 60 mg/m2	EV D1 14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1 14/14 dias 4 ciclos',
  'pegfilgrastima 6 mg SC	D2 14/14 dias 4 ciclos',
  'Seguido de',
  'Paclitaxel	80 mg/m2 EV	D1 semanal 12 semanas',
  '',
  'Docetaxel 75 mg/m2	EV D1	21/21 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2	EV D1	21/21 dias 4 ciclos',
  '',
  'Pembrolizumabe	200 mg EV D1 21/21 dias C1-C4',
  'Paclitaxel	80 mg/m2 EV D1, D8 e D15 21/21 dias	C1-C4',
  'Carboplatina AUC 5	EV D1 21/21 dias C1-C4',
  'Seguido de',
  'Doxorrubicina 60 mg/m2	EV D1	21/21 dias C1-C4',
  'Ciclofosfamida 600 mg/m2 EV D1 21/21 dias C1-C4',
  'Pembrolizumabe 200 mg EV D1 21/21 dias C5-C8',
  '(continua na adjuvância)',
  '',
  'Capecitabina 1250 mg/m2 12/12h VO D1-D14 21/21 dias',
  '',
  'Docetaxel 75 mg/m2 EV D1 21/21 dias 4-6 ciclos',
  'Carboplatina AUC 5 EV D1 21/21 dias 4-6 ciclos',
  '',
  'Carboplatina AUC 5 EV D1 21/21 dias 6 ciclos',
  'Paclitaxel 175 mg/m2 EV D1 21/21 dias 6 ciclos',
  '',
  'Doxorrubicina 60 mg/m2 EV D1 14/14 dias 4 ciclos',
  'Ciclofosfamida 600 mg/m2 EV D1 14/14 dias 4 ciclos',
  'pegfilgrastima 6 mg SC D2 14/14 dias 4 ciclos',
  'Seguido de',
  'Paclitaxel 80 mg/m2 EV D1 semanal 12 semanas',
  'Carboplatina AUC 1.5 EV D1 semanal 12 semanas',
];

export const CONDUTA_17 = [
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU',
  'Tamoxifeno  20 mg VO ao dia OU	+	trastuzumabe 6 mg/kg EV 21/21 dias + pertuzumabe 420 mg EV 21/21 dias',
  'Exemestano 25 mg VO ao dia OU por 6-8 ciclos por 6-8 ciclos',
  'Fulvestranto 500 mg IM D1, D15 e D28 e a cada 28 dias após',
  '',
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU',
  'Tamoxifeno  20 mg VO ao dia OU	+	trastuzumabe 6 mg/kg EV 21/21 dias',
  'Exemestano 25 mg VO ao dia OU por 6-8 ciclos',
  'Fulvestranto 500 mg IM D1, D15 e D28 e a cada 28 dias após',
];

export const CONDUTA_18 = [
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU',
  'Tamoxifeno 20 mg VO ao dia OU',
  'Exemestano 25 mg VO ao dia OU',
  'Fulvestranto 500 mg IM D1, D15 e D28 e a cada 28 dias após OU',
  '',
  '(se pré-menopausada acrescentar goserelina 10,8 mg SC 3/3 meses)',
  'Anastrozol 1 mg VO ao dia OU',
  'Letrozol 2,5 mg VO ao dia OU',
  'Tamoxifeno 20 mg VO ao dia OU',
  'Exemestano 25 mg VO ao dia OU',
  'Fulvestranto 500 mg IM D1, D15 e D28 e a cada 28 dias após',
];

export const CONDUTA_BRCA = [
  'Olaparibe 300 mg	VO 12/12h	28/28 dias paliativo e adjuvancia',
  'Talozaparibe	1 mg VO 12/12h 28/28 dias',
];

export const CONDUTA_PIK3CA = [
  'Alpelisibe 300 mg VO D1-D28 + fulvestranto 500 mg D1, D15 e após D28 a cada 28 dias'
];

export const CONDUTA_CPS_PDL1 = [
  'Pembro 400 mg EV D1 42/42 dias + paclitaxel 90 mg/m2 EV D1, D8 e D15 28/28 dias',
  'Pembro 400 mg EV D1 42/42 dias + gencitabina 1000 mg/m2 EV D1, D8 + carboplatina AUC 2 EV 21/21 dias',
];

export const CONDUTA_ = [
  'Larotrectinibe 100 mg VO D1-D28 28/28 dias'
];


