import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { FormControl, FormGroup } from '@angular/forms';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';
import {
  inputValueResolver,
  verificarVigilanciaAtiva
} from '../../../../../app/modules/client/modulos-cancer/prostata/util';
import { Tip } from '../../../../components/button-tip-selector/button-tip-selector.component';
import { optionsResolver } from './util';

@Component({
  selector: 'app-input-n',
  templateUrl: './input-n.component.html',
})
export class InputNComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  offsetWidth = 10;
  idN: string;
  idIntervencaoN: string;
  fixedHeight = '455px';
  vigilanciaAtiva: boolean;
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  tipoVigilanciaAtiva: FormControl = new FormControl('vigilancia-ativa');
  form: FormGroup = new FormGroup({
    n: new FormControl('Nx'),
    intervencaoN: new FormControl('Nx'),
  });

  vigilanciaAtivaTips: Tip[] = [
    {
      name: 'Vigilância ativa',
      value: 'vigilancia-ativa'
    },
    {
      name: 'Intervenção',
      value: 'intervencao'
    }
  ];

  nRangeOptions: RangeConfig = optionsResolver();

  constructor(public override injector: Injector,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }

  ngOnInit() {
    const { n, intervencaoN, idN, idIntervencaoN } = inputValueResolver('n', this.ficha?.itens);
    this.idN = idN;
    this.idIntervencaoN = idIntervencaoN;
    let formValues = {};
    if (n !== undefined) formValues = Object.assign({}, formValues, { n });
    if (intervencaoN !== undefined) formValues = Object.assign({}, formValues, { intervencaoN });
    this.form.patchValue(formValues);

    const vigilanciaAtiva = verificarVigilanciaAtiva(this.ficha);
    if (vigilanciaAtiva) {
      this.setHeight(525);
      this.vigilanciaAtiva = true;
    }
  }

  handleTipoVigilanciaAtivaChange(event: any) {
    this.tipoVigilanciaAtiva.setValue(event.value);
  }

  async handleResetValuesClick() {
    const alert = await this.alertHelper.create({
      title: 'Redefinir valores',
      text: 'Tem certeza que deseja zerar os valores para este estadiamento?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          const items: ItemFicha[] = [];
          if (this.form.get('n').value && this.idN) items.push({ chave: 'n', valor: null, id: this.idN });
          if (this.form.get('intervencaoN').value && this.idIntervencaoN) items.push({ chave: 'intervencaoN', valor: null, id: this.idIntervencaoN });
          setTimeout(async () => {
            await this.modalController.dismiss(items);
          }, 100);
        }
      }
    });
    await alert.present();
  }

  async handleEndEditClick() {
    const items: ItemFicha[] = [];
    const newItemN: ItemFicha = { chave: 'n', valor: this.form.get('n').value };
    if (this.idN) newItemN.id = this.idN;
    items.push(newItemN);
    if (this.vigilanciaAtiva) {
      const newItemIntervencao: ItemFicha = { chave: 'intervencaoN', valor: this.form.get('intervencaoN').value };
      if (this.idIntervencaoN) newItemIntervencao.id = this.idIntervencaoN;
      items.push(newItemIntervencao);
    }
    await this.modalController.dismiss(items);
  }
}
