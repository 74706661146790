import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import { FormGroup, FormControl } from '@angular/forms';
import { ResponsiveService } from '../../../../services/responsive.service';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';
import { ItemFicha } from '../../../../models/ficha.model';

@Component({
	selector: 'app-input-tipo-tumoral',
	templateUrl: './input-tipo-tumoral.component.html',
})
export class InputTipoTumoralComponent extends AbstractModal implements InputWithModal {
	
	fichaPaciente: any;
	id: string;
	offsetWidth = 10;
	fixedHeight = '400px';
	fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
	
	
	form: FormGroup = new FormGroup({
		tipoTumoral: new FormControl('')
	});
	
	// TODO: Buscar esses valores de algum lugar fixo...
	tiposTumoralOptions = [
		'Luminal A',
		'Luminal B',
		'Triplo negativo',
		'HER - 2',
		'Desconhecido',
	];
	
	constructor(public override injector: Injector,
	            public responsiveService: ResponsiveService) {
		super(injector);
	}
	
	ngOnInit() {
		const {tipoTumoral, id} = inputValueResolver('tipoTumoral', this.fichaPaciente?.itens);
		this.id = id;
		if (tipoTumoral !== undefined) this.form.patchValue({tipoTumoral});
	}
	
	async handleEndEditClick() {
		if (this.form.get('tipoTumoral').pristine) {
			await this.modalController.dismiss({confirm: false});
		} else {
			const newItem: ItemFicha = {
				chave: 'tipoTumoral',
				valor: this.form.get('tipoTumoral').value
			};
			if (this.id) newItem.id = this.id;
			const items: ItemFicha[] = [newItem];
			await this.modalController.dismiss(items);
		}
	}
	
}
