import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../components/_base/abstract-modal';
import { ModuloService } from '../../services/modulo.service';
import { IMenuModuloConfig } from '../../constants/modulos-cancer';
import { FilterConfig, FilterAvancado } from '../../interfaces/filter-config';
import CustomModalHelper from '../../helper/custom-modal-helper';
import { FiltroDinamicoComponent } from '../filtro-dinamico/filtro-dinamico.component';
import { FormControl, FormGroup } from '@angular/forms';
import { FichaService } from '../../services/ficha.service';
import CustomAlertHelper from '../../helper/custom-alert-helper';
import { BASE_FILTER_CONFIG } from './util';
import { CondutaBase } from '../../models/conduta.model';
import { fromStrTo2DigitDate } from '../../util/utils';

@Component({
  selector: 'app-filtros-avancados',
  templateUrl: './filtros-avancados.component.html',
})
export class FiltrosAvancadosComponent extends AbstractModal {

  offsetWidth = 100;
  offsetHeight = 100;
  moduloAtual: IMenuModuloConfig;
  groupedFiltersConfig: Array<FilterConfig[]>;
  filtersConfigMapping: { [key: string]: FilterConfig };
  form: FormGroup = new FormGroup({
    condutas: new FormControl([])
  });
  filtersConfig: FilterConfig[];
  hasSomeAppliedFilter: boolean;
  baseFilterConfig = BASE_FILTER_CONFIG;

  constructor(public readonly injector: Injector,
              private moduloService: ModuloService,
              private modalHelper: CustomModalHelper,
              private fichaService: FichaService,
              private alertHelper: CustomAlertHelper) {
    super(injector);
    moduloService.$moduloAtual.subscribe((modulo) => {
      this.moduloAtual = modulo;
      this.buildFilterFields();
    });
  }

  async buildFilterFields() {
    const filtersConfig = await this.getFiltersConfig();
    this.filtersConfigMapping = {};
    filtersConfig?.concat(this.baseFilterConfig)
      .forEach(config => this.filtersConfigMapping[config.nome] = config);
    this.groupedFiltersConfig = this.groupFilterConfigByCategoria(filtersConfig);
    this.filtersConfig = filtersConfig;
    this.buildFormGroup();
  }

  buildFormGroup() {
    if (!this.moduloAtual?.name) { return; }
    const formValues = this.fichaService.getFiltrosAvancadosFormValues(this.moduloAtual.name);
    if (formValues?.condutas?.length) this.form.get('condutas').setValue(formValues.condutas);
    this.filtersConfig?.concat(this.baseFilterConfig)
      .forEach((config) => {
        const fieldValue = (formValues && formValues[config.nome]) ? formValues[config.nome] : null;
        if (fieldValue) this.hasSomeAppliedFilter = true;
        this.form.addControl(config.nome, new FormControl(fieldValue));
    });
  }

  async getFiltersConfig(): Promise<FilterConfig[]> {
    const modulo = this.moduloAtual?.name;
    if (!modulo) { return; }
    const { FILTERS_CONFIG } = await import(`../../modals/modulo-${modulo}/ficha-paciente`);
    return FILTERS_CONFIG;
  }

  groupFilterConfigByCategoria(filtersConfig: FilterConfig[]): Array<FilterConfig[]> {
    const categorias = {};
    filtersConfig?.forEach((config) => {
      const categoria = config.categoria || '';
      if (!categorias[categoria]) categorias[categoria] = [];
      categorias[categoria].push(config);
    });
    return Object.values(categorias);
  }

  getSelectedValuesAsText(config: FilterConfig) {
    return () => {
      const formControlAccessor = config.nome.includes('.') ? [config.nome] : config.nome;
      const formControl = this.form.get(formControlAccessor);
      if (!formControl) { return; }
      const fieldValue = formControl.value;
      if (config.tipo === 'range-data') {
        if (fieldValue?.min && fieldValue?.max) {
          return `${fromStrTo2DigitDate(fieldValue.min)} á ${fromStrTo2DigitDate(fieldValue.max)}`;
        }
      } else if (Array.isArray(fieldValue)) {
        return fieldValue.join(', ');
      }
    };
  }

  async handleInputClick(config: FilterConfig) {
    if (config.tipo === 'modal') {
      await this.openCustomFilterModal(config);
    } else {
      await this.openDefaultFilterModal(config);
    }
  }

  async openDefaultFilterModal(config: FilterConfig) {
    const formControlAccessor = config.nome.includes('.') ? [config.nome] : config.nome;
    const formControl = this.form.get(formControlAccessor);
    const value = formControl.value;
    const modal = await this.modalHelper.create({
      component: FiltroDinamicoComponent,
      componentProps: { config, value },
      handler: ({data}) => {
        if (data?.confirm) {
          const formControlAccessor = config.nome.includes('.') ? [config.nome] : config.nome;
          this.form.get(formControlAccessor).setValue(data.payload?.filtros);
        }
      }
    });
    await modal.present();
  }

  async openCustomFilterModal(config: FilterConfig) {
    const modulo = this.moduloAtual.name;
    const component = config.nome;
    const CustomFilterModalModule = await import(`../../modals/modulo-${modulo}/filtros/${component}/${component}.component`);
    const componentName = Object.keys(CustomFilterModalModule)[0];
    const CustomFilterModalComponent = CustomFilterModalModule[componentName];

    const modal = await this.modalHelper.create({
      component: CustomFilterModalComponent,
      componentProps: {}
    });

    await modal.present();
  }

  async handleAplicarFiltrosClick() {
    const itemsFilters = this.buildFiltrosAvancados();
    this.fichaService.setFiltrosAvancados(this.moduloAtual.name, itemsFilters);
    this.fichaService.setFiltrosAvancadosFormValues(this.moduloAtual.name, this.form.getRawValue());
    await this.handleCLickClose();
  }

  async handleResetFiltersClick() {
    const alert = await this.alertHelper.create({
      title: 'Resetar filtros',
      text: 'Tem certeza que deseja zerar os filtros avançados para este módulo?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          this.fichaService.clearFiltrosAvancados(this.moduloAtual.name);
          this.fichaService.clearFiltrosAvancadosFormValues(this.moduloAtual.name);
          await this.handleCLickClose();
        }
      }
    });
    await alert.present();
  }

  buildFiltrosAvancados() {
    const formFilters = this.form.getRawValue();
    const filters: FilterAvancado[] = [];
    const condutas: any[] = this.form.get('condutas').value;
    Object.keys(formFilters).filter(filterKey => filterKey !== 'condutas')
      .forEach(filterKey => {
        const values = formFilters[filterKey];
        if (!values || (Array.isArray(values) && !values.length)) { return; }
        if (this.filtersConfigMapping[filterKey]?.compor) {
          const result = this.filtersConfigMapping[filterKey].compor(values);
          filters.push(result);
        } else {
          filters.push({
            tipo: this.filtersConfigMapping[filterKey].tipoFiltro,
            detalhe: {
              chave: filterKey,
              valores: values
            }
          });
        }
    });

    condutas.forEach((conduta) => {
      filters.push({
        tipo: 'conduta',
        detalhe: {
          valores: [conduta]
        }
      });
    });

    return filters;
  }

  async handleAdicionarCondutaClick(conduta?: CondutaBase<any>) {
    const modulo = this.moduloAtual.name;
    const { AdicionarCondutaComponent } = await import(`../../modals/modulo-${modulo}/ficha-paciente/adicionar-conduta/adicionar-conduta.component`);
    const modal = await this.modalHelper.create({
      component: AdicionarCondutaComponent,
      componentProps: {
        filtro: true,
        editarFiltro: !!conduta,
        conduta
      },
      handler: ({ data }) => {
        if (data?.conduta) {
          const control = this.form.get('condutas');
          const condutas = control.value;
          if (conduta) {
            const condutaIndex = condutas.indexOf(conduta);
            condutas.splice(condutaIndex, 1, data.conduta);
          } else {
            condutas.push(data.conduta);
          }
          control.setValue(condutas);
        }

        if (data?.payload?.excluirConduta) {
          const condutasControl = this.form.get('condutas');
          const condutas: any[] = condutasControl.value;
          const index = condutas.indexOf(conduta);
          condutas.splice(index, 1);
          condutasControl.setValue(condutas);
        }
      }
    });
    await modal.present();
  }
}
