import { AdicionarCondutaComponent } from './adicionar-conduta/adicionar-conduta.component';
import {
  HISTOPATOLOGICO_CHAVE, HISTOPATOLOGICO_OPTIONS,
  InputHistopatologicoComponent
} from './input-histopatologico/input-histopatologico.component';
import { InputMComponent } from './input-m/input-m.component';
import { InputNComponent } from './input-n/input-n.component';
import { InputPainelNgsComponent } from './input-painel-ngs/input-painel-ngs.component';
import { InputPdl1Component } from './input-pdl1/input-pdl1.component';
import { InputTComponent } from './input-t/input-t.component';
import { FilterConfig } from '../../../interfaces/filter-config';
import { rangeConfigToSelectionOption } from '../../../util/utils';
import { optionsResolver as tOptionsResolver } from './input-t/util';
import { optionsResolver as nOptionsResolver } from './input-n/util';
import { optionsResolver as mOptionsResolver } from './input-m/util';

export const MODALS = [
  AdicionarCondutaComponent,
  InputHistopatologicoComponent,
  InputMComponent,
  InputNComponent,
  InputPainelNgsComponent,
  InputPdl1Component,
  InputTComponent
];

export const FILTERS_CONFIG: FilterConfig[] = [
  {
    categoria: 'Dados de exames',
    titulo: 'Histopatológico',
    nome: HISTOPATOLOGICO_CHAVE,
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: HISTOPATOLOGICO_OPTIONS
  },
  {
    categoria: 'Dados de exames',
    titulo: 'T',
    alturaDinamica: false,
    nome: 't',
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: [].concat(
      rangeConfigToSelectionOption(tOptionsResolver('c')),
      rangeConfigToSelectionOption(tOptionsResolver('p')),
      rangeConfigToSelectionOption(tOptionsResolver('yp')),
    )
  },
  {
    categoria: 'Dados de exames',
    titulo: 'N',
    nome: 'n',
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: [].concat(
      rangeConfigToSelectionOption(nOptionsResolver('c')),
      rangeConfigToSelectionOption(nOptionsResolver('p')),
      rangeConfigToSelectionOption(nOptionsResolver('yp')),
    )
  },
  {
    categoria: 'Dados de exames',
    titulo: 'M',
    nome: 'm',
    largura: '4',
    tipoFiltro: 'item',
    tipo: 'selecao-multipla',
    opcoes: rangeConfigToSelectionOption(mOptionsResolver())
  },
];

