import { Component } from '@angular/core';
import { AbstractButton } from '../../_base/abstract-button';

@Component({
  selector: 'app-brand-button',
  templateUrl: './brand-button.component.html'
})
export class BrandButtonComponent extends AbstractButton {

  constructor() {
    super();
  }

}
