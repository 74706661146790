import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

export interface Tab {
  id: string;
  name: string;
  active?: boolean;
}

@Component({
  selector: 'app-tabs-component',
  templateUrl: './tabs-component.component.html',
  styleUrls: ['./tabs-component.component.scss']
})
export class TabsComponentComponent implements OnInit, OnChanges {

  currentTab: string;
  @Input() tabs: Tab[];
  @Input() textCustomClasses: string;
  @Output() onTabClick: EventEmitter<Tab> = new EventEmitter<Tab>();

  constructor() { }

  ngOnInit() {
    this.currentTab = this.tabs.find(tab => tab.active).name;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentTab = changes.tabs.currentValue.find(tab => tab.active).name;
  }

  handleTabClick(tab: Tab) {
    this.currentTab = tab.name;
    this.onTabClick.emit(tab);
  }
}
