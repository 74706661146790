import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/pulmao-sclc/util';
import { ThreeWayToggleConfig } from '../../../../components/_base/abstract-input';

@Component({
  selector: 'app-input-tmb',
  templateUrl: './input-tmb.component.html',
})
export class InputTmbComponent extends AbstractModal {

  id: string;
  ficha: Ficha;
  offsetWidth = 10;
  fixedHeight = '175px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    tmb: new FormControl(false)
  });

  threeWayToggleConfig: ThreeWayToggleConfig = {
    nonValueText: 'Não testado',
    negativeValueText: '<10',
    positiveValueText: '> ou = 10',
  };

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { tmb, id } = inputValueResolver('tmb', this.ficha?.itens);
    this.id = id;
    this.form.patchValue({ tmb });
  }

  async handleEndEditClick() {
    if (this.form.get('tmb').pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: 'tmb',
        valor: this.form.get('tmb').value
      };
      if (this.id) newItem.id = this.id;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
