import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMenuModuloConfig, menuModulosConfig } from "../constants/modulos-cancer";
import { DominioService } from './dominio.service';
import { loading } from '../util/loading';

const ONCOMAX_MODULO_ATUAL = '_oncomax_actual_module';
const ONCOMAX_MODULO_LIST = '_oncomax_modulo_list';

@Injectable({
  providedIn: 'root'
})
export class ModuloService {

  isMobile: boolean;
  $moduloAtual: BehaviorSubject<IMenuModuloConfig>;

  constructor(private dominioService: DominioService) {
  }

  async init(isMobile) {
    this.isMobile = isMobile;
    let moduloAtualStr = localStorage.getItem(ONCOMAX_MODULO_ATUAL);
    if (moduloAtualStr) {
      let moduloAtualJson: IMenuModuloConfig = JSON.parse(moduloAtualStr);
      this.$moduloAtual = new BehaviorSubject<IMenuModuloConfig>(moduloAtualJson);
    } else {
      this.$moduloAtual = new BehaviorSubject<IMenuModuloConfig>(null);
    }
  }

  setModuloOnStorage(modulo: IMenuModuloConfig) {
    if(!modulo || this.isMobile) return;
    localStorage.setItem(ONCOMAX_MODULO_ATUAL, JSON.stringify(modulo));
  }

  setModuloAtual(modulo: string) {
    if (!modulo) return;
    const moduloFromLocalStorage = this.getModuloOnLocalStorageByName(modulo);
    if (moduloFromLocalStorage) {
      this.setModuloOnStorage(moduloFromLocalStorage);
      this.$moduloAtual.next(moduloFromLocalStorage);
    } else {
      const selectedModule: IMenuModuloConfig = this.findModuloByName(modulo, menuModulosConfig);
      loading(this.dominioService.getModuleByName(selectedModule.name).subscribe((modulo) => {
        selectedModule.id = modulo._id;
        this.addModuloOnLocalStorageList(selectedModule);
        this.setModuloOnStorage(selectedModule);
        this.$moduloAtual.next(selectedModule);
      }));
    }
  }

  findModuloByName(moduloName: string, modules: IMenuModuloConfig[]) {
    for (let i = 0; i < modules.length; i++) {
      if (modules[i].name === moduloName) {
        return modules[i];
      }
      if (modules[i].modules) {
        let found = this.findModuloByName(moduloName, modules[i].modules);
        if (found) {
          return found;
        }
      }
    }
  }

  addModuloOnLocalStorageList(modulo: IMenuModuloConfig) {
    let modulos: any = localStorage.getItem(ONCOMAX_MODULO_LIST);
    if (!modulos) {
      modulos = [modulo];
    } else {
      modulos = JSON.parse(modulos);
      if (!modulos.some(m => m.name === modulo.name)) {
        modulos.push(modulo);
      }
    }
    return localStorage.setItem(ONCOMAX_MODULO_LIST, JSON.stringify(modulos));
  }

  getModuloOnLocalStorageByName(moduloName: string) {
    let modulos: any = localStorage.getItem(ONCOMAX_MODULO_LIST);
    if (modulos) {
      modulos = JSON.parse(modulos);
      const modulo = modulos.find(modulo => modulo.name === moduloName);
      if (modulo) return modulo;
    }
  }

  clearModuloAtual() {
    localStorage.removeItem(ONCOMAX_MODULO_ATUAL);
    this.$moduloAtual.next(null);
  }

  getModuloListOnLocalStorage() {
    const modulos = localStorage.getItem(ONCOMAX_MODULO_LIST);
    if (modulos) return JSON.parse(modulos);
    return [];
  }
}
