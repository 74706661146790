import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DominioService extends AbstractService<any> {

  constructor(public http: HttpClient) {
    super('dominio', http);
  }

  getUfs() {
    return this.http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .pipe(map((res) => this.mapDomainToOption(res, 'sigla', 'sigla')));
  }

  getUfsClinicas() {
    return super.get('clinica/ufs').pipe(map((res) => this.mapArrayStringToOption(res)));
  }

  getEstados() {
    return this.http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .pipe(map((res) => this.mapDomainToOption(res, 'nome', 'sigla')));
  }

  getCidadesByUf(uf) {
      return this.http.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
        .pipe(map((res: any[]) => res.sort((a, b) => a.nome.localeCompare(b.nome))))
        .pipe(map((res) => this.mapDomainToOption(res, 'nome', 'nome')));
  }

  getTipoAtendimento(): Observable<any[]> {
    return super.get('tipo-atendimento').pipe(map((res => this.mapDomainToOption(res,'nome', '_id'))));
  }

  getConvenios(): Observable<any[]> {
    return super.get('convenio').pipe(map((res => this.mapDomainToOption(res,'nome', 'nome'))));
  }

  getModuleByName(moduleName: string): Observable<any> {
    return super.get(`modulo/${moduleName}`);
  }

  mapDomainToOption(response, labelField, valueField) {
    return response.map(r => ({
      nome: r[labelField],
      valor: r[valueField]
    }));
  }

  mapArrayStringToOption(response) {
    return response.map(r => ({ nome: r, valor: r }));
  }
}

