import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { SelectionOption } from '../../../../components/_base/abstract-input';

@Component({
  selector: 'app-testes-preditivos',
  templateUrl: './testes-preditivos.component.html',
})
export class FiltrosTestesPreditivosComponent extends AbstractModal {

  fixedHeight = '525px';
  fixedWidth = '420px';
  oncotypeOptions: SelectionOption[] = [
    {
      nome: '≤10',
      valor: {
        min: 0,
        max: 10
      }
    },
    {
      nome: '11 a 25',
      valor: {
        min: 11,
        max: 25
      }
    },
    {
      nome: '≥ 26',
      valor: {
        min: 26,
        max: 100
      }
    }
  ];

  mamaprintOptions = [
    'Baixo risco',
    'Alto risco',
  ];

  constructor(public override injector: Injector) {
    super(injector);
  }

  get oncotypeOptionsArr(): string[] {
    return this.oncotypeOptions.map(option => option.nome);
  }

}
