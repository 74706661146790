import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-estado-vazio',
  templateUrl: './estado-vazio.component.html',
})
export class EstadoVazioComponent {

  @Input() src: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() iconCustomClasses: string;

  constructor() { }

}
