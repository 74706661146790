import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { SelectionOption } from '../../../../components/_base/abstract-input';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { inputValueResolver } from '../../../../../app/modules/client/modulos-cancer/mama/util';

export const SITIO_CHAVE = 'sitio';
export const SITIO_OPTIONS: SelectionOption[] = [
  {
    nome: 'Mama pré',
    valor: 'Mama pré',
  },
  {
    nome: 'Mama pós',
    valor: 'Mama pós',
  },
  {
    nome: 'Axila',
    valor: 'Axila',
  },
  {
    nome: 'Osso',
    valor: 'Osso',
  },
  {
    nome: 'Linfonodo',
    valor: 'Linfonodo',
  },
  {
    nome: 'Pele',
    valor: 'Pele',
  },
  {
    nome: 'Pulmão',
    valor: 'Pulmão',
  },
  {
    nome: 'Fígado',
    valor: 'Fígado',
  },
  {
    nome: 'Outros',
    valor: 'Outros',
  },
];

@Component({
  selector: 'app-input-sitio-imuno',
  templateUrl: './input-sitio-imuno.component.html',
})
export class InputSitioImunoComponent extends AbstractModal implements OnInit {

  ficha: Ficha;
  id: string;
  index: number;
  offsetWidth = 10;
  analitico: boolean;
  data: Date;
  fixedHeight = '560px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;

  form: FormGroup = new FormGroup({
    sitio: new FormControl('Mama pré')
  });

  sitioOptions = SITIO_OPTIONS;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    const { sitio, id, data, analitico } = inputValueResolver(SITIO_CHAVE, this.ficha?.itens, this.index);
    this.id = id;
    this.data = data;
    this.analitico = analitico;
    if (sitio !== undefined) this.form.patchValue({ sitio });
  }

  handleClearInputClick() {
    const items: ItemFicha[] = [
      { chave: 'sitio', valor: null, id: this.id },
    ];
    setTimeout(async () => {
      await this.modalController.dismiss(items);
    }, 100);
  }

  async handleEndEditClick() {
    if (this.form.get(SITIO_CHAVE).pristine) {
      await this.modalController.dismiss({ confirm: false });
    } else {
      const newItem: ItemFicha = {
        chave: SITIO_CHAVE,
        valor: this.form.get(SITIO_CHAVE).value
      };
      if (this.id) newItem.id = this.id;
      if (this.data) newItem.data = this.data;
      const items: ItemFicha[] = [newItem];
      await this.modalController.dismiss(items);
    }
  }
}
