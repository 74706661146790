import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ButtonComponent {
  id?: string;
  text: string;
  iconStart: string;
  iconEnd: string;
  rounded: boolean;
  minHeight: boolean;
  customStyle: any;
  customClasses: string;
  customTextClasses: string;
  iconPosition: string;
  clicked: EventEmitter<any>;
  border: boolean;
  disabled: boolean;
  outlined: boolean;
  badge: boolean;
}

@Component({
  template: ''
})
export abstract class AbstractButton implements ButtonComponent {

  @Input() id: string;
  @Input() text: string;
  @Input() iconStart: string;
  @Input() iconEnd: string;
  @Input() rounded: boolean;
  @Input() minHeight: boolean;
  @Input() customClasses: string;
  @Input() customStyle: any;
  @Input() customTextClasses: string;
  @Input() border: boolean = true;
  @Input() iconPosition = 'end';
  @Input() disabled: boolean = false;
  @Input() outlined: boolean = false;
  @Input() badge: boolean = false;

  @Output()
  clicked: EventEmitter<any> = new EventEmitter<any>();

  handleButtonClick() {
    if (this.disabled) {return;}
    this.clicked.emit();
  }
}
