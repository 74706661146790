import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';
import { inputValueResolver, } from '../../../../../app/modules/client/modulos-cancer/pulmao-nsclc/util';
import { toast } from '../../../../util/toast';
import { optionsResolver } from './util';

@Component({
  selector: 'app-input-m',
  templateUrl: './input-m.component.html',
})
export class InputMComponent extends AbstractModal implements InputWithModal {

  id: string;
  ficha: Ficha;
  $digesting: boolean = false;
  offsetWidth = 10;
  fixedHeight = '475px';
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    m: new FormControl('Mx'),
  });

  mRangeOptions: RangeConfig = optionsResolver();
  mRangeSubOptions: RangeConfig;

  constructor(public override injector: Injector,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }

  ngOnInit() {
    const { m, id } = inputValueResolver('m', this.ficha?.itens);
    this.id = id;
    this.loadFormValues(m);
    this.form.get('m').valueChanges.subscribe((value) => {
      this.mRangeSubOptions = null;
      this.$digesting = true;
      const selectedOption = this.mRangeOptions.options.find(o => String(o.value).startsWith(value));
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.mRangeSubOptions = {
            options: selectedOption.options
          };
        });
      } else {
        this.mRangeSubOptions = null;
      }
      this.$digesting = false;
    });
  }

  loadFormValues(mValue: string) {
    if (![null, undefined].includes(mValue)) {
      const tOption = this.mRangeOptions.options.find((option) => mValue.startsWith(option.value));
      this.form.patchValue({ m: tOption.value });
      if (tOption?.options?.length) {
        this.mRangeSubOptions = {
          options: tOption.options
        };
        this.form.addControl(tOption.value, new FormControl(mValue));
      }
    }
  }

  async handleResetValuesClick() {
    const alert = await this.alertHelper.create({
      title: 'Redefinir valores',
      text: 'Tem certeza que deseja zerar os valores para este estadiamento?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          const items: ItemFicha[] = [];
          if (this.form.get('m').value && this.id) items.push({ chave: 'm', valor: null, id: this.id });
          setTimeout(async () => {
            await this.modalController.dismiss(items);
          }, 100);
        }
      }
    });
    await alert.present();
  }

  async handleEndEditClick() {
    const items: ItemFicha[] = [];
    let valor;
    if (this.mRangeSubOptions?.options?.length) {
      const fieldName = this.form.get('m').value;
      valor = this.form.get(fieldName).value;
      if (valor === 'M1') {
        await toast('É necessário escolher a subclassificação');
        return;
      }
    } else {
      valor = this.form.get('m').value;
    }
    const newItem: ItemFicha = { chave: 'm', valor };
    if (this.id) newItem.id = this.id;
    items.push(newItem);
    await this.modalController.dismiss(items);
  }
}
