import { Component, Injector } from '@angular/core';
import { AbstractModal } from '../../../../components/_base/abstract-modal';
import { RangeConfig } from '../../../../components/_base/abstract-input';
import { Ficha, ItemFicha } from '../../../../models/ficha.model';
import { FormControl, FormGroup } from '@angular/forms';
import { InputWithModal } from '../../../../interfaces/input-with-modal';
import CustomAlertHelper from '../../../../helper/custom-alert-helper';
import { Tip } from '../../../../components/button-tip-selector/button-tip-selector.component';
import {
  inputValueResolver,
  verificarVigilanciaAtiva
} from '../../../../../app/modules/client/modulos-cancer/prostata/util';
import { optionsResolver } from './util';

@Component({
  selector: 'app-input-m',
  templateUrl: './input-m.component.html',
})
export class InputMComponent extends AbstractModal implements InputWithModal {

  ficha: Ficha;
  idM: string;
  idIntervencaoM:  string;
  $digesting: boolean = false;
  vigilanciaAtiva: boolean;
  offsetWidth = 10;
  fixedHeight = '465px';
  tipoVigilanciaAtiva: FormControl = new FormControl('vigilancia-ativa');
  fixedWidth = !this.responsiveService.isMobilePlatform ? '420px' : null;
  form: FormGroup = new FormGroup({
    m: new FormControl('Mx'),
    intervencaoM: new FormControl('Mx'),
  });

  vigilanciaAtivaTips: Tip[] = [
    {
      name: 'Vigilância ativa',
      value: 'vigilancia-ativa'
    },
    {
      name: 'Intervenção',
      value: 'intervencao'
    }
  ];

  mRangeOptions: RangeConfig = optionsResolver();
  mRangeSubOptions: RangeConfig;
  intervencaoMRangeSubOptions: RangeConfig;

  constructor(public override injector: Injector,
              private alertHelper: CustomAlertHelper) {
    super(injector);
  }

  ngOnInit() {
    const { m, intervencaoM, idM, idIntervencaoM } = inputValueResolver('m', this.ficha?.itens);
    this.idM = idM;
    this.idIntervencaoM = idIntervencaoM;
    const vigilanciaAtiva = verificarVigilanciaAtiva(this.ficha);
    if (vigilanciaAtiva) {
      this.setHeight(525);
      this.vigilanciaAtiva = true;
    }
    this.loadFormValues(m, intervencaoM);
    this.form.get('m').valueChanges.subscribe((value) => {
      this.mRangeSubOptions = null;
      this.$digesting = true;
      const selectedOption = this.mRangeOptions.options.find(o => String(o.value).startsWith(value));
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.mRangeSubOptions = {
            options: selectedOption.options
          };
        });
      } else {
        this.mRangeSubOptions = null;
      }
      this.$digesting = false;
    });

    this.form.get('intervencaoM').valueChanges.subscribe((value) => {
      this.intervencaoMRangeSubOptions = null;
      this.$digesting = true;
      const selectedOption = this.mRangeOptions.options.find(o => String(o.value).startsWith(value));
      if (selectedOption && selectedOption.options) {
        setTimeout(() => {
          this.form.addControl(value, new FormControl(selectedOption.options[0].value));
          this.intervencaoMRangeSubOptions = {
            options: selectedOption.options
          };
        });
      } else {
        this.intervencaoMRangeSubOptions = null;
      }
      this.$digesting = false;
    });
  }

  loadFormValues(mValue: string, intervencaoMValue: string) {
    if (![null, undefined].includes(mValue)) {
      const tOption = this.mRangeOptions.options.find((option) => mValue.startsWith(option.value));
      this.form.patchValue({ m: tOption.value });
      if (tOption?.options?.length) {
        this.mRangeSubOptions = {
          options: tOption.options
        };
        this.form.addControl(tOption.value, new FormControl(mValue));
      }
    }

    if (![null, undefined].includes(intervencaoMValue)) {
      const intervencaoMOptions = this.mRangeOptions.options.find((option) => intervencaoMValue.startsWith(option.value));
      this.form.patchValue({ intervencaoM: intervencaoMOptions.value });
      if (intervencaoMOptions?.options?.length) {
        this.intervencaoMRangeSubOptions = {
          options: intervencaoMOptions.options
        };
        this.form.addControl(intervencaoMOptions.value, new FormControl(intervencaoMValue));
      }
    }
  }

  handleTipoVigilanciaAtivaChange(event: any) {
    this.tipoVigilanciaAtiva.setValue(event.value);
  }

  async handleResetValuesClick() {
    const alert = await this.alertHelper.create({
      title: 'Redefinir valores',
      text: 'Tem certeza que deseja zerar os valores para este estadiamento?',
      okText: 'Sim',
      cancelText: 'Não',
      confirmButtonType: 'danger',
      cancelButtonType: 'neutral',
      handler: async ({data}) => {
        if (data.confirm) {
          const items: ItemFicha[] = [];
          if (this.form.get('m').value && this.idM) items.push({ chave: 'm', valor: null, id: this.idM });
          if (this.form.get('intervencaoM').value && this.idIntervencaoM) items.push({ chave: 'intervencaoM', valor: null, id: this.idIntervencaoM });
          setTimeout(async () => {
            await this.modalController.dismiss(items);
          }, 100);
        }
      }
    });
    await alert.present();
  }

  async handleEndEditClick() {
    const items: ItemFicha[] = [];
    let valor;
    if (this.vigilanciaAtiva && !this.form.get('intervencaoM').pristine) {
      if (this.intervencaoMRangeSubOptions?.options?.length) {
        const fieldName = this.form.get('intervencaoM').value;
        valor = this.form.get(fieldName).value;
      } else {
        valor = this.form.get('intervencaoM').value;
      }
      const newItem: ItemFicha = { chave: 'intervencaoM', valor };
      if (this.idIntervencaoM) newItem.id = this.idIntervencaoM;
      items.push(newItem);
    }
    if (this.mRangeSubOptions?.options?.length) {
      const fieldName = this.form.get('m').value;
      valor = this.form.get(fieldName).value;
    } else {
      valor = this.form.get('m').value;
    }
    const newItem: ItemFicha = { chave: 'm', valor };
    if (this.idM) newItem.id = this.idM;
    items.push(newItem);
    await this.modalController.dismiss(items);
  }
}
