import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
})
export class StatusBadgeComponent {

  @Input() value: { cor?: string; texto?: string } = {};
  @Input() customTextClasses = '';

  constructor() { }
}
